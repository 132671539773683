import React from 'react';
import styled from 'styled-components';

import Modal, { ModalHeader, ModalScrollable } from 'components/modal';
import Icon from 'components/icon';

const HeaderValue = styled(ModalHeader)`
  font-size: 1.25em;
`;
const BodyWrapper = styled.div`
  max-width: 600px;
`;
const Details = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  align-items: center;

  > div {
    display: flex;
    flex-flow: column;
    word-break: break-word;
    font-size: 1.13em;
    gap: 10px;
  }
`;
const ActionRowWrapper = styled.div`
  display: flex;
  flex-flow: column;
  word-break: break-word;
  font-size: 1.13em;
  border-top: 1px solid #e6e6e6;

  > div {
    padding: 10px 5px;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      background-color: #e6e6e6;
    }
  }
`;
export default ({ onClose, onSelect, userContext }) => {
  return (
    <Modal open height="auto" width="auto" onClose={onClose}>
      <HeaderValue>For your security, let's make sure it's you</HeaderValue>
      <ModalScrollable>
        <BodyWrapper>
          <Details>
            <Icon name="fa-solid fa-mobile-signal" size={80} />
            <div>
              <div>We'll send you a one-time code.</div>
              <div>
                By continuing, you agree that we can send you the code using the method you select.
              </div>
              <div>
                Availability may be affected by your mobile carrier's coverage area. Your mobile
                carrier's message and data rates may apply.
              </div>
            </div>
          </Details>
          <div>Select one</div>
          <ActionRowWrapper>
            <div onClick={() => onSelect('sms')}>
              <span>
                Send to phone{' '}
                {userContext?.challenge?.phonePostfix
                  ? `ending in ${userContext?.challenge?.phonePostfix}`
                  : 'on file'}
              </span>
              <Icon name="fa-solid fa-chevron-right" />
            </div>
            <div onClick={() => onSelect('email')}>
              <span>Send to email on file</span>
              <Icon name="fa-solid fa-chevron-right" />
            </div>
          </ActionRowWrapper>
        </BodyWrapper>
      </ModalScrollable>
    </Modal>
  );
};
