import React from 'react';
import styled from 'styled-components';
import { renderPrettyDate } from 'utils/dateHelpers';
import Avatar from 'components/avatar';
import ShadowRender from 'components/shadow-render';

const NarrowChatWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: ${({ gap }) => gap};
  margin-bottom: ${({ margin }) => margin};
  width: 100%;
  .actor-info {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 10px;
    .time {
      font-size: 12px;
      color: #71818f;
    }
  }
  .message-content {
    margin-left: 30px;
    font-size: 14px;
  }
`;

const Narrow = ({
  content = 'Please review this application',
  createdAt,
  sharedBy,
  userName,
  portalUser
}) => {
  const timeString = createdAt ? renderPrettyDate(createdAt) : undefined;
  const shareByName = sharedBy?.name || userName || 'Someone';

  return (
    <NarrowChatWrapper gap={!portalUser ? '5px' : '0px'} margin={!portalUser ? '10px' : '0px'}>
      <div className="actor-info">
        <Avatar
          size={22}
          imageUrl={sharedBy?.image}
          duogram={sharedBy?.duogram || String.duogram(shareByName)}
        />
        <strong>{shareByName}</strong>
        {timeString ? <i className="time">{timeString}</i> : <></>}
      </div>
      <div className="message-content">
        <ShadowRender html={content} />
      </div>
    </NarrowChatWrapper>
  );
};

export default Narrow;
