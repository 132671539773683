import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import Input from 'components/input';
import FormGroup from 'components/form-group';
import Button from 'components/button';
import useAudience from 'hooks/useAudience';
import { UserContext, useNavigateWithContext, CompanyContext } from 'state';
import { REGISTER } from 'dal/user';
import AlternateVerification from '../login-challenge/alternate-verification';

const Wrapper = styled.main``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 935px;
  margin: 0 auto;
  align-items: center;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
`;

const Description = styled.p`
  font-weight: 200;
  text-align: center;
`;

const InputWrapper = styled.div`
  max-width: 400px;
  margin-top: 50px;
  label {
    justify-content: center;
    font-size: 1em !important;
  }
  input {
    text-align: center;
    min-width: 280px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
`;

const Actions = styled.div`
  margin-top: 70px;
  display: flex;
  @media (max-width: 500px) {
    position: fixed;
    margin-top: 0px;
    bottom: 0;
    button {
      width: 150px;
    }
    padding: 20px;
  }
`;
const DEBUG = styled.div`
  color: #9c653e;
  background: #ffc;
  border: 1px dotted #fc6;
  padding: 10px;
`;
export default ({ onBack, registerData }) => {
  const [audience] = useAudience();
  const { userContext, setUserContext } = React.useContext(UserContext);
  const [verifyCode, setVerifyCode] = React.useState('');
  const [verifyType, setVerifyType] = React.useState('sms');
  const [inProgress, setInProgress] = React.useState(false);
  const { companyContext } = React.useContext(CompanyContext);
  const { t } = useTranslation();

  let navigate = useNavigateWithContext();
  const createAccount = () => {
    if (inProgress) return;
    setInProgress(true);
    REGISTER({
      ...registerData,
      phoneCode: verifyCode,
      companyId: companyContext?.id,
      audience: audience?.uuid
    })
      .then((response) => {
        setUserContext(response);
        navigate(audience.paths.home);
        setInProgress(false);
      })
      .catch((err) => {
        setInProgress(false);
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          {verifyType === 'email' ? (
            <Trans i18nKey="phoneVerification2fa.title">WE JUST SENT YOU A MESSAGE</Trans>
          ) : (
            <>{t('phoneVerefication.title')}</>
          )}
        </Title>
        <Description>
          {verifyType === 'email' ? (
            'PLEASE CHECK YOUR EMAIL FOR THE CODE WE JUST SENT YOU AND ENTER IT BELOW'
          ) : (
            <>
              {t('phoneVerefication.description-1')} <strong>{registerData.phone}</strong> <br />
              {t('phoneVerefication.description-2')}
            </>
          )}
        </Description>
        <InputWrapper>
          <FormGroup label={t('phoneVerefication.verificaionCode')}>
            <Input value={verifyCode} onChange={(e) => setVerifyCode(e.target.value)} />
          </FormGroup>
          <AlternateVerification
            type="validation"
            userContext={userContext}
            phone={registerData.phone}
            email={registerData.email}
            verifyType={verifyType}
            setVerifyType={setVerifyType}
          />
          {!!userContext?.challenge?.debug?.challenge && (
            <DEBUG>
              {t('phoneVerefication.debug-code')}:
              <br />
              {userContext?.challenge?.debug?.challenge}
            </DEBUG>
          )}
        </InputWrapper>
        <Actions>
          <Button width={200} height={40} variant="secondary" onClick={onBack}>
            {t('buttonsActions.backButton')}
          </Button>
          <Button
            width={200}
            height={40}
            marginLeft={20}
            variant="primary"
            onClick={() => createAccount()}
            disabled={!verifyCode || inProgress}>
            {t('phoneVerefication.verify-button')}
          </Button>
        </Actions>
      </Container>
    </Wrapper>
  );
};
