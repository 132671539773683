import React from 'react';
import Tooltip from 'components/tooltip';
import Icon, { IconShape } from 'components/icon';
import { TableSection } from 'components/section';
import {
  toMoneyString,
  getScreeningStatus,
  getMatchingScoreRange,
  VERIFICATION_STATUS,
  APPLICANT_TYPE,
  baseScreeningInfo
} from 'pages/approver/share/detail/application-view/helpers';
import VerificationIdentity from './verification-identity';
import CreditRating from '../credit-rating';
import VerificationIncome from './verification-income';

export default ({ className, application, handleActions, applicationConfig }) => {
  const { columns, totalRow } = React.useMemo(() => {
    let cols = [
      {
        label: '',
        uuid: 'iconCol',
        className: 'iconCol',
        render: (data) => {
          if (data.closedByApplicant) {
            return (
              <Tooltip
                text={
                  <div>
                    <div>Cancelled by applicant</div>
                    {data.cancelReason && <div>Reason:{data.cancelReason}</div>}
                  </div>
                }>
                <Icon iconOnly={true} name="fa-solid fa-ban" size={15} />
              </Tooltip>
            );
          }
          return APPLICANT_TYPE[data.type] ? (
            <Icon {...(APPLICANT_TYPE[data.type] || {})} size={15} />
          ) : undefined;
        }
      },
      {
        label: 'Name',
        uuid: 'name',
        className: 'capitalize clickable',
        onClick: (e, rowData) => handleActions({ id: 'applicant-view', data: rowData })
      },
      {
        label: 'Status',
        uuid: 'status',
        className: 'capitalize',
        render: (rowData) => (
          <div className={rowData.applicantCompleted ? 'highlight' : ''}>{rowData.appStatus}</div>
        )
      },
      {
        uuid: 'qualityScore',
        label: 'Score',
        className: 'capitalize centerIconCol',
        render: (rowData) => {
          return (
            <Tooltip
              text={
                !rowData.qualityScore ? (
                  <div>Quality score not yet available</div>
                ) : (
                  <div>
                    {rowData.qualityScore?.points || 0} / {rowData.qualityScore?.totalPoints || 0} ={' '}
                    <strong>{rowData.qualityScore?.score}</strong>
                  </div>
                )
              }>
              <strong
                style={{ color: rowData.qualityScore?.color || '#ccc' }}
                onClick={() => handleActions({ id: 'applicant-quality', data: rowData })}>
                {rowData.qualityScore?.score || 0}
              </strong>
            </Tooltip>
          );
        }
      },
      {
        uuid: 'identity',
        label: 'ID',
        className: 'capitalize centerIconCol',
        render: (rowData) => {
          return <VerificationIdentity handleActions={handleActions} rowData={rowData} />;
        }
      },
      {
        uuid: 'credit',
        label: 'Credit',
        className: 'capitalize centerIconCol',
        render: (rowData) => {
          return <CreditRating creditRating={rowData?.creditRating} />;
        }
      },
      {
        uuid: 'criminal',
        label: 'Criminal',
        className: 'capitalize centerIconCol',
        render: (rowData) => {
          let { description, icon, stepStatus } = baseScreeningInfo({ type: 'criminal', rowData });

          //show screening button
          if (!stepStatus.done) {
            return (
              <Tooltip text={'Criminal check details are not available for this applicant'}>
                N/A
              </Tooltip>
            );
          }

          return <IconShape title={description} {...icon} />;
        }
      },
      {
        uuid: 'eviction',
        label: 'Eviction',
        className: 'capitalize centerIconCol',
        render: (rowData) => {
          let { description, icon, stepStatus } = baseScreeningInfo({ type: 'eviction', rowData });

          //show screening button
          if (!stepStatus.done) {
            return (
              <Tooltip text={'Eviction check details are not available for this applicant'}>
                N/A
              </Tooltip>
            );
          }

          return <IconShape title={description} {...icon} />;
        }
      },
      {
        uuid: 'income',
        label: 'Income (mo.)',
        className: 'capitalize centerIconCol',
        render: (rowData) => {
          return <VerificationIncome handleActions={handleActions} rowData={rowData} />;
        }
      }
    ];

    ['credit', 'criminal', 'eviction', 'identity', 'income', 'qualityScore'].forEach((key) => {
      if (applicationConfig?.disabledSections?.[key]) {
        cols = cols.filter((c) => c.uuid !== key);
      }
    });
    const totalRow = (application?.applicants || []).reduce(
      (final, rowData) => {
        final.applicantCompleted = final.applicantCompleted && rowData.applicantCompleted;
        final.identity = final.identity && getScreeningStatus('identity', rowData)?.success;
        let crimStats = getScreeningStatus('criminal', rowData);
        final.criminal = final.criminal && crimStats?.done && !crimStats.redFlags?.length;
        let evictStats = getScreeningStatus('eviction', rowData);
        final.eviction = final.eviction && evictStats?.done && !evictStats.redFlags?.length;

        if (rowData.qualityScore?.score) {
          final.qualityScoreCount = final.qualityScoreCount + 1;
          final.qualityScoreTotal = final.qualityScoreTotal + rowData.qualityScore?.score;
        }
        if (rowData.qualityScore?.scoreRanges) {
          final.scoreRanges = rowData.qualityScore?.scoreRanges;
        }
        return final;
      },
      {
        name: 'TOTAL',
        credit: application.financials?.avgCreditRating?.label || (
          <Icon title={'No credit score available'} {...VERIFICATION_STATUS.fail} />
        ),
        income: application.financials?.monthlyNetIncome ? (
          toMoneyString(application.financials?.monthlyNetIncome || 0, null, null, 0)
        ) : (
          <Icon title={'No income information available'} {...VERIFICATION_STATUS.fail} />
        ),
        applicantCompleted: true,
        identity: true,
        criminal: true,
        eviction: true,
        qualityScoreTotal: 0,
        qualityScoreCount: 0
      }
    );
    if (totalRow.qualityScoreCount > 0) {
      const avgScore = Math.round(totalRow.qualityScoreTotal / totalRow.qualityScoreCount);
      const qualityProfile = application?.applicants?.find((f) => f.qualityProfile)?.qualityProfile;
      const matchingRange = getMatchingScoreRange({
        score: avgScore,
        scoreRanges: qualityProfile.scoreRanges
      });
      totalRow.qualityScore = (
        <strong style={{ color: matchingRange?.color || '#ccc' }}>{avgScore}</strong>
      );
    }

    ['criminal', 'identity', 'eviction'].forEach((key) => {
      totalRow[key] = (
        <Icon
          title="Some screens are pending or have warnings"
          {...(totalRow[key] ? VERIFICATION_STATUS.pass : VERIFICATION_STATUS.fail)}
        />
      );
    });
    totalRow.status = totalRow.applicantCompleted ? (
      <div className={'highlight'}>Complete</div>
    ) : (
      <div>Incomplete</div>
    );

    return { columns: cols, totalRow: totalRow };
  }, [applicationConfig, application]);

  return (
    <TableSection
      id="application-members"
      className={className}
      handleActions={handleActions}
      data={application?.applicants}
      columns={columns}
      title="APPLICANTS"
      titleIcon="fa-duotone fa-address-card"
      totalRow={totalRow}
    />
  );
};
