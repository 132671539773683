import { POST, GET } from './utils';

// creates or loads application
export const GET_VERIFAST_URL = ({ userContext, applicationId, actionId, requestType }) =>
  GET({
    url: `verifast/${actionId ? 'action' : 'app'}/${actionId || applicationId || ''}${
      requestType ? `?requestType=${requestType}` : ''
    }`,
    token: userContext.accessToken.token
  });

// updates current client status of verifast flow
export const UPDATE_VERIFAST_STATUS = ({
  userContext,
  applicationId,
  actionId,
  verificationId,
  data
}) =>
  POST({
    url: `verifast/${actionId ? 'action' : 'app'}/${actionId || applicationId}/${verificationId}`,
    data,
    token: userContext.accessToken.token
  });

// fetches status
export const GET_VERIFAST_STATUS = ({ userContext, applicationId, actionId, data }) =>
  POST({
    url: `verifast/check/${actionId ? 'action' : 'app'}/${actionId || applicationId}`,
    data,
    token: userContext.accessToken.token
  });
