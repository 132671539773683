import React from 'react';
import styled, { css } from 'styled-components';
import { primary } from 'styles/colors';
import { darken } from 'polished';
import ErrorBoundary from 'components/errorBoundary';
import { Section } from 'components/section';
import BorderedBox from 'components/bordered-box';
import {
  toMoneyString,
  NO_RENT_REASONS,
  SCREENING_STEP_STATUSES_DONE
} from 'pages/approver/share/detail/application-view/helpers';
import { Card, CardDataGroup, CardDataDisplay, Address, Work, Criminal, Eviction } from './card';
import Summary from './summary';
import Questions from './questions';
import Section8 from './section8';
import Quality from './quality';

const SimpleList = styled.div`
  display: grid;
  ${({ gridSize }) => {
    if (gridSize) {
      return `grid-template-columns: ${[...Array(gridSize).keys()].map(() => '1fr').join(' ')};`;
    }
    return css`
      grid-template-columns: 1fr 1fr 1fr;
      @media screen and (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
      }
      @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
      }
    `;
  }}
  gap: 10px;
`;

const DataBox = styled(BorderedBox)`
  flex: 1;
  font-size: 14px;
  font-weight: normal;
`;
const NoIncomeProvidedDetails = styled(CardDataGroup)`
  margin-bottom: 0px;
  margin-top: 10px;
`;
const IDLinks = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
  font-size: 14px;
`;
const VerifiedIncomeBlock = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding: 9px 57px 7px 28px;
  border: solid 1px #c8d6df;
  background-color: #fff;
  display: flex;

  .incomeSection {
    flex: 1;
    > strong {
      color: #0d2a36;
      margin: 4px 38px 8px 0;
      font-size: 18px;
      font-weight: 900;
      display: block;
    }
  }
  .line {
    flex: 0;
    width: 1px;
    height: 96px;
    margin: 0 53px 0 51px;
    border: solid 1px rgba(0, 0, 0, 0.1);
  }
  .incomeRow {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    > strong {
      color: #000;
      font-size: 14px;
      margin: 0 0 4px;
    }
    .pseudoInput {
      align-self: end;
      margin-bottom: 6px;
    }
  }
`;

const EmergencyContactWrapper = styled(CardDataGroup)`
  gap: 10px;
  flex-wrap: wrap;
`;
const EmergencyContactBlock = styled.div`
  .clickable {
    color: ${({ theme }) => theme?.colors?.primary || primary};
    cursor: pointer;
    &:hover {
      color: ${({ theme }) => darken(0.05, theme?.colors?.primary || primary)};
    }
  }
`;

const DisplayAddress = ({ address, addressString, addresses = [] }) => {
  const repAddress = address || addresses.find((addr) => addr.current) || addresses[0];

  if (addressString && !repAddress) {
    const addressParts = addressString.split(',').map((z) => z.trim());
    return (
      <div>
        <div>{addressParts[0]}</div>
        {addressParts.length > 1 && <div>{addressParts.slice(1).join(', ')}</div>}
      </div>
    );
  }

  return !repAddress ? (
    ''
  ) : (
    <div>
      <div>{repAddress.address}</div>
      <div>
        {repAddress.city}, {repAddress.state} {repAddress.postal_code}
      </div>
    </div>
  );
};
let ID_TYPE_MAP = {
  dl: `Driver's License`,
  id: 'ID Card',
  pp: 'Passport',
  ppc: 'Passport Card',
  pr: 'Permanent Resident Card',
  default: 'Misc ID'
};
const IDInfo = ({ identityDetails, applicant, handleActions }) => {
  if (!identityDetails?.issuingAuthority && !identityDetails?.type) {
    return <></>;
  }

  const IDType = ID_TYPE_MAP[identityDetails.type] || ID_TYPE_MAP.default;
  const IDAuth = identityDetails.issuingAuthority;

  return (
    <IDLinks>
      <div>
        {IDType} / {IDAuth}
      </div>
    </IDLinks>
  );
};

const IncomeStats = ({ applicant }) => {
  // applicant is not far enough in progress for this module to appear
  if (!applicant?.bankSource || applicant.bankSource === 'none') return <></>;

  if (
    applicant?.noRentContribution &&
    !Number(applicant.reportedGrossIncome || 0) &&
    !Number(applicant.monthlyNetIncome || 0) &&
    !Number(applicant.currentBalance || 0) &&
    !Number(applicant.avgClosingBalance || 0)
  ) {
    return <></>;
  }

  return (
    <VerifiedIncomeBlock>
      <div className="incomeSection">
        <strong>Verified Income</strong>
        <div className="incomeRow">
          <strong>Monthly Income Before Taxes</strong>
          <strong>Avg Monthly Income</strong>
        </div>
        <div className="incomeRow">
          <span className="pseudoInput">
            {toMoneyString(Number(applicant.reportedGrossIncome || 0))}
          </span>
          <span className="pseudoInput">
            {toMoneyString(Number(applicant.monthlyNetIncome || 0))}
          </span>
        </div>
      </div>
      <div className="line" />
      <div className="incomeSection">
        <strong>Verified Balances</strong>
        <div className="incomeRow">
          <strong>Total Cash Available</strong>
          <strong>Avg Monthly Closing Balance</strong>
        </div>
        <div className="incomeRow">
          <span className="pseudoInput">
            {toMoneyString(Number(applicant.currentBalance || 0))}
          </span>
          <span className="pseudoInput">
            {toMoneyString(Number(applicant.avgClosingBalance || 0))}
          </span>
        </div>
      </div>
    </VerifiedIncomeBlock>
  );
};

export default ({ applicant, applicationConfig, handleActions, application }) => {
  return (
    <>
      <ErrorBoundary>
        <Summary
          location={application?.location}
          applicationConfig={applicationConfig}
          applicant={applicant}
          handleActions={handleActions}
        />
      </ErrorBoundary>
      {!applicationConfig?.disabledSections?.quality && (
        <Quality
          applicationConfig={applicationConfig}
          applicant={applicant}
          handleActions={handleActions}
        />
      )}
      {!applicationConfig?.disabledSections?.contactInfo && (
        <Section title="PERSONAL INFO" icon="fa-duotone fa-address-card" id="personal-info">
          <Card title={applicant.name}>
            <CardDataGroup grid={true}>
              <CardDataDisplay
                label="Mobile"
                description={
                  (applicant.phone?.find((p) => p.type === 'mobile') || applicant.phone?.[0])
                    ?.number
                }
              />
              <CardDataDisplay label="Email" description={applicant.email} />
              <CardDataDisplay label="Age" description={applicant.age} />
            </CardDataGroup>
            {!!applicant?.identityDetails && (
              <CardDataGroup>
                <CardDataDisplay
                  label="ID Type / Issuing Location"
                  description={
                    !applicant?.identityDetails?.issuingAuthority &&
                    !applicant?.identityDetails?.type ? (
                      ''
                    ) : (
                      <IDInfo
                        identityDetails={applicant?.identityDetails}
                        applicant={applicant}
                        handleActions={handleActions}
                      />
                    )
                  }
                />
                <CardDataDisplay
                  label="Name On Id"
                  description={applicant?.identityDetails?.name}
                />
                <CardDataDisplay
                  label="ID Address"
                  description={
                    applicant?.identityDetails?.address ? (
                      <DisplayAddress addressString={applicant?.identityDetails?.address} />
                    ) : undefined
                  }
                />
                <CardDataDisplay
                  label="Provided Address"
                  description={
                    applicant?.identityDetails?.address ? (
                      <DisplayAddress addresses={applicant.addresses} />
                    ) : undefined
                  }
                />
              </CardDataGroup>
            )}
            {!!applicant.emergencyContacts?.length && (
              <EmergencyContactWrapper>
                {applicant.emergencyContacts.map((ec, ix) => {
                  const contactName = `${ec.firstName} ${ec.lastName}`.trim() || 'N/A';
                  return (
                    <CardDataDisplay
                      key={ix}
                      label="Emergency Contact"
                      description={
                        <EmergencyContactBlock>
                          <div>{contactName}</div>
                          {ec.relationship && <div>Relationship: {ec.relationship}</div>}
                          {ec.email && (
                            <div>
                              Email: <span className="clickable">{ec.email}</span>
                            </div>
                          )}
                          {ec.phone?.map((phn, ix) => (
                            <div key={ix}>
                              Phone: <span className="clickable">{phn.number}</span>
                            </div>
                          ))}
                        </EmergencyContactBlock>
                      }
                    />
                  );
                })}
              </EmergencyContactWrapper>
            )}
          </Card>
        </Section>
      )}
      <Section title="HOUSING HISTORY" icon="fa-duotone fa-house" id="housing-history">
        <ErrorBoundary>
          {!applicant.addresses?.length ? (
            <DataBox>No housing history specified</DataBox>
          ) : (
            <SimpleList>
              {applicant.addresses?.map((a, i) => (
                <Address
                  key={i}
                  address={a}
                  applicant={applicant}
                  handleActions={handleActions}
                  applicationConfig={applicationConfig}
                />
              ))}
            </SimpleList>
          )}
        </ErrorBoundary>
      </Section>
      {!applicationConfig?.disabledSections?.questionnaire && (
        <ErrorBoundary>
          <Section
            title="ADDITIONAL QUESTIONS"
            icon="fa-duotone fa-message-question"
            id="additional-questions">
            {!applicant.questionnaire?.length ? (
              <DataBox>Questionnaire not completed</DataBox>
            ) : (
              <Questions questions={applicant.questionnaire} />
            )}
          </Section>
        </ErrorBoundary>
      )}
      <Section title="WORK HISTORY" icon="fa-duotone fa-briefcase" id="work-history">
        <ErrorBoundary>
          <IncomeStats applicant={applicant} handleActions={handleActions} />
          {!applicant.income?.length ? (
            <DataBox>
              {applicant?.noRentContribution
                ? 'Applicant stated they will not contribute to income'
                : 'No work history specified'}

              {(applicant.noRentReason?.length || applicant.noRentComments) && (
                <NoIncomeProvidedDetails
                  grid={true}
                  gridSize={
                    (!applicant.noRentReason?.length ? 0 : 1) + (!applicant.noRentComments ? 0 : 1)
                  }>
                  {applicant.noRentReason?.length && (
                    <CardDataDisplay
                      label="Provided Reasons"
                      description={applicant.noRentReason
                        .map((r) => NO_RENT_REASONS.find((z) => z.uuid === r)?.label || r)
                        .join(', ')}
                    />
                  )}
                  {applicant.noRentComments && (
                    <CardDataDisplay label="Comments" description={applicant.noRentComments} />
                  )}
                </NoIncomeProvidedDetails>
              )}
            </DataBox>
          ) : (
            <SimpleList>
              {applicant.income?.map((a, i) => (
                <Work
                  key={i}
                  applicant={applicant}
                  work={a}
                  handleActions={handleActions}
                  applicationConfig={applicationConfig}
                />
              ))}
            </SimpleList>
          )}
        </ErrorBoundary>
      </Section>
      {!applicationConfig?.disabledSections?.section8 && (
        <Section title="HOUSING SUPPORT" icon="fa-duotone fa-hand-holding-hand" id="section8">
          <ErrorBoundary>
            {!applicant.section8 ? (
              <DataBox>Section 8 information not specified</DataBox>
            ) : (
              <Section8 applicant={applicant} handleActions={handleActions} />
            )}
          </ErrorBoundary>
        </Section>
      )}
      {!applicationConfig?.disabledSections?.criminal && (
        <Section title="CRIMINAL HISTORY" icon="fa-duotone fa-gavel" id="criminal-history">
          <ErrorBoundary>
            {!SCREENING_STEP_STATUSES_DONE.includes(applicant.criminalStatus) ? (
              <DataBox>Criminal history not yet available</DataBox>
            ) : !applicant.criminalDetails?.length ? (
              <DataBox>No criminal history found</DataBox>
            ) : (
              <SimpleList>
                {applicant.criminalDetails?.map((a, i) => (
                  <Criminal key={i} detail={a} />
                ))}
              </SimpleList>
            )}
          </ErrorBoundary>
        </Section>
      )}
      {!applicationConfig?.disabledSections?.eviction && (
        <Section title="EVICTION HISTORY" icon="fa-duotone fa-door-open" id="eviction-history">
          <ErrorBoundary>
            {!SCREENING_STEP_STATUSES_DONE.includes(applicant.evictionStatus) ? (
              <DataBox>Eviction history not yet available</DataBox>
            ) : !applicant.evictionDetails?.length ? (
              <DataBox>No eviction history found</DataBox>
            ) : (
              <SimpleList>
                {applicant.evictionDetails?.map((a, i) => (
                  <Eviction key={i} detail={a} />
                ))}
              </SimpleList>
            )}
          </ErrorBoundary>
        </Section>
      )}
    </>
  );
};
