import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';
import { useAPIData } from 'dal/useAPIData';
import { useUiState, UserContext, useNavigateWithContext } from 'state';
import Option, { OptionsGroup } from 'components/option';
import { useOrgId } from 'hooks/useSessionVariables';
import ShareItem from './share-item';
import { useActions } from '../useActions';

const Wrapper = styled.main`
  height: 100%;
  flex: 1;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
  .select-language {
    margin-top: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  margin: 20px 0 15px;
  text-transform: uppercase;
  font-size: 1.5em;
`;

const GridOptions = styled(OptionsGroup)`
  display: flex;
  > div {
    width: 200px;
    .Option_label {
      font-size: 1.25em;
    }
  }
`;

const Grid = styled.div``;

export default () => {
  const orgId = useOrgId();
  const navigate = useNavigateWithContext();
  const { t } = useTranslation();
  const { userContext } = React.useContext(UserContext);
  const { handleActions, refreshKey } = useActions({});
  const [initialLoad, setInitialLoad] = React.useState(false);
  const { updateUiState } = useUiState();
  const isMobile = useMediaQuery({ maxWidth: 960 });

  // this loads all applications for the user
  const [shareList, listFetchInProgress] = useAPIData(
    'application.LIST_SHARES',
    { userContext, companyId: orgId },
    {
      disableEmptyArgs: true,
      reFetchWhen: [refreshKey],
      onSuccess: (data) => {
        !initialLoad && setInitialLoad(true);
      },
      onError: () => {
        !initialLoad && setInitialLoad(true);
      }
    }
  );

  React.useEffect(() => {
    updateUiState({ menuOpen: isMobile ? false : true });
  }, [isMobile]);

  if (!initialLoad) {
    return (
      <Wrapper>
        <Container>Loading...</Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {(shareList?.length || 0) === 0 && (
        <Container>
          <Title>{t('myShares.title')}</Title>
          <GridOptions className="center">
            <Option
              size={200}
              value="appCode"
              label={t('myShares.enterCode')}
              icon={{ name: 'fa-barcode fa-solid' }}
              onClick={() => navigate('shares/code')}
            />
          </GridOptions>
        </Container>
      )}
      {shareList?.length > 0 && (
        <Container>
          <Header>
            <Title>{t('myShares.title')}</Title>
          </Header>
          <Grid>
            {shareList
              ?.sort((a, b) => {
                let firstDate = new Date(a?.createdAt).getTime();
                let secondDate = new Date(b?.createdAt).getTime();
                if (!isNaN(firstDate) && !isNaN(secondDate)) {
                  return secondDate - firstDate;
                }
                if (isNaN(firstDate) && isNaN(secondDate)) {
                  return 0;
                }
                return isNaN(firstDate) ? -1 : 1;
              })
              ?.map((share, ix) => (
                <ShareItem key={ix} share={share} handleActions={handleActions} />
              ))}
          </Grid>
        </Container>
      )}
    </Wrapper>
  );
};
