import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';

const HeaderDetails = styled.div`
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 10px;
  .breadcrumbs {
    flex-grow: 0;
    flex-shrink: 0;
  }
  .title {
    line-height: initial;
    font-size: 36px;
    font-weight: 900;
    display: flex;
    gap: 10px;
    flex: 1;
  }
  .header-date {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    margin-top: 10px;
  }
  .breadcrumbs {
    margin-top: 8px;
    i {
      color: #002857;
      font-size: 20px;
    }
  }
  .paging {
    margin-bottom: 8px;
    > div {
      cursor: pointer;
    }
  }
  .crumbs {
    margin-bottom: 5px;
    color: rgba(255, 255, 255, 0.6);
    .active {
      color: #fff;
    }
  }
`;

const BackButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  background-color: #fff;
  border-radius: 50%;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row;
  gap: ${({ gap = 20 }) => gap}px;
`;

export default ({ applicant, application, handleActions, applicationConfig }) => {
  return (
    <HeaderDetails>
      <div>
        {applicant && (
          <Row gap={10} className="crumbs">
            <div>Applicants</div>
            {applicationConfig?.disabledSections?.includeLocationInformation !== false && (
              <>
                <div>/</div>
                <div>{application?.location?.name}</div>
              </>
            )}
            <div>/</div>
            <div className="active">{application?.applicationName} Household</div>
          </Row>
        )}
        <Row>
          <div className="breadcrumbs">
            <BackButton
              onClick={() => {
                handleActions({ id: applicant ? 'applicant-view' : 'all-shares', data: '' });
              }}>
              <Icon name="fa-solid fa-arrow-left" />
            </BackButton>
          </div>
          <div className="title">
            {applicant?.name ? (
              <div>{applicant.name}</div>
            ) : (
              <div>{application?.applicationName} Household</div>
            )}
          </div>
        </Row>
        {applicant?.started ? (
          <div className="header-date">
            <span>STARTED</span> {applicant?.started?.toLocaleDateString()} at{' '}
            {applicant?.started?.toLocaleTimeString()}
          </div>
        ) : (
          <div className="header-date">
            <span>CREATED</span> {application?.createdAt?.toLocaleDateString()} at{' '}
            {application?.createdAt?.toLocaleTimeString()}
          </div>
        )}
      </div>
    </HeaderDetails>
  );
};
