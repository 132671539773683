import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { AUDIENCES } from 'helpers/enums';
import { UserContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import Button from 'components/button';
import InputDate from 'components/input-date';
import Text from 'components/text';
import { FieldGroup, Title, SubTitle, BoxNote, ActionsRow, Loader } from './common';

const Wrapper = styled.div`
  ${({ isMobile }) =>
    !isMobile
      ? css`
          padding: 10px 20px 20px;
        `
      : ''}
`;
const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;

const currentDate = new Date();
export default ({ values: initialValues, action, isMobile, onSubmit, location }) => {
  const { userContext } = React.useContext(UserContext);
  const { t } = useTranslation();

  const applicantInfoSchema = yup.object().shape({
    tourDate: yup.date().required(`${t('form.validate.provideTourDate')}`),
    tourComments: yup.string().max(5000, `${t('form.validate.long')}`)
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      tourDate: action?.tourDate || initialValues?.tourDate || '',
      tourComments: action?.tourComments || initialValues?.tourComments || ''
    },
    validationSchema: applicantInfoSchema,
    onSubmit: (vals) => {
      onSubmit({ tourDate: vals.tourDate, tourComments: vals.tourComments });
    }
  });

  const [tourNotes, tourNotesInProgress, tourNotesFetched, tourFailedFetch] = useAPIData(
    'application.TOUR_NOTES',
    !location?.showingConfig?.tourInformationTemplateId
      ? undefined
      : {
          userContext,
          applicationId: action?._id,
          contextId: location?._id,
          bypass: true,
          audience: AUDIENCES.showing.uuid
        },
    {
      disableEmptyArgs: true,
      reFetchWhen: [action?._id, action?.locationId]
    }
  );

  const loadingNotes = location?.showingConfig?.tourInformationTemplateId
    ? !tourNotesFetched && !tourFailedFetch
    : false;

  if (loadingNotes) {
    return (
      <Wrapper isMobile={isMobile}>
        <Title>
          <Trans i18nKey="showing.detail.tour.title">Tour Information</Trans>
        </Title>
        <Loader />
      </Wrapper>
    );
  }

  return (
    <Wrapper isMobile={isMobile}>
      <Title>
        <Trans i18nKey="showing.detail.tour.title">Tour Information</Trans>
      </Title>
      <SubTitle>
        <Trans i18nKey="showing.detail.tour.subTitle">When would you like to tour our home?</Trans>
      </SubTitle>
      <FieldGroup
        label={
          <Trans i18nKey="showing.detail.tour.tourDate">
            When would you like to tour our home?
          </Trans>
        }
        errorText={touched.tourDate && errors.tourDate}>
        <InputDate
          name="tourDate"
          value={values.tourDate || ''}
          onChange={handleChange}
          disabledDays={(selected) => {
            return selected < currentDate;
          }}
        />
      </FieldGroup>
      <FieldGroup
        label={<Trans i18nKey="showing.detail.tour.comments">Comments</Trans>}
        errorText={touched.tourComments && errors.tourComments}>
        <Text
          name="tourComments"
          rows="3"
          value={values.tourComments}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FieldGroup>
      <BoxNote>
        {tourNotes?.content ? (
          <div dangerouslySetInnerHTML={{ __html: tourNotes?.content }} />
        ) : (
          <>
            <strong>
              <Trans i18nKey="showing.detail.tour.noteTitle">Please Note</Trans>
            </strong>
            <p>
              <Trans i18nKey="showing.detail.tour.noteTitle">
                Your requested date/time needs to be reviewed by our leasing team. Once approved,
                you will be contacted with timing and instructions for accessing the home.
              </Trans>
            </p>
          </>
        )}
      </BoxNote>
      <ActionsRow>
        <StyledButton variant="primary" onClick={handleSubmit}>
          Next
        </StyledButton>
      </ActionsRow>
    </Wrapper>
  );
};
