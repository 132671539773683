import _ from 'lodash';

export const encodeGoogleAddress = (result) => {
  if (!result) {
    return undefined;
  }

  let address = (
    (result.address_components || []).find((component) =>
      (component.types || []).includes('street_address')
    ) || {}
  ).long_name;
  let streetNumber = (
    (result.address_components || []).find((component) =>
      (component.types || []).includes('street_number')
    ) || {}
  ).long_name;
  const streetName = (
    (result.address_components || []).find((component) =>
      (component.types || []).includes('route')
    ) || {}
  ).long_name;
  const plusCode = (
    (result.address_components || []).find((component) =>
      (component.types || []).includes('plus_code')
    ) || {}
  ).long_name;

  // if no address form one from the street name and number
  if (!address) address = [streetNumber, streetName].filter((z) => z).join(' ');

  //plus codes are used in places where there are no street addresses, its really a google only thing, but the best we have
  if (!address && plusCode) address = plusCode;
  if (!streetNumber && plusCode) streetNumber = plusCode;

  const lat = ((result.geometry || {}).location || {}).lat;
  const lng = ((result.geometry || {}).location || {}).lng;

  return {
    formattedAddress: result.formatted_address,
    address: address,
    city:
      (
        (result.address_components || []).find((component) =>
          (component.types || []).includes('locality')
        ) || {}
      ).long_name ||
      (
        (result.address_components || []).find((component) =>
          (component.types || []).includes('sublocality')
        ) || {}
      ).long_name ||
      (
        (result.address_components || []).find((component) =>
          (component.types || []).includes('neighborhood')
        ) || {}
      ).long_name ||
      (
        (result.address_components || []).find((component) =>
          (component.types || []).includes('administrative_area_level_2')
        ) || {}
      ).long_name,
    county: (
      (result.address_components || []).find((component) =>
        (component.types || []).includes('administrative_area_level_2')
      ) || {}
    ).long_name,
    state: (
      (result.address_components || []).find((component) =>
        (component.types || []).includes('administrative_area_level_1')
      ) || {}
    ).long_name,
    stateCode: (
      (result.address_components || []).find((component) =>
        (component.types || []).includes('administrative_area_level_1')
      ) || {}
    ).short_name,
    postalCode: (
      (result.address_components || []).find((component) =>
        (component.types || []).includes('postal_code')
      ) || {}
    ).long_name,
    country: (
      (result.address_components || []).find((component) =>
        (component.types || []).includes('country')
      ) || {}
    ).short_name,
    countryName: (
      (result.address_components || []).find((component) =>
        (component.types || []).includes('country')
      ) || {}
    ).long_name,
    streetNumber: streetNumber,
    streetName: streetName,
    geopoint:
      lng && lat ? [_.isFunction(lng) ? lng() : lng, _.isFunction(lat) ? lat() : lat] : undefined
  };
};
