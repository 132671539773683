import React from 'react';
import styled from 'styled-components';
import Avatar from 'components/avatar';
import { formatPhone } from 'utils/phoneHelpers';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 0 20px;
  .body {
    flex: 1;
  }
`;

const LogoWrapper = styled.div`
  width: 200px;
  height: 60px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('${({ url }) => url || ''}');
`;

export default ({ location }) => {
  if (!location?.aptlyListings?.contactName && !location?.companyInfo?.logo) {
    return <></>;
  }

  return (
    <Wrapper>
      {(location?.aptlyListings?.contactImage || location?.aptlyListings?.duogram) && (
        <Avatar
          size={40}
          imageUrl={location?.aptlyListings?.contactImage}
          duogram={location?.aptlyListings?.duogram}
        />
      )}
      {!!location?.aptlyListings?.contactName && (
        <div className="body">
          <div className="name">{location?.aptlyListings?.contactName}</div>
          <div className="phone">{formatPhone(location?.aptlyListings?.contactPhone)}</div>
          <div className="email">{location?.aptlyListings?.contactEmail}</div>
        </div>
      )}
      {!!location?.companyInfo?.logo && <LogoWrapper url={location?.companyInfo?.logo} />}
    </Wrapper>
  );
};
