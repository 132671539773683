import React from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { Trans } from 'react-i18next';

import { MANUAL_VERIFICATION } from 'dal/applications';
import { UserContext } from 'state';
import { SupportModal } from 'components/contactUs';
import Button from 'components/button';
import { Row, Title } from '../common-styles';
import FileUpload from './upload';

const Step = styled.div`
  padding: 10px 0px;
  border-radius: 6px;
  .title {
    display: flex;
    align-items: end;
    gap: 5px;
  }
  li {
    list-style: none;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 740px) {
    padding: 0 10px;
  }
`;

const Required = styled.div`
  flex-shrink: 0;
  font-size: 0.81em;
  color: red;
  margin-top: -20px;
`;
const Optional = styled.div`
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: 0.81em;
  color: gray;
  font-style: italic;
`;

const UploadInput = styled.label`
  display: block;
  align-items: center;
  padding: 15px 20px;
  background-color: #f7f7f7;
  border: dashed 1px #ddd;
  min-height: 40px;
  margin-bottom: 10px;
  border-radius: 2px;
  input {
    display: none;
  }
  .input-label {
    margin-top: -25px;
    font-weight: bold;
    width: 300px;
    flex-shrink: 0;
    margin-right: 10px;
    @media screen and (max-width: 425px) {
      width: 210px;
    }
  }
  .uploadLink {
    cursor: pointer;
    font-size: 0.81em;
    padding: 0px;
    color: #fff;
    border-radius: 25px;
    background: #00a1ff;
    font-weight: bold;
    width: 80px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
`;
const ActionLink = styled.a`
  cursor: pointer;
  padding: 0px;
  color: var(--color-link);
  background-color: transparent;
  font-weight: bold;
`;

const Upload = ({ label, action, field, required, localFiles = {}, setLocalFiles, t }) => {
  const existingFile = (action?.files || {})[field];
  return (
    <UploadInput className="custom-file-upload">
      <div className="box-upload">
        <div className="input-label">{label}</div>
        <FileUpload
          required={required}
          autoUpload={true}
          localFiles={localFiles}
          setLocalFiles={setLocalFiles}
          locationId={action.locationId}
          actionId={action._id}
          field={field}
          existingFile={existingFile}
          fileTypes={['.pdf', '.jpg', '.jpeg', '.png']}
        />
      </div>
      {required && !localFiles[field] && <Required> {t('required')}</Required>}
      {!required && !localFiles[field] && <Optional>{t('optional')}</Optional>}
    </UploadInput>
  );
};

const IDUpload = ({ action, t }) => {
  const [localFiles, setLocalFiles] = React.useState(action.files || {});

  return (
    <div>
      <Upload
        t={t}
        label={t('verifyYourIdentity.modal_manual.govBack')}
        field="govFront"
        required={true}
        localFiles={localFiles}
        setLocalFiles={setLocalFiles}
        action={action}
      />
      <Upload
        t={t}
        label={t('verifyYourIdentity.modal_manual.govFront')}
        field="govBack"
        required={true}
        localFiles={localFiles}
        setLocalFiles={setLocalFiles}
        action={action}
      />
    </div>
  );
};

export default ({ autoIdDisabled, onClose, action, onNext, t }) => {
  const { userContext } = React.useContext(UserContext);
  const [modalOpen, setModalOpen] = React.useState();
  const onContinue = () => {
    MANUAL_VERIFICATION({
      userContext,
      locationId: action.locationId,
      applicationId: action._id,
      data: { type: 'identity' }
    })
      .then((result) => {
        if (!result.status) {
          return toast.error('Missing required data');
        }
        onNext({ action: result?.item });
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };
  return (
    <div>
      {modalOpen && (
        <SupportModal
          width="600px"
          height="620px"
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          isContactUs={true}
        />
      )}
      <Row className="center">
        <Title>
          {autoIdDisabled ? (
            <Trans i18nKey="verifyYourIdentity.modal_manual.autoDisabledTitle">
              To continue, we need to verify your identity
            </Trans>
          ) : (
            <Trans i18nKey="verifyYourIdentity.modal_manual.title">
              Skipping automated ID verification?
            </Trans>
          )}
        </Title>
      </Row>
      {!autoIdDisabled && (
        <>
          <Row>
            <Step>
              {t('verifyYourIdentity.modal_manual.description')}{' '}
              <ActionLink
                href="https://aptly.notion.site/What-kind-of-ID-do-I-need-for-verification-24ea29cffc154e3b8e485794dcbd29f1"
                target="_blank"
                rel="noreferrer">
                FAQs
              </ActionLink>{' '}
              {t('or')}{' '}
              <ActionLink onClick={() => setModalOpen(true)}>
                {t('buttonsActions.contact_us')}
              </ActionLink>
              .
            </Step>
          </Row>
          <Row>
            <Step>
              <Trans i18nKey="verifyYourIdentity.modal_manual.providePhotos">
                <strong>Verify your ID by uploading photos</strong>
              </Trans>
            </Step>
          </Row>
        </>
      )}
      <Row>
        <IDUpload action={action} t={t} />
      </Row>
      <br />
      <Row className="flex gap-10 center">
        <Button variant="secondary" onClick={onClose} width={95}>
          {t('buttonsActions.backButton')}
        </Button>
        <Button variant="primary" onClick={onContinue} width={95}>
          {t('buttonsActions.next')}
        </Button>
      </Row>
    </div>
  );
};
