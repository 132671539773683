import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from 'components/button';
import InputDate from 'components/input-date';
import Select from 'components/select';
import Input from 'components/input';
import Icon from 'components/icon';
import CurrencyInput from 'components/currency-input';
import { FieldGroup, Title, SubTitle, ActionsRow } from '../common';
import Custom from './custom';

const Wrapper = styled.div`
  .titleIcon {
    margin-top: 20px;
    color: #00aef5;
  }
  .actionIcon {
    color: #00aef5;
  }
  ${({ isMobile }) =>
    !isMobile
      ? css`
          padding: 10px 20px 20px;
        `
      : ''}
`;
const CenteredContent = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
`;
const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;
const PetRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  input {
    width: 30px;
  }
`;
const MinIncome = styled.div`
  display: flex;
  gap: 10px;
  margin-top: -5px;
  margin-bottom: 10px;
  font-size: 0.9em;
`;

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
const moneyToNumber = (amount) =>
  Number.isFinite(amount)
    ? amount
    : amount?.replace
    ? Number(amount?.replace(/[a-zA-Z,$ ]/gi, ''))
    : undefined;
const formatMoney = (amount = '') => {
  const val = `${amount || ''}`.replace(/[a-zA-Z,$ ]/gi, '');
  return moneyFormatter.format(val);
};

const currentDate = new Date();

const MoveInDate = ({ saving, values: initialValues, action, onSubmit }) => {
  const { t } = useTranslation();

  const applicantInfoSchema = yup.object().shape({
    moveIn: yup.date().required(`${t('form.validate.provideTourDate')}`)
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      moveIn: action?.moveIn || initialValues?.moveIn || ''
    },
    validationSchema: applicantInfoSchema,
    onSubmit: (vals) => {
      onSubmit({ moveIn: vals.moveIn });
    }
  });

  return (
    <CenteredContent>
      <Icon name="fa-duotone fa-people-carry-box titleIcon" size={64} />
      <SubTitle>
        <Trans i18nKey="showing.detail.qualification.moveDate">When are you looking to move?</Trans>
      </SubTitle>
      <FieldGroup errorText={touched.moveIn && errors.moveIn}>
        <InputDate
          name="moveIn"
          value={values.moveIn || ''}
          onChange={handleChange}
          disabledDays={(selected) => selected < currentDate}
        />
      </FieldGroup>
      <ActionsRow>
        <StyledButton variant="primary" onClick={handleSubmit} disabled={saving}>
          Next
        </StyledButton>
      </ActionsRow>
    </CenteredContent>
  );
};
const HowDidYouHearAboutUs = ({
  saving,
  location,
  values: initialValues,
  action,
  onBack,
  onSubmit
}) => {
  const { t } = useTranslation();

  const applicantInfoSchema = yup.object().shape({
    leadSource: yup.string().required(`${t('form.validate.leadSource')}`)
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      leadSource: action?.leadSource || initialValues?.leadSource || ''
    },
    validationSchema: applicantInfoSchema,
    validate: (v) => {
      let errors = {};
      if (!v.leadSource) errors.leadSource = 'Please provide a value';

      return errors;
    },
    onSubmit: (vals) => {
      onSubmit({ leadSource: vals.leadSource });
    }
  });

  return (
    <CenteredContent>
      <Icon name="fa-duotone fa-square-question titleIcon" size={64} />
      <SubTitle>
        <Trans i18nKey="showing.detail.qualification.leadSource">How did you hear about us?</Trans>
      </SubTitle>
      <FieldGroup errorText={touched.leadSource && errors.leadSource}>
        <Select name="leadSource" value={values.leadSource} onChange={handleChange}>
          <option value="">Please select...</option>
          {location.showingConfig.leadSources?.map((ls, ix) => (
            <option key={ix} value={ls}>
              {ls}
            </option>
          ))}
        </Select>
      </FieldGroup>
      <ActionsRow>
        <StyledButton variant="secondary" onClick={onBack}>
          Back
        </StyledButton>
        <StyledButton variant="primary" onClick={handleSubmit} disabled={saving}>
          Next
        </StyledButton>
      </ActionsRow>
    </CenteredContent>
  );
};

const Pets = ({ saving, values: initialValues, action, onSubmit, onBack }) => {
  const { t } = useTranslation();

  const applicantInfoSchema = yup.object().shape({
    dogCount: yup.number().required(`${t('form.validate.dogCount')}`),
    catCount: yup.number().required(`${t('form.validate.catCount')}`)
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      dogCount: action?.dogCount || initialValues?.dogCount || 0,
      catCount: action?.catCount || initialValues?.catCount || 0
    },
    validationSchema: applicantInfoSchema,
    onSubmit: (vals) => {
      onSubmit({ catCount: vals.catCount, dogCount: vals.dogCount });
    }
  });

  return (
    <CenteredContent>
      <SubTitle>
        <Trans i18nKey="showing.detail.qualification.pets">Do you have pets?</Trans>
      </SubTitle>
      <FieldGroup errorText={touched.dogCount && errors.dogCount}>
        <PetRow>
          <Icon name="fa-duotone fa-dog actionIcon" size={32} />
          <Input
            type="number"
            name="dogCount"
            value={values.dogCount || 0}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </PetRow>
      </FieldGroup>
      <FieldGroup errorText={touched.catCount && errors.catCount}>
        <PetRow>
          <Icon name="fa-duotone fa-cat actionIcon" size={32} />
          <Input
            type="number"
            name="catCount"
            value={values.catCount || 0}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </PetRow>
      </FieldGroup>
      <ActionsRow>
        <StyledButton variant="secondary" onClick={onBack}>
          Back
        </StyledButton>
        <StyledButton variant="primary" onClick={handleSubmit} disabled={saving}>
          Next
        </StyledButton>
      </ActionsRow>
    </CenteredContent>
  );
};

const Income = ({
  saving,
  location,
  values: initialValues,
  action,
  onSubmit,
  onBack,
  handleActions
}) => {
  const { t } = useTranslation();

  const applicantInfoSchema = yup.object().shape({
    householdIncome: yup.number().required(`${t('form.validate.householdIncome')}`)
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      householdIncome: action?.householdIncome || initialValues?.householdIncome || 0
    },
    validationSchema: applicantInfoSchema,
    validate: (val) => {
      const err = {};

      if (
        location?.showingConfig?.householdIncomeRequirementValue &&
        val.householdIncome * 100 < location?.showingConfig?.householdIncomeRequirementValue
      ) {
        err.householdIncome =
          'Unfortunately this is below minimum household income. You will not qualify for this home. Please select a different property.';
      }
      return err;
    },
    onSubmit: (vals) => {
      onSubmit({ householdIncome: vals.householdIncome });
    }
  });

  const notQualified =
    location?.showingConfig?.householdIncomeRequirementValue &&
    values.householdIncome * 100 < location?.showingConfig?.householdIncomeRequirementValue;
  const showPropSelector = notQualified && touched?.householdIncome;

  return (
    <CenteredContent>
      <Icon name="fa-duotone fa-wallet titleIcon" size={64} />
      <SubTitle>
        <Trans i18nKey="showing.detail.qualification.householdIncome">
          What is your monthly household income?
        </Trans>
      </SubTitle>

      {location?.showingConfig?.householdIncomeRequirementDisplay ? (
        <MinIncome>
          <span>
            <Trans i18nKey="showing.detail.qualification.householdIncome">
              Minimum household income for this property:
            </Trans>
          </span>
          <strong>{location?.showingConfig?.householdIncomeRequirementDisplay}</strong>
        </MinIncome>
      ) : (
        <></>
      )}
      <FieldGroup
        errorText={errors.householdIncome}
        subText={
          <Trans i18nKey="showing.detail.qualification.householdIncomeSubText">
            The number doesn't have to be exact
          </Trans>
        }>
        <CurrencyInput
          value={formatMoney(values.householdIncome || 0)}
          name="householdIncome"
          onChange={(e) =>
            handleChange({ ...e, target: { ...e.target, value: moneyToNumber(e.target.value) } })
          }
          onBlur={(e) =>
            handleBlur({ ...e, target: { ...e.target, value: moneyToNumber(e.target.value) } })
          }
          reportOnChange={true}
        />
      </FieldGroup>
      {showPropSelector ? (
        <ActionsRow>
          <StyledButton
            variant="secondary"
            onClick={() => handleActions({ id: 'change-property' })}>
            Select a different property
          </StyledButton>
        </ActionsRow>
      ) : (
        <></>
      )}
      <ActionsRow>
        <StyledButton variant="secondary" onClick={onBack}>
          Back
        </StyledButton>
        <StyledButton variant="primary" onClick={handleSubmit} disabled={saving || notQualified}>
          Next
        </StyledButton>
      </ActionsRow>
    </CenteredContent>
  );
};

const stepFlow = ['moveIn', 'leadSource', 'pets', 'income', 'custom'];
const getActiveSteps = (location) => {
  let steps = [].concat(stepFlow);
  if (!location?.showingConfig?.enableLeadSource || !location?.showingConfig?.leadSources?.length) {
    steps = steps.filter((s) => s !== 'leadSource');
  }
  if (!location?.showingConfig?.questionnaire?.length) {
    steps = steps.filter((s) => s !== 'custom');
  }

  return steps;
};
export default ({
  location,
  values: initialValues,
  action,
  isMobile,
  onSubmit: externalSubmit,
  handleActions
}) => {
  const activeSteps = React.useMemo(() => getActiveSteps(location), [location?._id]);
  const [step, setStep] = React.useState('moveIn');
  const [saving, setSaving] = React.useState(false);

  const onSubmit = (values, step) => {
    if (saving) return;
    setSaving(true);
    const nextStep = activeSteps[activeSteps.indexOf(step) + 1];
    const hideModal = !nextStep;
    externalSubmit(values, {
      keepOpen: !hideModal,
      hideLoader: !hideModal,
      stepIncomplete: !hideModal
    }).then((result) => {
      setSaving(false);
      if (result) nextStep && setStep(nextStep);
    });
  };
  const onBack = () => setStep(activeSteps[activeSteps.indexOf(step) - 1]);
  return (
    <Wrapper isMobile={isMobile}>
      <Title>
        <Trans i18nKey="showing.detail.qualification.title">Qualification Questions</Trans>
      </Title>
      {step === 'moveIn' && (
        <MoveInDate
          saving={saving}
          initialValues={initialValues}
          action={action}
          isMobile={isMobile}
          onSubmit={(val) => onSubmit(val, 'moveIn')}
        />
      )}
      {step === 'leadSource' && (
        <HowDidYouHearAboutUs
          location={location}
          saving={saving}
          initialValues={initialValues}
          action={action}
          isMobile={isMobile}
          onBack={onBack}
          onSubmit={(val) => onSubmit(val, 'leadSource')}
        />
      )}
      {step === 'pets' && (
        <Pets
          saving={saving}
          initialValues={initialValues}
          action={action}
          isMobile={isMobile}
          onBack={onBack}
          onSubmit={(val) => onSubmit(val, 'pets')}
        />
      )}
      {step === 'income' && (
        <Income
          saving={saving}
          handleActions={handleActions}
          location={location}
          initialValues={initialValues}
          action={action}
          isMobile={isMobile}
          onBack={onBack}
          onSubmit={(val) => onSubmit(val, 'income')}
        />
      )}
      {step === 'custom' && (
        <Custom
          saving={saving}
          initialValues={initialValues}
          action={action}
          location={location}
          isMobile={isMobile}
          onBack={onBack}
          onSubmit={(val) => onSubmit(val, 'custom')}
        />
      )}
    </Wrapper>
  );
};
