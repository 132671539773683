import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import { useDocumentContext } from './state';
import { UPDATE_DOCUMENT } from 'dal/eSignature';
import toast from 'react-hot-toast';
import FullScreenLoader from 'components/full-screen-loader';

const SignFooter = styled.div`
  padding: 20px 15px;
  border-top: 1px solid #ddd;
  gap: 10px;
`;

const Content = styled.div`
  display: flex;
  max-width: 600px;
  margin: 0 auto;
  button {
    padding: 7px 24px;
    background-color: #dc0100;
    color: #fff;
    border-radius: 6px;
  }
`;

const Message = styled.div`
  flex: 1;
  .title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }
`;

export default () => {
  const [loading, setLoading] = React.useState();
  const { doc, user, ui, documentData, params } = useDocumentContext();

  const onSign = async () => {
    setLoading(true);
    try {
      const data = await UPDATE_DOCUMENT({
        action: 'sign',
        signature: { ...ui.signature, location: ui.location },
        pages: ui.pages,
        token: params.token,
        documentId: doc._id
      });
      if (data.status) {
        documentData.updateDocument(data.document.document);
        documentData.signComplete();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Error Signing document, please try later.');
    }
  };

  return (
    <SignFooter>
      <Content>
        <Message>
          <div className="title">WELL DONE!</div>
          You've completed the required fields. Review your work, then select{' '}
          <strong>FINISH</strong> .
        </Message>
        <Button onClick={onSign}>FINISH</Button>
      </Content>
      {loading && <FullScreenLoader variant="dark" />}
    </SignFooter>
  );
};
