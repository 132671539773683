import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

import Icon from 'components/icon';
import { displayLocationInfo } from 'helpers/location';

const Wrapper = styled.div`
  display: grid;
  gap: 5px;
`;

const ItemWrapper = styled.div`
  padding: 10px 15px;
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  .body {
    flex: 1;
  }
  &:hover {
    background-color: #f5f5f5;
  }
  .itemTitle {
    font-weight: bold;
  }
`;

const ItemImage = styled.div`
  width: 40px;
  height: 40px;
  background-image: url('${({ image }) => image}');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Item = ({ orgId, locationId, floorplan, isFullPage }) => {
  const navigate = useNavigate();
  const encodedFloorplan = React.useMemo(() => {
    return displayLocationInfo(floorplan);
  }, [floorplan?._id]);

  return (
    <ItemWrapper
      onClick={() =>
        navigate(
          isFullPage
            ? `/s/${orgId}/property/${locationId}/${floorplan._id}`
            : `/search/${orgId}/${locationId}/${floorplan._id}`
        )
      }>
      {encodedFloorplan?.displayInfo?.heroImage && (
        <ItemImage image={encodedFloorplan?.displayInfo?.heroImage} />
      )}
      <div className="body">
        <div className="detail">
          {encodedFloorplan?.displayInfo?.title || encodedFloorplan?.bedCount}
        </div>
        <div className="itemTitle">{encodedFloorplan?.displayInfo?.formattedPrice}</div>
        <div className="detail">
          {!encodedFloorplan?.availableUnits
            ? 'Availability Limited'
            : `${encodedFloorplan?.availableUnits} Unit${
                encodedFloorplan?.availableUnits === 1 ? '' : 's'
              } available`}
        </div>
      </div>
      <Icon name="fa-solid fa-chevron-right" />
    </ItemWrapper>
  );
};

export default ({ orgId, location, isFullPage }) => {
  const floorplans = location?.floorplans || [];
  return (
    <Wrapper>
      {floorplans.map((floorplan) => {
        return (
          <Item
            key={floorplan._id}
            isFullPage={isFullPage}
            locationId={location?._id}
            orgId={orgId}
            floorplan={floorplan}
          />
        );
      })}
    </Wrapper>
  );
};
