import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

import Button from 'components/button';
import Icon from 'components/icon';
import Avatar from 'components/avatar';
import { SupportModal } from 'components/contactUs';

const Location = styled.div`
  width: 370px;
  padding: 20px;
  .title {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  .title-mobile {
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
    font-size: 1.13em;
    padding: 0 20px;
  }
  .rental-app-title {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  ${({ isMobile }) =>
    !isMobile
      ? ''
      : css`
          width: 100%;
          padding: 0 0 10px;
          background-color: #ddebf5;
          .title {
            padding: 0 20px;
            font-size: 1.25em;
            margin-bottom: 20px;
          }
          button {
            background-color: rgba(0, 0, 0, 0.1) !important;
          }
        `}
`;

const PropertyActions = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 25px;
  font-size: 0.81em;
  button {
    background-color: #ddecf5;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: 4px;
    color: #00a1ff;
    gap: 10px;
  }
  ${({ isMobile }) => (!isMobile ? '' : 'padding: 0 20px;')}
`;

const Logo = styled.div`
  width: 100%;
  height: 300px;
  background-color: #f5f5f5;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ image }) => image});
  ${({ isMobile }) => (!isMobile ? '' : 'height: 160px;border-radius: 0px;')}
`;

const DataList = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  ${({ isMobile }) => (!isMobile ? '' : 'padding: 0 20px;')}
`;

const Data = styled.div`
  font-size: 0.81em;
  flex: 1;
  .misc {
    text-transform: uppercase;
  }
  .label {
    color: #777;
    text-transform: uppercase;
  }
  .data {
    font-weight: bold;
    text-transform: lowercase;
  }
`;

const Agent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  display: flex;
  gap: 10px;
  .agent-name {
    font-weight: bold;
  }
  .agent-contact {
    font-size: 0.88em;
  }
  .avatar {
    border: 1px solid #ddd;
    background-color: #eee;
  }
  ${({ isMobile }) => (!isMobile ? '' : 'padding: 0 20px;')}
`;

export default ({ className, location = {}, onChangeProperty, isMobile, hideActions }) => {
  const [showContactModal, setShowModal] = React.useState();
  const { t } = useTranslation();

  const {
    coverPhoto = [],
    marketingImages = [],
    marketRent,
    agent,
    availableDate
  } = location.applicationConfig || {};

  const photo = coverPhoto?.[0] || marketingImages?.[0];

  return (
    <Location className={className} isMobile={isMobile}>
      {!isMobile && <div className="title">{location.name}</div>}
      {photo && <Logo image={photo} isMobile={isMobile} />}
      {isMobile && <div className="title-mobile">{location.name}</div>}
      <DataList isMobile={isMobile}>
        {!!marketRent && (
          <Data>
            <div className="label">{t('aplicationItem.rent')}</div>
            <div className="data">{marketRent}/mo</div>
          </Data>
        )}
        {!!availableDate && (
          <Data>
            <div className="label">{t('aplicationItem.availableDate')}</div>
            <div className="data">{availableDate}</div>
          </Data>
        )}
        {(!!location.bedCount || !!location.bathCount || !!location.squareFeet) && (
          <Data>
            <div className="misc">
              <div>
                {[
                  location.bedCount ? `${location.bedCount} BD` : '',
                  location.bathCount ? `${location.bathCount} BA` : ''
                ]
                  .filter((z) => z)
                  .join(' | ')}
              </div>
              {!!location.squareFeet && <div>{location.squareFeet} SF</div>}
            </div>
          </Data>
        )}
      </DataList>
      {agent?.name && (
        <Agent isMobile={isMobile}>
          {(!!agent.photo || !!agent.duogram) && (
            <Avatar imageUrl={agent.photo} duogram={agent.duogram} />
          )}
          <div className="agent-details">
            {!!agent.name && <div className="agent-name">{agent.name}</div>}
            {!!agent.phone && <div className="agent-contact">{agent.phone}</div>}
            {!!agent.email && <div className="agent-contact">{agent.email}</div>}
          </div>
        </Agent>
      )}
      {!hideActions && (
        <PropertyActions isMobile={isMobile}>
          <Button onClick={() => setShowModal(true)}>
            <Icon name="fa-duotone fa-messages-question" />
            {t('buttonsActions.askQuestion')}
          </Button>
          {onChangeProperty && (
            <Button onClick={onChangeProperty}>
              <Icon name="fa-light fa-arrows-retweet" />
              {t('buttonsActions.changeProperty')}
            </Button>
          )}
        </PropertyActions>
      )}
      {showContactModal && (
        <SupportModal
          width="600px"
          height="620px"
          modalOpen={showContactModal}
          setModalOpen={() => setShowModal(false)}
          isContactUs={true}
        />
      )}
    </Location>
  );
};
