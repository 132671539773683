import V1 from './v1';
import V2 from './v2';

export default (props) => {
  if (props.application?.consent?.consentVersion === 'v1') {
    return <V1 {...props} />;
  }

  return <V2 {...props} />;
};
