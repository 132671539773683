import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { Trans } from 'react-i18next';
import styled, { css } from 'styled-components';

import Button from 'components/button';
import { useNavigateWithContext } from 'state';
import { Title, SubTitle } from './common';

const Wrapper = styled.div`
  ${({ isMobile }) =>
    !isMobile
      ? css`
          padding: 10px 20px 20px;
        `
      : ''}

  .flexRow {
    display: flex;
    ${({ isMobile }) =>
      isMobile
        ? css`
            flex-flow: column;
            gap: 0px;
            margin-bottom: 20px;
          `
        : css`
            gap: 20px;
          `}
  }
`;
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  &.no-margin {
    margin-bottom: 0;
  }
  .left-section {
    display: flex;
    align-items: center;
    input {
      margin-right: 10px;
    }
  }
`;
const CenteredTitle = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;
const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;

export default ({ onShowView, onChangeProperty }) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });

  return (
    <Wrapper isMobile={isMobile}>
      <Title>
        <Trans i18nKey="showing.existing.title">
          Continuing from a previously submitted tour request?
        </Trans>
      </Title>
      <CenteredTitle>
        <Trans i18nKey="showing.existing.description">
          You already have a showing request for this location. Please review that request or submit
          one for a different location.
        </Trans>
      </CenteredTitle>

      <Row className="center" style={{ flexFlow: 'row', gap: '15px' }}>
        <StyledButton variant="primary" onClick={onChangeProperty}>
          Choose New Location
        </StyledButton>
        <StyledButton variant="primary" onClick={onShowView}>
          Go To Tour Request
        </StyledButton>
      </Row>
    </Wrapper>
  );
};
