import React from 'react';
import { Map } from '@vis.gl/react-google-maps';
import styled from 'styled-components';

import InfoBox from './info-box';
import { isValidLat, isValidLong } from 'helpers/location';
import ClusteredMarkers from './clustered-markers';

const Wrapper = styled.div`
  flex: 1;
  background: #ddd;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  .IPAZAH-content-container {
    svg {
      fill: red;
      circle:first-child {
        opacity: 0.8 !important;
      }
    }
  }
`;

const VisMap = ({
  locations = [],
  activeMarker,
  onMarkerClick,
  setMapReady,
  mapCenter,
  visibleMapBounds = {
    south: -6.303732835134541, //roughly the US, should only be visible right after org is first setup
    west: -158.50239090624999,
    north: 62.43645915392766,
    east: -46.26606278125
  },
  onCameraChanged
}) => {
  const [currentCenter, setCurrentCenter] = React.useState(mapCenter);
  const handleCameraChange = React.useCallback((cameraChangeEvent) => {
    onCameraChanged(cameraChangeEvent?.detail);
    setMapReady(true);
  }, []);

  // reset this so we have a clean map pan
  const handleCenterChanged = () => setCurrentCenter({});

  React.useEffect(() => {
    const updatedVal = { ...currentCenter };
    if (isValidLat(mapCenter?.center?.lat) && isValidLong(mapCenter?.center?.lng)) {
      updatedVal.center = mapCenter.center;
    }
    if (Number.isFinite(mapCenter?.zoom)) {
      updatedVal.zoom = mapCenter.zoom;
    }
    setCurrentCenter(updatedVal);
  }, [mapCenter]);

  return (
    <Map
      mapId="e92aff408f14a9d"
      defaultBounds={visibleMapBounds}
      center={currentCenter?.center}
      maxZoom={19}
      zoom={currentCenter?.zoom}
      onCenterChanged={handleCenterChanged}
      onZoomChanged={handleCenterChanged}
      onCameraChanged={handleCameraChange}
      style={{ width: '100%', height: '100%' }}
      gestureHandling="greedy">
      <ClusteredMarkers
        locations={locations}
        activeMarker={activeMarker}
        onMarkerClick={onMarkerClick}
      />
    </Map>
  );
};

export default ({
  locations = [],
  visibleMapBounds,
  setPreviewId,
  orgId,
  mapCenter,
  onCameraChanged,
  setMapReady,
  setActiveMarkerId
}) => {
  const [preview, setPreview] = React.useState();

  const geoLocations = React.useMemo(() => {
    return locations.filter((a) => {
      return isValidLong(a?.address?.geopoint?.[0]) && isValidLat(a?.address?.geopoint?.[1]);
    });
  }, [locations]);

  return (
    <Wrapper>
      <VisMap
        setMapReady={setMapReady}
        visibleMapBounds={visibleMapBounds}
        onCameraChanged={onCameraChanged}
        mapCenter={mapCenter}
        locations={geoLocations}
        activeMarker={preview}
        onMarkerClick={(marker) => {
          setActiveMarkerId(marker._id);
          document.getElementById(marker._id)?.scrollIntoView({ block: 'center' });
          setPreview(marker);
        }}
      />
      {preview && (
        <InfoBox
          orgId={orgId}
          location={preview}
          onClose={() => {
            setActiveMarkerId(undefined);
            setPreview(undefined);
          }}
          onClick={() => setPreviewId(preview._id)}
        />
      )}
    </Wrapper>
  );
};
