import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import { useTranslation } from 'react-i18next';
import Modal, { ModalScrollable, ModalHeader } from 'components/modal';
import Button from 'components/button';

const ButtonWrappers = styled.div`
  display: flex;
  gap: 20px;
  flex-flow: column;
  > button {
    border-radius: 5px !important;
    width: 100% !important;
    padding: 20px !important;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  gap: 20px;
`;
const selectorsToScale = ['application-root-wrapper'];
export const scaleFonts = (scaleFactor) => {
  for (const selector of selectorsToScale) {
    const elem = document.getElementById(selector);
    if (elem) {
      elem.style['font-size'] = `${scaleFactor}em`;
    }
  }
};

export default ({
  width,
  height,
  open,
  anchorEl,
  anchorDirection = 'topLeft',
  onClose,
  onChange
}) => {
  const [activeScale, _setActiveScale] = React.useState(() => {
    const textScale = Number(localStorage.getItem('textScale'));
    return !textScale || !Number.isFinite(textScale) ? 1 : textScale;
  });
  const { t } = useTranslation();

  const setActiveScale = (newScale) => {
    if (activeScale === newScale) {
      return;
    }
    localStorage.setItem('textScale', newScale);
    _setActiveScale(newScale);
    scaleFonts(newScale);
    onChange && onChange();
  };

  const FONT_SIZES = {
    Small: 0.9,
    Medium: 1,
    Large: 1.2,
    'Extra Large': 1.4
  };
  return (
    <Modal
      width={width || '400px'}
      height={height || 'auto'}
      open={open}
      anchorDirection={anchorDirection}
      anchorEl={anchorEl}
      hideClose={true}
      onClose={onClose}>
      <ModalHeader>
        <HeaderWrapper>
          <Icon name="fa-duotone fa-text-size" />
          <div className="title">{t('sidebar.textSize')}</div>
        </HeaderWrapper>
      </ModalHeader>
      <ModalScrollable>
        <ButtonWrappers>
          {Object.keys(FONT_SIZES).map((key) => {
            return (
              <Button
                key={key}
                variant={activeScale === FONT_SIZES[key] ? 'primary' : 'secondary'}
                onClick={() => setActiveScale(FONT_SIZES[key])}>
                {t(`sidebar.textSizes.${key}`)}
              </Button>
            );
          })}
        </ButtonWrappers>
      </ModalScrollable>
    </Modal>
  );
};
