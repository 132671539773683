import styled, { css } from 'styled-components';
import { readableColor } from 'polished';

const Circle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ radius = '50%' }) => radius};
  background-color: ${({ color }) => color};
  color: ${({ color }) => readableColor(color)};
  flex-shrink: 0;
  ${({ onClick }) => (onClick ? 'cursor:pointer;' : '')}
`;

const IconBase = styled.i`
  ${(props) => {
    if (!props.size) return;

    if (props.size?.indexOf && props.size.indexOf('em')) {
      return css`
        font-size: ${({ size }) => size};
      `;
    }

    return css`
      font-size: ${({ size }) => size}px;
      width: ${({ size }) => size}px;
      height: ${({ size }) => size}px;
    `;
  }};
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-style: normal;
  ${({ clickable, onClick }) => (clickable || onClick ? 'cursor:pointer;' : '')}
  ${({ color }) => (color ? `color:${color};-webkit-text-fill-color: ${color};` : '')}
`;

const Icon = ({
  paths = 0,
  size,
  clickable = false,
  name = '',
  className = '',
  color,
  borderColor,
  ...props
}) => {
  const isFontAwesome = name.startsWith('fa-');
  return (
    <IconBase
      className={`${className} ${isFontAwesome ? name : `icon-${name}`} `}
      borderColor={borderColor}
      size={size}
      color={color}
      clickable={clickable}
      {...props}>
      {paths > 0 &&
        Array.from({ length: paths }).map((path, i) => <span key={i} className={`path${i + 1}`} />)}
    </IconBase>
  );
};

export default Icon;

export const IconShape = ({
  className = '',
  size = 25,
  onClick,
  color = '#ccc',
  name = '',
  iconProps = {},
  radius,
  ...props
}) => (
  <Circle
    className={`Aptly_IconShape ${className}`}
    size={size}
    onClick={onClick}
    color={color}
    radius={radius}>
    <Icon name={name} {...props} {...iconProps} />
  </Circle>
);
