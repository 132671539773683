import React from 'react';
import MaskedInput from 'react-text-mask';
import Input from 'components/input';

export default ({ tag, ...props }) => (
  <MaskedInput
    mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
    placeholder="(000) 000-0000"
    render={(ref, props) =>
      tag === 'defaultInput' ? <input ref={ref} {...props} /> : <Input ref={ref} {...props} />
    }
    {...props}
  />
);
