export const homeTypeOpts = [
  { label: 'Single Family', value: 'singleFamily' },
  { label: 'Townhouse', value: 'townhouse' },
  { label: 'Apartment Unit', value: 'apartmentUnit' },
  { label: 'Condo Unit', value: 'condoUnit' },
  { label: 'Not Mentioned', value: 'all' }
];

export const amenitiesOptions = [
  { label: 'Cottage' },
  { label: 'EV Charger' },
  { label: 'Fenced Yard' },
  { label: 'Garage' },
  { label: 'Garden' },
  { label: 'Pool' },
  { label: 'Special Offers' },
  { label: 'Solar' }
];

export const petOptions = [
  { label: 'Allows Dogs', value: 'dogs' },
  { label: 'Allows Cats', value: 'cats' }
];

export const bedsBathsOpts = [
  { label: 'Any', value: '' },
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '+7', value: 7 }
];

export const filterControls = {
  beds: {
    key: 'beds',
    label: 'Bedrooms',
    isActive: (filters) => !!filters.beds
  },
  baths: {
    key: 'baths',
    label: 'Bathrooms',
    isActive: (filters) => !!filters.baths
  },
  price: {
    key: 'price',
    label: 'Price',
    isActive: (filters) => filters.price?.min || filters.price?.max
  },
  homeType: {
    key: 'homeType',
    label: 'Home Type',
    isActive: (filters) => filters.homeType?.length > 0
  },
  petsAllowed: {
    key: 'petsAllowed',
    label: 'Pets',
    isActive: (filters) => !!filters.petsAllowed
  },
  moveInDate: {
    key: 'moveInDate',
    label: 'Move In Date',
    isActive: (filters) => !!filters.moveInDate
  },
  amenities: {
    key: 'amenities',
    label: 'Amenities',
    isActive: (filters) => filters.amenities.length > 0
  }
  // restrictToMap: {
  //   key: 'restrictToMap',
  //   label: 'Search based on map boundary',
  //   isActive: (filters) => !!filters.restrictToMap
  // }
};

export const defaultFilters = {
  text: '',
  beds: undefined,
  baths: undefined,
  price: {
    min: '',
    max: ''
  },
  homeType: [],
  petsAllowed: undefined,
  restrictToMap: true,
  moveInDate: '',
  amenities: []
};
