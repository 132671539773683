import React from 'react';
import { AUDIENCES } from 'helpers/enums';

const determineActiveAudience = () => {
  const parts = window.location.pathname.split('/').filter((z) => z);
  return Object.values(AUDIENCES).find((aud) => parts[1] === aud.pathname) || AUDIENCES.applicant;
};

export default () => {
  const [audience, setAudience] = React.useState(determineActiveAudience);
  return [audience, setAudience];
};
