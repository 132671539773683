import React from 'react';
import styled from 'styled-components';
import Icon from './icon';

const Wrapper = styled.div`
  label {
    font-size: 0.81em;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 70px;
    border: 2px dashed #ddd;
    border-radius: 6px;
    gap: 3px;
    &:hover {
      background-color: #fafafa;
      border-color: #ccc;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }
`;

const FileList = styled.div`
  margin-bottom: 15px;
`;

const FilePlaceHolder = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 15px;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  margin-bottom: 3px;
  .body {
    flex: 1;
  }
  .size {
    font-size: 0.75em;
    color: #777;
  }
  .name {
    font-weight: bold;
    font-size: 0.88em;
  }
  i {
    cursor: pointer;
    color: #777;
    &:hover {
      color: #000;
    }
  }
`;

export default ({ name, onChange, field = {}, multiple }) => {
  const [files, setFiles] = React.useState([]);

  const isMultiple = field ? (field.type === 'files' ? true : false) : multiple;

  const handleChange = React.useCallback((e) => {
    const files = e.target.files;
    const allFiles = [];
    (Object.values(files) || []).forEach((file) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          data: reader.result
        };
        allFiles.push(fileInfo);
        if (allFiles.length === files.length) {
          setFiles((files) => (isMultiple ? [...files, ...allFiles] : [allFiles[0]]));
          if (e.target) {
            e.target.value = null;
          }
        }
      };
    });
  }, []);

  React.useEffect(() => {
    onChange && onChange({ target: { value: isMultiple ? files : files[0], name } });
  }, [files]);

  const handleRemove = (index) => setFiles(files.filter((f, i) => i !== index));

  return (
    <Wrapper>
      {files.length > 0 && (
        <FileList>
          {files.map((file, index) => (
            <FilePlaceHolder key={index}>
              <div className="body">
                <div className="name">{file.name}</div>
                <div className="size">{file.size}</div>
              </div>
              <Icon name="fa-solid fa-circle-minus" onClick={() => handleRemove(index)} />
            </FilePlaceHolder>
          ))}
        </FileList>
      )}
      <label htmlFor="file-uploader">
        <Icon name="fa-solid fa-upload" color="#777" />
        Click or drag to add files
        <input
          name=""
          type="file"
          id="file-uploader"
          onChange={handleChange}
          accept="image/jpeg,image/gif,image/png,application/pdf"
          multiple={isMultiple}
        />
      </label>
    </Wrapper>
  );
};
