import React from 'react';
import styled from 'styled-components';
import RichTextEditor from 'react-rte';
import debounce from 'lodash/debounce';

const Wrapper = styled.div`
  .public-DraftEditor-content {
    min-height: 200px;
  }
  .toolbar-editor {
    box-sizing: border-box;
    button {
      border: none;
      background: none;
      background-color: #f5f5f5 !important;
      box-shadow: none !important;
      margin-right: 2px;
      border-radius: 2px !important;
      border: 1px solid #ddd !important;
    }
    select {
      box-shadow: none;
      border: 1px solid #ddd;
      background-color: #f5f5f5;
      padding-left: 5px;
    }
  }
`;

export default ({ name, value: initialValue, onChange, placeholder, className, resetToken }) => {
  const [value, setValue] = React.useState(() =>
    RichTextEditor.createValueFromString(initialValue || '', 'html')
  );

  const debounceChange = React.useCallback(
    debounce((fn) => fn(), 300),
    []
  );

  const handleChange = (content) => {
    setValue(content);
    debounceChange(() => {
      onChange && onChange({ target: { name, value: content.toString('html') } });
    });
  };

  React.useEffect(() => {
    if (resetToken) {
      setValue(RichTextEditor.createValueFromString(initialValue || '', 'html'));
    }
  }, [resetToken]);

  return (
    <Wrapper className={className}>
      <RichTextEditor
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        toolbarClassName="toolbar-editor"
        editorClassName="editor-wrapper"
      />
    </Wrapper>
  );
};
