import * as yup from 'yup';

export const defaultContact = {
  firstName: '',
  lastName: '',
  email: '',
  phone: [] //{ number: '', type: 'mobile' }]
};

export const CONTACT_SCHEMA = yup.object().shape({
  uuid: yup.string(),
  personId: yup.string(),
  firstName: yup.string().required(`Please provide a first name`),
  lastName: yup.string().required(`Please provide a last name`),
  email: yup.string().trim().email(`Please provide a valid email`),
  phone: yup.array(
    yup.object({
      number: yup
        .string()
        .phone(`Please provide a valid phone number`)
        .required(`Please provide a phone number`),
      uuid: yup.string(),
      type: yup.string()
    })
  ),
  oneOfRequiredValidation: yup
    .boolean()
    .test('email | phone', 'Please provide either an email or phone', function (val, context) {
      const { email, phone } = context?.parent || {};
      return !!email || !!phone?.some((s) => s?.number);
    })
});
