import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const PageWrapper = styled.div`
  > p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export default ({ name }) => {
  const { t } = useTranslation();
  return (
    <PageWrapper>
      <h3>{t('consent_term.title')}</h3>
      <p>{t('consent_term.p1')}</p>
      <h3>{t('consent_term.title2')}</h3>
      <p>
        {name} {t('consent_term.p2')}
      </p>
      <h3>{t('consent_term.title3')}</h3>
      <p>
        {name} {t('consent_term.p3')}
      </p>
      <h3>{t('consent_term.title4')}</h3>
      <p>
        {t('consent_term.p4', { name: name })}
        <ul className="table">
          <li>{t('consent_term.li_1')}</li>
          <li>{t('consent_term.li_2')}</li>
          <li>{t('consent_term.li_3')}</li>
          <li>{t('consent_term.li_4')}</li>
          <li>{t('consent_term.li_5')}</li>
        </ul>
      </p>
      <h3>{t('consent_term.title5')}</h3>
      <p>
        <p>
          {name} {t('consent_term.p5')}
        </p>
        <p>{t('consent_term.p6')}</p>
        <p>{t('consent_term.p7')}</p>
        <p>{t('consent_term.p8')}</p>
        <ul>
          <li>{t('consent_term.li_6')} </li>
          <li>{t('consent_term.li_7')} </li>
        </ul>
        <p>{t('consent_term.p9')}</p>
        <p>{t('consent_term.p10')}</p>
        <p>{t('consent_term.p11')}</p>
      </p>
      <h3>{t('consent_term.title6')}</h3>
      <p>
        <p>
          Para información en español, visite{' '}
          <a href="https://www.consumerfinance.gov/learnmore">
            https://www.consumerfinance.gov/learnmore
          </a>{' '}
          o escribe a la Consumer Financial Protection Bureau, 1700 G Street N.W., Washington, DC
          20552.
        </p>
        <p>{t('consent_term.p12')}</p>
        <ul>
          <li>{t('consent_term.li_8')}</li>
          <li>
            {t('consent_term.li_9')}
            <ul>
              <li>{t('consent_term.li_10')}</li>
              <li>{t('consent_term.li_11')}</li>
              <li>{t('consent_term.li_12')}</li>
              <li>{t('consent_term.li_13')}</li>
              <li>{t('consent_term.li_14')}</li>
            </ul>
            In addition, all consumers are entitled to one free disclosure every 12 months upon
            request from each nationwide credit bureau and from nationwide specialty consumer
            reporting agencies. See{' '}
            <a href="https://www.consumerfinance.gov/learnmore">
              www.consumerfinance.gov/learnmore
            </a>{' '}
            for additional information.
          </li>
          <li>{t('consent_term.li_15')}</li>
          <li>
            {t('consent_term.li_16')}
            <a href="https://www.consumerfinance.gov/learnmore" />
          </li>
          <li>{t('consent_term.li_17')}</li>
          <li>{t('consent_term.li_18')}</li>
          <li>{t('consent_term.li_19')}</li>
          <li>
            {t('consent_term.li_20')}
            <a href="https://www.consumerfinance.gov/learnmore">
              www.consumerfinance.gov/learnmore
            </a>
            .
          </li>
          <li>{t('consent_term.li_21')}</li>
          <li>
            {t('consent_term.li_22')}
            <h4>{t('consent_term.title7')}</h4>
            <p>{t('consent_term.p13')}</p>
            <p>{t('consent_term.p14')}</p>
            <p>{t('consent_term.p15')}</p>
          </li>
          <li>{t('consent_term.li_23')}</li>
          <li>
            {t('consent_term.li_24')}{' '}
            <a href="https://www.consumerfinance.gov/learnmore">
              www.consumerfinance.gov/learnmore
            </a>
            .
          </li>
        </ul>
        <p>
          {t('consent_term.p16')}
          <ul className="tableCols">
            <li>
              <div>
                <h4>{t('consent_term.title8')}</h4>
              </div>
              <div>
                <h4>{t('consent_term.title9')}</h4>
              </div>
            </li>
            <li>
              <div>
                <p>{t('consent_term.p17')}</p>
                <p>{t('consent_term.p18')}</p>
              </div>
              <div>
                <p>
                  a. Consumer Financial Protection Bureau
                  <br />
                  1700 G Street, N.W.
                  <br />
                  Washington, DC 20552
                </p>
                <p>
                  b. Federal Trade Commission
                  <br />
                  Consumer Response Center
                  <br />
                  600 Pennsylvania Avenue, N.W.
                  <br />
                  Washington, DC 20580
                  <br />
                  (877) 382-4357
                  <br />
                </p>
              </div>
            </li>
            <li>
              <div>
                2. To the extent not included in item 1 above:
                <br />
                a. National banks, federal savings associations, and federal branches and federal
                agencies of foreign banks
                <br />
                b. State member banks, branches and agencies of foreign banks (other than federal
                branches, federal agencies, and Insured State Branches of Foreign Banks), commercial
                lending companies owned or controlled by foreign banks, and organizations operating
                under section 25 or 25A of the Federal Reserve Act.
                <br />
                c. Nonmember Insured Banks, Insured State Branches of Foreign Banks, and insured
                state savings associations
                <br />
                d. Federal Credit Unions
                <br />
              </div>
              <div>
                a. Office of the Comptroller of the Currency
                <br />
                Customer Assistance Group
                <br />
                1301 McKinney Street, Suite 3450
                <br />
                Houston, TX 77010-9050
                <br />
                b. Federal Reserve Consumer Help Center
                <br />
                P.O. Box 1200
                <br />
                Minneapolis, MN 55480
                <br />
                c. FDIC Consumer Response Center
                <br />
                1100 Walnut Street, Box #11
                <br />
                Kansas City, MO 64106
                <br />
                d. National Credit Union Administration
                <br />
                Office of Consumer Financial Protection (OCFP)
                <br />
                Division of Consumer Compliance Policy and Outreach
                <br />
                1775 Duke Street
                <br />
                Alexandria, VA 22314
                <br />
              </div>
            </li>
            <li>
              <div>3. Air carriers</div>
              <div>
                Asst. General Counsel for Aviation Enforcement & Proceedings
                <br />
                Aviation Consumer Protection Division
                <br />
                Department of Transportation
                <br />
                1200 New Jersey Avenue, S.E.
                <br />
                Washington, DC 20590
              </div>
            </li>
            <li>
              <div>4. Creditors Subject to the Surface Transportation Board</div>
              <div>
                Office of Proceedings, Surface Transportation Board
                <br />
                Department of Transportation
                <br />
                395 E Street, S.W.
                <br />
                Washington, DC 20423
              </div>
            </li>
            <li>
              <div>5. Creditors Subject to the Packers and Stockyards Act, 1921 </div>
              <div>Nearest Packers and Stockyards Administration area supervisor</div>
            </li>
            <li>
              <div>6. Small Business Investment Companies</div>
              <div>
                Associate Deputy Administrator for Capital Access
                <br />
                United States Small Business Administration
                <br />
                409 Third Street, S.W., Suite 8200
                <br />
                Washington, DC 20416
              </div>
            </li>
            <li>
              <div>7. Brokers and Dealers</div>
              <div>
                Securities and Exchange Commission
                <br />
                100 F Street, N.E.
                <br />
                Washington, DC 20549
              </div>
            </li>
            <li>
              <div>
                8. Federal Land Banks, Federal Land Bank Associations, Federal Intermediate Credit
                Banks, and Production Credit Associations
              </div>
              <div>
                Farm Credit Administration
                <br />
                1501 Farm Credit Drive
                <br />
                McLean, VA 22102-5090
              </div>
            </li>
            <li>
              <div>9. Retailers, Finance Companies, and All Other Creditors Not Listed Above</div>
              <div>
                Federal Trade Commission
                <br />
                Consumer Response Center
                <br />
                600 Pennsylvania Avenue, N.W.
                <br />
                Washington, DC 20580
                <br />
                (877) 382-4357
              </div>
            </li>
          </ul>
        </p>
      </p>
      <h3>{t('consent_term.title10')}</h3>
      <p>
        {t('consent_term.p19')}
        <ul>
          <li>{t('consent_term.li_25')}</li>
          <li>{t('consent_term.li_26')}</li>
          <li>{t('consent_term.li_27')}</li>
          <li>{t('consent_term.li_28')}</li>
        </ul>
        <p>{t('consent_term.p20')}</p>
        <p>{t('consent_term.p21')}</p>
      </p>
    </PageWrapper>
  );
};
