import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';
import { useDocumentContext } from '../../state';
import html2React from 'html-react-parser';
import SignaturePlaceHolder from './signature-placeholder';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  background-color: #f5f5f5;
  overflow: hidden;
`;

const Body = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px 20px 80px;
  @media (max-width: 500px) {
    padding: 10px 10px 80px;
  }
`;

const Page = styled.div`
  border: 1px solid #ddd;
  width: 794px;
  margin: 0 auto;
  .u-row {
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    width: 100%;
    zoom: 0.8;
  }
  @media (max-width: 800px) {
    width: 100%;
    zoom: 0.7;
  }
  @media (max-width: 500px) {
    width: 100%;
    zoom: 0.5;
  }
`;

const Pager = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  left: calc(50% - 69px);
  bottom: 20px;
  height: 40px;
  padding: 0 10px;
  background-color: #000;
  border-radius: 4px;
  color: #fff;
  gap: 10px;
  button {
    color: #fff;
    &::disabled {
      color: rgba(255, 255, 255, 0.5);
    }
  }
`;

const HtmlRender = ({ html }) => {
  const previewRef = React.useRef(null);
  const shadow = React.useRef();

  React.useEffect(() => {
    if (!shadow.current) {
      shadow.current = previewRef.current.attachShadow({ mode: 'open' });
    }
    shadow.current.innerHTML = html;
  }, [html]);

  return <div ref={previewRef} className="preview-html" />;
};

export default () => {
  const { ui, doc, user } = useDocumentContext();
  const { currentPage } = ui;
  const pageRef = React.useRef();

  const elements = React.useMemo(() => {
    let index = 0;
    return html2React(currentPage.mergeHtml, {
      replace: (domNode) => {
        if (domNode?.attribs?.['data-user-uuid'] === user.uuid) {
          const signatureKey = `zone${index}`;
          index = index + 1;
          const type = domNode?.attribs?.['data-action-type'] || 'signature';
          return (
            <SignaturePlaceHolder page={currentPage} type={type} signatureKey={signatureKey} />
          );
        }
      }
    });
  }, [currentPage.mergeHtml]);

  React.useEffect(() => {
    pageRef.current.scrollTop = 0;
  }, [currentPage.uuid]);

  return (
    <Wrapper>
      <Body ref={pageRef}>
        <Page>
          {user.hasSigned || !user.requireSignature || doc.isExpired ? (
            <HtmlRender html={currentPage.mergeHtml} />
          ) : (
            <div>{elements}</div>
          )}
        </Page>
      </Body>
      {doc.pages.length > 1 && (
        <Pager>
          <Button disabled={ui.prevPageDisabled} onClick={ui.prevPage}>
            <Icon name="fa-light fa-chevron-left" clickable />
          </Button>
          Page {ui.activePage + 1}/{doc.pages.length}
          <Button disabled={ui.nextPageDisabled} onClick={ui.nextPage}>
            <Icon name="fa-light fa-chevron-right" clickable />
          </Button>
        </Pager>
      )}
    </Wrapper>
  );
};
