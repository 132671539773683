import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { UserContext } from 'state';
import { LOGIN_INIT, FORGOT_PASSWORD } from 'dal/user';
import { useContextId, useOrgId } from 'hooks/useSessionVariables';
import useAudience from 'hooks/useAudience';
import useMobile from 'hooks/useMobile';
import { LoginField, LoginActions } from '../common';

const Wrapper = styled.div`
  .login-form {
    display: flex;
    flex-flow: column;
    gap: 20px;
  }
`;

const SignupSchema = (t) => {
  return Yup.object().shape({
    email: Yup.string()
      .email(`${t('form.validate.invalidEmail')}`)
      .required(`${t('form.validate.email')}`),
    auth: Yup.string().required(`${t('form.validate.password')}`)
  });
};

export default ({ content, handleActions }) => {
  const { isTabletOrMobile } = useMobile();
  const [audience] = useAudience();
  const contextId = useContextId();
  const orgId = useOrgId();
  const [loginError, setLoginError] = React.useState();
  const { userContext, setUserContext } = React.useContext(UserContext);
  const { t } = useTranslation();

  const { values, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { email: '', auth: '' },
    validationSchema: SignupSchema(t),
    onSubmit: (values) => {
      LOGIN_INIT(values)
        .then((response) => {
          setUserContext({ ...userContext, ...response });
          handleActions({ id: 'setView', data: 'loginChallenge' });
        })
        .catch((err) => {
          setLoginError('Incorrect email or password');
          console.error({ errData: err.data, err });
        });
    }
  });
  const fieldBlurAndFocus = (e) => {
    if (isTabletOrMobile) {
      e.target.previousSibling?.scrollIntoView(true);
    }
    handleBlur(e);
  };

  const forgotPassword = () => {
    if (!values.email) {
      return toast.error('Please enter your email address');
    }
    FORGOT_PASSWORD({
      email: values.email,
      locationId: contextId,
      orgId,
      audience: audience?.uuid
    })
      .then(() => {
        toast.success(`Please check your email ${values.email} for instructions`);
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };

  return (
    <Wrapper>
      {content.title && <div className="title">{content.title}</div>}
      {content.description && <div className="description">{content.description}</div>}
      <div className="sub-title">
        <Trans i18nKey="login.title">Log In</Trans>
      </div>
      <div className="login-form">
        <LoginField
          label="Email"
          name="email"
          icon="Email"
          onChange={handleChange}
          onBlur={fieldBlurAndFocus}
          value={values.email}
          errorText={errors.email}
          onFocus={() => setLoginError(undefined)}
        />
        <LoginField
          label="Password"
          name="auth"
          type="password"
          icon="password"
          errorText={loginError}
          showStrength={false}
          onChange={handleChange}
          onBlur={fieldBlurAndFocus}
          value={values.auth}
          onFocus={() => setLoginError(undefined)}
        />
        <LoginActions
          handleActions={handleActions}
          onLogin={handleSubmit}
          onForgotPassword={forgotPassword}
          state={'login'}
        />
      </div>
    </Wrapper>
  );
};
