import React from 'react';
import styled from 'styled-components';
import { SEARCH_LOCATIONS } from '../dal/forms';
import { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import Button from 'components/button';
import Icon from 'components/icon';

const Wrapper = styled.div``;

const OptionItem = styled.div`
  .subtitle {
    font-size: 0.75em;
    color: #777;
  }
`;

const List = styled.div`
  margin-top: 8px;
`;

const Card = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  padding: 10px 15px;
  background-color: #fff;
  border-radius: 4px;
  margin-bottom: 4px;
  .body {
    flex: 1;
  }
  .subtitle {
    font-size: 0.75em;
    color: #777;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const searchCards = async (callback, params) => {
  try {
    const result = await SEARCH_LOCATIONS({ data: params });
    const options = result?.data?.map((option) => ({
      ...option,
      label: option.name,
      value: option._id
    }));
    callback(options || []);
  } catch (error) {
    callback([]);
    console.error(error);
  }
};

const debouncedSearch = debounce(searchCards, 300);

const Option = (props) => {
  const { address, name } = props.data;
  return (
    <components.Option {...props}>
      <OptionItem>
        <div>{name}</div>
        <div className="subtitle">{address?.formattedAddress}</div>
      </OptionItem>
    </components.Option>
  );
};

const styles = {
  control: (styles) => ({ ...styles, borderColor: '#ddd' }),
  menu: (styles) => ({
    ...styles,
    zIndex: 300
  })
};

export default ({ name, onChange, field: { formId, boardId, uuid: fieldUuid }, value }) => {
  const getStateValue = () => {
    if (Array.isArray(value)) return value.filter((v) => v);
    return value ? [].concat(value) : [];
  };
  const [cards, setCards] = React.useState(getStateValue);
  const handleRemove = (id) => setCards(cards.filter((c) => c._id !== id));
  const handleChange = ({ name, value, address }) => {
    if (cards.some((c) => c._id === value)) return;
    const val = { name, _id: value, duogram: String.duogram(name) };
    if (address?.formattedAddress) val.locationAddress = address.formattedAddress;
    setCards([...cards, val]);
  };

  const loadOptions = (query, callback) => {
    debouncedSearch(callback, { query, formId, boardId, fieldUuid });
  };

  React.useEffect(() => {
    onChange && onChange({ target: { name, value: cards } });
  }, [cards]);

  return (
    <Wrapper>
      <AsyncSelect
        placeholder="Search..."
        value=""
        onChange={handleChange}
        loadOptions={loadOptions}
        components={{ Option }}
        styles={styles}
      />
      {cards.length > 0 && (
        <List>
          {cards.map((c) => (
            <Card key={c._id}>
              <div className="body">
                <div>{c.name}</div>
                {c.locationAddress && <div className="subtitle">{c.locationAddress}</div>}
              </div>
              <Button onClick={() => handleRemove(c._id)}>
                <Icon name="fa-thin fa-xmark" />
              </Button>
            </Card>
          ))}
        </List>
      )}
    </Wrapper>
  );
};
