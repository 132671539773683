import React from 'react';
import Modal, { ModalScrollable, ModalTitle, ModalHeader } from 'components/modal';

export default ({ open = false, onClose }) => {
  return !open ? null : (
    <Modal
      closeOnOutsideClick={true}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      width="auto"
      height="auto">
      <ModalHeader>
        <ModalTitle>Property no longer available!</ModalTitle>
      </ModalHeader>
      <ModalScrollable>
        Thanks for your interest!
        <br /> <br />
        The location you've selected is no longer available to tour. Please choose another one of
        our homes.
      </ModalScrollable>
    </Modal>
  );
};
