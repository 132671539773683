import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';

const LocationContent = styled.div`
  display: flex;
  align-items: flex-start;
  flex-flow: column;
  .address {
    font-size: 12px;
    font-weight: normal;
  }
  .address-change {
    font-size: 12px;
    font-weight: normal;
    display: flex;
    width: 100%;
    margin-top: 5px;
    justify-content: flex-end;
  }
`;

const ExtrasPod = styled.div`
  display: flex;
  gap: 20px;
  > div {
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
  }
`;
const Data = styled.div`
  margin-top: -72px;
  .boxes {
    display: flex;
    gap: 20px;
  }
  .section-title {
    color: #fff;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`;
const DataBox = styled.div`
  ${({ flex }) => (flex ? 'display:flex;align-items: center;justify-content: center;' : '')}
  ${({ size }) => (size ? `min-width:${size};` : '')}
  flex-shrink: 0;
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 10px 20px;
  font-size: 18px;
  min-height: 80px;
  font-weight: bold;
  .title {
    text-transform: uppercase;
    color: #a8b2b7;
    font-size: 12px;
  }
  .box-subtitle {
    font-weight: normal;
    font-size: 12px;
  }
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  i {
    color: initial;
    font-size: 15px;
    top: -4px;
    position: relative;
    right: -13px;
  }
`;

const encodeApp = (application) => {
  const householdMembers = (application?.applicants || []).reduce(
    (f, c) =>
      Object.assign(f, {
        [c.type === 'guarantor' ? 'guarantors' : 'tenants']:
          f[c.type === 'guarantor' ? 'guarantors' : 'tenants'] + 1
      }),
    {
      tenants: 0,
      guarantors: 0
    }
  );
  householdMembers.minors = application?.minors?.length || 0;
  householdMembers.cats = application?.pets?.filter((a) => a.type === 'cat')?.length || 0;
  householdMembers.dogs = application?.pets?.filter((a) => a.type === 'dog')?.length || 0;
  householdMembers.miscPets = application?.pets?.filter((a) => a.type === 'other')?.length || 0;
  householdMembers.cars = application?.vehicles?.filter((a) => a.type === 'car')?.length || 0;
  householdMembers.motorcycles =
    application?.vehicles?.filter((a) => a.type === 'motorcycle')?.length || 0;
  householdMembers.miscVehicles =
    application?.vehicles?.filter((a) => ['other', 'trailer'].includes(a.type))?.length || 0;
  householdMembers.vehicleOrPets =
    (application?.pets?.length || 0) + (application?.vehicles?.length || 0);

  return householdMembers;
};

export default ({ applicant, application, applicationConfig, handleActions }) => {
  if (applicant) {
    return (
      <Data gridSize={1}>
        <div className="section-title">Applicant at a Glance</div>
        <div className="boxes">
          <DataBox>
            <div className="title">TOURED</div>
            {!applicant.hasToured || !applicant.tourDate?.toLocaleDateString
              ? 'No'
              : applicant.tourDate.toLocaleDateString()}
          </DataBox>
        </div>
      </Data>
    );
  }

  const householdMembers = encodeApp(application);
  const gridSize = householdMembers.vehicleOrPets > 0 ? 4 : 3;
  return (
    <Data gridSize={gridSize}>
      <div className="section-title">Application at a Glance</div>
      <div className="boxes">
        {!applicationConfig?.disabledSections?.locationInformation && (
          <DataBox>
            <TitleRow className="title">
              <div>Application Location</div>
              {application.hasPastLocations && (
                <Icon
                  name="fa-duotone fa-clock-rotate-left"
                  onClick={() => handleActions({ id: 'location-history' })}
                />
              )}
            </TitleRow>
            <LocationContent>
              <div>{application?.location?.name}</div>
              {(application?.location?.general ||
                (application?.location?.address?.formattedAddress &&
                  application?.location?.address?.formattedAddress !==
                    application?.location?.name)) && (
                <div className="address">
                  {application?.location?.general
                    ? 'Generic Unit'
                    : application?.location?.address?.formattedAddress || ''}
                </div>
              )}
            </LocationContent>
          </DataBox>
        )}
        {application?.moveIn?.toLocaleDateString && (
          <DataBox>
            <div className="title">Desired Move-In Date</div>
            {application?.moveIn?.toLocaleDateString()}
          </DataBox>
        )}
        <DataBox>
          <div className="title">Household Members</div>
          <div>
            <div onClick={() => handleActions({ id: 'scroll-to', data: 'application-members' })}>
              {householdMembers.tenants} Adult{householdMembers.tenants > 1 ? 's' : ''}
            </div>
            {!!householdMembers.minors && (
              <div onClick={() => handleActions({ id: 'scroll-to', data: 'application-minors' })}>
                {householdMembers.minors} Minor{householdMembers.minors > 1 ? 's' : ''}
              </div>
            )}
            {!!householdMembers.guarantors && (
              <div onClick={() => handleActions({ id: 'scroll-to', data: 'application-members' })}>
                {householdMembers.guarantors} Guarantor{householdMembers.guarantors > 1 ? 's' : ''}
              </div>
            )}
          </div>
        </DataBox>
        {!!householdMembers.vehicleOrPets && (
          <DataBox flex={true}>
            <ExtrasPod>
              {application?.vehicles?.length > 0 && (
                <div
                  onClick={() => handleActions({ id: 'scroll-to', data: 'application-vehicles' })}>
                  <Icon name="fa-duotone fa-car-side" size={24} />
                  <div>
                    {application?.vehicles?.length} Vehicle
                    {application?.vehicles?.length > 1 ? 's' : ''}
                  </div>
                </div>
              )}
              {application?.pets?.length > 0 && (
                <div
                  onClick={() => handleActions({ id: 'scroll-to', data: 'application-animals' })}>
                  <Icon name="fa-duotone fa-dog" size={24} />
                  <div>
                    {application?.pets?.length} Animal{application?.pets?.length > 1 ? 's' : ''}
                  </div>
                </div>
              )}
            </ExtrasPod>
          </DataBox>
        )}
      </div>
    </Data>
  );
};
