import React from 'react';
import styled from 'styled-components';
import { Row, Title, PageContainer, PageWrapper } from './common-styles';
import Button from 'components/button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import SignaturePad from 'components/signature-pad';
import FormGroup from 'components/form-group';
import Input from 'components/input';
import { UserContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import AddressPin from 'components/addressPin';
import { ADD_FILE } from 'dal/applications';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';
import Icon from 'components/icon';
import { renderToString } from 'react-dom/server';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';

const schema = yup.object({});

const StyledPageWrapper = styled(PageWrapper)`
  .signature {
    max-width: 100% !important;
    height: 100px !important;
  }
`;
const PageDescription = styled.div`
  ul {
    margin: 10px 20px;
    padding: 0;
  }
  .table {
    li {
      list-style-type: none;
      border: 1px solid #777;
      margin-top: -1px;
      display: flex;
      padding: 10px;
      div {
        width: 50%;
      }
    }
  }
  .tableCols {
    li {
      list-style-type: none;
      border: 1px solid #777;
      margin-top: -1px;
      display: flex;
      div {
        padding: 10px;
        width: 50%;
        border-right: 1px solid;
      }
      div:last-child {
        border-right: 0;
      }
      h4 {
        margin: 0;
      }
    }
  }
`;
const ApplicantInfoRow = styled.div`
  display: flex;
  gap: 10px;
  > div {
    width: 50%;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

function urltoFile(url, filename, mimeType) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}

const TermsWrapper = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 10px;
  .terms-header {
    background-color: #b3bdc5;
    padding: 4px 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-weight: bold;
  }
  .content {
    padding: 10px;
    max-height: 300px;
    overflow-y: auto;
  }
`;

const sheet = new ServerStyleSheet();
const Terms = ({ children, title }) => {
  const handlePrint = () => {
    const content = renderToString(
      <StyleSheetManager sheet={sheet.instance}>
        <div style={{ padding: 20 }}>{children}</div>
      </StyleSheetManager>
    );
    const styleTags = sheet.getStyleTags();
    const printLayout = `
      <html>
        <head>
          <title>${title}</title>
          <style>
          ${styleTags}
          </style>
          <script>
            window.onload = () => {
              print();
            }
          </script>
        </head>
        <body>
          ${content}
        </body>
      </html>`;
    const w = window.open('', 'print', `width=${screen.width},height=${screen.height}`);
    w.document.write(printLayout);
    w.document.close();
  };

  return (
    <TermsWrapper>
      <div className="terms-header">
        <span>{title}</span> <Icon name="fa-solid fa-print" onClick={handlePrint} />
      </div>
      <div className="content">{children}</div>
    </TermsWrapper>
  );
};

export default ({ onNext, onBack, application, saveApplicationPart, onRefresh }) => {
  const { t } = useTranslation();
  const { userContext } = React.useContext(UserContext);

  const [location] = useAPIData(
    'context.LOAD_CONTEXT',
    !application?.locationId
      ? undefined
      : { userContext, contextId: application?.locationId, bypass: true },
    {
      disableEmptyArgs: true,
      reFetchWhen: [application?._id, application?.locationId]
    }
  );
  const [applicationTerms] = useAPIData(
    'application.APPLICATION_TERMS',
    !application?.locationId
      ? undefined
      : {
          userContext,
          applicationId: application?._id,
          contextId: application?.locationId,
          bypass: true
        },
    {
      disableEmptyArgs: true,
      reFetchWhen: [application?._id, application?.locationId]
    }
  );

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: { name: '', signature: '' },
    validationSchema: schema,
    onSubmit: () => {
      urltoFile(values.signature, 'terms_signature', 'image/png')
        .then((file) =>
          ADD_FILE({
            userContext,
            locationId: application.locationId,
            applicationId: application._id,
            field: 'terms_signature',
            file: file
          })
        )
        .then(() => {
          saveApplicationPart({
            termsConsent: { consented: true, termsConsentName: values.name }
          }).then(() => {
            onRefresh();
            onNext();
          });
        })
        .catch((err) => {
          toast.error(
            `Signature failed: ${err.data?.message ? err.data?.message : err.message || err.name}`
          );
        });
    }
  });
  const applicantInfo = React.useMemo(() => {
    return []
      .concat({
        firstName: application.applicant.firstName,
        lastName: application.applicant.lastName,
        type: 'self'
      })
      .concat(application.otherContacts)
      .filter((z) => z && z?.type !== 'emergencyContact');
  });

  const locationName = location?.name || location?.applicationConfig?.marketingTitle || '';

  return (
    <StyledPageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('orgTerms.title')}</Title>
        </Row>
        <Row>
          <ApplicantInfoRow>
            <div>
              <Trans i18nKey="orgTerms.applying">
                <strong>Applying For</strong>
              </Trans>
            </div>
            <div>
              <Trans i18nKey="orgTerms.applicant">
                <strong>Applicant{applicantInfo.length > 1 ? 's' : ''}</strong>
              </Trans>
            </div>
          </ApplicantInfoRow>
        </Row>
        <Row>
          <ApplicantInfoRow>
            <div>
              {locationName && <div>{locationName}</div>}
              {location?.address && <AddressPin address={location?.address} />}
            </div>
            <div>
              {applicantInfo?.map((z, ix) => (
                <div key={ix}>
                  {z.firstName || ''} {z.lastName || ''}
                  {z.type === 'self' ? `${t('you')}` : ''}
                </div>
              ))}
            </div>
          </ApplicantInfoRow>
        </Row>
        <Row>
          <Terms title={t('consent.applicantTerms')}>
            <div
              dangerouslySetInnerHTML={{
                __html: applicationTerms?.content
              }}
            />
          </Terms>
        </Row>
        <Row>
          <FormGroup label={t('consent.lableName')} errorText={errors.name}>
            <Input name="name" value={values.name} onChange={handleChange} />
          </FormGroup>
          <FormGroup label={t('consent.signature')} errorText={errors.signature}>
            <SignaturePad
              className="signature"
              name="signature"
              value={values.signature}
              onChange={handleChange}
            />
          </FormGroup>
        </Row>
        <Row className="center flex gap-10">
          <Button variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.backButton')}
          </Button>
          <Button
            disabled={!values.signature || !values.name}
            radius="25px"
            variant="primary"
            onClick={handleSubmit}
            width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </StyledPageWrapper>
  );
};
