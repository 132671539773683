import styled from 'styled-components';

const PageWrapper = styled.div`
  .headerRow {
    font-weight: bold;
    margin-top: 15pt;
    margin-right: 0in;
    margin-bottom: 7.5pt;
    margin-left: 0in;
    background: white;
    font-size: 14.5pt;
    font-family: 'Lato', sans-serif;
    color: #333333;
  }
`;

export default () => {
  return (
    <PageWrapper>
      <div>
        <p className="headerRow">CONSENT SUMMARY</p>

        <p>
          <span>
            I understand that by consenting below I'm providing 'WRITTEN INSTRUCTIONS' to Aptly
            under the fair credit reporting act authorizing <b>Credit Reporting Services, Inc. </b>
            to obtain information from my personal credit profile or other information from credit
            bureaus. I authorize company to obtain such information solely to approve me to rent a
            home.
          </span>
        </p>

        <p className="headerRow">DISCLOSURE REGARDING BACKGROUND INVESTIGATION ON YOU</p>

        <p>
          <span>
            ('the Company') may obtain 'consumer reports' about you from a consumer reporting agency
            for tenant screening purposes. A 'consumer report' is a background screening report that
            may contain information regarding your social security number and identification
            documents, criminal history, sex offender registry status, credit history, employment
            history, education history, driving history, professional licenses, drug testing results
            and other information about you. It may bear upon your character, general reputation,
            personal characteristics, and/or mode of living.
          </span>
        </p>

        <p className="headerRow">NOTICE REGARDING INVESTIGATIVE CONSUMER REPORTS</p>

        <p>
          <span>
            ('the Company') may also request an 'investigative consumer report' on you from a
            consumer reporting agency. An 'investigative consumer report' is a background screening
            report generated through personal interviews with sources such as your neighbors,
            friends or associates. The consumer reporting agency that may prepare an 'investigative
            consumer report' on you for the Company is <b>Credit Reporting Services, Inc. (CRS)</b>,
            548 Market St, #72907, San Francisco, CA, 94104, United States. The information
            contained in an 'investigative consumer report' may bear upon your character, general
            reputation, personal characteristics, and/or mode of living. Please be advised that the
            nature and scope of the most common form of investigative consumer report that may be
            ordered by the Company is an investigation into your tenant/rental history. During such
            an investigation, CRS may ask questions about your tenant/rental history to certain
            knowledgeable individuals and provide response information to the Company. Note: You
            have the right to request additional information regarding the nature and scope of any
            'investigative consumer report' ordered by the Company on you. You may do so by
            contacting the Company
          </span>
        </p>

        <p className="headerRow">ADDITIONAL STATE LAW NOTICES</p>

        <p>
          <span>
            If you live in or are seeking to rent property from ('the Company') in Washington State,
            Massachusetts, New Jersey, New York, or California, please note the following
            information which we are required by state law to provide to you
          </span>
        </p>

        <ul type="disc">
          <li>
            <span>
              State of Washington applicants/residents only: If the Company requests an
              investigative consumer report (as defined by state law) from a consumer reporting
              agency, you have the right to receive a complete and accurate disclosure of the nature
              and scope of the investigation requested by the Company. You also have the right to
              request a written summary of your rights and remedies under the Washington Fair Credit
              Reporting Act.
            </span>
          </li>
          <li>
            <span>
              For Massachusetts applicants/residents only: If the business requests a consumer
              report (as defined by state law) from a consumer reporting agency, you have the right
              to obtain a copy of the report upon request.
            </span>
          </li>
          <li>
            <span>
              New Jersey applicants/residents only: If the Company requests an investigative
              consumer report (as defined by state law) from a consumer reporting agency, you have
              the right to have a copy of the report upon request.
            </span>
          </li>
          <li>
            <span>
              New York applicants/residents only: You have the right, upon written request, to be
              informed of whether or not an investigative consumer report (as defined by state law)
              was requested from a consumer reporting agency. If a report was requested, you will be
              provided with the name and address of the consumer reporting agency to whom the
              request was made. You may also inspect and receive a copy of the report by contacting
              CRS, 548 Market St, #72907, San Francisco, CA, 94104, United States.
            </span>
          </li>
          <li>
            <span>
              California applicants/residents only: In addition to this document, you are separately
              receiving a copy of the NOTICE REGARDING BACKGROUND INVESTIGATION PURSUANT TO
              CALIFORNIA LAW.
            </span>
          </li>
        </ul>

        <p className="headerRow">
          <span>NOTICE REGARDING BACKGROUND INVESTIGATION PURSUANT TO CALIFORNIA LAW</span>
        </p>

        <p>
          <span>
            ('the Company') intends to obtain information about you from an investigative consumer
            reporting agency and a consumer credit reporting agency for tenant screening purposes.
          </span>
        </p>

        <p>
          <span>
            Thus, you can expect to be the subject of 'investigative consumer reports' and 'consumer
            credit reports' obtained for tenant screening purposes. Investigative consumer reports
            about you will be prepared by an investigative consumer reporting agency ('ICRA') named{' '}
            <b>Credit Reporting Services, Inc.</b>, 548 Market St, #72907, San Francisco, CA, 94104,
            United States. They may include information regarding your social security number and
            identification documents, criminal history, sex offender registry status, credit
            history, employment history, education history, driving history, professional licenses,
            drug testing results and other information about you. They may also include comments
            from individuals who are knowledgeable about your tenant/rental history or other
            information about you. Consumer credit reports about you will be prepared by a consumer
            credit reporting agency ('CCRA') named <b>Credit Reporting Services, Inc.</b>, 548
            Market St, #72907, San Francisco, CA, 94104, United States. They may include information
            regarding your credit score, payment history, credit history, and other information
            about you.
          </span>
        </p>

        <p>
          <span>
            The reports obtained by the Company may include information about your character,
            general reputation, personal characteristics, and mode of living and may be used as a
            factor in making tenant-related decisions.
          </span>
        </p>

        <p>
          <span>
            Under California Civil Code section 1786.22, you are entitled to find out from an ICRA
            what is in the ICRA's file on you with proper identification, as follows:
          </span>
        </p>

        <ul type="disc">
          <li>
            <span>
              In person, by visual inspection of your file during normal business hours and upon
              reasonable notice. You also may request a copy of the information in person. The ICRA
              may not charge you more than the actual copying costs for providing you with a copy of
              your file. By requesting a copy be sent to a specified addressee by certified mail.
              ICRAs complying with requests for certified mailings shall not be liable for
              disclosures to third parties caused by mishandling of mail after such mailings leave
              the ICRAs.
            </span>
          </li>
          <li>
            <span>
              A summary of all information contained in the ICRA's file on you (which is required to
              be provided by the California Civil Code) will be provided to you via telephone, if
              you have made a written request for telephone disclosure and the toll charge, if any,
              for the telephone call is prepaid by or charged directly to you.
            </span>
          </li>
        </ul>

        <p>
          <span>
            'Proper Identification' includes documents such as a valid driver's license, social
            security account number, military identification card, and credit cards. Only if you
            cannot identify yourself with such information may the ICRA require additional
            information concerning your employment and personal or family history in order to verify
            your identity.
          </span>
        </p>

        <p>
          <span>
            The ICRA will provide trained personnel to explain any information furnished to you and
            will provide a written explanation of any coded information contained in files
            maintained on you. This written explanation will be provided whenever a file is provided
            to you for visual inspection.
          </span>
        </p>

        <p>
          <span>
            You may be accompanied by one other person of your choosing, who must furnish reasonable
            identification. An ICRA may require you to furnish a written statement granting
            permission to the ICRA to discuss your file in such person's presence.
          </span>
        </p>

        <p className="headerRow">
          <span>A Summary of Your Rights Under the Fair Credit Reporting Act</span>
        </p>

        <p>
          <span>Para informaci n en espa ol, visite&nbsp;</span>
          <span>
            <a href="https://www.consumerfinance.gov/learnmore">
              <span>https://www.consumerfinance.gov/learnmore</span>
            </a>
          </span>
          <span>
            &nbsp;o escribe a la Consumer Financial Protection Bureau, 1700 G Street N.W.,
            Washington, DC 20552.
          </span>
        </p>

        <p>
          <span>
            The federal Fair Credit Reporting Act (FCRA) promotes the accuracy, fairness, and
            privacy of information in the files of consumer reporting agencies. There are many types
            of consumer reporting agencies, including credit bureaus and specialty agencies (such as
            agencies that sell information about check writing histories, medical records, and
            rental history records). Here is a summary of your major rights under FCRA. For more
            information, including information about additional rights, go to
            www.consumerfinance.gov/learnmore or write to: Consumer Financial Protection Bureau,
            1700 G Street N.W., Washington, DC 20552.
          </span>
        </p>

        <ul type="disc">
          <li>
            <span>
              You must be told if information in your file has been used against you. Anyone who
              uses a credit report or another type of consumer report to deny your application for
              credit, insurance, or employment or to take another adverse action against you must
              tell you, and must give you the name, address, and phone number of the agency that
              provided the information
            </span>
          </li>
          <li>
            <span>
              You have the right to know what is in your file. You may request and obtain all the
              information about you in the files of a consumer reporting agency (your 'file
              disclosure'). You will be required to provide proper identification, which may include
              your Social Security number. In many cases, the disclosure will be free. You are
              entitled to a free file disclosure if:
            </span>
          </li>
          <ul type="disc">
            <li>
              <span>
                a person has taken adverse action against you because of information in your credit
                report;
              </span>
            </li>
            <li>
              <span>
                you are the victim of identity theft and place a fraud alert in your file;
              </span>
            </li>
            <li>
              <span>your file contains inaccurate information as a result of fraud;</span>
            </li>
            <li>
              <span>you are on public assistance;</span>
            </li>
            <li>
              <span>you are unemployed but expect to apply for employment within 60 days.</span>
            </li>
          </ul>
          <li>
            <span>
              In addition, all consumers are entitled to one free disclosure every 12 months upon
              request from each nationwide credit bureau and from nationwide specialty consumer
              reporting agencies. See&nbsp;
            </span>
            <span>
              <a href="https://www.consumerfinance.gov/learnmore">
                <span>www.consumerfinance.gov/learnmore</span>
              </a>
            </span>
            <span>&nbsp;for additional information.</span>
          </li>
          <li>
            <span>
              You have the right to ask for a credit score. Credit scores are numerical summaries of
              your credit-worthiness based on information from credit bureaus. You may request a
              credit score from consumer reporting agencies that create scores or distribute scores
              used in residential real property loans, but you will have to pay for it. In some
              mortgage transactions, you will receive credit score information for free from the
              mortgage lender.
            </span>
          </li>
          <li>
            <span>
              You have the right to dispute incomplete or inaccurate information. If you identify
              information in your file that is incomplete or inaccurate, and report it to the
              consumer reporting agency, the agency must investigate unless your dispute is
              frivolous. See for an explanation of dispute procedures.
            </span>
          </li>
          <li>
            <span>
              Consumer reporting agencies must correct or delete inaccurate, incomplete, or
              unverifiable information. Inaccurate, incomplete, or unverifiable information must be
              removed or corrected, usually within 30 days. However, a consumer reporting agency may
              continue to report information it has verified as accurate.
            </span>
          </li>
          <li>
            <span>
              Consumer reporting agencies may not report outdated negative information. In most
              cases, a consumer reporting agency may not report negative information that is more
              than seven years old, or bankruptcies that are more than 10 years old.
            </span>
          </li>
          <li>
            <span>
              Access to your file is limited. A consumer reporting agency may provide information
              about you only to people with a valid need usually to consider an application with a
              creditor, insurer, employer, landlord, or other business. The FCRA specifies those
              with a valid need for access.
            </span>
          </li>
          <li>
            <span>
              You must give your consent for reports to be provided to employers. A consumer
              reporting agency may not give out information about you to your employer, or a
              potential employer, without your written consent given to the employer. Written
              consent generally is not required in the trucking industry. For more information, go
              to{' '}
            </span>
            <span>
              <a href="http://www.consumerfinance.gov/learnmore">
                <span>www.consumerfinance.gov/learnmore</span>
              </a>
            </span>
            <span>.</span>
          </li>
          <li>
            <span>
              You may limit ''prescreened'' offers of credit and insurance you get based on
              information in your credit report. Unsolicited 'prescreened' offers for credit and
              insurance must include a toll-free phone number you can call if you choose to remove
              your name and address form the lists these offers are based on. You may opt out with
              the nationwide credit bureaus at 1-888-5-OPTOUT (1-888-567-8688).
            </span>
          </li>
          <li>
            <span>
              The following FCRA right applies with respect to nationwide consumer reporting
              agencies:
            </span>
          </li>
        </ul>

        <p className="headerRow">CONSUMERS HAVE THE RIGHT TO OBTAIN A SECURITY FREEZE</p>

        <p>
          <span>
            You have a right to place a 'security freeze' on your credit report, which will prohibit
            a consumer reporting agency from releasing information in your credit report without
            your express authorization. The security freeze is designed to prevent credit, loans,
            and services from being approved in your name without your consent. However, you should
            be aware that using a security freeze to take control over who gets access to the
            personal and financial information in your credit report may delay, interfere with, or
            prohibit the timely approval of any subsequent request or application you make regarding
            a new loan, credit, mortgage, or any other account involving the extension of credit.
          </span>
        </p>

        <p>
          <span>
            As an alternative to a security freeze, you have the right to place an initial or
            extended fraud alert on your credit file at no cost. An initial fraud alert is a 1-year
            alert that is 3 placed on a consumer s credit file. Upon seeing a fraud alert display on
            a consumer s credit file, a business is required to take steps to verify the consumer s
            identity before extending new credit. If you are a victim of identity theft, you are
            entitled to an extended fraud alert, which is a fraud alert lasting 7 years.
          </span>
        </p>

        <p>
          <span>
            A security freeze does not apply to a person or entity, or its affiliates, or collection
            agencies acting on behalf of the person or entity, with which you have an existing
            account that requests information in your credit report for the purposes of reviewing or
            collecting the account. Reviewing the account includes activities related to account
            maintenance, monitoring, credit line increases, and account upgrades and enhancements.
          </span>
        </p>

        <ul type="disc">
          <li>
            <span>
              You may seek damages from violators. If a consumer reporting agency, or, in some
              cases, a user of consumer reports or a furnisher of information to a consumer
              reporting agency violates the FCRA, you may be able to sue in state or federal court.
            </span>
          </li>
          <li>
            <span>
              Identity theft victims and active duty military personnel have additional rights. For
              more information, visit&nbsp;
            </span>
            <span>
              <a href="https://www.consumerfinance.gov/learnmore">
                <span>www.consumerfinance.gov/learnmore</span>
              </a>
            </span>
            <span>.</span>
          </li>
        </ul>

        <p>
          <span>
            States may enforce the FCRA, and many states have their own consumer reporting laws. In
            some cases, you may have more rights under state law. For more information, contact your
            state or local consumer protection agency or your state Attorney General. For
            information about your federal rights, contact:
          </span>
        </p>

        <ul type="disc">
          <li>
            <span>
              Banks, savings associations, and credit unions with total assets of over $10 billion
              and their affiliates
            </span>
          </li>
          <li>
            <span>
              Such affiliates that are not banks, savings associations, or credit unions also should
              list, in addition to the CFPB:
              <br />
              <br />
              Consumer Financial Protection Bureau
              <br />
              1700 G Street, N.W.
              <br />
              Washington, DC 20552
              <br />
              <br />
              Federal Trade Commission
              <br />
              Consumer Response Center
              <br />
              600 Pennsylvania Avenue, N.W.
              <br />
              Washington, DC 20580
              <br />
              (877) 382-4357
              <br />
              <br />
            </span>
          </li>
        </ul>

        <p>
          <span>
            {' '}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span>To the extent not included in item 1 above:</span>
        </p>

        <p>
          <span>
            {' '}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span>
            National banks, federal savings associations, and federal branches and federal agencies
            of foreign banks
          </span>
        </p>

        <p>
          <span>
            {' '}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span>
            State member banks, branches and agencies of foreign banks (other than federal branches,
            federal agencies, and Insured State Branches of Foreign Banks), commercial lending
            companies owned or controlled by foreign banks, and organizations operating under
            section 25 or 25A of the Federal Reserve Act.
          </span>
        </p>

        <p>
          <span>
            {' '}
            <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </span>
          </span>
          <span>
            Nonmember Insured Banks, Insured State Branches of Foreign Banks, and insured state
            savings associations
          </span>
        </p>
        <p>
          <span>Federal Credit Unions</span>
        </p>

        <p>
          <span>
            Office of the Comptroller of the Currency
            <br />
            Customer Assistance Group
            <br />
            1301 McKinney Street, Suite 3450
            <br />
            Houston, TX 77010-9050
            <br />
            <br />
            Federal Reserve Consumer Help Center
            <br />
            P.O. Box 1200
            <br />
            Minneapolis, MN 55480
            <br />
            <br />
            FDIC Consumer Response Center
            <br />
            1100 Walnut Street, Box #11
            <br />
            Kansas City, MO 64106
            <br />
            <br />
          </span>
        </p>

        <p>
          <span>
            National Credit Union Administration
            <br />
            Office of Consumer Financial Protection (OCFP)
            <br />
            Division of Consumer Compliance Policy and Outreach
            <br />
            1775 Duke Street
            <br />
            Alexandria, VA 22314
          </span>
        </p>

        <p>
          <span>
            Air carriers
            <br />
            Asst. General Counsel for Aviation Enforcement &amp; Proceedings
            <br />
            Aviation Consumer Protection Division
            <br />
            Department of Transportation
            <br />
            1200 New Jersey Avenue, S.E.
            <br />
            Washington, DC 20590
            <br />
            <br />
            Creditors Subject to the Surface Transportation Board
            <br />
            Office of Proceedings, Surface Transportation Board
            <br />
            Department of Transportation
            <br />
            395 E Street, S.W.
            <br />
            Washington, DC 20423
          </span>
        </p>

        <p>
          <span>
            Creditors Subject to the Packers and Stockyards Act, 1921
            <br />
            Nearest Packers and Stockyards Administration area supervisor
          </span>
        </p>

        <p>
          <span>
            Small Business Investment Companies
            <br />
            Associate Deputy Administrator for Capital Access
            <br />
            United States Small Business Administration
            <br />
            409 Third Street, S.W., Suite 8200
            <br />
            Washington, DC 20416
          </span>
        </p>

        <p>
          <span>Brokers and Dealers</span>
        </p>

        <p>
          <span>
            Securities and Exchange Commission
            <br />
            100 F Street, N.E.
            <br />
            Washington, DC 20549
          </span>
        </p>

        <p>
          <span>
            Federal Land Banks, Federal Land Bank Associations, Federal Intermediate Credit Banks,
            and Production Credit Associations
          </span>
        </p>

        <p>
          <span>
            Farm Credit Administration
            <br />
            1501 Farm Credit Drive
            <br />
            McLean, VA 22102-5090
          </span>
        </p>

        <p>
          <span>
            Retailers, Finance Companies, and All Other Creditors Not Listed Above Federal Trade
            Commission
            <br />
            Consumer Response Center
            <br />
            600 Pennsylvania Avenue, N.W.
            <br />
            Washington, DC 20580
            <br />
            (877) 382-4357
          </span>
        </p>

        <p className="headerRow">
          <span>ACKNOWLEDGMENT AND AUTHORIZATION REGARDING BACKGROUND INVESTIGATION</span>
        </p>

        <p>
          <span>
            By consenting below, I acknowledge receipt of the following separate documents (and
            certify that I have read and understood them):
          </span>
        </p>

        <ul type="disc">
          <li>
            <span>DISCLOSURE REGARDING BACKGROUND INVESTIGATION ON YOU;</span>
          </li>
          <li>
            <span>A SUMMARY OF YOUR RIGHTS UNDER THE FAIR CREDIT REPORTING ACT;</span>
          </li>
          <li>
            <span>NOTICE REGARDING INVESTIGATIVE CONSUMER REPORTS; and</span>
          </li>
          <li>
            <span>ADDITIONAL STATE LAW NOTICES;</span>
          </li>
        </ul>

        <p>
          <span>
            By consenting below, I also authorize Aptly (''the Company'') to obtain ''consumer
            reports'' and ''investigative consumer reports'' about me for tenant screening purposes.
          </span>
        </p>

        <p>
          <span>
            By consenting below, I also authorize law enforcement agencies, learning institutions
            (including public and private schools, colleges and universities), information service
            bureaus, credit bureaus, data repositories, courts (federal, state and local), motor
            vehicle records agencies, my past or present employers, the military, and other public
            or private individuals and institutions to furnish any and all information on me that is
            requested by CRS, the consumer reporting agency preparing reports about me for Aptly.
            The information that can be disclosed to CRS includes, but is not limited to, criminal
            history, sex offender registry status, credit history, employment history, education
            history, driving history, and information regarding my professional licenses, social
            security number, identification documents, and drug testing results.
          </span>
        </p>

        <p>&nbsp;</p>
      </div>
    </PageWrapper>
  );
};
