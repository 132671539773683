import { useUiState } from 'state';

export default () => {
  const { updateUiState } = useUiState();
  return (props) =>
    updateUiState({
      confirmationDialog: {
        title: props.title,
        width: props.width,
        maxWidth: props.maxWidth,
        description: props.description,
        cancelLabel: props.cancelLabel,
        confirmLabel: props.confirmLabel,
        onCancel: props.onCancel,
        onConfirm: props.onConfirm,
        hideCancel: props.hideCancel
      }
    });
};
