import React from 'react';
import styled, { css } from 'styled-components';
import { useAPIData } from '../../dal/useAPIData';
import { UserContext, useNavigateWithContext } from '../../state';
import Button from '../../components/button';
import Option, { OptionsGroup } from '../../components/option';
import { useRefCode } from '../../hooks/useSessionVariables';
import Icon from '../../components/icon';
import ApplicationItem from './application-item';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { useUiState } from 'state';

const Wrapper = styled.main`
  height: 100%;
  flex: 1;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 15px;
  .select-language {
    margin-top: 10px;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  margin: 20px 0 15px;
  text-transform: uppercase;
  font-size: 1.5em;
`;

const GridOptions = styled(OptionsGroup)`
  display: flex;
  > div {
    width: 200px;
    .Option_label {
      font-size: 1.25em;
    }
  }
`;

const Add = styled(Button)`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const ActionWrapper = styled.div``;

const Grid = styled.div``;

export default () => {
  const refCode = useRefCode();
  const { userContext } = React.useContext(UserContext);
  const [refreshKey, setRefreshKey] = React.useState(new Date().getTime());
  const [initialLoad, setInitialLoad] = React.useState(false);
  let navigate = useNavigateWithContext();
  const { t } = useTranslation();
  const { updateUiState } = useUiState();
  const isMobile = useMediaQuery({ maxWidth: 960 });

  // this loads all applications for the user
  const [applicationList, listFetchInProgress] = useAPIData(
    'application.LIST_APPLICATIONS',
    { userContext },
    {
      disableEmptyArgs: true,
      reFetchWhen: [refreshKey],
      onSuccess: (data) => {
        !initialLoad && setInitialLoad(true);
        if (data.applications.length === 0 && !refCode) {
          navigate(`app/applications/form/create`);
        }
      },
      onError: () => {
        !initialLoad && setInitialLoad(true);
      }
    }
  );

  React.useEffect(() => {
    updateUiState({ menuOpen: isMobile ? false : true });
  }, [isMobile]);

  if (!initialLoad) {
    return (
      <Wrapper>
        <Container>Loading...</Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {(applicationList?.applications?.length || 0) === 0 && (
        <Container>
          <Title>{t('myApplications.startNewApplications')}</Title>
          <GridOptions className="center">
            <Option
              size={200}
              value="appCode"
              label={t('myApplications.startNewApplications')}
              icon={{ name: 'fa-plus fa-solid' }}
              onClick={() => {
                navigate(`app/applications/form/create`);
              }}
            />
            <Option
              size={200}
              value="appCode"
              label={t('myApplications.enterApplicationCode')}
              icon={{ name: 'fa-barcode fa-solid' }}
              onClick={() => navigate('app/code')}
            />
          </GridOptions>
        </Container>
      )}
      {applicationList?.applications?.length > 0 && (
        <Container>
          <Header>
            <Title>{t('myApplications.title')}</Title>
            <ActionWrapper isMobile={isMobile}>
              <Add
                variant="primary"
                height={30}
                radius="25px"
                onClick={() => {
                  navigate(`app/applications/form/create`);
                }}>
                <Icon name="fa-solid fa-plus" size={14} />
                {t('buttonsActions.new')}
              </Add>
            </ActionWrapper>
          </Header>
          <Grid>
            {applicationList?.applications
              ?.sort((a, b) => {
                let firstDate = new Date(a?.updatedAt).getTime();
                let secondDate = new Date(b?.updatedAt).getTime();
                if (!isNaN(firstDate) && !isNaN(secondDate)) {
                  return secondDate - firstDate;
                }
                if (isNaN(firstDate) && isNaN(secondDate)) {
                  return 0;
                }
                return isNaN(firstDate) ? -1 : 1;
              })
              ?.map((application, ix) => (
                <ApplicationItem
                  key={ix}
                  application={application}
                  navigate={navigate}
                  refreshList={() => setRefreshKey(new Date().getTime())}
                />
              ))}
          </Grid>
        </Container>
      )}
    </Wrapper>
  );
};
