import React from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';

import { UserContext } from 'state';
import { SAVE_APPLICATION_ITEM } from 'dal/application-items';
import Modal from 'components/modal';
import Button from 'components/button';
import FormGroup from 'components/form-group';
import Text from 'components/text';

const cancelSchema = yup.object().shape({
  reason: yup
    .string()
    .max(5000, 'Message is too long')
    .min(1, 'Please provide a reason')
    .required('Please provide a reason')
});

const Content = styled.div`
  padding: 20px 15px;
  flex: 1;
  h2 {
    margin: 0px;
  }
`;

const Actions = styled.div`
  padding: 0px 15px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;
const StyledFormGroup = styled(FormGroup)`
  margin: 15px 0px;
`;

export default ({ showing, onClose }) => {
  const { userContext } = React.useContext(UserContext);
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { reason: '' },
    validationSchema: cancelSchema,
    onSubmit: () => {
      SAVE_APPLICATION_ITEM({
        userContext,
        objectId: showing._id,
        data: {
          content: { cancel: true, cancelReason: values.reason }
        }
      })
        .then(() => {
          toast.success('Showing cancelled!');
          onClose && onClose({ cancelled: true });
        })
        .catch((err) => {
          console.error({ errData: err.data, err });
          toast.error(err.data?.message ? err.data?.message : err.message || err.name);
        });
    }
  });

  const handleConfirm = () => {
    handleSubmit();
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  return (
    <Modal open={true} height="auto" width="400px">
      <Content>
        <h2>Cancel this showing?</h2>
        <p>
          Cancelling this showing will alert the property that you are no longer interested in this
          location. Any fees collected will not be refunded. Are you sure you'd like to continue?
        </p>
        <StyledFormGroup
          label={'Reason for cancelling'}
          errorText={touched.reason && errors.reason}>
          <Text
            name="reason"
            value={values.reason}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={`Please let us know why you'd like to cancel this showing...`}
          />
        </StyledFormGroup>
      </Content>
      <Actions>
        <Button variant="secondary" onClick={handleCancel} width={80}>
          No
        </Button>
        <Button variant="primary" onClick={handleConfirm} width={80}>
          Yes
        </Button>
      </Actions>
    </Modal>
  );
};
