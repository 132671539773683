import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import Section from './section';
import { Trans } from 'react-i18next';

const DataList = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 15px;
  flex-wrap: wrap;
`;
const DataBox = styled.div`
  font-size: 0.88em;
  .dataBox-label {
    font-weight: bold;
  }
`;

const Row = styled.div`
  margin: 20px 45px;
  @media (max-width: 960px) {
    margin: 20px 10px;
  }
`;

export default ({ application = {}, t }) => {
  if (!application.section8?.active) {
    return <></>; // nothing to show here...
  }
  return (
    <Section title={<Trans i18nKey="summary.section8.title">HOUSING SUPPORT</Trans>}>
      <DataList>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.section8.voucher">Voucher Name</Trans>
          </div>
          <div className="dataBox-text">{application.section8.voucherName}</div>
        </DataBox>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.section8.beds">Voucher Bed Count</Trans>
          </div>
          <div className="dataBox-text">{application.section8.bedCount}</div>
        </DataBox>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.section8.amount">Maximum Approval Amount</Trans>
          </div>
          <div className="dataBox-text">
            {application.section8.amountApproved?.renderPrettyCurrency()}
          </div>
        </DataBox>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.section8.kids">Kids Under 6</Trans>
          </div>
          <div className="dataBox-text">{application.section8.kidsUnder6}</div>
        </DataBox>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.section8.rta">RTA Packet?</Trans>
          </div>
          {application.section8.rtaPacket ? (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle-check`} color="green" /> {t('yes')}
            </div>
          ) : (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle`} color="gray" /> {t('no')}
            </div>
          )}
        </DataBox>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.section8.budget">Budget Sheet?</Trans>
          </div>
          {application.section8.budgetSheet ? (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle-check`} color="green" /> {t('yes')}
            </div>
          ) : (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle`} color="gray" /> {t('no')}
            </div>
          )}
        </DataBox>
        <DataBox>
          <div className="dataBox-label">SSI?</div>
          {application.section8.ssi ? (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle-check`} color="green" /> {t('yes')}
            </div>
          ) : (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle`} color="gray" /> {t('no')}
            </div>
          )}
        </DataBox>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.section8.child_support">Child Support?</Trans>
          </div>
          {application.section8.childSupport ? (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle-check`} color="green" /> {t('yes')}
            </div>
          ) : (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle`} color="gray" /> {t('no')}
            </div>
          )}
        </DataBox>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.section8.security">Security Agreement?</Trans>
          </div>
          {application.section8.securityAgreement ? (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle-check`} color="green" /> {t('yes')}
            </div>
          ) : (
            <div className="dataBox-text">
              <Icon name={`fa-duotone fa-circle`} color="gray" /> {t('no')}
            </div>
          )}
        </DataBox>
      </DataList>
    </Section>
  );
};
