import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';
import FontSelect from 'components/font-select';
import { useParams } from 'react-router-dom';
import { useNavigateWithContext } from 'state';
import { renderToString } from 'react-dom/server';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';
import Print from '../summary';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { CompanyContext } from 'state';
import { SupportModal } from 'components/contactUs';
import CancelAppDialog from '../../applications/cancel-app-dialog';

const NavBar = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  .companyLogo {
    max-width: 70px;
    max-height: 55px;
    padding: 10px 0px;
  }
  .back-button {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
  .change-text-size {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
  .actions-right {
    margin-left: auto;
    display: flex;
    gap: 15px;
    align-item: center;
  }
  &.mobile {
    border-bottom: 1px solid #ddd;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    > div {
      display: flex;
      align-items: center;
    }
    > div:nth-child(2) {
      justify-content: center;
    }
    > div:nth-child(3) {
      justify-content: flex-end;
    }
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const sheet = new ServerStyleSheet();
const handlePrint = (props) => {
  const content = renderToString(
    <StyleSheetManager sheet={sheet.instance}>
      <Print {...props} />
    </StyleSheetManager>
  );
  const styleTags = sheet.getStyleTags();
  const printLayout = `
    <html>
      <head>
        <title>Print Application</title>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
        body {
          font-family: 'Mukta', sans-serif;
        }
        ${styleTags}
        </style>
        <script>
          window.onload = () => {
            setTimeout(() => {
              print();
            }, 300);
          }
        </script>
      <script src="https://kit.fontawesome.com/00cde30716.js"  crossorigin="anonymous"></script>
      </head>
      <body>
        ${content}
      </body>
    </html>`;
  const w = window.open('', 'print', `width=${screen.width},height=${screen.height}`);
  w.document.write(printLayout);
  w.document.close();
};

export default ({ applicationLocked, location, application, showTextSizeChanger }) => {
  const navigate = useNavigateWithContext();
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const params = useParams();
  const [activeModal, setActiveModal] = React.useState();
  const isOverview = !params['*'];
  const isPrint = params['*'] === 'summary';
  const isCreate = params['*'] === 'app/applications/form/create';
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const { companyContext } = React.useContext(CompanyContext);
  const [showModal, setShowModal] = React.useState();
  const goBack = (s) => {
    navigate(
      isOverview || isCreate || applicationLocked
        ? 'app/applications'
        : `app/applications/form/v2/${params.applicationId}`
    );
  };

  const onPrint = () => {
    if (isOverview) return navigate(`app/applications/form/v2/${params.applicationId}/summary`);
    handlePrint({ location, application });
  };

  return (
    <NavBar className={isMobile ? 'mobile' : ''}>
      {showCancelDialog && (
        <CancelAppDialog
          t={t}
          applicationId={application._id}
          locationId={application.locationId}
          onClose={({ cancelled } = {}) => {
            setShowCancelDialog(false);
            if (cancelled) navigate('app/applications');
          }}
        />
      )}
      {!isMobile && (
        <>
          <Button className="back-button" onClick={goBack}>
            <Icon name="fa-solid fa-chevron-left" />
            <div className="title">
              {isOverview || isCreate || applicationLocked
                ? t('buttonsActions.allApplications')
                : t('buttonsActions.overview')}
            </div>
          </Button>
          <div className="actions-right">
            {isOverview && (
              <Button variant="warning" onClick={() => setShowCancelDialog(true)}>
                {t('buttonsActions.delete')}
              </Button>
            )}
            {(isPrint || isOverview) && (
              <Button variant="secondary" onClick={onPrint}>
                {t('buttonsActions.print')}
              </Button>
            )}
            {showTextSizeChanger && (
              <Button
                className="change-text-size"
                onClick={(e) =>
                  setActiveModal({
                    modal: 'textSize',
                    anchorEl: e.target,
                    anchorDirection: 'topRight'
                  })
                }>
                <Icon name="fa-duotone fa-text-size" />
                <div className="title">{t('sidebar.textSize')}</div>
              </Button>
            )}
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className="">
            <Button className="back-button" onClick={goBack}>
              <Icon name="fa-solid fa-chevron-left" />
              <div className="title">
                {isOverview || isCreate || applicationLocked
                  ? t('buttonsActions.allApplicationsMobile')
                  : t('buttonsActions.overviewMobile')}
              </div>
            </Button>
          </div>
          <div className="logo">
            <img className="companyLogo" src={companyContext?.logo} />
          </div>
          <ActionWrapper>
            <Button
              className="change-text-size"
              title={t('sidebar.textSize')}
              onClick={(e) =>
                setActiveModal({
                  modal: 'textSize',
                  anchorEl: e.target,
                  anchorDirection: 'topRight'
                })
              }>
              <Icon name="fa-duotone fa-text-size" color="#00a1ff" size={20} />
            </Button>
            <Button onClick={() => setShowModal(true)}>
              <Icon name="fa-solid fa-messages-question" color="#00a1ff" size={20} />
            </Button>
          </ActionWrapper>
        </>
      )}
      <SupportModal
        width="600px"
        height="620px"
        modalOpen={showModal}
        setModalOpen={() => setShowModal(false)}
        isContactUs={true}
      />
      {activeModal?.modal === 'textSize' && (
        <FontSelect
          open={true}
          anchorEl={activeModal?.anchorEl}
          anchorDirection={activeModal?.anchorDirection}
          onClose={() => setActiveModal('')}
          onChange={() => (isMobile ? setActiveModal('') : undefined)}
        />
      )}
    </NavBar>
  );
};
