import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';
import { Trans } from 'react-i18next';
import { useActions } from '../useActions';

const NavBar = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2em;
  margin-top: 20px;

  .back-button {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
`;
const FailedFetch = styled.div`
  display: flex;
  gap: 20px;
  font-size: 30px;
  align-items: center;
  margin-top: 100px;
`;

export default ({ handleActions }) => {
  return (
    <div>
      <NavBar>
        <Button className="back-button" onClick={() => handleActions({ id: 'all-shares' })}>
          <Icon name="fa-solid fa-chevron-left" />
          <div className="title">
            <Trans>ALL SHARES</Trans>
          </div>
        </Button>
      </NavBar>
      <FailedFetch>
        <Icon name="fa-solid fa-magnifying-glass" size={40} />
        <div>
          <Trans i18nKey="share.details.not-found">
            This share is not currently active. Please contact the individual who has shared it with
            you and request an extension if you still need to review it.
          </Trans>
        </div>
      </FailedFetch>
    </div>
  );
};
