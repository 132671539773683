import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url(/assets/scratch.jpg);
  background-size: cover;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #000;
  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }
  > div {
    width: 600px;
    padding: 20px;
    height: 200px;
    margin: auto;
    border-radius: 10px;
    background-color: #fff;
    font-size: 1.5em;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    > div:last-child {
      margin-top: 10px;
      font-size: 1.25em;
      color: #666;
    }
  }
`;

//TO SEE A LIST OF KNOWN ERROR CODES GO TO METEOR CODE BASE > server/screening/standardErrors
export default ({ errorValue }) => {
  const errorMessage =
    typeof errorValue === 'string' ? errorValue : errorValue?.reason || errorValue?.code;

  if (errorMessage === 'Inactive link') {
    return (
      <Wrapper>
        <div>
          <p>
            The link you have clicked is <strong>inactive.</strong>
          </p>
          <div>
            <a href="/">Go Home</a>
          </div>
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <div>
        <div>An error has occurred with your request</div>
        <div>{errorMessage || 'EXPIRED LINK'}</div>
      </div>
    </Wrapper>
  );
};
