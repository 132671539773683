export const renderPrettyCurrency = (numberOrString, withDecimals = true, currency = 'USD') => {
  let localVal = Number.parseFloat(numberOrString);
  if (Number.isNaN(localVal)) {
    return '';
  }

  if (!Intl) {
    return `$${localVal}`;
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: withDecimals ? 2 : 0
  });

  return formatter.format(localVal);
};

Number.prototype.renderPrettyCurrency = function (withDecimals = true, currency) {
  return renderPrettyCurrency(this, withDecimals, currency);
};
String.prototype.renderPrettyCurrency = function (withDecimals = true, currency) {
  return renderPrettyCurrency(this, withDecimals, currency);
};
