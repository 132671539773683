import StepFactory from './step-factory';
import { isStepDone, getActionStepStatus } from './common';

export default ({ action, location }) => {
  const steps = [
    'personalInfo',
    'identity',
    'tourInfo',
    'qualificationQuestions',
    'actionFee',
    'submit'
  ]
    .reduce((final, cur) => {
      const newStep = StepFactory[cur] ? StepFactory[cur]({ action }) : undefined;
      if (!newStep || newStep.disabled) return final;

      const ix = final.length; // we need the index this will be after we add it

      newStep.isLocked = !ix
        ? undefined
        : (action, steps) => {
            return !isStepDone(final[ix - 1]?.key, steps);
          };

      return final.concat(newStep);
    }, [])
    .map((step) => {
      const appStep = action?.steps?.find((st) => st.key === step.key);
      return {
        ...step,
        status: getActionStepStatus({ key: step.key, action, location, appStep, step })
      };
    });
  return steps.map((step) => {
    step.status.hidden = !!step.hidden;
    step.status.locked = step?.isLocked ? step.isLocked(action, steps) : false;
    return step;
  });
};
