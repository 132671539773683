import React from 'react';
import { Row, Title, PageContainer, PageWrapper, Description } from '../common-styles';
import Button from '../../../components/button';
import PetEdition from './pet-edition';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../../../state';
import PetList from './pet-list';
import PetScreeningVendor from './screening-vendor';

const OPTIONS = (t) => {
  return [
    {
      value: 'dog',
      label: `${t('dog')}`,
      icon: {
        name: 'fa-duotone fa-dog',
        color: '#00aade'
      }
    },
    {
      value: 'cat',
      label: `${t('cat')}`,
      icon: {
        name: 'fa-duotone fa-cat',
        color: '#00aade'
      }
    },
    {
      value: 'other',
      label: `${t('other')}`,
      icon: {
        name: 'fa-duotone fa-rabbit',
        color: '#00aade'
      }
    }
  ];
};

const PetInfo = ({
  companyContext,
  application,
  location,
  saveApplicationPart,
  onNext,
  onBack
}) => {
  const [petEdit, setPetEdit] = React.useState();
  const dialog = useConfirmDialog();
  const { t } = useTranslation();
  const options = OPTIONS(t);
  const otherPets = [].concat(
    application?.relatedAppData?.pets?.map((r) => ({ ...r, readOnly: true })) || []
  );
  const [pets, setPets] = React.useState(() => application.pets || []);

  const handleRemove = ({ uuid, name }) => {
    dialog({
      title: `Are you sure you want to remove pet ${name}`,
      onConfirm: () => {
        setPets(pets.filter((p) => p.uuid !== uuid));
      }
    });
  };
  const handleAdd = (pet) => {
    const isEdit = pets.some((p) => p.uuid === pet.uuid);
    setPets(
      isEdit
        ? pets.map((p) => {
            if (p.uuid !== pet.uuid) return p;
            return pet;
          })
        : [...pets, pet]
    );
  };

  const handleNext = () => {
    saveApplicationPart({ pets }, false).then(() => {
      onNext();
    });
  };

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('petsMembers.title')}</Title>
        </Row>
        {location?.applicationConfig?.petBlurb && (
          <Row>
            <Description
              dangerouslySetInnerHTML={{
                __html: location.applicationConfig.petBlurb
              }}
            />
          </Row>
        )}
        {!companyContext?.disablePetCollection && (
          <Row>
            {options.map((option, i) => (
              <PetList
                key={i}
                title={option.label}
                icon={option.icon}
                onAdd={() => setPetEdit({ type: option.value })}
                items={pets.filter((p) => p.type === option.value)}
                otherPets={otherPets.filter((p) => p.type === option.value)}
                onEdit={setPetEdit}
                onRemove={handleRemove}
              />
            ))}
          </Row>
        )}
        {!companyContext?.disablePetCollection && (
          <Row className="center">
            <p>{t('petsMembers.add')}</p>
          </Row>
        )}
        <Row className="center flex gap-10">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.cancel')}
          </Button>
          <Button radius="25px" variant="primary" onClick={handleNext} width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
      <PetEdition
        open={!!petEdit}
        pet={petEdit}
        onSave={handleAdd}
        onClose={() => setPetEdit(undefined)}
        application={application}
        t={t}
      />
    </PageWrapper>
  );
};

export default (params) => {
  const { companyContext } = React.useContext(CompanyContext);
  const petScreeningVendor =
    params?.location?.companyInfo?.petScreeningType || companyContext?.petScreeningType || 'aptly';

  if (['petScreening'].includes(petScreeningVendor)) {
    return <PetScreeningVendor {...params} />;
  }

  return <PetInfo companyContext={companyContext} {...params} />;
};
