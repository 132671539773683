import React from 'react';
import ErrorBoundary from 'components/errorBoundary';
import Applicants from './application-applicants';
import Vehicles from './application-vehicles';
import Animals from './application-animals';
import Minors from './application-minors';
import { Section } from 'components/section';
export default ({ application, applicationConfig, card, handleActions, refreshApplication }) => {
  return (
    <>
      <ErrorBoundary>
        <Applicants
          applicationConfig={applicationConfig}
          application={application}
          handleActions={handleActions}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <Minors
          applicationConfig={applicationConfig}
          application={application}
          handleActions={handleActions}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <Vehicles
          applicationConfig={applicationConfig}
          application={application}
          handleActions={handleActions}
        />
      </ErrorBoundary>
      <ErrorBoundary>
        <Animals
          applicationConfig={applicationConfig}
          application={application}
          handleActions={handleActions}
        />
      </ErrorBoundary>
    </>
  );
};
