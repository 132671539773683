import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import { SupportModal } from 'components/contactUs';
import { useTranslation } from 'react-i18next';
import EB from 'components/errorBoundary';
import FAQ from 'data/FAQs';

export const Row = styled.div`
  margin: ${({ spacing = 15 }) => spacing}px 0px;
  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &.text-center {
    text-align: center;
  }
  &.flex {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &.gap-10 {
    gap: 10px;
  }
  &.gap-15 {
    gap: 15px;
  }
`;

const Wrapper = styled.div`
  height: 100%;
  flex: 1;
  overflow-y: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  max-width: 1120px;
  width: 100%;
  margin: 0 auto;
`;

const Title = styled.div`
  font-weight: bold;
  font-size: 1.25em;
`;

const CollapsibleWrapper = styled.div`
  padding: 12px 20px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  &:hover {
    background-color: #fafafa;
  }
  &:last-child {
    border-bottom: none;
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
  }
`;

const FaqWrapper = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 6px;
`;

const OptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 150px;
  background-color: #fff;
  border: 1px solid #ddd;
  padding-bottom: 15px;
  border-radius: 6px;
  cursor: pointer;
  .label {
    font-weight: bold;
  }
  .description {
    color: var(--color-primary);
  }

  .icon-wrapper {
    padding: 20px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

// eslint-disable-next-line no-undef
const APTLY_CHAT_ACTIVE = `${process.env.REACT_APP_APTLY_CHAT}`.indexOf('on') >= 0;

const Option = ({ icon, label, description, onClick }) => (
  <OptionWrapper onClick={onClick}>
    <div className="icon-wrapper">
      <Icon name={icon} size={30} />
    </div>
    <div className="label">{label}</div>
    <div className="description">{description}</div>
  </OptionWrapper>
);

const Collapsible = ({ open, title, description, onClick, t }) => {
  return (
    <CollapsibleWrapper onClick={onClick}>
      <div className="title">
        {title}
        <Icon name={open ? 'fa-light fa-chevron-up' : 'fa-light fa-chevron-down'} />
      </div>
      {open && <p>{description}</p>}
    </CollapsibleWrapper>
  );
};

export default () => {
  const { t } = useTranslation();
  const [itemOpen, setItemOpen] = React.useState();
  const [modalOpen, setModalOpen] = React.useState();
  return (
    <Wrapper>
      <Container>
        {modalOpen && <SupportModal modalOpen={modalOpen} setModalOpen={setModalOpen} />}
        <Row className="center">
          <Title>{t('support.title')}</Title>
        </Row>
        {APTLY_CHAT_ACTIVE && (
          <Row className="center flex gap-15">
            <Option
              icon="fa-duotone fa-envelope-open-text"
              label={t('support.email')}
              description={t('support.descripEmail')}
              onClick={() => setModalOpen(true)}
            />
          </Row>
        )}
        <FaqWrapper>
          <Row className="center">
            <strong>{t('support.collapsibleTitle')}</strong>
          </Row>
          <EB>
            {FAQ(t).map((question, index) => (
              <Collapsible
                key={index}
                open={index === itemOpen}
                onClick={() => setItemOpen(index)}
                {...question}
                t={t}
              />
            ))}
          </EB>
        </FaqWrapper>
      </Container>
    </Wrapper>
  );
};
