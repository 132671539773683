import React from 'react';
import Input from './input';

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});

const formatMoney = (amount = '') => {
  const val = amount.replace(/[a-zA-Z,$ ]/gi, '');
  if (!val || val === '.') return '';
  return moneyFormatter.format(val);
};

export default ({ name, onChange, value: initialValue, reportOnChange, onBlur }) => {
  const [value, setValue] = React.useState(initialValue);
  const handleBlur = (event) => {
    const value = !event.target.value ? '' : formatMoney(event.target.value);
    setValue(value);
    onChange && onChange({ target: { name, value } });
    onBlur && onBlur({ target: { name, value } });
  };
  const handleChange = (event) => {
    setValue(event.target.value);
    if (!reportOnChange) return;

    const value = !event.target.value ? '' : formatMoney(event.target.value);
    onChange && onChange({ target: { name, value } });
  };
  return <Input name={name} value={value} onChange={handleChange} onBlur={handleBlur} />;
};
