import { isString } from 'lodash';
const scoreWeight = { 'A+': 4.5, A: 4, 'B+': 3.5, B: 3, 'C+': 2.5, C: 2, D: 1, F: 0 };
const scoreModels = {
  vantage: { 'A+': 800, A: 750, 'B+': 700, B: 650, 'C+': 600, C: 550, D: 450, F: 0 },
  fico: { 'A+': 800, A: 750, 'B+': 700, B: 650, 'C+': 600, C: 550, D: 450, F: 0 }
};
const scoreLabels = ['A+', 'A', 'B+', 'B', 'C+', 'C', 'D', 'F'];
const scoreModelRanges = {
  vantage: { max: 850, min: 300 },
  fico: { max: 850, min: 300 }
};

const SCORE_MAX = 850;
const SCORE_MIN = 400; // mid point between D & F
const RATING_MIN = 0.5; // mid point between D & F

export const SCORE_WEIGHT_LABELS = Object.keys(scoreWeight).reduce(
  (final, cur) => Object.assign(final, { [scoreWeight[cur]]: cur }),
  {}
);
export const getCreditScoreLabel = (value, type = 'fico') => {
  const model = scoreModels[(type || 'fico').toLowerCase()];
  const percent = value < SCORE_MIN ? 0 : (value - SCORE_MIN) / (SCORE_MAX - SCORE_MIN);
  const label = Object.keys(model).find((a) => value >= model[a]);
  return { label, percent: percent < 0.02 ? 0.02 : percent > 0.98 ? 0.98 : percent };
};

export const getCreditRatingPercent = (rating) => {
  let localRating = rating;
  if (localRating && isString(localRating)) {
    localRating = scoreWeight[rating];
  }
  if (!Number.isFinite(localRating)) return undefined;
  // this is the percent grade
  // 4.5 == 100, 1 = 0 so 4.5-1 is the max range is 3.5. Thats what we will be dealing with.
  const percent = (localRating - RATING_MIN) / (4.5 - RATING_MIN);

  return percent < 0.02 ? 0.02 : percent > 0.98 ? 0.98 : percent;
};
export const getCreditRatingRange = (rating, type = 'fico') => {
  if (!isString(rating)) return;
  const model = scoreModels[(type || 'fico').toLowerCase()];
  const cleansedRating = rating?.toUpperCase();
  const baseIndex = scoreLabels.indexOf(cleansedRating);

  if (!model || baseIndex < 0) return;

  return {
    max:
      baseIndex === 0 ? scoreModelRanges[type || 'fico']?.max : model[scoreLabels[baseIndex - 1]],
    min: model[cleansedRating] || scoreModelRanges[type || 'fico']?.min
  };
};
