import React from 'react';
import { isEmpty } from 'lodash';
import useAudience from 'hooks/useAudience';

// eslint-disable-next-line no-undef
const INTERCOM_ACTIVE = `${process.env.REACT_APP_INTERCOM}`.indexOf('on') >= 0;
// eslint-disable-next-line no-undef
const REACT_APP_ENVIRONMENT = `${process.env.REACT_APP_ENVIRONMENT || ''}`;

const BASE_INTERCOM_SETTINGS = { api_base: 'https://api-iam.intercom.io', app_id: 'ddjfzx8n' };
const handleError = (err) => console.error(err);

const initIntercom = () => {
  window.intercomSettings = BASE_INTERCOM_SETTINGS;
  const w = window;
  const ic = w.Intercom;

  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    const d = document;
    const i = function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args) {
      i.q.push(args);
    };
    w.Intercom = i;
    const l = function () {
      const s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = 'https://widget.intercom.io/widget/ddjfzx8n';
      const x = d.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
};

const updateIntercomProperties = ({ companyContext, userContext, audience, dataContext }) => {
  if (!window.Intercom) {
    return false;
  }

  window.Intercom('update', {
    //------------ standard attributes  -----------
    user_id: userContext?._id,
    email: userContext?.email,
    phone: userContext?.phone,
    name: !userContext
      ? undefined
      : `${userContext?.firstName || ''} ${userContext?.lastName || ''}`,
    company: companyContext
      ? {
          company_id: companyContext?.id,
          name: companyContext?.companyName
        }
      : undefined,
    //------------ custom attributes  -----------
    // USER context attributes
    portal_user_id: userContext?._id,
    audience: audience?.label,
    environment: REACT_APP_ENVIRONMENT,
    // COMPANY context attributes
    company_name: companyContext?.companyName,
    company_id: companyContext?.id,
    bank_verification: companyContext?.disableBankScreening
      ? 'disabled'
      : companyContext?.requireBankConnect
      ? 'required'
      : 'optional',
    id_verification: companyContext?.disableAutoIDVerification
      ? 'disabled'
      : companyContext?.requireAutoIDVerification
      ? 'required'
      : 'optional',
    // data contexts from what the user is doing right now
    ...dataContext
  });
};

const internalUseIntercom = ({ userContext, companyContext }) => {
  const [failed, setFailed] = React.useState(false);
  const [isVisible, setIsVisible] = React.useState(false);
  const [ready, setReady] = React.useState(false);
  const [audience] = useAudience();
  const [dataContext, setDataContext] = React.useState({});

  React.useEffect(() => {
    try {
      initIntercom();
    } catch (error) {
      setFailed(true);
      handleError(error);
    }
  }, []);

  React.useEffect(() => {
    const checkIntercomStatus = (iteration) => {
      if (iteration > 30) {
        setFailed(true);
        setReady(true);
        return true;
      }

      if (window.Intercom) {
        setReady(true);
        return true;
      }

      setTimeout(() => checkIntercomStatus(iteration + 1), 500);
      return false;
    };

    //check the status
    checkIntercomStatus(0);
  }, []);

  React.useEffect(() => {
    if (!ready) return;

    try {
      updateIntercomProperties({
        audience,
        userContext,
        companyContext,
        dataContext
      });
    } catch (err) {
      handleError(err);
    }
  }, [ready, audience, userContext, companyContext, dataContext]);

  const updateContactInfo = (data) => {
    try {
      const newDataContext = {};

      if (data.app) {
        try {
          newDataContext.moveDate = data.app.moveIn
            ? new Date(data.app.moveIn).renderLocale()
            : undefined;
        } catch (err) {
          //empty by design
        }
        newDataContext.application_id = data.app._id;
        newDataContext.applicant_name = `${data?.app?.applicant?.firstName} ${data?.app?.applicant?.lastName}`;
        newDataContext.application_step = data.app.step;
      }

      if (data.action) {
        try {
          newDataContext.tourDate = data.showing.tourDate
            ? new Date(data.showing.tourDate).renderLocale()
            : undefined;
        } catch (err) {
          //empty by design
        }
        newDataContext.action_id = data.action._id;
        newDataContext.action_type = data.action.type;
        newDataContext.applicant_name = `${data?.showing?.applicant?.firstName} ${data?.showing?.applicant?.lastName}`;
        newDataContext.action_step = data.action.step;
      }

      if (data.location) {
        newDataContext.desired_property = data.location?.name;
      }

      //only update intercom if things have changed as they have some rate limiting on this function
      if (isEmpty(newDataContext)) return;
      if (Object.keys(newDataContext).some((key) => newDataContext[key] !== dataContext[key])) {
        setDataContext((existing) => {
          return { ...existing, ...newDataContext };
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const toggleWindow = (state) => {
    const toggleToState = [true, false].includes(state) ? state : !isVisible;
    if (!window.Intercom) return;
    window.Intercom(toggleToState ? 'show' : 'hide');
    setIsVisible(toggleToState);
  };
  return [ready, updateContactInfo, toggleWindow];
};

export const useIntercom = (...params) => {
  try {
    if (!INTERCOM_ACTIVE) {
      return [true, () => {}];
    }

    return internalUseIntercom(...params);
  } catch (error) {
    handleError(error);
    return [true, () => {}];
  }
};
