import React from 'react';
import styled, { css } from 'styled-components';
import { Card, CardDataGroup, CardDataDisplay } from './common';
import { toMoneyString } from 'pages/approver/share/detail/application-view/helpers';
import { primary } from 'styles/colors';
import { darken } from 'polished';

const formatDate = (date) => (date || new Date()).toLocaleDateString();
const StyledCard = styled(Card)`
  .title {
    font-size: 16px;
  }
`;

const ActionDiv = styled.div`
  display: flex;
  gap: 10px;
  > span {
    ${({ onClick, theme }) =>
      onClick
        ? css`
            color: ${theme?.colors?.primary || primary};
            cursor: pointer;
            &:hover {
              color: ${({ theme }) => darken(0.05, theme?.colors?.primary || primary)};
            }
          `
        : ''}
  }
`;
export const Address = ({ address = {}, applicationConfig }) => {
  return (
    <StyledCard title={address.current ? 'Current Address' : 'Past Address'}>
      <CardDataGroup>
        <CardDataDisplay
          label="Address"
          description={address.formattedAddress?.formattedAddress || address?.address}
        />
      </CardDataGroup>
      {address.startDate && (
        <CardDataGroup>
          <CardDataDisplay
            label="Resided"
            description={`${formatDate(address.startDate)} - ${
              address.current ? 'Current' : formatDate(address.endDate)
            }`}
          />
        </CardDataGroup>
      )}
      {address?.rentalReference?.pastRent && (
        <CardDataGroup>
          <CardDataDisplay
            label="Past Rent"
            description={toMoneyString(address?.rentalReference?.pastRent, null, true)}
          />
        </CardDataGroup>
      )}
      {address.type === 'R' && (
        <CardDataGroup>
          <CardDataDisplay
            label="Landlord/Management Company"
            description={
              <>
                <div>
                  {address.rentalReference.name ||
                    `${address.rentalReference.firstName || ''} ${
                      address.rentalReference.lastName || ''
                    }`.trim() ||
                    'Landlord not provided'}
                </div>
                {!applicationConfig?.disabledSections?.contactInfo && (
                  <>
                    {address?.rentalReference?.phone && (
                      <ActionDiv>
                        Phone: <span>{address?.rentalReference?.phone}</span>
                      </ActionDiv>
                    )}
                    {address?.rentalReference?.email && (
                      <ActionDiv>
                        Email: <span>{address?.rentalReference?.email}</span>
                      </ActionDiv>
                    )}
                  </>
                )}
              </>
            }
          />
        </CardDataGroup>
      )}

      {address.type === 'O' && (
        <CardDataGroup>
          <CardDataDisplay
            label="Mortgage Company"
            description={
              <>
                <div>{address.mortgageCompany || 'Details not provided'}</div>
              </>
            }
          />
        </CardDataGroup>
      )}

      {address.type === 'S' && (
        <CardDataGroup>
          <CardDataDisplay
            label="Staying with family/friends"
            description={address.housingStatus || 'Details not provided'}
          />
        </CardDataGroup>
      )}
    </StyledCard>
  );
};
