import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';

import { randomId } from 'dal/utils';
import Input from 'components/input';
import FormGroup from 'components/form-group';
import Phone from 'components/phone';
import Button from 'components/button';
import Modal from 'components/modal';

import { defaultContact, CONTACT_SCHEMA } from './common';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SingleContactForm = ({ name, onChange, value: initialValue = {} }) => {
  const { values, errors, touched, handleChange, handleBlur, setTouched, dirty, isValid } =
    useFormik({
      initialValues: { uuid: randomId(), ...defaultContact, ...initialValue },
      validationSchema: CONTACT_SCHEMA
    });

  React.useEffect(() => {
    if (!dirty) return; // only bother reporting and validating when things have been changed
    setTouched({ firstName: true, lastName: true, email: true, phone: true }, true);

    //need to propagate a nothing so that parent validation doesn't fire
    onChange?.({ target: { name, value: values, isValid: isValid } });
  }, [values, isValid]);

  return (
    <Wrapper>
      <FormGroup label={'First Name'} errorText={touched.firstName && errors.firstName}>
        <Input
          name="firstName"
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormGroup>
      <FormGroup label={'Last Name'} errorText={touched.lastName && errors.lastName}>
        <Input
          name="lastName"
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </FormGroup>
      <FormGroup
        label={'Email'}
        errorText={(touched.email && errors.email) || errors.oneOfRequiredValidation}>
        <Input
          icon="fa-solid fa-at"
          name="email"
          value={values.email}
          onChange={(e) => {
            e.target.value = (e.target.value || '').trim(); // force whitespace trim
            handleChange(e);
          }}
          onBlur={handleBlur}
        />
      </FormGroup>
      <FormGroup label={'Phone'}>
        <Phone
          allowZero={true}
          errors={(touched.phone && errors.phone) || errors.oneOfRequiredValidation}
          name="phone"
          value={values.phone}
          onChange={handleChange}
        />
      </FormGroup>
    </Wrapper>
  );
};

export default SingleContactForm;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 30px 40px;
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  justify-content: flex-end;
  gap: 12px;
  button {
    padding: 7px 12px !important;
    text-transform: uppercase;
  }
`;

export const SingleContactModal = ({ open, onClose, onSave, value, ...props }) => {
  const [isValid, setIsValid] = React.useState(false);
  const [contactInfo, setContactInfo] = React.useState({
    uuid: randomId(),
    ...defaultContact,
    ...value
  });
  return (
    <Modal closeOnOutsideClick={false} open={open} onClose={onClose} height={'auto'}>
      <Scrollable>
        <SingleContactForm
          value={contactInfo}
          onChange={(val) => {
            setContactInfo(val.target?.value);
            setIsValid(!!val.target?.isValid);
          }}
        />
      </Scrollable>
      <Actions>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          variant="primary"
          radius="25px"
          disabled={!isValid}
          onClick={() => onSave(contactInfo)}>
          Save
        </Button>
      </Actions>
    </Modal>
  );
};
