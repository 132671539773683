import React from 'react';
import styled from 'styled-components';
import { Row, Title, PageContainer, PageWrapper } from './common-styles';
import FormGroup, { RowGroup } from 'components/form-group';
import Button from 'components/button';
import Select from 'components/select';
import { useFormik } from 'formik';
import * as yup from 'yup';
import InputDate from 'components/input-date';
import { useTranslation } from 'react-i18next';

const TourRow = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  > div {
    flex: 1;
    margin-bottom: 25px !important;
  }
`;

const currentDate = new Date();
const pastDate = new Date(new Date().setDate(new Date().getDate() - 7));
export default ({ application, saveApplicationPart, onBack, onNext }) => {
  const { t } = useTranslation();
  const initialValues = {
    moveIn: application?.moveIn || '',
    hasToured: [true, false].includes(application?.hasToured) ? application?.hasToured : '',
    tourDate: application?.tourDate || '',
    ...(application.applicant || {})
  };

  const handleContinue = (values) => {
    setSaveInProgress(true);
    saveApplicationPart({
      applicant: values,
      moveIn: values.moveIn,
      hasToured: values.hasToured,
      tourDate: values.tourDate
    })
      .then(() => {
        setSaveInProgress(false);
        onNext();
      })
      .catch(() => setSaveInProgress(false));
  };

  const applicantInfoSchema = yup.object().shape({
    moveIn: yup
      .date()
      .required(`${t('form.validate.provideMoveIn')}`)
      .min(pastDate, `${t('form.validate.invalidMoveIn')}`),
    hasToured: yup.boolean().required(`${t('form.validate.provideHasToured')}`),
    tourDate: yup.date().when('hasToured', {
      is: true,
      then: yup.date().required(`${t('form.validate.provideTourDate')}`),
      otherwise: (s) => s.strip()
    })
  });

  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: applicantInfoSchema,
      onSubmit: handleContinue
    });

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center">
          <Title>{t('moveInStep.title')}</Title>
        </Row>
        <Row spacing={30}>
          <Row>
            <RowGroup columns="1fr 1fr">
              <FormGroup label={t('form.moveIn')} errorText={touched.moveIn && errors.moveIn}>
                <InputDate
                  name="moveIn"
                  value={values.moveIn}
                  onChange={(e) => {
                    e.target.value = e.target.value || '';
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  disabledDays={(date) => date < currentDate}
                />
              </FormGroup>
              <FormGroup
                label={t('form.haveToured')}
                errorText={touched.hasToured && errors.hasToured}>
                <Select
                  name="hasToured"
                  value={[true, false].includes(values.hasToured) ? `${+values.hasToured}` : ''}
                  onChange={(e) => {
                    setFieldValue(
                      'hasToured',
                      Number.isFinite(parseInt(e.target.value))
                        ? !!parseInt(e.target.value)
                        : e.target.value
                    );
                  }}>
                  <option value="">Please select...</option>
                  <option value={'1'}>Yes</option>
                  <option value={'0'}>No</option>
                </Select>
              </FormGroup>
            </RowGroup>
            {values.hasToured && (
              <FormGroup label={t('form.tourDate')} errorText={touched.tourDate && errors.tourDate}>
                <InputDate
                  name="tourDate"
                  value={values.tourDate}
                  onChange={(e) => {
                    e.target.value = e.target.value || '';
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                />
              </FormGroup>
            )}
          </Row>
        </Row>
        <Row className="center flex gap-10 action-buttons">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.cancel')}
          </Button>
          <Button
            radius="25px"
            variant="primary"
            disabled={saveInProgress}
            onClick={handleSubmit}
            width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
