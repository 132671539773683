import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { renderPrettyDate } from 'utils/dateHelpers';
import Icon from 'components/icon';
import Avatar from 'components/avatar';
import ShadowRender from 'components/shadow-render';
import useAudience from 'hooks/useAudience';
import { useRefCode } from 'hooks/useSessionVariables';
import { useAPIData } from 'dal/useAPIData';
import useMobile from 'hooks/useMobile';

const Wrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: 10px;
  overflow: auto;
`;
const ChatWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
`;
const ChatBubble = styled.div`
  flex: 1;
  width: 100%;
  .bubble {
    padding: 16px;
    border-radius: 5px;
    background-color: #e3ebf1;
    overflow: auto;
  }
  .name {
    margin-top: 5px;
    text-align: right;
  }
`;
const AvatarWrapper = styled.div``;

const CheckListDescription = styled.div`
  .bullets {
    display: flex;
    flex-flow: column;
    gap: 20px;
  }
  .description {
    font-size: 1.25em;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 30px;
    i {
      color: #007cff;
    }
    p {
      margin: 0;
    }
  }
`;

const audienceDescriptions = {
  approver: ({ t, isMobile }) => {
    const refCode = useRefCode();

    const [codeDoc] = useAPIData('application.GET_CODE', refCode ? { code: refCode } : undefined, {
      reFetchWhen: [refCode],
      disableEmptyArgs: true
    });

    const timeString = codeDoc?.createdAt ? renderPrettyDate(codeDoc?.createdAt) : undefined;

    if (isMobile && !codeDoc) return <></>;

    return (
      <Wrapper>
        <div className="section-description">
          <h1>
            {t('homePage.approver.welcome')}
            {codeDoc?.shareDetails?.sharedWith?.name
              ? ` ${codeDoc?.shareDetails?.sharedWith?.name}`
              : ''}
            !
          </h1>
          <div>{t('homePage.approver.intro')}</div>
          {!codeDoc?.shareDetails?.message ? (
            <></>
          ) : (
            <ChatWrapper>
              <ChatBubble>
                <div className="bubble">
                  <ShadowRender html={codeDoc.shareDetails?.message} />
                </div>
                <div className="name">
                  <strong>{codeDoc?.shareDetails?.sharedBy?.name || 'Someone'}</strong>
                  {timeString ? ` on ${timeString}` : ''}
                </div>
              </ChatBubble>
              {(!!codeDoc?.shareDetails?.sharedBy?.duogram ||
                !!codeDoc?.shareDetails?.sharedBy?.image) && (
                <AvatarWrapper>
                  <Avatar
                    size={50}
                    imageUrl={codeDoc?.shareDetails?.sharedBy?.image}
                    duogram={codeDoc?.shareDetails?.sharedBy?.duogram}
                  />
                </AvatarWrapper>
              )}
            </ChatWrapper>
          )}
        </div>
      </Wrapper>
    );
  },
  applicant: ({ t, isMobile }) => {
    if (isMobile) return <></>;
    return (
      <Wrapper>
        <CheckListDescription>
          <h1>{t('homePage.title')}</h1>
          <div className="bullets">
            <div className="description">
              <Icon name="fa-duotone fa-circle-check" paths={2} size={18} />
              <p>{t('homePage.description-1')}</p>
            </div>
            <div className="description">
              <Icon name="fa-duotone fa-circle-check" paths={2} size={18} />
              <p>{t('homePage.description-2')}</p>
            </div>
            <div className="description">
              <Icon name="fa-duotone fa-circle-check" paths={2} size={18} />
              <p>{t('homePage.description-3')}</p>
            </div>
            <div className="description">
              <p>{t('homePage.description-4')}</p>
            </div>
          </div>
        </CheckListDescription>
      </Wrapper>
    );
  }
};

export default () => {
  const { isTabletOrMobile } = useMobile();
  const { t } = useTranslation();
  const [audience] = useAudience();

  const Description = audienceDescriptions[audience.uuid] || audienceDescriptions.applicant;

  return <Description t={t} isMobile={isTabletOrMobile} />;
};
