import React from 'react';
import styled from 'styled-components';
import { UserContext } from 'state';
import { Row, Title, PageWrapper, PageContainer } from './common-styles';
import Button from '../../components/button';
import Icon from '../../components/icon';
import VerifastModal from './verifast-modal';
import { Trans, useTranslation } from 'react-i18next';
import FileUpload from './upload';
import { MANUAL_VERIFICATION } from 'dal/applications';
import { SupportModal } from 'components/contactUs';
import toast from 'react-hot-toast';

const Step = styled.div`
  padding: 10px 0px;
  border-radius: 6px;
  .title {
    display: flex;
    align-items: end;
    gap: 5px;
  }
  li {
    list-style: none;
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }
  @media screen and (max-width: 740px) {
    padding: 0 10px;
  }
`;

const Required = styled.div`
  flex-shrink: 0;
  font-size: 0.81em;
  color: red;
  margin-top: -20px;
`;
const Optional = styled.div`
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: 0.81em;
  color: gray;
  font-style: italic;
`;

const UploadInput = styled.label`
  display: block;
  align-items: center;
  padding: 15px 20px;
  background-color: #f7f7f7;
  border: dashed 1px #ddd;
  min-height: 40px;
  margin-bottom: 10px;
  border-radius: 2px;
  input {
    display: none;
  }
  .input-label {
    margin-top: -25px;
    font-weight: bold;
    width: 300px;
    flex-shrink: 0;
    margin-right: 10px;
    @media screen and (max-width: 425px) {
      width: 210px;
    }
  }
  .uploadLink {
    cursor: pointer;
    font-size: 0.81em;
    padding: 0px;
    color: #fff;
    border-radius: 25px;
    background: #00a1ff;
    font-weight: bold;
    width: 80px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
`;
const ActionLink = styled.a`
  cursor: pointer;
  padding: 0px;
  color: var(--color-link);
  background-color: transparent;
  font-weight: bold;
`;

const RowLogos = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: 20px;
  img {
    width: 60px;
    aspect-ratio: 3/2;
    object-fit: contain;
  }
  .box-persona {
    position: relative;

    img {
      width: 120px;
      margin-top: -25px;
      margin-left: -10px;
    }
    .title {
      position: absolute;
      margin-top: -10px;
      font-size: 0.63em;
    }
    .privacy {
      position: absolute;
      font-size: 0.69em;
      margin-top: -30px;
      a {
        color: #007cff;
        text-decoration: none;
      }
    }
  }

  @media screen and (max-width: 425px) {
    .box-persona {
      flex-basis: 100%;
      text-align: center;
      > div {
        width: 100%;
        text-align: center;
      }
    }
  }
`;

const ButtonsRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-bottom: 25px;
  align-items: center;
  .skip-verify {
    width: 100%;
  }
`;

const Upload = ({ label, application, field, required, localFiles = {}, setLocalFiles, t }) => {
  const existingFile = (application?.files || {})[field];
  return (
    <UploadInput className="custom-file-upload">
      <div className="box-upload">
        <div className="input-label">{label}</div>
        <FileUpload
          required={required}
          autoUpload={true}
          localFiles={localFiles}
          setLocalFiles={setLocalFiles}
          locationId={application.locationId}
          applicationId={application._id}
          field={field}
          existingFile={existingFile}
          fileTypes={['.pdf', '.jpg', '.jpeg', '.png']}
        />
      </div>
      {required && !localFiles[field] && <Required> {t('required')}</Required>}
      {!required && !localFiles[field] && <Optional>{t('optional')}</Optional>}
    </UploadInput>
  );
};

const IDUpload = ({ application, t }) => {
  const [localFiles, setLocalFiles] = React.useState(application.files || {});

  return (
    <div>
      <Upload
        t={t}
        label={t('verifyYourIdentity.modal_manual.govBack')}
        field="govFront"
        required={true}
        localFiles={localFiles}
        setLocalFiles={setLocalFiles}
        application={application}
      />
      <Upload
        t={t}
        label={t('verifyYourIdentity.modal_manual.govFront')}
        field="govBack"
        required={true}
        localFiles={localFiles}
        setLocalFiles={setLocalFiles}
        application={application}
      />
    </div>
  );
};

const MissingIdModal = ({ autoIdDisabled, onClose, application, onNext, t }) => {
  const { userContext } = React.useContext(UserContext);
  const [modalOpen, setModalOpen] = React.useState();
  const onContinue = () => {
    MANUAL_VERIFICATION({
      userContext,
      locationId: application.locationId,
      applicationId: application._id,
      data: { type: 'identity' }
    })
      .then((result) => {
        if (!result.status) {
          return toast.error('Missing required data');
        }
        onNext({ application: result?.application });
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };
  return (
    <div>
      {modalOpen && (
        <SupportModal
          width="600px"
          height="620px"
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          isContactUs={true}
        />
      )}
      <Row className="center">
        <Title>
          {autoIdDisabled ? (
            <Trans i18nKey="verifyYourIdentity.modal_manual.autoDisabledTitle">
              To continue, we need to verify your identity
            </Trans>
          ) : (
            <Trans i18nKey="verifyYourIdentity.modal_manual.title">
              Skipping automated ID verification?
            </Trans>
          )}
        </Title>
      </Row>
      {!autoIdDisabled && (
        <>
          <Row>
            <Step>
              {t('verifyYourIdentity.modal_manual.description')}{' '}
              <ActionLink
                href="https://aptly.notion.site/What-kind-of-ID-do-I-need-for-verification-24ea29cffc154e3b8e485794dcbd29f1"
                target="_blank"
                rel="noreferrer">
                FAQs
              </ActionLink>{' '}
              {t('or')}{' '}
              <ActionLink onClick={() => setModalOpen(true)}>
                {t('buttonsActions.contact_us')}
              </ActionLink>
              .
            </Step>
          </Row>
          <Row>
            <Step>
              <Trans i18nKey="verifyYourIdentity.modal_manual.providePhotos">
                <strong>Verify your ID by uploading photos</strong>
              </Trans>
            </Step>
          </Row>
        </>
      )}
      <Row>
        <IDUpload application={application} t={t} />
      </Row>
      <br />
      <Row className="flex gap-10 center">
        <Button variant="secondary" onClick={onClose} width={95}>
          {t('buttonsActions.backButton')}
        </Button>
        <Button variant="primary" onClick={onContinue} width={95}>
          {t('buttonsActions.next')}
        </Button>
      </Row>
    </div>
  );
};

export default ({ application, location, onNext, onBack, onRefresh }) => {
  const disableAuto = !!location?.companyInfo?.disableAutoIDVerification;
  const requireAuto =
    !!location?.companyInfo?.requireAutoIDVerification &&
    !location?.companyInfo?.disableAutoIDVerification;

  const [openModal, setOpenModal] = React.useState(false);
  const { t } = useTranslation();
  const [openNoIdModal, setOpenNoIdModal] = React.useState(disableAuto);
  const showManualStep = disableAuto || openNoIdModal;

  return (
    <PageWrapper>
      <PageContainer>
        {showManualStep && !requireAuto ? (
          <MissingIdModal
            t={t}
            autoIdDisabled={disableAuto}
            open={openNoIdModal}
            onClose={() => (disableAuto ? onBack() : setOpenNoIdModal(false))}
            application={application}
            onNext={() => {
              onRefresh();
              onNext();
            }}
          />
        ) : (
          <>
            <VerifastModal
              applicationId={application?._id}
              open={openModal}
              requestType="identity"
              onClose={() => {
                setOpenModal(false);
              }}
              onComplete={() => {
                setOpenModal(false);
                onRefresh();
                onNext();
              }}
            />
            <Row className="center">
              <Title>{t('verifyYourIdentity.title')}</Title>
            </Row>
            <Row className="center">
              <Icon name="fa-duotone fa-face-viewfinder" color="#00aade" size={60} />
            </Row>
            <div className="description-box">
              <Row spacing={0}>
                <p>{t('verifyYourIdentity.description')}</p>
                <p>{t('verifyYourIdentity.description-2')}</p>
                <Step>
                  <p className="title">
                    <Icon name="fa-duotone fa-circle-1" size={25} />
                    {t('verifyYourIdentity.stepPhoto.title')}
                  </p>
                  <ul>
                    <li>
                      <Icon name="fa-duotone fa-check" />
                      {t('verifyYourIdentity.stepPhoto.descrip-1')}
                    </li>
                    <li>
                      <Icon name="fa-duotone fa-check" />
                      {t('verifyYourIdentity.stepPhoto.descrip-2')}
                    </li>
                    <li>
                      <Icon name="fa-duotone fa-check" />
                      {t('verifyYourIdentity.stepPhoto.descrip-3')}
                    </li>
                  </ul>
                </Step>
                <Step>
                  <p className="title">
                    <Icon name="fa-duotone fa-circle-2" size={25} />
                    {t('verifyYourIdentity.stepSelfie.title')}
                  </p>
                  <ul>
                    <li>
                      <Icon name="fa-duotone fa-check" />
                      {t('verifyYourIdentity.stepSelfie.descrip-1')}
                    </li>
                  </ul>
                </Step>
              </Row>
            </div>
            <Row className="flex gap-10 center">
              <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
                {t('buttonsActions.cancel')}
              </Button>
              <Button radius="25px" variant="primary" onClick={() => setOpenModal(true)} width={90}>
                {t('verifyYourIdentity.actions.primary')}
              </Button>
            </Row>
            {!location?.companyInfo?.requireAutoIDVerification && (
              <Row className="center">
                <Button
                  className="skip-verify"
                  radius="25px"
                  variant="link"
                  onClick={() => setOpenNoIdModal(true)}>
                  {t('verifyYourIdentity.actions.secondary')}
                </Button>
              </Row>
            )}
            <br />
            <Row className="center" spacing={0}>
              <Row spacing={0}>
                <RowLogos>
                  <div className="box-persona">
                    <div className="title">POWERED BY</div>
                    <img className="img-persona" src="/assets/bitmap.jpg" alt="" />
                    <div className="privacy">
                      Persona&apos;s{' '}
                      <span>
                        <a
                          href="https://www.personasupport.org/privacy-policy"
                          target="_blank"
                          rel="noreferrer">
                          privacy policy
                        </a>
                      </span>
                    </div>
                  </div>
                  <div>
                    <img src="/assets/ccpa.png" alt="" />
                  </div>
                  <div>
                    <img src="/assets/gdpr.png" alt="" />
                  </div>
                  <div>
                    <img src="/assets/soc2.png" alt="" />
                  </div>
                  <div>
                    <img src="/assets/fr.png" alt="" />
                  </div>
                </RowLogos>
              </Row>
            </Row>
          </>
        )}
      </PageContainer>
    </PageWrapper>
  );
};
