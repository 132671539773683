import React from 'react';
import styled from 'styled-components';
import Section from './section';
import Icon from 'components/icon';
import { Trans } from 'react-i18next';

const DataList = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 15px;
  flex-wrap: wrap;
`;
const DataBox = styled.div`
  font-size: 0.88em;
  .dataBox-label {
    font-weight: bold;
  }
`;
const PhoneRow = styled.div`
  display: flex;
  gap: 5px;
  > i {
    width: 16px;
  }
`;

const PHONE_TYPES = {
  mobile: { name: 'fa-duotone fa-mobile' },
  work: { name: 'fa-duotone fa-phone-intercom' },
  home: { name: 'fa-duotone fa-phone' }
};

export default ({ application = {}, t }) => {
  const {
    firstName = '',
    middleName = '',
    lastName = '',
    phone = [],
    email
  } = application.applicant || {};

  const name = `${firstName} ${middleName} ${lastName}`;

  return (
    <Section title={<Trans i18nKey="summary.personal.title">PERSONAL INFO</Trans>}>
      <div>
        <strong>{name}</strong>
      </div>
      <DataList>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.personal.phone">Phone Number</Trans>
          </div>
          {phone.map((phn, ix) => {
            return (
              <PhoneRow key={ix} className="dataBox-text">
                <Icon title={phn.type.capitalize()} {...PHONE_TYPES[phn.type]} /> {phn.number}
              </PhoneRow>
            );
          })}
        </DataBox>
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.personal.email">Email</Trans>
          </div>
          <div className="dataBox-text">{email}</div>
        </DataBox>
        {application.moveIn && (
          <DataBox>
            <div className="dataBox-label">
              <Trans i18nKey="summary.personal.move">Move Date</Trans>
            </div>
            <div className="dataBox-text">
              {new Date(application.moveIn).renderLocale({ time: false })}
            </div>
          </DataBox>
        )}
        <DataBox>
          <div className="dataBox-label">
            <Trans i18nKey="summary.personal.toured">Toured Rental?</Trans>
          </div>
          <div className="dataBox-text">{application.hasToured ? t('yes') : t('no')}</div>
        </DataBox>
        {(application.hasToured || application.tourDate) && (
          <DataBox>
            <div className="dataBox-label">
              <Trans i18nKey="summary.personal.tour_date">Tour Date</Trans>
            </div>
            <div className="dataBox-text">
              {new Date(application.tourDate).renderLocale({ time: false })}
            </div>
          </DataBox>
        )}
      </DataList>
    </Section>
  );
};
