import React from 'react';
import styled from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import AppStatus from '../app-status';
import PropertyPreview from '../layout/property';

import Section from './section';
import PersonalInfo from './personal-info';
import HousingHistory from './housing-history';
import Section8Details from './section8';
import Household from './household';
import Income from './income';
import Attachments from './attachments';
import Receipt from './receipt';

const Wrapper = styled.div`
  background-color: #fff;
  .text-center {
    text-align: center;
  }
  .receiptLink {
    margin-top: 10px;
  }
`;

const Row = styled.div`
  margin: 20px 45px;
  @media (max-width: 960px) {
    margin: 20px 10px;
  }
`;

const Title = styled.div`
  font-size: 2em;
  text-align: center;
  font-weight: bold;
`;

const Property = styled.div`
  border: 1px solid #ddd;
  padding: 15px 20px;
  display: flex;
  gap: 15px;
  img {
    width: 100px;
    height: 70px;
    object-fit: cover;
    border-radius: 2px;
  }
  .property-title {
    font-size: 1.2em;
    margin-bottom: 7px;
  }
  .property-info {
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
  }
  .box-info {
    line-height: 1.4;
    .box-label {
      font-size: 0.81em;
      color: #777;
      font-weight: bold;
    }
    .box-text {
      font-weight: bold;
    }
  }
`;

const StatusRow = ({ application, ...params }) => {
  if (application.submitted) {
    let repDate = application.submittedAt || application.completedAt || application.updatedAt;
    return (
      <p params={params}>
        <strong>
          <Trans i18nKey="summary.header.submitted">Submitted</Trans>
        </strong>{' '}
        {repDate && <>{new Date(repDate).renderLocale({ spacer: ' at ' })}</>}
      </p>
    );
  }

  return <></>;
};

const HeaderSection = ({ isMobile, application, location, t }) => {
  const { coverPhoto: [photo] = [], marketRent } = location.applicationConfig || {};

  return (
    <>
      {!isMobile && (
        <Row className="text-center">
          <Title>
            <Trans i18nKey="summary.header.title">My Application</Trans>
          </Title>
          <StatusRow application={application} t={t} />
        </Row>
      )}
      {isMobile && (
        <>
          <PropertyPreview location={location} hidePropertyChanger forceDisplay t={t} />
          <Row>
            <StatusRow style={{ textAlign: 'center' }} application={application} t={t} />
          </Row>
        </>
      )}
      {!isMobile && (
        <Row>
          <Property>
            {photo && <img src={photo} alt="" />}
            <div className="property-details">
              <div className="property-title">{location.name}</div>
              <div className="property-info">
                {!!marketRent && (
                  <div className="box-info">
                    <div className="box-label">
                      <Trans i18nKey="summary.header.rent">RENT</Trans>
                    </div>
                    <div className="box-text">{marketRent}/mo</div>
                  </div>
                )}
                {(!!location.bedCount || !!location.bathCount) && (
                  <div className="box-info">
                    <div className="box-label">
                      <Trans i18nKey="summary.header.beds_baths">BEDS/BATHS</Trans>
                    </div>
                    <div className="box-text">
                      {location.bedCount}/{location.bathCount}
                    </div>
                  </div>
                )}
                {!!location.squareFeet && (
                  <div className="box-info">
                    <div className="box-label">
                      <Trans i18nKey="summary.header.size">SIZE</Trans>
                    </div>
                    <div className="box-text">{location.squareFeet} SF</div>
                  </div>
                )}
              </div>
            </div>
          </Property>
        </Row>
      )}
    </>
  );
};

const IdentityVerification = ({ application }) => {
  const verified = !!application.verifiedIdentity;
  const automatedVerified = application?.verification?.idVendor !== 'manual';
  return (
    <Row>
      <Section
        title={<Trans i18nKey="summary.identity.title">IDENTITY VERIFICATION</Trans>}
        text={
          !verified ? (
            <Trans i18nKey="summary.identity.pending">Pending</Trans>
          ) : (
            <>
              <Trans i18nKey="summary.identity.verified">Verified</Trans>
              {application.verifiedAt
                ? ` on ${new Date(application.verifiedAt).renderLocale({ time: false })}`
                : ''}
            </>
          )
        }>
        {verified ? (
          <>
            {!automatedVerified ? (
              <Trans i18nKey="summary.identity.verification_complete">Verification Complete!</Trans>
            ) : (
              <Trans i18nKey="summary.identity.verification_complete_auto">
                Automated Verification Complete!
              </Trans>
            )}
          </>
        ) : (
          <Trans i18nKey="summary.identity.pending_verification">Pending Verification</Trans>
        )}
      </Section>
    </Row>
  );
};

const FeeDetails = ({ application, location }) => {
  if (!application.chargeApplicants || !application.paid) {
    return <></>; // we don't charge the applicant so don't show this
  }

  const { paidAt, totalPaid, currency } = application.payments || {};
  return (
    <Row>
      <Section
        title={<Trans i18nKey="summary.fee_details.title">APPLICATION FEE</Trans>}
        text={
          <>
            <Trans i18nKey="summary.fee_details.paid">Paid</Trans>
            {paidAt ? ` on ${new Date(paidAt).renderLocale({ time: false })}` : ''}
          </>
        }>
        {totalPaid ? (
          <div>
            {(totalPaid / 100)?.renderPrettyCurrency(true, currency)}{' '}
            <Trans i18nKey="summary.fee_details.paid">paid</Trans>
          </div>
        ) : (
          ``
        )}
        <div className="receiptLink">
          <Receipt application={application} location={location} />
        </div>
      </Section>
    </Row>
  );
};

export default ({ application, applicationLocked, location = {}, onRefresh, setLoading }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 700 });

  return (
    <Wrapper>
      <HeaderSection isMobile={isMobile} application={application} location={location} t={t} />
      <Row>
        <AppStatus application={application} location={location} />
      </Row>
      <IdentityVerification
        isMobile={isMobile}
        application={application}
        location={location}
        t={t}
      />
      <Row>
        <PersonalInfo isMobile={isMobile} application={application} location={location} t={t} />
      </Row>
      {application.addresses?.length > 0 && (
        <Row>
          <HousingHistory isMobile={isMobile} application={application} location={location} t={t} />
        </Row>
      )}
      {!!application.section8?.active && (
        <Row>
          <Section8Details
            isMobile={isMobile}
            application={application}
            location={location}
            t={t}
          />
        </Row>
      )}
      {/* <Row>
        <BGCheck isMobile={isMobile} application={application} location={location} />
      </Row> */}
      <FeeDetails isMobile={isMobile} application={application} location={location} t={t} />
      <Row>
        <Household isMobile={isMobile} application={application} location={location} t={t} />
      </Row>
      <Row>
        <Income isMobile={isMobile} application={application} location={location} t={t} />
      </Row>
      <Row>
        <Attachments
          isMobile={isMobile}
          application={application}
          location={location}
          onRefresh={onRefresh}
          setLoading={setLoading}
          t={t}
        />
      </Row>
    </Wrapper>
  );
};
