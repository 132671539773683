import React from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useNavigateWithContext, UserContext } from 'state';
import Button from 'components/button';
import Input from 'components/input';
import { REDEEM_CODE } from 'dal/applications';
import { getContinueUrl } from 'helpers/appVersions';
import useAudience from 'hooks/useAudience';

const Wrapper = styled.main`
  flex: 1;
  overflow-y: auto;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  @media (max-width: 1250px) {
    padding: 0 20px;
  }
`;

const Title = styled.h2`
  margin: 30px 0;
  text-transform: uppercase;
  display: flex;
  align-items: center;
`;

const Content = styled.div`
  padding-left: 20px;
  text-align: center;
  justify-content: center;
  div {
    justify-content: center;
  }
  > h3 {
    text-align: center;
  }
`;
const Back = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  font-size: 1.25em;
`;
const CodeInput = styled(Input)``;
const Actions = styled.div`
  justify-content: center;
  display: flex;
  max-width: 500px;
  margin: auto;
  > .application-action {
    margin-left: 10px;
  }
`;

export default () => {
  const { codeValue } = useParams();
  const [audience] = useAudience();
  const [code, setCode] = React.useState(codeValue || '');
  const { userContext } = React.useContext(UserContext);
  let navigate = useNavigateWithContext();

  const redeemCode = () =>
    REDEEM_CODE({
      userContext,
      code
    })
      .then((result) => {
        if (result?.application?._id) {
          navigate(getContinueUrl(result?.application));
        }
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
        throw err;
      });

  return (
    <Wrapper>
      <Container>
        <Title>
          <Back onClick={() => navigate(audience.paths.home)}>
            <i className="fa-solid fa-arrow-left" />
          </Back>
          ENTER APPLICATION CODE
        </Title>
        <Content>
          <h3>If you have been provided a special application code enter it below.</h3>
          <br />
          <Actions>
            <CodeInput
              value={code}
              onChange={(e) => setCode(e.target.value)}
              on
              placeholder="Enter code ...."
            />
            <Button
              className="application-action"
              disabled={!code}
              variant="primary"
              height={35}
              onClick={redeemCode}>
              SUBMIT
            </Button>
          </Actions>
        </Content>
      </Container>
    </Wrapper>
  );
};
