import { POST } from './utils';

//this loads the data context for the landing page
export const SUPPORT_REQUEST = ({ userContext, data }) => {
  return POST({
    url: `support/request`,
    data,
    token: userContext?.accessToken?.token
  });
};
