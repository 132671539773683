import React from 'react';
import styled from 'styled-components';
import Progress from '../../components/percent-bar';
import Button from '../../components/button';
import Icon from '../../components/icon';
import NewMember from '../application-form-v2/members/new-member';
import { UserContext } from '../../state';
import { APPEND_APPLICATION } from '../../dal/applications';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { getContinueUrl } from 'helpers/appVersions';
import CancelAppDialog from './cancel-app-dialog';
import Menu from 'components/menu';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 290px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ddd;
  .data-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  margin-bottom: 15px;
`;

const Content = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 45px;
  @media (max-width: 960px) {
    margin: 25px 20px;
  }
  &.property-data {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  &.household-list {
    display: block;
  }
  &.actions {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 25px 45px;
    padding-top: 0px;
    gap: 10px;
    button:last-child {
      margin-left: auto;
    }
    @media (max-width: 960px) {
      padding: 25px 20px;
      padding-top: 0px;
    }
  }
`;

const Logo = styled.div`
  width: 120px;
  height: 80px;
  border-radius: 2px;
  border: none;
  background-image: url(${({ image }) => image}), url('/assets/location_placeholder.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Title = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 1.25em;
`;

const DataGroup = styled.div`
  display: flex;
  flex-direction: column;
  &.centered {
    align-items: center;
  }
  .label {
    font-size: 0.81em;
    text-transform: uppercase;
    color: #a0a0a0;
    margin-bottom: 5px;
  }
  .data {
    font-weight: bold;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &:last-child {
    border-right: none;
  }
`;

const Action = styled(Button)`
  min-width: 80px;
  font-size: 0.81em;
  i {
    margin-right: 2px;
  }
`;

const MemberWrapper = styled.div`
  display: flex;
  align-items: center;
  i {
    margin-right: 10px;
  }
`;

const MemberProgress = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  .progress-labels {
    display: flex;
    justify-content: space-between;
  }
`;

export default ({ application, navigate, refreshList }) => {
  const { t } = useTranslation();
  const { userContext } = React.useContext(UserContext);
  const [editMember, setEditMember] = React.useState();
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const location = application?.location || {};
  const image =
    location?.applicationConfig?.coverPhoto?.[0] ||
    location?.applicationConfig?.marketingImages?.[0];
  const { applicationLocked, allowAdd, inputCompleted, continueAction, householdMembers } =
    React.useMemo(() => {
      const paymentStepComplete =
        application?.location?.collectPayment === false || !!application?.paid;
      let inputCompleted =
        !!application?.submitted &&
        !!application?.verified &&
        paymentStepComplete &&
        !!application?.consent?.consented;

      return {
        applicationLocked:
          location?.companyInfo?.applicationLock === 'onClose' &&
          !!application?.completed &&
          inputCompleted,
        allowAdd:
          (!application?.type || application.type === 'primary') &&
          !application.status &&
          application?.paid &&
          application?.consent?.consented,
        inputCompleted,
        householdMembers: [{ ...application.applicant, type: 'primary', application: application }]
          .concat(application.otherContacts?.filter((z) => !['emergencyContact'].includes(z.type)))
          .reduce((final, z) => {
            if (!z) {
              return final;
            }
            const rV = Object.assign(z, {
              name: `${z?.firstName || ''} ${z?.lastName || ''}`.trim(),
              key: `${z?.type || ''}_${z?.uuid || ''}`.trim(),
              stepStatus: z?.application?.stepStatus || {
                ...application.stepStatus,
                progress: 0,
                complete: 0
              }
            });
            return !rV.name ? final : final.concat(rV);
          }, []),
        continueAction: inputCompleted
          ? () => navigate(`app/applications/form/v2/${application._id}/summary`)
          : () => {
              navigate(getContinueUrl(application));
            }
      };
    });

  const handleAdd = (member) => {
    const newContactList = []
      .concat(application.otherContacts)
      .concat(member)
      .filter((z) => z);

    APPEND_APPLICATION({
      userContext,
      locationId: application.locationId,
      applicationId: application._id,
      data: { otherContacts: newContactList }
    })
      .then(() => {
        setEditMember(undefined);
        refreshList();
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };

  return (
    <Wrapper>
      <Content>
        <Row>
          <Title>{location.address?.formattedAddress || location.name}</Title>
        </Row>
        <Row className="property-data">
          <Logo image={image} />
          <div className="data-list">
            <DataGroup>
              <div className="label">{t('aplicationItem.availableDate')}</div>
              <div className="data">{location.applicationConfig?.availableDate || 'No data'}</div>
            </DataGroup>
            <DataGroup>
              <div className="label">{t('aplicationItem.rent')}</div>
              <div className="data">{location.applicationConfig?.marketRent || '-'}</div>
            </DataGroup>
            <DataGroup>
              <div className="label">{t('aplicationItem.bedBaths')}</div>
              <div className="data">
                {location.bedCount}/{location.bathCount}
              </div>
            </DataGroup>
            <DataGroup>
              <div className="label">{t('aplicationItem.size')}</div>
              <div className="data">{location.squareFeet || '-'}</div>
            </DataGroup>
          </div>
        </Row>
        {householdMembers.length > 0 && (
          <Row className="household-list">
            <DataGroup>
              <div className="label">{t('aplicationItem.householdMembers')}</div>
              <div>
                {householdMembers.map((z) => {
                  const name = `${z?.firstName || ''} ${z?.lastName || ''}`.trim();
                  const key = `${z?.type || ''}_${z?.uuid || ''}`.trim();
                  const stepStatus = z?.stepStatus || {
                    ...application.stepStatus,
                    progress: 0,
                    complete: 0
                  };
                  return (
                    <MemberProgress key={key}>
                      <div className="progress-labels">
                        <div>{name}</div>
                        <div>
                          {stepStatus.complete} of {stepStatus.count}
                        </div>
                      </div>
                      <Progress progress={stepStatus.progress} />
                    </MemberProgress>
                  );
                })}
              </div>
            </DataGroup>
          </Row>
        )}
      </Content>
      <Row className="actions">
        <Action variant="primary" onClick={continueAction}>
          {inputCompleted ? `${t('aplicationItem.view')}` : `${t('aplicationItem.continue')}`}
        </Action>
        {allowAdd && (
          <Menu
            options={[
              { label: 'Invite Applicant', type: 'resident' },
              { label: 'Invite Guarantor', type: 'guarantor' }
            ]}
            onItemClick={({ type }) => setEditMember({ type })}>
            {(provided) => (
              <Button {...provided} title="Invite">
                <Icon name="fa-duotone fa-user-plus" />
              </Button>
            )}
          </Menu>
        )}
        {!applicationLocked && (
          <Action variant="secondary" onClick={() => setShowCancelDialog(true)} width={80}>
            {t('buttonsActions.delete')}
          </Action>
        )}
      </Row>
      <NewMember
        onSave={handleAdd}
        open={!!editMember}
        onClose={() => setEditMember(undefined)}
        member={editMember}
        location={location}
        application={application}
      />
      {showCancelDialog && (
        <CancelAppDialog
          t={t}
          applicationId={application._id}
          locationId={application.locationId}
          onClose={({ cancelled } = {}) => {
            setShowCancelDialog(false);
            if (cancelled) {
              refreshList();
            }
          }}
        />
      )}
    </Wrapper>
  );
};
