import React, { useEffect, useState } from 'react';
import { useMap, useMapsLibrary, APIProvider } from '@vis.gl/react-google-maps';
import AsyncCreatable from 'react-select/async-creatable';
import Icon from 'components/icon';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { components } from 'react-select';
import { ellipsis } from 'polished';

const { Option: OptionContainer } = components;
const API_KEY = 'AIzaSyDwNJevslox8yiM06faMhmlBhC9_jLpjq4';

const DropdownWrapper = styled.div`
  padding: 0 10px;
`;

const OptionLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 13px;
  overflow: hidden;
  i {
    color: #19b2e2;
  }
  .name-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    span {
      ${ellipsis()}
    }
  }
`;

const DropdownIndicator = () => (
  <DropdownWrapper>
    <Icon name="fa-duotone fa-solid fa-magnifying-glass" />
  </DropdownWrapper>
);

const Option = ({ ...props }) => {
  const { data } = props;
  return (
    <OptionContainer {...props}>
      <OptionLabel>
        {data.type === 'googleSuggestion' && <Icon name="fa-regular fa-location-dot" />}
        {data.icon && <Icon name={data.icon} />}
        {data.__isNew__ && <Icon name="fa-regular fa-magnifying-glass" />}
        <div className="name-wrapper">
          <span>{data.label}</span>
        </div>
      </OptionLabel>
    </OptionContainer>
  );
};

const selectStyles = {
  container: (styles) => ({ ...styles }),
  control: (styles) => ({ ...styles, borderColor: '#ddd', height: 45 })
};

const fetchPredictions = async ({
  inputValue,
  callback,
  autocompleteService,
  sessionToken,
  loadOptions,
  disablePlaces
}) => {
  if (!inputValue) return callback([]);

  if (disablePlaces) {
    const res = loadOptions ? await loadOptions(inputValue) : [];
    callback(res);
  }

  if (!autocompleteService) return callback([]);

  const request = { input: inputValue, sessionToken };
  const response = await autocompleteService.getPlacePredictions(request);
  const customResults = loadOptions ? await loadOptions(inputValue) : [];
  const results = [
    ...customResults,
    ...response.predictions.map((p) => ({
      ...p,
      label: p.description,
      value: p.place_id,
      type: 'googleSuggestion'
    }))
  ];
  callback(results);
};

const Autocomplete = ({ onPlaceSelect, loadOptions, autoFocus, disablePlaces }) => {
  const map = useMap();
  const places = useMapsLibrary('places');
  const [sessionToken, setSessionToken] = useState();
  const [autocompleteService, setAutocompleteService] = useState(null);
  const [placesService, setPlacesService] = useState(null);

  useEffect(() => {
    if (!places || disablePlaces) return;
    setAutocompleteService(new places.AutocompleteService());
    setPlacesService(new places.PlacesService(map));
    setSessionToken(new places.AutocompleteSessionToken());
    return () => setAutocompleteService(null);
  }, [map, places]);

  const debouncedSearch = React.useCallback(debounce(fetchPredictions, 300), []);

  const loadResults = (inputValue, callback) => {
    debouncedSearch({
      inputValue,
      callback,
      autocompleteService,
      sessionToken,
      loadOptions,
      disablePlaces
    });
  };

  const onSelect = (option) => {
    if (option?.type === 'googleSuggestion') {
      const detailRequestOptions = {
        placeId: option.place_id,
        fields: ['geometry', 'name', 'formatted_address'],
        sessionToken
      };
      const detailsRequestCallback = (placeDetails) => {
        option.placeDetails = placeDetails;
        option.formatted_address = placeDetails.formatted_address;
        if (placeDetails?.geometry?.location) {
          option.geopoint = [
            placeDetails?.geometry?.location?.lng(), // Q: is that the correct order?
            placeDetails?.geometry?.location?.lat()
          ];
        }
        setSessionToken(new places.AutocompleteSessionToken());
        onPlaceSelect?.(option);
      };
      placesService?.getDetails(detailRequestOptions, detailsRequestCallback);
    }
    onPlaceSelect?.(option);
  };

  return (
    <AsyncCreatable
      autoFocus={autoFocus}
      formatCreateLabel={(val) => `Search: ${val}`}
      createOptionPosition="first"
      placeholder="Address, neighborhood, city, zip..."
      loadOptions={loadResults}
      styles={selectStyles}
      components={{ DropdownIndicator, Option }}
      onChange={onSelect}
      noOptionsMessage={() => 'Type to search...'}
      isClearable
    />
  );
};

// pass withProvider = true if autocomplete used with no provider parent
export default ({ withProvider, ...props }) => {
  return withProvider ? (
    <APIProvider apiKey={API_KEY}>
      <Autocomplete {...props} />
    </APIProvider>
  ) : (
    <Autocomplete {...props} />
  );
};
