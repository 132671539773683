const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
export const toMoneyString = (amount = '') => {
  const val = `${amount || ''}`.replace(/[a-zA-Z,$ ]/gi, '');
  return moneyFormatter.format(val);
};

const isValidNumber = (val) => val !== null && val !== undefined && Number.isFinite(Number(val));
