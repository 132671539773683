import React from 'react';
import toast from 'react-hot-toast';
import { Route, Routes, useLocation } from 'react-router-dom';
import { UserContext, useNavigateWithContext } from 'state';
import { VALID_USER_SESSION } from 'dal/user';
import { REDEEM_CODE } from 'dal/applications';
import { LoginRoutes, ProfileRoutes, SupportRoutes } from './commonRoutes';
import { useRefCode } from 'hooks/useSessionVariables';

import Code from '../code';
import ShareList from '../approver/share/list';
import ShareDetail from '../approver/share/detail';

const PUBLIC_ROUTES = ['home'];
export default () => {
  const location = useLocation();
  const navigate = useNavigateWithContext();
  const refCode = useRefCode();
  const [initialRefHandled, setInitialRefHandled] = React.useState(false);
  const { userContext, bounceUser } = React.useContext(UserContext);
  //Need to bounce the user to login if they are missing a usercontext
  React.useEffect(() => {
    const pathTier = ((location?.pathname || '').split('/')[3] || '').toLowerCase();
    if (!PUBLIC_ROUTES.includes(pathTier)) {
      if (!VALID_USER_SESSION(userContext?.accessToken)) {
        bounceUser();
      }
    }
  }, [location]);

  //Need to bounce the user to login if they are missing a usercontext
  React.useEffect(() => {
    if (userContext?._id && !initialRefHandled && refCode) {
      setInitialRefHandled(true);
      REDEEM_CODE({ userContext, code: refCode })
        .then((result) => {
          if (result?.shareId) {
            navigate(`shares/detail/${result?.shareId}`);
          }
        })
        .catch((err) => {
          toast.error(
            err.data?.message
              ? err.data?.message
              : err.message || 'Referral code is invalid or expired'
          );
        });
    }
  }, [userContext?._id]);

  return (
    <Routes>
      {LoginRoutes}
      {ProfileRoutes}
      {SupportRoutes}
      <Route path="shares">
        <Route path="code">
          <Route index={true} element={<Code />} />
          <Route path=":codeValue" element={<Code />} />
        </Route>
        <Route path="list" element={<ShareList />} />
        <Route path="detail/:shareId" element={<ShareDetail />} />
      </Route>
    </Routes>
  );
};
