import React from 'react';
import styled from 'styled-components';

import Input from 'components/input';
import FormGroup from 'components/form-group';

const FieldWrapper = styled(FormGroup)`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1px;
  .group-label {
    font-size: 0.875em !important;
    font-weight: normal;
    color: #71828f;
    text-transform: uppercase;
    margin: 0;
    align-self: flex-start;
  }
  input {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px #d4dae1;
    background-color: #fff;
  }
  .disabled-input {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px #d4dae1;
  }
`;

export const LoginField = ({ label, errorText, className, ...props }) => {
  return (
    <FieldWrapper label={label} className={className} errorText={errorText}>
      <Input {...props} />
    </FieldWrapper>
  );
};
