import React from 'react';
import styled from 'styled-components';
import ProgressBar from 'components/percent-bar';
import Button from 'components/button';
import { useNavigateWithContext } from 'state';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Icon from 'components/icon';
import Progress from 'components/percent-bar';
import { useTranslation } from 'react-i18next';
import { STEP_STATES } from 'helpers/enums';
import Menu from 'components/menu';
import ChangeLocation from './modal-change-location';
import AppStatus from './app-status';
import CancelAppDialog from '../applications/cancel-app-dialog';
import { t } from 'i18next';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex-grow: 1;
  height: 100%;
`;

const Row = styled.div`
  margin: 30px 45px;
  @media (max-width: 960px) {
    margin: 0px;
  }
`;

const Title = styled.div`
  font-size: 1.75em;
  text-align: center;
  font-weight: bold;
`;

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  .progress-labels {
    flex-shrink: 0;
    .title {
      font-weight: bold;
    }
  }
`;

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 30px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  @media (max-width: 960px) {
    margin: 0px;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 15px 20px;
    &:last-child {
      border-bottom: none;
    }
  }
  gap: 10px;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  .body {
    flex: 1;
  }
  .section-title {
    font-weight: bold;
    text-transform: uppercase;
  }
`;

const StatusMobile = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 1px -2px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  > div {
    flex: 1;
  }
  .title {
    font-weight: bold;
  }
  .description {
    font-size: 0.75em;
    color: #777;
  }
  button {
    display: flex;
    align-items: center;
  }
`;

const MainAction = styled(Button)`
  padding: 7px 12px;
  color: #fff;
  background-color: var(--color-primary);
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  text-transform: uppercase;
  min-width: 100px;
  &.done {
  }
  &.completed,
  &.done,
  &.inProgress {
    color: var(--color-primary);
    background-color: #fff;
    border: 1px solid var(--color-primary);
  }
  &:disabled {
    background-color: #d4dae1 !important;
    color: #71828f !important;
    opacity: 1;
    border: none;
  }
`;

const SectionsList = styled.div``;
const ApprovalWrapper = styled.div`
  position: relative;
  min-height: 30px;
  margin-left: 25px;
  line-height: 35px;

  > img {
    width: 50px;
    position: absolute;
    top: -10px;
    left: -40px;
  }
`;
const ConditionalWrapper = styled.div`
  margin-bottom: 5px;
  font-style: italic;
`;

const StatusMessage = styled.div`
  border: 1px solid #ddd;
  display: grid;
  grid-template-columns: 1fr 2fr;
  padding: 20px;
  gap: 10px;
  line-height: 1.4;
  &.submitted {
    background-color: #fffed1;
    border-color: #f7b500;
  }
  &.denied {
    border-color: #e02020;
    background-color: rgba(224, 32, 32, 0.1);
  }
  &.approved {
    border-color: #6dd400;
    background-color: #e6ffcd;
  }
`;

const createSectionKey = (id) => `section_${id}`;
const Section = ({ title, id, description, onClick, isMobile, status }) => {
  const disabled = status?.locked || status.actionEnabled === false;

  return (
    <SectionWrapper
      id={createSectionKey(id)}
      title={status?.locked ? t('overviewStep.stepLockedTooltip') : undefined}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}>
      {isMobile && (
        <>
          {[STEP_STATES.done, STEP_STATES.completed].includes(status.state) ? (
            <Icon name={status.icon || 'fa-duotone fa-circle-check'} color="#5bcd05" />
          ) : (
            <Icon name={status.icon || 'fa-sharp fa-light fa-circle'} color="#ccc" />
          )}
        </>
      )}
      <div className="body">
        <div className="section-title">{title}</div>
        <div className="section-description">{description}</div>
      </div>
      {isMobile ? (
        <>{status.actionEnabled && !disabled && <Icon name="fa-solid fa-chevron-right" />}</>
      ) : (
        <MainAction disabled={disabled} className={status.state}>
          {status.state === STEP_STATES.done && (
            <Icon name={status.icon || 'fa-duotone fa-circle-check'} />
          )}
          {status.label}
        </MainAction>
      )}
    </SectionWrapper>
  );
};

export default ({ application, location, steps, nextStep, locked, setLoading, onRefresh }) => {
  const navigate = useNavigateWithContext();
  const params = useParams();
  const [showChangeLocationModal, setShowChangeLocationModal] = React.useState();
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const { t } = useTranslation();

  const navToSection = (s) => {
    navigate(`app/applications/form/v2/${params.applicationId}/${s.path}`);
  };

  const handleActions = ({ id }) => {
    if (id === 'print') {
      return navigate(`app/applications/form/v2/${params.applicationId}/summary`);
    }
    if (id === 'change') {
      return setShowChangeLocationModal(true);
    }
    if (id === 'delete') {
      return setShowCancelDialog(true);
    }
  };

  // This will auto-scroll the section so that the next step is visible
  React.useEffect(() => {
    if (nextStep?.key) {
      let focusElement =
        application?.paid && application?.submitted && application?.verified
          ? 'statusMessage'
          : nextStep.key;

      document.getElementById(createSectionKey(focusElement))?.scrollIntoView({
        block: 'center',
        inline: 'nearest'
      });
    }
  }, [nextStep?.key]);

  return (
    <Wrapper>
      {showCancelDialog && (
        <CancelAppDialog
          t={t}
          applicationId={application._id}
          locationId={application.locationId}
          onClose={({ cancelled } = {}) => {
            setShowCancelDialog(false);
            if (cancelled) navigate('app/applications');
          }}
        />
      )}
      {isMobile && (
        <Row>
          <AppStatus application={application} location={location} />
        </Row>
      )}
      {!isMobile && (
        <>
          <Row>
            <Title>{t('overviewStep.title')}</Title>
          </Row>
          <Row>
            <AppStatus application={application} location={location} />
          </Row>
          {!locked && (
            <Row>
              <ProgressWrapper>
                <div className="progress-labels">
                  <div className="title">{t('overviewStep.status')}</div>
                  <div className="status">
                    {application.stepStatus?.complete} {t('overviewStep.of')}{' '}
                    {application.stepStatus?.count} {t('overviewStep.complete')}
                  </div>
                </div>
                <ProgressBar height={15} progress={application.stepStatus?.progress} />
              </ProgressWrapper>
            </Row>
          )}
        </>
      )}
      {locked ? (
        <Row style={{ flexGrow: 1 }}>
          <div style={{ textAlign: 'center' }}>
            Thanks for your interest! While this home is not off the market, there is another
            applicant in the final stages.
            <br />
            <br />
            Feel free to check out one of our other locations or contact us to be put on a wait list
            if the current offer falls through.
            <br />
            <br />
          </div>
        </Row>
      ) : (
        <Row>
          <SectionsList>
            {steps
              .filter((s) => !s.hidden)
              .map((s, i) => (
                <Section
                  key={i}
                  id={s.key}
                  {...s}
                  onClick={() => navToSection(s)}
                  isMobile={isMobile}
                />
              ))}
          </SectionsList>
        </Row>
      )}
      {isMobile && (
        <StatusMobile>
          <div>
            <div className="title">{t('overviewStep.status')}</div>
            <Progress height={8} color="#5bcd05" progress={application.stepStatus?.progress} />
            <div className="description">
              {application.stepStatus?.complete} {t('overviewStep.of')}{' '}
              {application.stepStatus?.count} {t('overviewStep.complete')}
            </div>
          </div>
          <Menu
            onItemClick={handleActions}
            options={[
              { label: 'Print', icon: 'fa-solid fa-print', id: 'print' },
              { label: 'Delete', icon: 'fa-solid fa-trash', id: 'delete' },
              { label: 'Change Property', icon: 'fa-solid fa-arrows-retweet', id: 'change' }
            ]}>
            {(provided) => (
              <Button {...provided}>
                <Icon name="fa-solid fa-ellipsis" size={24} />
              </Button>
            )}
          </Menu>
          {showChangeLocationModal && (
            <ChangeLocation
              onRefresh={onRefresh}
              setLoading={setLoading}
              location={location}
              application={application}
              open={showChangeLocationModal}
              onClose={() => setShowChangeLocationModal(false)}
            />
          )}
        </StatusMobile>
      )}
    </Wrapper>
  );
};
