import React from 'react';
import styled from 'styled-components';
import Avatar from '../../components/avatar';
import Button from '../../components/button';
import FormGroup from '../../components/form-group';
import Input from '../../components/input';
import { useFormik } from 'formik';
import { UserContext } from '../../state';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  input {
    background-color: #fff;
    border: 1px solid #ddd;
  }
  @media (max-width: 500px) {
    background-color: #fff;
  }
  input:disabled {
    background-color: #dddddd5c;
    cursor: not-allowed;
  }
`;

export const PageContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  padding: 20px 45px;
  border-radius: 6px;
  max-width: 500px;
  margin: 20px auto;
  width: 100%;
  @media (max-width: 500px) {
    box-shadow: none;
    border: none;
    margin: 0;
  }
`;

export const Row = styled.div`
  margin: ${({ spacing = 15 }) => spacing}px 0px;
  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().max(50, 'Too Long!').required('Name is required'),
  lastName: Yup.string().max(50, 'Too Long!').required('Lastname is required'),
  phone: Yup.string().phone('Invalid phone').required('Phone is required')
});

export default () => {
  const { userContext, updateUserContext } = React.useContext(UserContext);
  const { t } = useTranslation();

  const { errors, touched, values, handleChange, handleSubmit, handleBlur } = useFormik({
    initialValues: {
      email: userContext?.email,
      firstName: userContext?.firstName,
      lastName: userContext?.lastName,
      phone: userContext?.phone
    },
    validationSchema: SignupSchema,
    onSubmit: () => {
      updateUserContext(values);
    }
  });

  return (
    <Wrapper>
      <PageContainer>
        <Row className="center">
          <Avatar
            firstName={values.firstName}
            lastName={values.lastName}
            imageUrl={userContext?.imageUrl}
            defaultImageUrl={
              'https://d19uz2b1vsd3mq.cloudfront.net/p/assets/images/avatars/ToyFaces_Colored_BG_111.jpg'
            }
            size={100}
          />
          {/* <br />
          <Button variant="link">Change Photo</Button> */}
        </Row>
        <Row>
          <FormGroup
            label={t('form.firstName')}
            errorText={touched?.firstName && errors?.firstName}>
            <Input
              name="firstName"
              value={values.firstName}
              icon="User"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
          <FormGroup label={t('form.lastName')} errorText={touched?.lastName && errors?.lastName}>
            <Input
              name="lastName"
              value={values.lastName}
              icon="User"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
          <FormGroup label={t('form.phone')} errorText={touched?.phone && errors?.phone}>
            <Input
              value={values.phone}
              name="phone"
              icon="Calls-SMS"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
          <FormGroup label={t('form.email')}>
            <Input
              name="email"
              disabled={true}
              icon="Email"
              value={values.email}
              onChange={() => {}}
            />
          </FormGroup>
        </Row>
        <Row className="center">
          <Button variant="primary" width={100} onClick={handleSubmit}>
            {t('buttonsActions.save')}
          </Button>
        </Row>
      </PageContainer>
    </Wrapper>
  );
};
