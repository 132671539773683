import React from 'react';
import styled from 'styled-components';
import ErrorBoundary from 'components/errorBoundary';
import ApplicationHeader from './header';
import ApplicationGlance from './glance';
import ApplicationDetails from './details';
import ApplicantDetails from './applicants';
import YourFeedback from './your-feedback';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #f1f3f6;
  overflow-y: auto;
  font-size: 14px;
  padding-bottom: 20px;
`;

const Content = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  width: 100%;
`;

const GlanceRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;
  > div {
    flex-grow: 1;
  }
`;
const Header = styled.div`
  height: 200px;
  background-color: ${({ theme }) => theme?.colors?.applicationBackground || '#002857'};

  margin: 0 auto;
  padding: 0 20px;
  width: 100%;

  color: #fff;
  .header-breadcrumb,
  .header-date {
    span {
      color: rgba(255, 255, 255, 0.6);
    }
  }
  .header-breadcrumb {
    display: flex;
    align-items: center;
  }
`;

const encodeAppConfig = (shareDetails) => {
  return {
    disabledSections: {
      ...shareDetails.options,
      approvalFlow: !shareDetails?.options?.appIncludeApprovalFlow,
      actionCenter: !shareDetails?.options?.includeActionCenter,
      quality: !shareDetails?.options?.includeQualityScore,
      qualityScore: !shareDetails?.options?.includeQualityScore,
      qualityScoreDetails: !shareDetails?.options?.includeQualityScoreDetails,
      credit: !shareDetails?.options?.includeCreditDetails,
      criminal: !shareDetails?.options?.includeCriminalDetails,
      eviction: !shareDetails?.options?.includeEvictionDetails,
      contactInfo: !shareDetails?.options?.includeContactInformation,
      applicationStatus: !shareDetails?.options?.includeApplicationStatus,
      locationInformation: !shareDetails?.options?.includeLocationInformation
    }
  };
};

export default ({ shareDetails, application, selectedApplicant, handleActions }) => {
  const applicationConfig = encodeAppConfig(shareDetails);
  const applicant = !selectedApplicant
    ? undefined
    : application?.applicants?.find((app) => app.uuid === selectedApplicant);

  return (
    <>
      <Wrapper id="application-wrapper">
        <Header id="application-header">
          <ErrorBoundary>
            <ApplicationHeader
              applicant={applicant}
              application={application}
              handleActions={handleActions}
              applicationConfig={applicationConfig}
            />
          </ErrorBoundary>
        </Header>
        <Content>
          <GlanceRow>
            <ErrorBoundary>
              <ApplicationGlance
                applicant={applicant}
                application={application}
                applicationConfig={applicationConfig}
                handleActions={handleActions}
              />
            </ErrorBoundary>
          </GlanceRow>
          <YourFeedback
            shareDetails={shareDetails}
            application={application}
            applicationConfig={applicationConfig}
            handleActions={handleActions}
          />
          {!applicant ? (
            <ApplicationDetails
              application={application}
              applicationConfig={applicationConfig}
              handleActions={handleActions}
            />
          ) : (
            <ApplicantDetails
              applicant={applicant}
              application={application}
              applicationConfig={applicationConfig}
              handleActions={handleActions}
            />
          )}
        </Content>
      </Wrapper>
    </>
  );
};
