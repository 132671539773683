import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { UserContext } from 'state';
import Button from 'components/button';
import Input from 'components/input';
import Phone from 'components/phone';
import { FieldGroup, Title, SubTitle } from './common';

const Wrapper = styled.div`
  ${({ isMobile }) =>
    !isMobile
      ? css`
          padding: 10px 20px 20px;
        `
      : ''}

  .flexRow {
    display: flex;
    ${({ isMobile }) =>
      isMobile
        ? css`
            flex-flow: column;
            gap: 0px;
            margin-bottom: 20px;
          `
        : css`
            gap: 20px;
          `}
  }
`;
const StyledPhone = styled(Phone)`
  .phoneInput {
    width: 100%;
    > div {
      width: 100%;
    }
  }
`;
const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;
export default ({ values: initialValues, action, isMobile, onSubmit }) => {
  const { t } = useTranslation();
  const { userContext } = React.useContext(UserContext);

  const applicantInfoSchema = yup.object().shape({
    firstName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.name')}`),
    lastName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.lastName')}`),
    middleName: yup.string(),
    email: yup
      .string()
      .email(`${t('form.validate.invalidEmail')}`)
      .required(`${t('form.validate.email')}`),
    phone: yup
      .array(
        yup.object({
          number: yup
            .string()
            .phone(`${t('form.validate.invalidPhone')}`)
            .required(`${t('form.validate.phone')}`),
          type: yup.string()
        })
      )
      .required(`${t('form.validate.phone')}`)
  });

  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      email: action?.applicant?.email || initialValues?.email || userContext?.email || '',
      phone: action?.applicant?.phone ||
        initialValues?.phone || [{ number: userContext?.phone || '', type: 'mobile' }],
      firstName:
        action?.applicant?.firstName || initialValues?.firstName || userContext?.firstName || '',
      lastName:
        action?.applicant?.lastName || initialValues?.lastName || userContext?.lastName || '',
      middleName:
        action?.applicant?.middleName || initialValues?.middleName || userContext?.middleName
    },
    validationSchema: applicantInfoSchema,
    onSubmit: (vals) => {
      onSubmit({ applicant: vals });
    }
  });

  return (
    <Wrapper isMobile={isMobile}>
      <Title>
        <Trans i18nKey="showing.detail.person.title">Personal Information</Trans>
      </Title>
      <SubTitle>
        <Trans i18nKey="showing.detail.person.titleName">What is your legal name?</Trans>
      </SubTitle>
      <div className="flexRow">
        <FieldGroup
          label={<Trans i18nKey="showing.detail.person.firstName">First Name</Trans>}
          errorText={touched.firstName && errors.firstName}>
          <Input
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FieldGroup>
        <FieldGroup
          label={<Trans i18nKey="showing.detail.person.middleName">Middle Name</Trans>}
          errorText={touched.firstName && errors.middleName}>
          <Input
            name="middleName"
            value={values.middleName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FieldGroup>
      </div>
      <div className="flexRow">
        <FieldGroup
          label={<Trans i18nKey="showing.detail.person.lastName">Last Name</Trans>}
          errorText={touched.lastName && errors.lastName}>
          <Input
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FieldGroup>
      </div>
      <SubTitle>
        <Trans i18nKey="showing.detail.person.titleName">Share your contact information</Trans>
      </SubTitle>
      <div className="flexRow">
        <FieldGroup
          label={<Trans i18nKey="showing.detail.person.email">Email</Trans>}
          errorText={touched.email && errors.email}>
          <Input name="email" value={values.email} onChange={handleChange} onBlur={handleBlur} />
        </FieldGroup>
        <FieldGroup
          label={<Trans i18nKey="showing.detail.person.mobilePhone">Mobile Phone</Trans>}
          errorText={touched.phone && errors.phone}>
          <StyledPhone
            multi={false}
            typePicker={false}
            name="phone"
            errors={errors.phone}
            value={values.phone}
            onChange={(e) => {
              handleChange({
                ...e,
                target: { ...e.target, value: e.target.value ? [e.target.value] : [] }
              });
            }}
            onBlur={handleBlur}
          />
        </FieldGroup>
      </div>
      <StyledButton variant="primary" onClick={handleSubmit}>
        Next
      </StyledButton>
    </Wrapper>
  );
};
