import React from 'react';
import styled from 'styled-components';
import Avatar from 'components/avatar';
import Button from 'components/button';
import Icon from 'components/icon';
import { useDocumentContext } from './state';
import { format, formatRelative } from 'date-fns';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  border-left: 1px solid #ddd;
  display: flex;
  font-size: 14px;
  flex-shrink: 0;
  &.pane_mdScreen {
    position: absolute;
    top: 0;
    right: 0;
    border-left: none;
    .pane_tabs > button {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
    }
    .pane_tabs > .separator {
      display: none;
    }
  }
  &.pane_tabOpen {
    background-color: #fff;
    border-left: 1px solid #ddd;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    .pane_tabs > button {
      box-shadow: none;
    }
    .pane_tabs > .separator {
      display: initial;
    }
  }
`;

const Tabs = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  border-right: 1px solid #ddd;
  padding: 5px;
  button {
    display: flex;
    width: 30px;
    height: 30px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background-color: #fff;
    &:last-child {
      border-bottom: none;
    }
    &:hover {
      background-color: #f5f5f5;
    }
    &.active {
      background-color: #eee;
    }
  }
  .separator {
    border-bottom: 1px solid #ddd;
  }
`;

const Content = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  width: 260px;
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
  .avatar {
    color: #fff;
  }
  .content {
    flex: 1;
  }
  .name {
    font-weight: bold;
  }
  .seen {
    font-size: 13px;
  }
  .status {
    padding: 4px 10px;
    border-radius: 4px;
    background-color: #b3bdc5;
    color: #fff;
    font-size: 13px;
  }
  &.signed .status {
    background-color: #47b971;
  }
`;

const SectionTitle = styled.div`
  margin-bottom: 12px;
  font-weight: bold;
`;

const TabWrapper = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 15px;
`;

const HistoryItemWrapper = styled.div`
  margin-bottom: 15px;
  .timestamp {
    font-weight: bold;
  }
`;

const Section = styled.div`
  margin-bottom: 24px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99;
`;

const Close = styled(Button)`
  position: absolute;
  top: 5px;
  left: -40px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ddd;
`;

const PaneOverlay = ({ enabled, children, onClick }) =>
  enabled ? <Overlay onClick={onClick}>{children}</Overlay> : children;

const Contact = ({ color, name, lastSeen, hasSigned, signedAt, type, uuid, currentUser }) => {
  return (
    <ContactWrapper className={hasSigned ? 'signed' : ''}>
      <Avatar color={color} firstName={name.split(' ')?.[0]} lastName={name.split(' ')?.[1]} />
      <div className="content">
        <div className="name">{name}</div>
        <div className="seen">
          {type === 'company'
            ? `Signed at ${formatRelative(new Date(signedAt), new Date())} `
            : !lastSeen
            ? currentUser?.uuid === uuid
              ? `Last seen now`
              : 'Not seen'
            : ` Last seen ${formatRelative(new Date(lastSeen), new Date())}`}
        </div>
      </div>
      <span className="status">{hasSigned ? 'Signed' : !lastSeen ? 'Sent' : 'Viewed'}</span>
    </ContactWrapper>
  );
};

const HistoryItem = ({ date, snippet }) => {
  return (
    <HistoryItemWrapper>
      <div className="timestamp">{format(new Date(date), 'MM/d/yy h:m a')}</div>
      <div>{snippet}</div>
    </HistoryItemWrapper>
  );
};

const Recipients = ({ recipients, companySigners, user }) => {
  return (
    <TabWrapper>
      <Section>
        <SectionTitle>Recipients</SectionTitle>
        {recipients.map((recipient) => (
          <Contact key={recipient.uuid} {...recipient} currentUser={user} />
        ))}
      </Section>
      {companySigners.length > 0 && (
        <Section>
          <SectionTitle>Company Signers</SectionTitle>
          {companySigners.map((recipient) => (
            <Contact key={recipient.uuid} {...recipient} type="company" currentUser={user} />
          ))}
        </Section>
      )}
    </TabWrapper>
  );
};

const History = ({ history = [] }) => {
  return (
    <TabWrapper>
      <SectionTitle>Document History</SectionTitle>
      {history.map((item) => (
        <HistoryItem key={item._id} {...item} />
      ))}
    </TabWrapper>
  );
};

export default () => {
  const {
    doc: { recipients = [], companySigners = [] },
    ui,
    history,
    user
  } = useDocumentContext();

  const isActive = (tab) => ui.statusPaneTab === tab;

  const mdScreen = useMediaQuery({ maxWidth: 1390 });

  React.useEffect(() => {
    ui.updateUi({ statusPaneTab: mdScreen ? undefined : 'recipients' });
  }, [mdScreen]);

  return (
    <PaneOverlay
      enabled={mdScreen && ui.statusPaneTab}
      onClick={() => ui.updateUi({ statusPaneTab: undefined })}>
      <Wrapper
        className={`${mdScreen ? 'pane_mdScreen' : ''} ${
          ui.statusPaneTab && mdScreen ? 'pane_tabOpen' : ''
        }`}
        onClick={(event) => event.stopPropagation()}>
        <Tabs className="pane_tabs">
          <Button
            title="Recipients"
            className={isActive('recipients') ? 'active' : ''}
            onClick={() => ui.updateUi({ statusPaneTab: 'recipients' })}>
            <Icon name="fa-solid fa-people-group" />
          </Button>
          <div className="separator" />
          <Button
            title="History"
            className={isActive('history') ? 'active' : ''}
            onClick={() => ui.updateUi({ statusPaneTab: 'history' })}>
            <Icon name="fa-solid fa-clock-rotate-left" />
          </Button>
        </Tabs>
        {ui.statusPaneTab && (
          <Content>
            {isActive('recipients') && (
              <Recipients recipients={recipients} companySigners={companySigners} user={user} />
            )}
            {isActive('history') && <History history={history} />}
          </Content>
        )}
        {mdScreen && ui.statusPaneTab && (
          <Close onClick={() => ui.updateUi({ statusPaneTab: undefined })}>
            <Icon name="fa-regular fa-xmark" />
          </Close>
        )}
      </Wrapper>
    </PaneOverlay>
  );
};
