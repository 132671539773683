import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

import { validatePhoneNumber, isAllowedCountry } from 'utils/phoneHelpers';
import passwordStrengthChecker from 'utils/passwordStrengthChecker';
import { VALIDATE_PHONE } from 'dal/user';
import { useOrgId } from 'hooks/useSessionVariables';
import useMobile from 'hooks/useMobile';
import { LoginField, LoginActions } from '../common';
import AccountVerify from './account-verify';

const Wrapper = styled.div`
  .login-form {
    display: flex;
    flex-flow: column;
    gap: 15px;
    > div {
      width: 100%;
      display: flex;
      flex-flow: row;
      gap: 10px;
    }
  }
`;

const BoxDetailPassword = styled.div`
  display: flex;
  flex-flow: column !important;
  width: 100%;
  border: 1px solid #f44336;
  border-radius: 4px;
  color: #f44336;
  padding: 10px;
  margin: -30px 0 20px;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    flex-wrap: wrap;
    @media (max-width: 740px) {
      display: block;
    }
    > div {
      width: 50%;
      flex-shrink: 0;
      @media (max-width: 740px) {
        width: 100%;
      }
    }
  }
`;
const SignupSchema = (t, isSSO) => {
  return Yup.object().shape({
    firstName: Yup.string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.name')}`),
    lastName: Yup.string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.lastName')}`),
    email: Yup.string()
      .email(`${t('form.validate.invalidEmail')}`)
      .required(`${t('form.validate.email')}`),
    phone: Yup.string()
      .phone(`${t('form.validate.invalidPhone')}`)
      .required(`${t('form.validate.phone')}`),
    auth: !isSSO
      ? Yup.string()
          .test('passwordStrength', `${t('form.validate.passwordStrength')}`, (value) => {
            const result = passwordStrengthChecker(value);
            if (['strong', 'good'].includes(result.score)) return true;
            return false;
          })
          .required(`${t('form.validate.password')}`)
      : undefined,
    authVerify: !isSSO
      ? Yup.string()
          .oneOf([Yup.ref('auth'), null], `${t('form.validate.passNotSame')}`)
          .required(`${t('form.validate.password')}`)
      : undefined
  });
};

export default ({ content, handleActions, registerData, isSSO }) => {
  const orgId = useOrgId();
  const { isTabletOrMobile } = useMobile();
  const [createStep, setCreateStep] = React.useState();
  const { t } = useTranslation();

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setValues } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      auth: '',
      authVerify: '',
      ...registerData
    },
    validationSchema: SignupSchema(t, isSSO),
    validate: (submittedValues) => {
      const errors = {};
      if (
        !submittedValues.phone ||
        !validatePhoneNumber(submittedValues.phone) ||
        !isAllowedCountry(submittedValues.phone)
      ) {
        errors.phone = 'Only US, CA, & MX numbers are allowed';
      }

      return errors;
    },
    onSubmit: (values) => {
      VALIDATE_PHONE({
        email: values.email,
        orgId,
        phone: values.phone
      })
        .then((response) => {
          setCreateStep('verify');
        })
        .catch((err) => {
          console.error({ errData: err.data, err });
          toast.error(err.data?.message ? err.data?.message : err.message || err.name);
        });
    }
  });

  React.useEffect(() => {
    setValues({ ...values, ...registerData });
  }, [registerData]);

  const fieldBlurAndFocus = (e) => {
    if (isTabletOrMobile) {
      e.target.previousSibling?.scrollIntoView(true);
    }
    handleBlur(e);
  };

  if (createStep === 'verify') {
    return <AccountVerify registerData={values} onBack={() => setCreateStep('')} />;
  }

  return (
    <Wrapper>
      {content.title && <div className="title">{content.title}</div>}
      {content.description && <div className="description">{content.description}</div>}
      <div className="sub-title">Create your account</div>
      <div className="login-form">
        <div>
          <LoginField
            label="First Name"
            name="firstName"
            onChange={handleChange}
            onBlur={fieldBlurAndFocus}
            value={values.firstName}
            errorText={touched.firstName && errors.firstName}
          />
          <LoginField
            label="Last Name"
            name="lastName"
            onChange={handleChange}
            onBlur={fieldBlurAndFocus}
            value={values.lastName}
            errorText={touched.lastName && errors.lastName}
          />
        </div>
        <div>
          <LoginField
            label="Email"
            name="email"
            icon="Email"
            onChange={isSSO ? undefined : handleChange}
            onBlur={isSSO ? undefined : fieldBlurAndFocus}
            value={values.email}
            errorText={touched.email && errors.email}
            readOnly={!!isSSO}
          />
          <LoginField
            label="Phone (US, CA, MX)"
            name="phone"
            icon="Calls-SMS"
            onChange={handleChange}
            onBlur={fieldBlurAndFocus}
            value={values.phone}
            errorText={touched.phone && errors.phone}
          />
        </div>
        {!isSSO && (
          <>
            <div>
              <LoginField
                label="Password"
                name="auth"
                onChange={handleChange}
                onBlur={fieldBlurAndFocus}
                value={values.auth}
                errorText={touched.auth && errors.auth}
                type="password"
                spellcheck="false"
                autocomplete="off"
              />
              <LoginField
                label="Verify Password"
                name="authVerify"
                onChange={handleChange}
                onBlur={fieldBlurAndFocus}
                value={values.authVerify}
                errorText={touched.authVerify && errors.authVerify}
                type="password"
                spellcheck="false"
                autocomplete="off"
                showStrength={false}
              />
            </div>
            {(!!values.auth || touched?.auth) && errors?.auth && (
              <BoxDetailPassword>
                <div>{t('homePage.detailsPassword.description')}</div>
                <div>
                  <div>- {t('homePage.detailsPassword.capitalLetter')}</div>
                  <div>- {t('homePage.detailsPassword.specialCharacter')}</div>
                  <div>- {t('homePage.detailsPassword.lowecase')}</div>
                  <div>- {t('homePage.detailsPassword.number')}</div>
                </div>
              </BoxDetailPassword>
            )}
          </>
        )}
      </div>
      <LoginActions handleActions={handleActions} onCreateAccount={handleSubmit} />
    </Wrapper>
  );
};
