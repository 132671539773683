import React from 'react';
import styled from 'styled-components';

import { useAPIData } from 'dal/useAPIData';
import { useNavigateWithContext } from 'state';
import useAudience from 'hooks/useAudience';
import { useContextId } from 'hooks/useSessionVariables';
import Property from 'components/property';
import Icon from 'components/icon';
import SectionDescription from './section-description';
import useMobile from 'hooks/useMobile';

const StyledProperty = styled(Property)`
  width: 100%;
  flex-shrink: 0;
  overflow: auto;
  ${({ isMobile }) => (isMobile ? '' : `max-width:375px;`)}
`;
const StyledLoader = styled(Icon)`
  width: 100%;
  margin: auto;
  flex-shrink: 0;
  ${({ isMobile }) => (isMobile ? '' : `max-width:375px;`)}
`;

const PropertyPanel = ({ audience, isMobile }) => {
  const contextId = useContextId();
  const navigate = useNavigateWithContext();

  const [data, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_CONTEXT',
    !contextId ? undefined : { contextId: contextId, audience: audience?.uuid },
    { reFetchWhen: [contextId], disableEmptyArgs: true }
  );

  if (!contextId || failedFetch) {
    return <SectionDescription />;
  }

  if (!dataFetched) {
    return (
      <StyledLoader name="fa-duotone fa-spinner-third fa-spin" size={100} isMobile={isMobile} />
    );
  }

  return (
    <StyledProperty
      location={data}
      forceDisplay={true}
      isMobile={isMobile}
      onChangeProperty={() => navigate('locations')}
    />
  );
};

export default () => {
  const { isTabletOrMobile } = useMobile();
  const [audience] = useAudience();

  if (audience?.uuid === 'approver') {
    return <SectionDescription />;
  }

  // Don't show property selector on mobile views, it's too noisy
  if (isTabletOrMobile) {
    return <></>;
  }

  return <PropertyPanel audience={audience} isMobile={isTabletOrMobile} />;
};
