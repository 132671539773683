import styled from 'styled-components';
import Button from 'components/button';
import Select from 'components/select';
import Icon from 'components/icon';

const Wrapper = styled.div`
  display: flex;
  position: sticky;
  top: 10px;
  z-index: 300;
  background-color: #fff;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 10px 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: 1px solid #eee;
  border-radius: 25px;
  .Aptly_Select {
    border-right: 1px solid #eee;
    &:nth-child(3) {
      border: none;
    }
  }
  select {
    flex: 1;
    border: none;
    background-color: #fff;
    border-radius: 0px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export default ({ filters, setFilters }) => (
  <Wrapper>
    <Select
      placeHolder="Beds"
      value={filters.minBed}
      onChange={(e) => {
        setFilters({ ...filters, minBed: e.target.value });
      }}>
      <option value="">Beds</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
    </Select>
    <Select
      placeHolder="Baths"
      value={filters.minBath}
      onChange={(e) => {
        setFilters({ ...filters, minBath: e.target.value });
      }}>
      <option value="">Baths</option>
      <option value="1">1</option>
      <option value="2">2</option>
      <option value="3">3</option>
      <option value="4">4</option>
      <option value="5">5</option>
      <option value="6">6</option>
    </Select>
    <Select
      placeHolder="Max Rent"
      value={filters.maxRent}
      onChange={(e) => {
        setFilters({ ...filters, maxRent: e.target.value });
      }}>
      <option value="">Any Rent</option>
      <option value="1000">$1,000</option>
      <option value="1500">$1,500</option>
      <option value="2000">$2,000</option>
      <option value="3000">$3,000</option>
      <option value="4000">$4,000</option>
      <option value="5000">$5,000</option>
      <option value="8000">$8,000</option>
      <option value="9000">$9,000</option>
      <option value="10000">$10,000</option>
      <option value="11000">$11,000</option>
      <option value="12000">$12,000</option>
    </Select>
    <Button variant="primary" radius="50%">
      <Icon name="fa-solid fa-magnifying-glass" />
    </Button>
  </Wrapper>
);
