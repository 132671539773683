import REGION_STATES from 'data/country-states.json';

const REGION_STATES_MAP = Object.keys(REGION_STATES).reduce(
  (coMap, coKey) =>
    Object.assign(coMap, {
      [coKey]: REGION_STATES[coKey].reduce(
        (stateList, state) => Object.assign(stateList, { [state.abbreviation]: state }),
        {}
      )
    }),
  {}
);

export const getStateList = (country = 'US') => {
  return REGION_STATES[country] || [];
};

export const getStateFromAbbreviation = (stateAbbreviation, country = 'US') => {
  return REGION_STATES_MAP[country]?.[stateAbbreviation];
};
