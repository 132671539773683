import React from 'react';
import styled from 'styled-components';
import { TableSection } from 'components/section';

const CorpHighlight = styled.div`
  color: #49b22f;
`;

export default ({ className, application, handleActions }) => {
  const columns = [
    { label: 'Type', uuid: 'type', className: 'capitalize' },
    { label: 'Year', uuid: 'year' },
    {
      label: 'Make / Model',
      uuid: 'name',
      className: 'capitalize',
      render: (vehicle) => [vehicle?.make, vehicle?.model].filter((v) => v).join(' / ')
    },
    { label: 'Color', uuid: 'color', className: 'capitalize' },
    {
      label: 'Lic. Plate',
      uuid: 'licensePlate',
      render: (v) =>
        v.licensePlateState ? `(${v.licensePlateState}) ${v.licensePlate || ''}` : v.licensePlate
    },
    {
      label: 'Corp/Oversized',
      uuid: 'serviceAnimal',
      render: (v) => (v.oversized ? <CorpHighlight>Yes</CorpHighlight> : <div>No</div>)
    },
    { label: 'Added By', uuid: 'addedBy', className: 'capitalize' }
  ];
  return (
    <TableSection
      id="application-vehicles"
      className={className}
      handleActions={handleActions}
      data={application?.vehicles}
      columns={columns}
      title="VEHICLES"
      noDataMessage="No vehicles specified"
      titleIcon="fa-duotone fa-car-side"
    />
  );
};
