import React from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import OptionRadio from 'components/option-radio';
import Button from 'components/button';
import Modal from 'components/modal';
import {
  Row,
  Title,
  Description,
  PageWrapper,
  PageContainer
} from '../application-form-v2/common-styles';

const Warning = styled.div`
  padding: 40px 20px;
  img {
    border-radius: 4px;
  }
`;
const StyledContainer = styled(PageContainer)`
  @media (max-width: 960px) {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 20px;
  }
`;

const TitleArea = styled(Title)``;

const GuarantorWarning = ({ open, onClose, t }) => {
  return (
    <Modal width="500px" height="auto" onClose={onClose} open={open}>
      <Warning>
        <Row className="center text-center">
          <h3>{t('GuarantorWarning.title')}</h3>
          <div>{t('GuarantorWarning.description')}</div>
        </Row>
        <Row className="center" spacing={40}>
          <img src="/assets/guarantor.png" alt="" />
        </Row>
        <Row className="center text-center">{t('GuarantorWarning.secondDescription')}</Row>
        <Row className="center">
          <Button radius="25px" variant="secondary" onClick={onClose}>
            {t('buttonsActions.backButton')}
          </Button>
        </Row>
      </Warning>
    </Modal>
  );
};

export default ({ onNext }) => {
  const [type, setType] = React.useState('');
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const [warningModal, setWarningModal] = React.useState(false);
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <StyledContainer>
        <Row>
          <TitleArea isMobile={isMobile}>{t('applicantType.title')}</TitleArea>
          <Description>{t('applicantType.description')} </Description>
        </Row>
        <Row spacing={25}>
          <Row>
            <OptionRadio
              checked={type === 'applicant'}
              onChange={() => setType('applicant')}
              icon="fa-duotone fa-file-user"
              label={t('applicantType.aplicatOption')}
              description={t('applicantType.aplicantDescription')}
            />
          </Row>
          <Row>
            <OptionRadio
              checked={type === 'guarantor'}
              onChange={() => {
                setWarningModal(true);
              }}
              icon="fa-duotone fa-user-shield"
              label={t('applicantType.guarantorOption')}
              description={t('applicantType.guarantorDescription')}
            />
          </Row>
        </Row>
        <Row className="center" style={{ flexFlow: 'row', gap: '15px' }}>
          <Button
            disabled={!type}
            radius="25px"
            variant="primary"
            onClick={() => onNext({ version: 'v2' })}
            width={80}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </StyledContainer>
      <GuarantorWarning open={warningModal} onClose={() => setWarningModal(false)} t={t} />
    </PageWrapper>
  );
};
