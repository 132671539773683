export const addressCodeType = {
  S: 'Staying with friends/family',
  R: 'Rent',
  O: 'Own'
};

//these are the states used by UX
export const STEP_STATES = {
  notStarted: 'notStarted',
  inProgress: 'inProgress',
  completed: 'completed',
  done: 'done'
};
export const STEP_STATUS = { pending: 0, done: 1, skipped: -1 };

export const AUDIENCES = {
  approver: {
    uuid: 'approver',
    pathname: 'approver',
    label: 'Approver',
    routePrefix: 'approver/',
    paths: { home: 'shares/list', public: 'home/login' }
  },
  applicant: {
    applicationType: 'lease',
    uuid: 'applicant',
    pathname: 'applicant',
    label: 'Applicant',
    routePrefix: 'applicant/',
    paths: {
      home: 'app/applications',
      public: 'locations',
      detail: (uuid) => `app/applications/form/v2/${uuid}`
    }
  },
  showing: {
    applicationType: 'showing',
    uuid: 'showing',
    pathname: 'showing',
    label: 'Showing',
    routePrefix: 'showing/',
    paths: {
      home: 'app/showing',
      public: 'locations',
      detail: (uuid) => `app/showing/${uuid}/summary`
    }
  }
};
