import { useMap } from '@vis.gl/react-google-maps';
import React from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { useMapsLibrary, useApiIsLoaded } from '@vis.gl/react-google-maps';
import Marker from './marker';

const ClusteredMarkers = ({ locations = [], activeMarker, onMarkerClick, zoom }) => {
  const [markers, setMarkers] = React.useState({});

  const map = useMap();
  const clusterer = React.useMemo(() => {
    if (!map) return null;
    return new MarkerClusterer({ map });
  }, [map]);

  React.useEffect(() => {
    if (!clusterer) return;
    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = React.useCallback((marker, key) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key])) return markers;
      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;
        return newMarkers;
      }
    });
  }, []);

  const handleMarkerClick = React.useCallback((marker) => {
    onMarkerClick(marker);
  }, []);

  return (
    <>
      {locations.map((location) => (
        <Marker
          key={location._id}
          isActive={activeMarker?._id === location._id}
          location={location}
          onClick={handleMarkerClick}
          setMarkerRef={setMarkerRef}
          zoom={map?.zoom}
        />
      ))}
    </>
  );
};
export default (params) => {
  const markers = useMapsLibrary('marker');
  const isLoaded = useApiIsLoaded();

  if (!params?.locations?.length || !isLoaded || !markers) return <></>;

  return <ClusteredMarkers {...params} />;
};
