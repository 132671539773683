import React from 'react';
import styled from 'styled-components';
import { Card, CardDataGroup, CardDataDisplay } from './common';
import { toMoneyString } from 'pages/approver/share/detail/application-view/helpers';
import { isObject } from 'lodash';

const WrappableContent = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  > div {
    min-width: 200px;
  }
`;

const forceString = (val) => `${val}`;
const EVICTION_FIELD_MAPPING = {
  firstName: (value) => ({ label: 'First Name', value: forceString(value) }),
  middleName: (value) => ({ label: 'Middle Name', value: forceString(value) }),
  lastName: (value) => ({ label: 'Last Name', value: forceString(value) }),
  address: (value) => ({
    label: 'Address',
    value: isObject(value)
      ? `${value.address} ${value.city}, ${value.state} ${value.zip}`
      : forceString(value)
  }),
  caseNumber: (value) => ({ label: 'Case #', value: forceString(value) }),
  court: (value) => ({ label: 'Court', value: forceString(value) }),
  disposition: (value) => ({ label: 'Disposition', value: forceString(value) }),
  noticeType: (value) => ({ label: 'Notice Type', value: forceString(value) }),
  judgementAmount: (value) => ({ label: 'Judgement Amt', value: toMoneyString(value) }),
  judgementDate: (value) => ({ label: 'Judgement Date', value: forceString(value) }),
  plaintiff: (value) => ({ label: 'Plaintiff', value: forceString(value) }),
  plaintiffAtty: (value) => ({ label: 'Plaintiff Attorney', value: forceString(value) }),
  plaintiffAttyPhone: (value) => ({ label: 'Plaintiff Attorney Phone', value: forceString(value) }),
  filingDate: (value) => ({ label: 'Filing Date', value: forceString(value) }),
  judgement: (value) => ({
    label: 'Judgement',
    value: value === 'P' ? 'Plaintiff' : forceString(value)
  })
};

export const Eviction = ({ detail }) => {
  const EvictionItem = ({ fieldName }) => {
    const val = detail[fieldName];
    const mapping = EVICTION_FIELD_MAPPING[fieldName]
      ? EVICTION_FIELD_MAPPING[fieldName](val)
      : undefined;

    return !val ? (
      <></>
    ) : (
      <CardDataGroup>
        <CardDataDisplay label={mapping.label} description={mapping.value} />
      </CardDataGroup>
    );
  };
  return (
    <Card>
      <WrappableContent>
        <EvictionItem fieldName="firstName" />
        <EvictionItem fieldName="middleName" />
        <EvictionItem fieldName="lastName" />
        <EvictionItem fieldName="caseNumber" />
        <EvictionItem fieldName="filingDate" />
        <EvictionItem fieldName="court" />
        <EvictionItem fieldName="address" />
        <EvictionItem fieldName="noticeType" />
        <EvictionItem fieldName="disposition" />
        <EvictionItem fieldName="judgement" />
        <EvictionItem fieldName="judgementAmount" />
        <EvictionItem fieldName="judgementDate" />
        <EvictionItem fieldName="plaintiff" />
        <EvictionItem fieldName="plaintiffAtty" />
        <EvictionItem fieldName="plaintiffAttyPhone" />
      </WrappableContent>
    </Card>
  );
};
