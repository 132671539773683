import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { UserContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import { AUDIENCES } from 'helpers/enums';
import Button from 'components/button';
import { Title, ActionsRow, Loader } from '../common';
import CouponCodeModal from './coupon';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  min-height: 350px;

  > div,
  > button {
    flex: 1;
    text-align: center;
  }
  .noExpand {
    flex-grow: 0;
  }
  ${({ isMobile }) =>
    !isMobile
      ? css`
          padding: 10px 20px 20px;
        `
      : ''}
  .price {
    font-size: 5em;
    font-weight: bold;
  }
`;
const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;
export default ({ onDone, onBack, action, handleActions }) => {
  const { userContext } = React.useContext(UserContext);
  const [couponCodeModal, setCouponCodeModal] = React.useState(false);
  const { t } = useTranslation();

  const provideCouponCode = (code) => {
    handleActions({ id: 'payment-coupon', data: code }).then((isSuccessful) => {
      if (isSuccessful) return onDone();
    });
  };
  const startPayment = () => {
    handleActions({ id: 'payment-start' });
  };

  // if you've paid you shouldn't be here!
  React.useEffect(() => {
    if (action.paid) onDone();
  }, []);

  const [location, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_CONTEXT',
    !action?.locationId
      ? undefined
      : {
          userContext,
          contextId: action?.locationId,
          bypass: true,
          audience: AUDIENCES.showing.uuid
        },
    {
      disableEmptyArgs: true,
      reFetchWhen: [action?._id, action?.locationId]
    }
  );

  if (!dataFetched) {
    return (
      <Wrapper>
        <Title>
          <Trans i18nKey="showing.detail.fee.title">Showing Fee</Trans>
        </Title>
        <div>
          <Loader />
        </div>
      </Wrapper>
    );
  }

  if (location.showingConfig?.disabled || !location.showingConfig) {
    return (
      <Wrapper>
        <Title>
          <Trans i18nKey="showing.detail.fee.title">Showing Fee</Trans>
        </Title>
        <div>
          <Trans i18nKey="showing.detail.fee.locked">
            Tour requests are no longer available for this unit. Please select a different one to
            continue.
          </Trans>
        </div>
        <ActionsRow className="noExpand">
          <StyledButton variant="primary" onClick={() => handleActions({ id: 'change-property' })}>
            Select a different property
          </StyledButton>
        </ActionsRow>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>
        <Trans i18nKey="showing.detail.fee.title">Showing Fee</Trans>
      </Title>
      <div className="price">{location?.showingConfig?.showingFee}</div>
      <div>
        <Trans i18nKey="showing.detail.fee.description">
          This is a non-refundable fee to cover the cost of screening.
        </Trans>
      </div>
      <Button variant="link" onClick={() => setCouponCodeModal(true)}>
        {t('appFeeStep.coupon')}
      </Button>
      <ActionsRow className="noExpand">
        <StyledButton variant="secondary" onClick={onBack}>
          {t('buttonsActions.cancel')}
        </StyledButton>
        <StyledButton variant="primary" onClick={startPayment}>
          {t('buttonsActions.next')}
        </StyledButton>
      </ActionsRow>
      {couponCodeModal && (
        <CouponCodeModal
          provideCouponCode={provideCouponCode}
          onClose={() => setCouponCodeModal(false)}
        />
      )}
    </Wrapper>
  );
};
