import React from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import toast from 'react-hot-toast';

import { useNavigateWithContext } from 'state';
import passwordStrengthChecker from 'utils/passwordStrengthChecker';
import Button from 'components/button';
import { LoginField, LoginButton } from './common';
import { RESET_PASSWORD } from 'dal/user';
import { useResetCode } from 'hooks/useSessionVariables';

const Wrapper = styled.div`
  overflow-y: auto;
  background: #eff8fd;
`;

const Container = styled.div`
  max-width: 935px;
  margin: 20px auto 0;
  flex: 1;
  width: 100%;
  padding: 28px;
  border: solid 1px #dfe7ed;
  background-color: #fff;
`;

const Grid = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
`;

const Section = styled.div`
  min-width: 370px;
  display: flex;
  flex-direction: column;
  padding: 20px;
  flex: 1;
  &.social {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-bottom: 0;
    }
  }
`;

const Title = styled.h1`
  font-size: 2.5em;
  text-align: center;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 740px) {
    font-size: 2.19em;
  }
`;

const Description = styled.p`
  text-align: center;
  font-weight: 200;
  margin-bottom: 20px;
`;

const Head = styled.div`
  padding: 0 20px;
`;

const Back = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 1.25em;
  position: absolute;
  left: 0px;
`;
const SignupSchema = Yup.object().shape({
  auth: Yup.string().when('uiConfig.SSORegister', {
    is: false,
    then: Yup.string()
      .test('passwordStrength', '', (value) => {
        const result = passwordStrengthChecker(value);
        if (['strong', 'good'].includes(result.score)) return true;
        return false;
      })
      .required('Password is required')
  }),
  authVerify: Yup.string().when('uiConfig.SSORegister', {
    is: false,
    then: Yup.string()
      .oneOf([Yup.ref('auth'), null], `Passwords don't match`)
      .required('Password is required')
  })
});

export default () => {
  const resetCode = useResetCode();

  let navigate = useNavigateWithContext();
  const isMobile = useMediaQuery({ maxWidth: 740 });

  const resetPassword = (registerData) => {
    RESET_PASSWORD({
      resetCode: resetCode,
      auth: registerData.auth
    })
      .then(() => {
        toast.success('Password have been reset');
        navigate('home/login');
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };

  React.useEffect(() => {
    if (!resetCode) {
      navigate('home/signup/login');
    }
  }, []);

  const {
    errors,
    touched,
    values: registerData,
    handleChange: handleRegisterChange,
    handleSubmit: handleRegisterSubmit,
    handleBlur
  } = useFormik({
    initialValues: {
      auth: '',
      authVerify: ''
    },
    validationSchema: SignupSchema,
    onSubmit: resetPassword
  });

  return (
    <Wrapper>
      <div>
        <Container>
          <Head>
            <Title>
              <Back onClick={() => navigate('home/signup/login')}>
                <i className="fa-solid fa-arrow-left" />
              </Back>
              RESET PASSWORD
            </Title>
            {!isMobile && (
              <Description>
                LET'S GET YOUR PASSWORD RESET SO YOU CAN FINISH YOUR APPLICATION
              </Description>
            )}
          </Head>
          <Grid>
            <Section>
              <LoginField
                label="PASSWORD"
                errorText={touched?.auth && errors?.auth}
                name="auth"
                value={registerData.auth}
                onChange={handleRegisterChange}
                type="password"
                icon="password"
                description="9 CHARACTERS OR MORE WITH UPPERCASE/LOWERCASE LETTERS, NUMBERS AND SPECIAL CHARACTERS"
                onBlur={handleBlur}
              />
              <LoginField
                label="VERIFY PASSWORD"
                errorText={touched?.authVerify && errors?.authVerify}
                name="authVerify"
                type="password"
                icon="password"
                showStrength={false}
                onChange={handleRegisterChange}
                value={registerData.authVerify}
                onBlur={handleBlur}
              />
              <LoginButton variant="primary" onClick={handleRegisterSubmit}>
                RESET PASSWORD
              </LoginButton>
            </Section>
          </Grid>
        </Container>
      </div>
    </Wrapper>
  );
};
