import React from 'react';
import { Row, Title, PageContainer, PageWrapper, Description } from '../common-styles';
import Button from '../../../components/button';
import { useTranslation } from 'react-i18next';
import Checkbox from 'components/checkbox';

export default ({ application, location, saveApplicationPart, onNext, onBack }) => {
  const { t } = useTranslation();
  const [acked, setAcked] = React.useState(!!application.consent?.petScreening);
  const [loading, setLoading] = React.useState(false);

  const handleNext = () => {
    if (!acked || loading) return;
    setLoading(true);
    saveApplicationPart({ consentPetScreening: acked }, false)
      .then(() => {
        onNext();
      })
      .catch(() => setLoading(false));
  };

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('petsMembers.title')}</Title>
        </Row>
        {location?.applicationConfig?.petBlurb && (
          <Row>
            <Description
              dangerouslySetInnerHTML={{
                __html: location.applicationConfig.petBlurb
              }}
            />
          </Row>
        )}
        <Row>
          <Checkbox
            checked={acked}
            onChange={() => setAcked(!acked)}
            label={t('petsMembers.petScreeningCheck')}
          />
        </Row>
        <Row className="center flex gap-10">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.cancel')}
          </Button>
          <Button
            radius="25px"
            variant="primary"
            onClick={handleNext}
            width={90}
            loading={loading}
            disabled={!acked || loading}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
