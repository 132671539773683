import React from 'react';
import styled from 'styled-components';

import FormGroup from 'components/form-group';
import Icon from 'components/icon';

export const Title = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.7em;
  text-align: center;
  margin-bottom: 20px;
`;
export const SubTitle = styled.div`
  font-weight: bold;
  font-size: 1.4em;
  margin-bottom: 10px;
  margin-top: 20px;
`;
export const ActionsRow = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-top: 30px;
`;
export const BoxNote = styled.div`
  padding: 15px 10px;
  border-radius: 8px;
  background-color: #fff7ca;
  display: flex;
  gap: 10px;
  flex-flow: column;
  > strong {
    font-size: 1.1em;
    text-transform: uppercase;
  }
  > p {
    margin: 0;
    padding: 0;
  }
`;

const FieldWrapper = styled(FormGroup)`
  width: 100%;
  display: flex;
  flex-flow: column;
  gap: 1px;
  .group-label {
    font-size: 0.875em !important;
    font-weight: normal;
    color: #71828f;
    text-transform: uppercase;
    margin: 0;
    align-self: flex-start;
  }
  input {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px #d4dae1;
    background-color: #fff;
  }
  textarea {
    border-radius: 8px;
    border: solid 1px #d4dae1;
    background-color: #fff;
  }
  .disabled-input {
    height: 50px;
    width: 100%;
    border-radius: 8px;
    border: solid 1px #d4dae1;
  }
`;

export const FieldGroup = ({ label, errorText, className, children }) => {
  return (
    <FieldWrapper label={label} className={className} errorText={errorText}>
      {children}
    </FieldWrapper>
  );
};

const StyledLoader = styled(Icon)`
  width: 100%;
  margin: auto;
  flex-shrink: 0;
`;
export const Loader = ({ size = 100 }) => (
  <StyledLoader name="fa-duotone fa-spinner-third fa-spin" size={size} />
);
