import React from 'react';
import styled from 'styled-components';
import { Row, Title, PageContainer, PageWrapper } from '../common-styles';
import Button from 'components/button';
import Select from 'components/select';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import FormGroup from 'components/form-group';
import Input from 'components/input';
import Text from 'components/text';
import CurrencyInput from 'components/currency-input';
import FileUpload from '../upload';
import * as yup from 'yup';

const section8Schema = (active) =>
  !active
    ? yup.object({ active: yup.boolean().typeError('Please specify if you use Section 8') })
    : yup.object({
        active: yup.boolean().typeError('Please specify if you use Section 8'),
        voucherName: yup.string().required('Please provide a voucher name'),
        bedCount: yup.number().required('Please provide a bed count'),
        amountApproved: yup
          .number()
          .typeError('Please provide an amount')
          .required('Please provide an amount')
          .min(0, 'Please provide an amount greater than 0'),
        kidsUnder6: yup
          .number()
          .typeError('Please provide the number of kids under 6')
          .required('Please provide the number of kids under 6')
          .min(-1, 'Please provide an amount greater than -1'),
        rtaPacket: yup.boolean().typeError('Please specify if you have an RTA packet'),
        budgetSheet: yup.boolean().typeError('Please specify if you have a budget sheet'),
        ssi: yup.boolean().typeError('Please specify if you have SSI'),
        childSupport: yup.boolean().typeError('Please specify if you have child support'),
        securityAgreement: yup
          .boolean()
          .typeError('Please specify if you have a security agreement')
      });
const otherHousingSchema = (active) =>
  !active
    ? yup.object({
        otherSupport: yup
          .boolean()
          .typeError('Please specify if you receive any other housing support')
      })
    : yup.object({
        otherSupport: yup
          .boolean()
          .typeError('Please specify if you receive any other housing support'),
        otherSupportDetails: yup.string().required('Please provide details of your housing support')
      });

const housingSupportSchema = ({ active, otherSupport }) =>
  section8Schema(active).concat(otherHousingSchema(otherSupport));

const Description = styled.p`
  background-color: #fff;
  padding: 20px;
  overflow: auto;
`;
const UploadInput = styled.label`
  display: block;
  align-items: center;
  padding: 15px 20px;
  background-color: #f7f7f7;
  border: solid 1px #ddd;
  min-height: 40px;
  margin-bottom: 10px;
  input {
    display: none;
  }
  .input-label {
    margin-top: -25px;
    font-weight: bold;
    width: 300px;
    flex-shrink: 0;
    margin-right: 10px;
    @media screen and (max-width: 425px) {
      width: 210px;
    }
  }
  .uploadLink {
    cursor: pointer;
    font-size: 0.81em;
    padding: 0px;
    color: #fff;
    border-radius: 25px;
    background: #00a1ff;
    font-weight: bold;
    width: 80px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .box-upload {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
  }
`;
const Required = styled.div`
  flex-shrink: 0;
  font-size: 0.81em;
  color: red;
  margin-top: -20px;
`;
const Optional = styled.div`
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: 0.81em;
  color: gray;
  font-style: italic;
`;

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
const moneyToNumber = (amount) =>
  Number.isFinite(amount)
    ? amount
    : amount?.replace
    ? Number(amount?.replace(/[a-zA-Z,$ ]/gi, ''))
    : undefined;
const formatMoney = (amount = '') => {
  const val = `${amount || ''}`.replace(/[a-zA-Z,$ ]/gi, '');
  return moneyFormatter.format(val);
};

const Upload = ({ label, application, field, required, localFiles = {}, setLocalFiles, t }) => {
  const existingFile = application?.files?.section8?.[field];

  return (
    <UploadInput className="custom-file-upload">
      <div className="box-upload">
        <div className="input-label">{label}</div>
        <FileUpload
          required={required}
          autoUpload={true}
          localFiles={localFiles}
          setLocalFiles={(files) => {
            const newFile = files[`section8.${field}`];
            setLocalFiles({ ...localFiles, [field]: newFile });
          }}
          locationId={application.locationId}
          applicationId={application._id}
          field={`section8.${field}`}
          existingFile={existingFile}
          fileTypes={['.pdf', '.jpg', '.jpeg', '.png']}
        />
      </div>
      {required && !localFiles[field] && <Required> {t('required')}</Required>}
      {!required && !localFiles[field] && <Optional>{t('optional')}</Optional>}
    </UploadInput>
  );
};

const getInitialValues = (app) => ({
  active: 'select',
  otherSupport: 'select',
  otherSupportDetails: '',
  voucherName: '',
  bedCount: '',
  amountApproved: '',
  kidsUnder6: '',
  rtaPacket: 'select',
  budgetSheet: 'select',
  ssi: 'select',
  childSupport: 'select',
  securityAgreement: 'select',
  ...app.section8
});

const convertYNToBool = (val) => (['y', 'n'].includes(val) ? val === 'y' : 'select');
const convertBoolToYn = (val) => ([true, false].includes(val) ? (val ? 'y' : 'n') : 'select');
const YesNoSelect = ({ values, setFieldValue, name, handleBlur, t }) => {
  return (
    <Select
      name={name}
      value={convertBoolToYn(values[name])}
      onChange={(event) => {
        setFieldValue(name, convertYNToBool(event.target.value));
      }}
      onBlur={handleBlur}>
      <option value="select">{t('buttonsActions.selectButoon')}</option>
      <option value="y">{t('yes')}</option>
      <option value="n">{t('no')}</option>
    </Select>
  );
};

const FileQuestion = ({
  name: prefix,
  touched,
  errors,
  localFiles,
  setLocalFiles,
  application,
  values,
  setFieldValue,
  handleBlur,
  t
}) => {
  const createFieldName = (v) => (v ? `${prefix}_${v}` : prefix);
  const fErrors = [
    touched[createFieldName()] && errors[createFieldName()],
    errors[createFieldName('file')]
  ]
    .filter((v) => v)
    .join(', ');

  return (
    <FormGroup label={t(`section8.${prefix}`)} errorText={fErrors}>
      <YesNoSelect
        name={createFieldName()}
        values={values}
        setFieldValue={setFieldValue}
        handleBlur={handleBlur}
        t={t}
      />
      {values[createFieldName()] === true && (
        <Upload
          t={t}
          label={t(`section8.${createFieldName('file')}`)}
          field={prefix}
          required={true}
          autoUpload={true}
          localFiles={localFiles}
          setLocalFiles={setLocalFiles}
          application={application}
        />
      )}
    </FormGroup>
  );
};

export default ({ onBack, onNext, application, saveApplicationPart }) => {
  const [localFiles, setLocalFiles] = React.useState(application.files?.section8 || {});
  const [isActive, setIsActive] = React.useState(false);
  const [otherSupport, setOtherSupport] = React.useState(false);
  const { t } = useTranslation();
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: getInitialValues(application),
      validationSchema: housingSupportSchema({ active: isActive, otherSupport }),
      validate: (val) => {
        const errors = {};
        ['rtaPacket', 'budgetSheet', 'ssi', 'childSupport', 'securityAgreement'].forEach((key) => {
          if (val[key] === true && !localFiles[key]) {
            errors[`${key}_file`] = 'Please provide supporting documentation';
          }
        });
        return errors;
      },
      onSubmit: (values) => {
        const vals = housingSupportSchema({
          active: values.active,
          otherSupport: values.otherSupport
        }).cast(values, {
          stripUnknown: true
        });
        saveApplicationPart({ section8: vals }, false).then(() => onNext());
      }
    });

  const handleNext = () => {
    handleSubmit();
  };

  React.useEffect(() => setIsActive(values.active), [values.active]);
  React.useEffect(() => setOtherSupport(values.otherSupport), [values.otherSupport]);
  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('section8.title')} </Title>
        </Row>
        <Description>{t('section8.description')}</Description>
        <Row>
          <FormGroup
            label={t('section8.otherSupport')}
            errorText={touched.otherSupport && errors.otherSupport}>
            <YesNoSelect
              name={`otherSupport`}
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              t={t}
            />
          </FormGroup>
        </Row>
        {values.otherSupport === true && (
          <Row>
            <FormGroup
              label={t('section8.otherSupportDetails')}
              errorText={touched.otherSupportDetails && errors.otherSupportDetails}>
              <Text
                name="otherSupportDetails"
                rows="3"
                value={values.otherSupportDetails}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Row>
        )}
        <Row>
          <FormGroup label={t('section8.active')} errorText={touched.active && errors.active}>
            <YesNoSelect
              name={`active`}
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              t={t}
            />
          </FormGroup>
        </Row>
        {values.active === true && (
          <Row>
            <FormGroup
              label={t('section8.voucherName')}
              errorText={touched.voucherName && errors.voucherName}>
              <Input
                name="voucherName"
                value={values.voucherName}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label={t('section8.bedCount')}
              errorText={touched.bedCount && errors.bedCount}>
              <Input
                type="number"
                name="bedCount"
                value={values.bedCount}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label={t('section8.amountApproved')}
              errorText={touched.amountApproved && errors.amountApproved}>
              <CurrencyInput
                value={formatMoney(values.amountApproved)}
                name="amountApproved"
                onChange={(e) =>
                  handleChange({
                    ...e,
                    target: { ...e.target, value: moneyToNumber(e.target.value) }
                  })
                }
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label={t('section8.kidsUnder6')}
              errorText={touched.kidsUnder6 && errors.kidsUnder6}>
              <Input
                type="number"
                name="kidsUnder6"
                value={values.kidsUnder6}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Row>
        )}
        {/*my guess is these will be custom per org */}
        {values.active === true && (
          <Row>
            <FileQuestion
              name="rtaPacket"
              touched={touched}
              errors={errors}
              localFiles={localFiles}
              setLocalFiles={setLocalFiles}
              application={application}
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              t={t}
            />
            <FileQuestion
              name="budgetSheet"
              touched={touched}
              errors={errors}
              localFiles={localFiles}
              setLocalFiles={setLocalFiles}
              application={application}
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              t={t}
            />
            <FileQuestion
              name="ssi"
              touched={touched}
              errors={errors}
              localFiles={localFiles}
              setLocalFiles={setLocalFiles}
              application={application}
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              t={t}
            />
            <FileQuestion
              name="childSupport"
              touched={touched}
              errors={errors}
              localFiles={localFiles}
              setLocalFiles={setLocalFiles}
              application={application}
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              t={t}
            />
            <FileQuestion
              name="securityAgreement"
              touched={touched}
              errors={errors}
              localFiles={localFiles}
              setLocalFiles={setLocalFiles}
              application={application}
              values={values}
              setFieldValue={setFieldValue}
              handleBlur={handleBlur}
              t={t}
            />
          </Row>
        )}
        <Row className="center flex gap-10">
          <Button radius="25px" variant="secondary" onClick={onBack} width={90}>
            {t('buttonsActions.backButton')}
          </Button>
          <Button radius="25px" variant="primary" onClick={handleNext} width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
