import React from 'react';
import { renderToString } from 'react-dom/server';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';
import styled from 'styled-components';

import Icon from 'components/icon';

const TermsWrapper = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 10px;
  .terms-header {
    background-color: #b3bdc5;
    padding: 4px 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    font-weight: bold;
  }
  .content {
    padding: 10px;
    max-height: 300px;
    overflow-y: auto;
  }
`;

const sheet = new ServerStyleSheet();
export default ({ children, title }) => {
  const handlePrint = () => {
    const content = renderToString(
      <StyleSheetManager sheet={sheet.instance}>
        <div style={{ padding: 20 }}>{children}</div>
      </StyleSheetManager>
    );
    const styleTags = sheet.getStyleTags();
    const printLayout = `
      <html>
        <head>
          <title>${title}</title>
          <style>
          ${styleTags}
          </style>
          <script>
            window.onload = () => {
              print();
            }
          </script>
        </head>
        <body>
          ${content}
        </body>
      </html>`;
    const w = window.open('', 'print', `width=${screen.width},height=${screen.height}`);
    w.document.write(printLayout);
    w.document.close();
  };

  return (
    <TermsWrapper>
      <div className="terms-header">
        <span>{title}</span> <Icon name="fa-solid fa-print" onClick={handlePrint} />
      </div>
      <div className="content">{children}</div>
    </TermsWrapper>
  );
};
