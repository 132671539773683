import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import Button from 'components/button';
import Icon from 'components/icon';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  img {
    max-width: 100%;
    max-height: 80%;
    border-radius: 10px;
  }
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  .img-box {
    height: 70%;
    width: 50%;
    margin: 0 auto;
  }
`;
const NavButton = styled(Button)`
  color: #fff;
  position: absolute;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  &.left {
    left: 20px;
  }
  &.right {
    right: 20px;
  }
`;

const Close = styled(Button)`
  display: flex;
  align-items: center;
  color: #fff;
  position: absolute;
  top: 20px;
  left: 20px;
  i {
    margin-right: 10px;
  }
`;

const LabelCount = styled.div`
  width: 100px;
  position: absolute;
  bottom: 15px;
  left: calc(50% - 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
`;

const Thumbnails = styled.div`
  display: flex;
  height: 100px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  margin-bottom: ${(props) => (props.isMobile ? '50px' : '20px')};
  justify-content: center;
  overflow-y: ${(props) => (props.isMobile ? 'scroll' : 'none')};
  justify-content: ${(props) => (props.isMobile ? 'flex-start' : 'center')};
  gap: 10px;

  .box-img {
    img {
      width: 70px;
      height: 70px;
      margin: 0 5px;
      cursor: pointer;
      transition: opacity 0.3s;
    }
    img.active {
      border: 1px solid #00a1ff;
      opacity: 1;
      width: 100%;
      object-fit: cover;
      transform: translate3d(calc(15 * -1px), 0, 0);
      transition: background-color 0.5s ease;
      transition: transform 0.5s ease;
      transform: scaleX(1);
    }
    img:not(.active) {
      opacity: 0.5;
      object-fit: cover;
    }
  }
`;

const Viewer = ({ urls = [], onClose }) => {
  const [position, setPosition] = React.useState(0);

  const isMobile = useMediaQuery({ maxWidth: 768 });
  const currentPos = `${position + 1}/${urls.length}`;
  const currentImage = urls[position];

  const handleThumbnailClick = (index) => {
    setPosition(index);
  };

  React.useEffect(() => {
    const handleKeydown = (event) => {
      if (event.key === 'ArrowLeft') {
        setPosition((prevIndex) => (prevIndex - 1 + urls.length) % urls.length);
      } else if (event.key === 'ArrowRight') {
        setPosition((prevIndex) => (prevIndex + 1) % urls.length);
      }
    };
    window.addEventListener('keydown', handleKeydown);
    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, [urls.length]);

  return (
    <Wrapper>
      <Content>
        <div className="img-box">
          <img src={currentImage} alt="" />
        </div>
        {urls.length > 1 && (
          <Thumbnails isMobile={isMobile}>
            {urls.map((image, index) => (
              <div className="box-img" key={index}>
                <img
                  src={image}
                  alt={`Thumbnail ${index + 1}`}
                  onClick={() => handleThumbnailClick(index)}
                  className={position === index ? 'active' : ''}
                />
              </div>
            ))}
          </Thumbnails>
        )}
      </Content>
      <LabelCount>{currentPos}</LabelCount>
      {position > 0 && (
        <NavButton className="left" onClick={() => setPosition(position - 1)}>
          <Icon name="fa-solid fa-chevron-left" />
        </NavButton>
      )}
      {position + 1 < urls.length && (
        <NavButton className="right" onClick={() => setPosition(position + 1)}>
          <Icon name="fa-solid fa-chevron-right" />
        </NavButton>
      )}
      <Close onClick={onClose}>
        <Icon name="fa-light fa-xmark" />
        CLOSE
      </Close>
    </Wrapper>
  );
};

export default ({ open, ...props }) => {
  return (
    <Modal width="100vw" height="100vh" maxHeight="100vw" radius="0px" color="#000" open={open}>
      <Viewer {...props} />
    </Modal>
  );
};
