import React from 'react';
import styled from 'styled-components';
import Icon from './icon';
import passwordStrengthChecker from '../utils/passwordStrengthChecker';

const Wrapper = styled.div``;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

const ReadOnlyInputBase = styled.div`
  padding: 0 15px;
  flex: 1;
  background-color: var(--color-bg-primary);
  height: 36px;
  border-radius: 0px;
  outline: none;
  border: 2px solid transparent;
  overflow: hidden;
  display: flex;
  align-items: center;
  &:focus {
    border-color: var(--color-primary);
  }
  &.input-icon-left {
    padding-left: 40px;
  }
  .input-icon-right {
    padding-right: 40px;
  }
`;
const InputBase = styled.input`
  width: 100%;
  flex: 1;
  padding: 0 15px;
  ${({ hasIcon }) => (hasIcon ? 'padding-left: 30px;' : '')}
  height: 32px;
  line-height: 1;
  background-color: var(--color-bg-primary);
  border-radius: 0px;
  outline: none;
  border: 1px solid #ddd;
  overflow: hidden;
  &:focus {
    border-color: var(--color-primary);
  }

  .input-icon-right {
    padding-right: 40px;
  }
`;

const IconWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  color: var(--color-font-third);
  &.password {
    right: 0;
    left: initial;
  }
`;

const Description = styled.div`
  font-size: 0.75em;
  font-weight: 200;
  margin-top: 8px;
`;

const StrengthBar = styled.div`
  display: flex;
  height: 15px;
  border-radius: 4px;
  background-color: transparent;
  margin-top: 8px;
  position: relative;
  &::before {
    content: '';
    width: ${({ width }) => width};
    background-color: ${({ color }) => color};
    border-radius: 4px;
  }
  div {
    height: 100%;
    display: flex;
    align-items: center;
    position: absolute;
    left: 0;
    padding: 0 10px;
    color: #fff;
    text-transform: uppercase;
    font-size: 0.75em;
  }
`;

const strength = {
  bad: {
    label: 'Bad',
    color: 'red',
    width: '20%'
  },
  weak: {
    label: 'Weak',
    color: '#ffd453',
    width: '40%'
  },
  good: {
    label: 'Good',
    color: '#4caf50',
    width: '60%'
  },
  strong: {
    label: 'Strong',
    color: 'green',
    width: '100%'
  }
};

const StrengthMeter = ({ password }) => {
  const result = passwordStrengthChecker(password);
  const msg = !password ? {} : strength[result.score];

  return (
    <StrengthBar {...msg}>
      <div>{msg.label}</div>
    </StrengthBar>
  );
};

const Input = (
  {
    icon,
    iconProps = {},
    className = '',
    type = 'text',
    description = '',
    showStrength = true,
    readOnly = false,
    ...props
  },
  ref
) => {
  const [passwordVisible, setPasswordVisible] = React.useState(false);

  return (
    <Wrapper>
      <InputWrapper>
        {icon && (
          <IconWrapper>
            <Icon name={icon} size={18} {...iconProps} />
          </IconWrapper>
        )}
        {readOnly ? (
          <ReadOnlyInputBase
            ref={ref}
            hasIcon={!!icon}
            {...props}
            className={`disabled-input ${icon ? 'input-icon-left' : ''} ${
              type === 'password' ? 'input-icon-right' : ''
            } ${className}`}>
            {props.value}
          </ReadOnlyInputBase>
        ) : (
          <InputBase
            ref={ref}
            hasIcon={!!icon}
            autoComplete="new-password"
            {...props}
            type={passwordVisible ? 'text' : type}
            className={`${icon ? 'input-icon-left' : ''} ${
              type === 'password' ? 'input-icon-right' : ''
            } ${className}`}
          />
        )}
        {type === 'password' && (
          <IconWrapper
            className="password"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              setPasswordVisible(!passwordVisible);
            }}>
            <Icon name={!passwordVisible ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'} />
          </IconWrapper>
        )}
      </InputWrapper>
      {description && <Description>{description}</Description>}
      {type === 'password' && showStrength && <StrengthMeter password={props.value} />}
    </Wrapper>
  );
};

export default React.forwardRef(Input);
