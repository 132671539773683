import React from 'react';
import styled from 'styled-components';
import Property from './property';
import Navbar from './nav-bar.js';
import { useMediaQuery } from 'react-responsive';
import { useUiState } from 'state';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  @media (max-width: 960px) {
    background-color: #fff;
  }
`;

const Form = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  @media (max-width: 960px) {
    padding: 0;
  }
`;

const FormContainer = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  @media (max-width: 960px) {
    display: flex;
    flex-flow: column;
    height: 100%;
    overflow: hidden;
    border: none;
  }
`;

const Content = styled.div`
  flex-grow: 1;
  @media (max-width: 960px) {
    overflow: auto;
  }
`;

export default ({ children, hideProperty, ...props }) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const { updateUiState } = useUiState();

  React.useEffect(() => {
    if (!isMobile) return;
    updateUiState({ hideHeader: true });
    return () => updateUiState({ hideHeader: false });
  }, [isMobile]);

  React.useEffect(() => {
    updateUiState({ menuOpen: false });
  }, []);

  if (isMobile) {
    return (
      <Wrapper>
        <Form>
          <FormContainer>
            <Navbar {...props} />
            {!hideProperty && <Property {...props} />}
            <Content>{children}</Content>
          </FormContainer>
        </Form>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      {!hideProperty && <Property {...props} />}
      <Form>
        <FormContainer>
          <Navbar {...props} />
          <Content>{children}</Content>
        </FormContainer>
      </Form>
    </Wrapper>
  );
};
