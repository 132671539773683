import React from 'react';
import { useMediaQuery } from 'react-responsive';

import { UserContext } from 'state';

const ALLOWED_VIEWS = ['login', 'signup', 'registerSSO'];
export default ({ loginView } = {}) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const [view, setView] = React.useState(() => {
    if (ALLOWED_VIEWS.includes(loginView)) return loginView;
    if (isMobile) return 'login';
    return 'signup';
  });
  const { userContext, setUserContext } = React.useContext(UserContext);
  const [registerData, setRegisterData] = React.useState({});

  const handleActions = (action) => {
    switch (true) {
      case action.id === 'setView':
        setView(action.data);
        return;
      case action.id === 'ssoLogin':
        setUserContext({ ...userContext, ...action.data });
        setView('loginChallenge');
        return;
      case action.id === 'ssoRegister':
        setRegisterData({ ...registerData, ...action.data });
        setView('ssoRegister');
        return;
      default:
        return;
    }
  };

  return { handleActions, view, registerData };
};
