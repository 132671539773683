import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import Button from 'components/button';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 10px;
  &:last-children {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  display: flex;
  gap: 10px;
  .title {
    flex: 1;
    font-weight: bold;
    font-size: 1.3em;
  }
`;

const List = styled.div`
  padding: 0px 20px 20px 20px;
  display: grid;
  gap: 10px;
`;

const MemberWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 2px 20px;
  position: relative;
  .actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
    padding: 10px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #444;
      font-size: 0.88em;
    }
  }
`;

const Item = ({
  companyName = '',
  position = '',
  income = [],
  onRemove,
  onEdit,
  readOnly,
  startDate,
  endDate,
  current
}) => {
  return (
    <MemberWrapper>
      {companyName && (
        <div>
          <p>
            <strong>Company</strong>
            <br />
            {companyName}
          </p>
        </div>
      )}
      {position && (
        <div>
          <p>
            <strong>Position</strong>
            <br />
            {position}
          </p>
        </div>
      )}
      {income && (
        <div>
          <p>
            <strong> Monthly Income (pre-tax)</strong>
            <br />
            {income?.renderPrettyCurrency
              ? income.renderPrettyCurrency(false)
              : (0).renderPrettyCurrency(false)}
          </p>
        </div>
      )}
      {startDate && (
        <div>
          <p>
            <strong>Since</strong>
            <br />
            {startDate && format(new Date(startDate), 'MM/d/yyyy')} -{' '}
            {current ? 'Current' : `${endDate && format(new Date(endDate), 'MM/d/yyyy')}`}
          </p>
        </div>
      )}

      {!readOnly && (
        <div className="actions">
          <Button onClick={onRemove} title="Remove">
            <Icon name="fa-solid fa-trash" />
          </Button>
          <Button onClick={onEdit} title="Edit">
            <Icon name="fa-solid fa-pen-to-square" />
          </Button>
        </div>
      )}
    </MemberWrapper>
  );
};

export default ({ title, items = [], onAdd, onRemove, onEdit, icon }) => {
  const hasItems = items.length > 0;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header onClick={onAdd}>
        <Icon
          name={hasItems ? 'fa-duotone fa-circle-check' : 'fa-light fa-circle'}
          color={hasItems ? 'green' : '#ccc'}
        />
        {icon && <Icon name={icon.name} />}
        <div className="title">{title}</div>
        <Button variant="primary" onClick={onAdd}>
          {t('buttonsActions.add')}
        </Button>
      </Header>
      {hasItems && (
        <List>
          {items.map((item) => (
            <Item
              key={item.uuid}
              {...item}
              onEdit={() => onEdit(item)}
              onRemove={() => onRemove(item)}
            />
          ))}
        </List>
      )}
    </Wrapper>
  );
};
