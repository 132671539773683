import React, { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  .content {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 200ms;
  }
  .content.open {
    grid-template-rows: 1fr;
    transition: grid-template-rows 200ms;
  }

  .text {
    transition: grid-template-rows 200ms;
    padding: 15px;
    text-align: justify;
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: solid 1px #c8d6df;
  background-color: #f0f3f6;
  .title {
    font-weight: bold;
  }
  i {
    font-size: 1.25em;
    color: #c8d6df;
  }
`;
const ToggleButton = ({ text, initialState }) => {
  const [open, setOpen] = useState(initialState);
  return (
    <Wrapper onClick={() => setOpen(!open)}>
      <Header>
        <div className="title">General Info</div>
        <i className="fa-light fa-angle-down" />
      </Header>

      {open && (
        <div className="content">
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: text
            }}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default ToggleButton;
