import Icon from './icon';
import styled from 'styled-components';

const Wrapper = styled.div`
  cursor: pointer;
  align-items: baseline;
  display: flex;
  gap: 10px;
`;
const StyledIcon = styled(Icon)``;

export default ({ address, className, styles }) => {
  return address?.formattedAddress ? (
    <Wrapper
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        window.open(
          `https://www.google.com/maps/place/${encodeURIComponent(address?.formattedAddress)}`,
          '_blank'
        );
      }}>
      <StyledIcon className={className} styles={styles} name="fa-duotone fa-location-dot" />
      <span>{address?.formattedAddress}</span>
    </Wrapper>
  ) : null;
};
