import Modal from 'components/modal';
import styled from 'styled-components';
import Button from 'components/button';
import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import FormGroup from 'components/form-group';
import Text from 'components/text';
import { DELETE_APPLICATION } from 'dal/applications';
import { UserContext } from 'state';
import toast from 'react-hot-toast';

const cancelSchema = yup.object().shape({
  reason: yup
    .string()
    .max(5000, 'Message is too long')
    .min(1, 'Please provide a reason')
    .required('Please provide a reason')
});

const Content = styled.div`
  padding: 20px 15px;
  flex: 1;
  h2 {
    margin: 0px;
  }
`;

const Actions = styled.div`
  padding: 0px 15px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;
const StyledFormGroup = styled(FormGroup)`
  margin: 15px 0px;
`;

export default ({ applicationId, locationId, onClose, t }) => {
  const { userContext } = React.useContext(UserContext);
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: { reason: '' },
    validationSchema: cancelSchema,
    onSubmit: () => {
      DELETE_APPLICATION({
        userContext,
        locationId: locationId,
        applicationId: applicationId,
        data: { cancelReason: values.reason }
      })
        .then(() => {
          toast.success('Application cancelled!');
          onClose && onClose({ cancelled: true });
        })
        .catch((err) => {
          console.error({ errData: err.data, err });
          toast.error(
            err.data?.message
              ? err.data?.message
              : err.message || err.name || 'Failed to cancel application'
          );
        });
    }
  });

  const handleConfirm = () => {
    handleSubmit();
  };

  const handleCancel = () => {
    onClose && onClose();
  };

  return (
    <Modal open={true} height="auto" width="400px">
      <Content>
        <h2>{t('cancel_dialog.title')}</h2>
        <p>{t('cancel_dialog.description')}</p>
        <StyledFormGroup
          label={t('cancel_dialog.reason')}
          errorText={touched.reason && errors.reason}>
          <Text
            name="reason"
            value={values.reason}
            onChange={handleChange}
            onBlur={handleBlur}
            placeholder={`${t('cancel_dialog.placeholder')}`}
          />
        </StyledFormGroup>
      </Content>
      <Actions>
        <Button variant="secondary" onClick={handleCancel} width={80}>
          NO
        </Button>
        <Button variant="primary" onClick={handleConfirm} width={80}>
          {t('yes')}
        </Button>
      </Actions>
    </Modal>
  );
};
