import React from 'react';
import styled from 'styled-components';
import { Routes, Route, useParams } from 'react-router-dom';

import { AUDIENCES } from 'helpers/enums';
import { useApplication } from 'hooks/useApplication';
import ErrorBoundary from 'components/errorBoundary';
import SpinningLoader from 'components/spinner';
import Layout from './layout';
import Overview from './overview';
import Summary from './summary';

const Wrapper = styled.div`
  height: 100%;
`;

const Content = styled.div`
  height: 100%;
`;

const navigateNext = ({
  index = 0,
  nextPath,
  section,
  routeMap,
  backToOverview,
  navigateRoute
}) => {
  // too many loops OR no next path
  if (!nextPath || index > 5) return backToOverview();

  const nextRoute = routeMap[nextPath];

  // the next step is valid, lets go to it
  if (!nextRoute?.hidden && !nextRoute?.disabled) return navigateRoute(nextPath);

  // the next step is the last one AND we shouldn't show it. just bounce out
  if (!nextRoute.nextPath) return backToOverview({ section, status: 1, refresh: true });

  //the next step is being skipped but it has a next step so we can fast forward to it
  return navigateNext({
    index: index + 1,
    nextPath: nextRoute.nextPath,
    section,
    routeMap,
    backToOverview,
    navigateRoute
  });
};
const navigateBack = ({ index = 0, backPath, routeMap, backToOverview, navigateRoute }) => {
  // too many loops OR no next path
  if (!backPath || index > 5) return backToOverview();

  const nextRoute = routeMap[backPath];

  // the next step is valid, lets go to it
  if (!nextRoute?.hidden && !nextRoute?.disabled) return navigateRoute(backPath);

  // the next step is the last one AND we shouldn't show it. just bounce out
  if (!nextRoute.backPath) return backToOverview();

  //the next step is being skipped but it has a next step so we can fast forward to it
  return navigateBack({
    index: index + 1,
    nextPath: nextRoute.backPath,
    routeMap,
    backToOverview,
    navigateRoute
  });
};

export default ({ updateChatClient }) => {
  const routeParams = useParams();
  const [loading, setLoading] = React.useState(false);
  const {
    onRefresh,
    dataFetched,
    steps,
    nextStep,
    routes,
    routeMap,
    application,
    applicationLocationContext,
    saveApplicationPart,
    provideCouponCode,
    manualVerification,
    submitApplication,
    navigate,
    navigateRoute,
    backToOverview,
    navigateToReview,
    applicationLocked
  } = useApplication({ updateChatClient });

  React.useEffect(() => {
    if (application?.applicationType === AUDIENCES.showing.applicationType) {
      navigate(AUDIENCES.showing.paths.detail(application._id), AUDIENCES.showing.uuid);
    }
  }, [application?.applicationType]);

  const coreParams = {
    steps,
    application,
    location: applicationLocationContext,
    backToOverview, // go back to the overview page
    navigateRoute, // go wherever you want
    navigateToReview, // go to the print/summary page
    onRefresh, // call API for new data
    setLoading, // throw up a spinner
    saveApplicationPart, //save the app
    submitApplication, // signs and closes the app keep
    provideCouponCode, // use a code and skip payment
    manualVerification, // check on manual verification details
    applicationLocked // if the application is locked for updates
  };

  // different state for locked for billing types
  let appPastLock = !application?.chargeApplicants ? application?.submitted : application?.paid;

  // Force only summary page when we are fully locked
  React.useEffect(() => {
    if (applicationLocked && routeParams['*'] !== 'summary') {
      navigate(`app/applications/form/v2/${application._id}/summary`);
    }
  }, [applicationLocked, routeParams['*']]);

  if (
    applicationLocationContext?.isLocked &&
    !appPastLock &&
    !['guarantor', 'co-occupant'].includes(application?.type) &&
    routeParams['*'] !== 'summary'
  ) {
    return (
      <Layout
        location={applicationLocationContext}
        application={application}
        onRefresh={onRefresh}
        locked={true}
        setLoading={setLoading}>
        <Wrapper>
          <Content>
            <ErrorBoundary>
              <Overview {...coreParams} locked={true} />
            </ErrorBoundary>
          </Content>
        </Wrapper>
      </Layout>
    );
  }

  return (
    <Layout
      applicationLocked={applicationLocked}
      location={applicationLocationContext}
      application={application}
      onRefresh={onRefresh}
      setLoading={setLoading}>
      <Wrapper>
        {loading && <SpinningLoader />}
        {dataFetched && application && applicationLocationContext && (
          <Content>
            <ErrorBoundary>
              <Routes>
                <Route index element={<Overview nextStep={nextStep} {...coreParams} />} />
                <Route path="summary" element={<Summary {...coreParams} />} />
                {routes.map(({ path, Component, onNext, nextPath, onBack, backPath, section }) => (
                  <Route
                    key={path}
                    path={path}
                    element={
                      <Component
                        {...coreParams}
                        backToOverview={({ saveProgress } = {}) =>
                          saveProgress
                            ? backToOverview({ section, status: 1, refresh: true })
                            : backToOverview()
                        }
                        onNext={() => {
                          if (onNext) return onNext({ application, backToOverview, navigateRoute });
                          if (nextPath) {
                            return navigateNext({
                              section,
                              index: 0,
                              nextPath,
                              routeMap,
                              backToOverview,
                              navigateRoute
                            });
                          }
                          return backToOverview({ section, status: 1, refresh: true });
                        }}
                        onBack={() => {
                          if (onBack) return onBack({ application, backToOverview, navigateRoute });
                          if (backPath)
                            return navigateBack({
                              index: 0,
                              backPath,
                              routeMap,
                              backToOverview,
                              navigateRoute
                            });
                          return backToOverview();
                        }}
                      />
                    }
                  />
                ))}
              </Routes>
            </ErrorBoundary>
          </Content>
        )}
      </Wrapper>
    </Layout>
  );
};
