import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  .boxBorder {
    height: ${({ variant }) => {
      if (variant === 'thick') return '6px';
      if (variant === 'thin') return '0px';
      return '3px';
    }};
    background-color: #c8d6df;
  }
`;

const Content = styled.div`
  padding: 10px 20px;
`;

export default ({ className, children, variant }) => {
  return (
    <Wrapper className={className} variant={variant}>
      <div className="boxBorder" />
      <Content className="boxContent">{children}</Content>
    </Wrapper>
  );
};
