String.duogram = function (text, secondText) {
  // return the first letter from the first and last words of text
  if (!text) {
    return '';
  }
  var r = '';
  let words = secondText ? [text, secondText] : text.split(' ');
  r = words[0].charAt(0);
  if (words.length > 1) {
    r += words[words.length - 1].charAt(0);
  }
  return r;
};

String.prototype.capitalize = function (text, secondText) {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const getFullName = (c) =>
  c.name ? c.name : [c.firstName, c.middleName, c.lastName].filter((c) => c).join(' ');
