import React from 'react';
import styled from 'styled-components';
import Icon from '../components/icon';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  font-family: 'Mukta', sans-serif;
  font-size: 2.5em;
  text-align: center;
  &.success {
    background-color: #8bc34a;
  }
  &.error {
    background-color: #f2b253;
  }
  color: #fff;
  i {
    margin-bottom: 20px;
  }
  a {
    display: inline-block;
    padding: 5px 20px;
    background-color: var(--color-primary);
    border-radius: 15px;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
    line-height: initial;
    margin: 50px;
  }
`;

export default ({ text, variant = 'success' }) => {
  return (
    <Wrapper className={variant}>
      {variant === 'success' && <Icon name="fa-thin fa-circle-check" size={100} />}
      {text}
    </Wrapper>
  );
};
