import React from 'react';
import { Row, Title, PageWrapper, PageContainer } from './common-styles';
import Button from 'components/button';
import { showFlair } from 'helpers/flair';
import { useNavigateWithContext } from 'state';
import { useTranslation } from 'react-i18next';

export default ({ navigateToReview }) => {
  const { t } = useTranslation();

  React.useEffect(() => {
    showFlair('confetti');
    showFlair('fireworks');
  }, []);
  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center">
          <Title>{t('success.title')}</Title>
        </Row>
        <Row className="center text-center">{t('success.description')}</Row>
        <Row className="center">
          <img style={{ maxWidth: '100%' }} src="/assets/confetti.gif" alt="" />
        </Row>
        <Row className="center">
          <Button radius="25px" variant="secondary" onClick={() => navigateToReview()}>
            {t('buttonsActions.finish')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
