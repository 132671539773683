import styled from 'styled-components';

export const PageWrapper = styled.div`
  flex: 1;
  @media (max-width: 500px) {
    background-color: #fff;
  }
`;

export const PageContainer = styled.div`
  background-color: #fff;
  border-radius: 6px;
  max-width: 700px;
  margin: 0px auto;
  .description-box {
    padding: 5px 60px;
    @media (max-width: 700px) {
      padding: 5px 5px;
    }
  }
  @media (max-width: 500px) {
    box-shadow: none;
    border: none;
    margin: 0;
    padding: 20px;
  }
`;

export const Row = styled.div`
  margin: ${({ spacing = 25 }) => spacing}px 0px;
  &.center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &.text-center {
    text-align: center;
  }
  &.flex {
    display: flex;
    align-items: center;
    flex-direction: row;
  }
  &.gap-10 {
    gap: 10px;
  }
  &.gap-15 {
    gap: 15px;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  font-size: 1.5em;
`;

export const Description = styled.p`
  margin: 0;
  margin-top: 10px;
  white-space: pre-line;
  &.text-center {
    text-align: center;
  }
`;
