import { VERIFICATION_STATUS } from './enums';

const SCREENING_STEP_STATUSES = [
  { key: 'completed', requested: true, done: true },
  { key: 'complete', requested: true, done: true },
  { key: 'verified', requested: true, done: true, success: true },
  { key: 'approved', requested: true, done: true, success: true },
  { key: 'denied', requested: true, done: true, fail: true },
  { key: 'failed', requested: true, done: true, fail: true },
  { key: 'warning', requested: true, done: true, fail: true, warn: true },
  { key: 'pending', requested: true },
  { key: 'unverified', requested: true, done: true },
  { key: 'not-requested', requested: false }
];
export const SCREENING_STEP_STATUSES_DONE = SCREENING_STEP_STATUSES.filter((f) => f.done).map(
  (f) => f.key
);

const NOT_REQUESTED = SCREENING_STEP_STATUSES.find((k) => k.key === 'not-requested');
export const getScreeningStatus = (type, rowData) => {
  switch (type) {
    case 'criminal':
      return {
        status: rowData.criminalStatus,
        source: rowData.criminalSource,
        redFlags: rowData.criminalRedFlags || [],
        details: rowData.criminalDetails,
        ...(SCREENING_STEP_STATUSES.find((ss) => ss.key === rowData.criminalStatus) ||
          NOT_REQUESTED)
      };
    case 'credit':
      return {
        status: rowData.creditStatus,
        source: rowData.creditSource,
        redFlags: rowData.creditRedFlags || [],
        ...(SCREENING_STEP_STATUSES.find((ss) => ss.key === rowData.creditStatus) || NOT_REQUESTED)
      };
    case 'eviction':
      return {
        status: rowData.evictionStatus,
        source: rowData.evictionSource,
        redFlags: rowData.evictionRedFlags || [],
        details: rowData.evictionDetails,
        ...(SCREENING_STEP_STATUSES.find((ss) => ss.key === rowData.evictionStatus) ||
          NOT_REQUESTED)
      };
    case 'identity':
      return {
        status: rowData.identityStatus,
        source: rowData.identitySource,
        redFlags: rowData.identityRedFlags || [],
        details: rowData.identityDetails,
        ...(SCREENING_STEP_STATUSES.find((ss) => ss.key === rowData.identityStatus) ||
          NOT_REQUESTED)
      };
    case 'income':
      return {
        status: rowData.bankStatus,
        source: rowData.bankSource,
        redFlags: rowData.bankRedFlags || [],
        details: rowData.bankDetails,
        ...(SCREENING_STEP_STATUSES.find((ss) => ss.key === rowData.bankStatus) || NOT_REQUESTED)
      };
    case 'petScreening':
      return {
        status: rowData.petScreeningStatus,
        source: rowData.petScreeningSource,
        redFlags: rowData.petScreeningRedFlags || [],
        details: rowData.petScreeningDetails,
        ...(SCREENING_STEP_STATUSES.find((ss) => ss.key === rowData.petScreeningStatus) ||
          NOT_REQUESTED)
      };
  }
  return { redFlags: [], ...NOT_REQUESTED };
};

export const NO_RENT_REASONS = [
  { uuid: 'student', label: 'Student' },
  { uuid: 'partiallyEmployed', label: 'Partially employed' },
  { uuid: 'unemployed', label: 'Unemployed' },
  { uuid: 'stayAtHome', label: 'Stay at home mom/dad' },
  { uuid: 'caregiver', label: 'Caregiver' },
  { uuid: 'other', label: 'Other' }
];

export const baseScreeningInfo = ({ type, rowData }) => {
  const stepStatus = getScreeningStatus(type, rowData);
  let description = '';
  let icon = VERIFICATION_STATUS.pending;
  if (stepStatus.done && stepStatus.fail) {
    description = stepStatus.source === 'manual' ? 'Flagged manually' : 'Review report';
    icon = stepStatus.warn ? VERIFICATION_STATUS.warn : VERIFICATION_STATUS.fail;
  } else if (stepStatus.done && !stepStatus.success && stepStatus.redFlags.length > 0) {
    icon = VERIFICATION_STATUS.fail;
  } else if (stepStatus.done) {
    icon = VERIFICATION_STATUS.pass;
  } else {
    icon = VERIFICATION_STATUS.pending;
  }

  return { description, icon, stepStatus };
};
