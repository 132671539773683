import styled from 'styled-components';
import Icon from './icon';

const Wrapper = styled.label`
  display: flex;
  .option-label {
    font-weight: bold;
    display: flex;
    align-items: center;
    i {
      margin-right: 7px;
    }
  }
`;

const Body = styled.div`
  flex: 1;
  margin-left: 15px;
`;

export default ({ icon, label, description, checked, onChange }) => {
  return (
    <Wrapper>
      <input checked={checked} type="radio" onChange={onChange} />
      <Body>
        <div className="option-label">
          {icon && <Icon name={icon} />}
          {label}
        </div>
        <div>{description}</div>
      </Body>
    </Wrapper>
  );
};
