import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { UserContext, CompanyContext } from 'state';
import Icon from 'components/icon';
import Button from 'components/button';
import Modal, { ModalScrollable, ModalFooter } from 'components/modal';
import FormGroup from 'components/form-group';
import Input from 'components/input';
import Text from 'components/text';
import { SUPPORT_REQUEST } from 'dal/support';
import toast from 'react-hot-toast';
import { Trans, useTranslation } from 'react-i18next';

const questionSchema = (t) => {
  return yup.object().shape({
    name: yup
      .string()
      .max(100, `${t('form.validate.long')}`)
      .required(`${t('form.validate.name')}`),
    email: yup
      .string()
      .email('Invalid email')
      .required(`${t('form.validate.email')}`),
    number: yup
      .string()
      .phone(`${t('form.validate.invalidPhone')}`)
      .required(`${t('form.validate.phoneNumber')}`),
    message: yup
      .string()
      .max(5000, `${t('form.validate.long')}`)
      .min(1, `${t('form.validate.question')}`)
      .required(`${t('form.validate.question')}`)
  });
};

const Wrapper = styled.div``;

const ChatButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #007cff;
`;

const BodyWrapper = styled.div`
  margin: 0px;
  margin: 10px;
  flex-grow: 1;
`;

const StyledFormGroup = styled(FormGroup)`
  margin: 15px 0px;
`;

const TitleRow = styled.div`
  padding: 5px 10px;
  background-color: #007cff;
  color: white;
  font-size: 0.88em;
  > strong {
    margin: 10px 0px;
    display: block;
    text-align: center;
    font-size: 1.25em;
  }
  .subtitle {
    text-align: center;
  }
`;
const InputContact = styled(Input)`
  background: #fff;
  border-radius: 0px;
  height: 40px;
`;

const TextContact = styled(Text)`
  background: #fff;
  border-radius: 0px;
  height: 40px;
`;

const ButtonWrappers = styled.div`
  display: flex;
  gap: 20px;
  flex-flow: column;
  margin-top: 50px;
  > button {
    border-radius: 5px !important;
    width: 100% !important;
    padding: 20px !important;
  }
`;

const PMCForm = ({ setModalOpen, isContactUs }) => {
  const { t } = useTranslation();
  const params = useParams();
  const { companyContext } = React.useContext(CompanyContext);
  const { userContext } = React.useContext(UserContext);
  const { values, touched, errors, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues: {
      name: `${userContext?.firstName || ''} ${userContext?.lastName || ''}`.trim(),
      email: userContext?.email || '',
      number: userContext?.phone,
      message: '',
      applicationId: params.applicationId,
      locationId: params.locationId,
      url: window.location.href,
      companyId: companyContext?.id || params?.orgId
    },
    validationSchema: questionSchema(t),
    onSubmit: () => {
      SUPPORT_REQUEST({ userContext, data: values })
        .then(() => {
          toast.success('Message sent!');
          setModalOpen(undefined);
        })
        .catch((err) => {
          toast.error(
            err.data?.message
              ? err.data?.message
              : err.message || err.name || 'Failed to send message'
          );
        });
    }
  });
  return (
    <>
      <ModalScrollable padding={0} style={{ background: '#f5f5fa' }}>
        <TitleRow>
          <Trans i18nKey="contactUs.title">
            <strong>
              You've got questions,
              {!isContactUs && <br />} we've got answers!
            </strong>
          </Trans>
          <div className={isContactUs && 'subtitle'}>{t('contactUs.description')}</div>
        </TitleRow>
        <BodyWrapper spacing={0} padding={10}>
          <StyledFormGroup label={t('form.name')} errorText={touched.name && errors.name}>
            <InputContact
              name="name"
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </StyledFormGroup>
          <StyledFormGroup label={t('form.email')} errorText={touched.email && errors.email}>
            <InputContact
              icon="fa-regular fa-at"
              type="email"
              name="email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </StyledFormGroup>
          <StyledFormGroup label={t('form.phone')} errorText={touched.number && errors.number}>
            <InputContact
              errors={errors.number}
              name="number"
              icon="Calls-SMS"
              value={values.number}
              onChange={handleChange}
            />
          </StyledFormGroup>
          <StyledFormGroup label={t('form.message')} errorText={touched.message && errors.message}>
            <TextContact
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </StyledFormGroup>
        </BodyWrapper>
      </ModalScrollable>
      <ModalFooter>
        <Button variant="secondary" onClick={() => setModalOpen(false)}>
          {t('buttonsActions.cancel')}
        </Button>
        <Button
          style={{ marginLeft: '20px' }}
          variant="primary"
          onClick={() => {
            handleSubmit();
          }}>
          {t('buttonsActions.sendQuestion')}
        </Button>
      </ModalFooter>
    </>
  );
};

const PickerModal = ({
  modalOpen,
  setModalOpen,
  anchorEl,
  width,
  height,
  isContactUs,
  contactMode
}) => {
  const { t } = useTranslation();
  const { toggleChatWindow } = React.useContext(CompanyContext);
  const [selectedContactMethod, setSelectedContactMethod] = React.useState(
    ['pmc', 'picker'].includes(contactMode) ? contactMode : !toggleChatWindow ? 'pmc' : 'picker'
  );

  return (
    <Modal
      width={width || '400px'}
      height={height || 'auto'}
      open={!!modalOpen}
      backdropBgColor={'#ffffff00'}
      anchorDirection={'topRight'}
      anchorEl={anchorEl}
      hideClose={true}
      onClose={() => setModalOpen(false)}>
      {selectedContactMethod === 'picker' && (
        <ModalScrollable padding={0} style={{ background: '#f5f5fa' }}>
          <TitleRow>
            <Trans i18nKey="contactUs.title">
              <strong>
                You've got questions,
                {!isContactUs && <br />} we've got answers!
              </strong>
            </Trans>
          </TitleRow>
          <BodyWrapper spacing={0} padding={10}>
            <ButtonWrappers>
              <Button
                variant="secondary"
                onClick={() => {
                  toggleChatWindow(true);
                  setModalOpen(false);
                }}>
                <Trans i18nKey="components.contact_us.button_aptly">
                  I've got a question or issue with the website
                </Trans>
              </Button>
              <Button variant="secondary" onClick={() => setSelectedContactMethod('pmc')}>
                <Trans i18nKey="components.contact_us.button_org">
                  I've got a question about the home I'm applying to or it's application
                  requirements
                </Trans>
              </Button>
            </ButtonWrappers>
          </BodyWrapper>
        </ModalScrollable>
      )}
      {selectedContactMethod === 'pmc' && (
        <PMCForm setModalOpen={setModalOpen} isContactUs={isContactUs} />
      )}
    </Modal>
  );
};

export const SupportModal = ({ modalOpen, ...props }) => {
  return !modalOpen ? null : <PickerModal modalOpen={modalOpen} {...props} />;
};

export default () => {
  const [modalOpen, setModalOpen] = React.useState(false);
  const pad = React.useRef(null);

  return (
    <Wrapper>
      <ChatButton
        ref={pad}
        onClick={(e) => {
          setModalOpen(e.target);
        }}>
        <Icon name="fa-duotone fa-comment-question" size={25} />
      </ChatButton>
      {modalOpen && (
        <SupportModal modalOpen={modalOpen} setModalOpen={setModalOpen} anchorEl={modalOpen} />
      )}
    </Wrapper>
  );
};
