import React from 'react';
import styled from 'styled-components';
import Tooltip from 'components/tooltip';
import Icon from 'components/icon';
import {
  getCreditRatingRange,
  VERIFICATION_STATUS
} from 'pages/approver/share/detail/application-view/helpers';

const Centered = styled.div`
  display: flex;
  justify-content: center;
`;

export default ({ creditRating, disabled, message }) => {
  if (disabled) {
    return (
      <Centered>
        <Tooltip text={message || 'Credit information has been disabled for this applicant'}>
          <Icon size={25} {...VERIFICATION_STATUS.disabled} />
        </Tooltip>
      </Centered>
    );
  }

  if (!creditRating) {
    return <Tooltip text={'Credit rating is not available for this applicant'}>N/A</Tooltip>;
  }

  const range = getCreditRatingRange(creditRating);
  const toolTipInfo = range ? `Applicant's score is between ${range.min} and ${range.max}` : '';

  return <Tooltip text={toolTipInfo}>{creditRating}</Tooltip>;
};
