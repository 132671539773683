import React from 'react';
import { useMediaQuery } from 'react-responsive';
import styled from 'styled-components';

import useAnchorTab from 'hooks/useAnchorTab';
import Icon from 'components/icon';
import Button from 'components/button';

import ContactOrg from './contact-org';
import Section from './section';
import Fees, { MultiUnitFee } from './fees';
import Location from './location';
import Benefits from './benefits';
import NearbyHomes from './nearby-homes';
import Nearby from './nearby';
import Gallery from '../gallery';
import PetPolicyModal from './pet-policy-modal';
import WhatsAvailable from './whats-available';
import { ellipsis } from 'polished';
import Tooltip from '@material-ui/core/Tooltip';

const Wrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  .line {
    border-top: 1px solid #ddd;
  }
`;

const Row = styled.div`
  margin: 35px 0px;
`;

const Header = styled.div`
  margin: 20px 0px 20px;
  padding: 0 20px;
  line-height: 1.4;
  .title {
    font-size: 36px;
  }
  .subtitle {
    font-size: 20px;
  }
  .priceRow {
    margin-top: 5px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: baseline;
    justify-content: space-between;
  }
  .price {
    flex-shrink: 0;
    font-size: 20px;
    font-weight: bold;
    span {
      color: #444;
      font-size: 15px;
      font-weight: normal;
    }
  }
  .amenities {
    flex-shrink: 0;
    flex-grow: 1;
    gap: 5px;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
  }
  .promoDetail {
    margin-top: 10px;
  }
  .promoDetailPreview {
    &:hover {
      cursor: pointer;
      span {
        text-decoration: underline;
      }
    }
    span {
      ${ellipsis()}
    }
  }
`;

const Tabs = styled.div`
  margin: 35px 0px;
  position: sticky;
  background-color: #fff;
  top: -1px;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  height: 45px;
  padding: 0 10px;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    padding: 0px;
    margin: 0px;
    color: var(--color-font-primary);
    &.active {
      font-weight: bold;
    }
    @media (max-width: 480px) {
      font-size: 14px;
    }
  }
`;

const FieldsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 20px;
  gap: 20px;
`;

const FieldWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  .fieldLabel {
    font-size: 13px;
    color: #777;
    margin-bottom: 7px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const Details = styled.div`
  .detailsLabel {
    font-weight: bold;
    margin-bottom: 7px;
  }
  padding: 0 20px;
`;

const Amenities = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  white-space: pre;
`;

const fields = [
  {
    label: 'Available Date',
    icon: 'fa-solid fa-calendar-star',
    uuid: 'availableDate',
    value: (location) => {
      const pDate = new Date(location?.displayInfo?.availableDate);
      if (isNaN(pDate)) return '';

      if (pDate.getTime() < new Date().getTime()) return 'Now';

      return pDate.toLocaleDateString();
    }
  },
  {
    label: 'Lease Terms',
    icon: 'fa-solid fa-ruler',
    uuid: 'leaseTerm',
    value: (location) => location?.displayInfo?.leaseTerms
  },
  { label: 'Type', icon: 'fa-solid fa-building-memo', uuid: 'buildingType' },
  { label: 'Pets', icon: 'fa-solid fa-paw', uuid: 'petRestrictions' },
  { label: 'Parking', icon: 'fa-solid fa-garage-open', uuid: 'parkingType' },
  { label: 'Laundry', icon: 'fa-duotone fa-solid fa-washing-machine', uuid: 'laundryType' },
  { label: 'Heating', icon: 'fa-solid fa-temperature-three-quarters', uuid: 'heatingType' },
  { label: 'Cooling', icon: 'fa-solid fa-snowflake', uuid: 'coolingType' }
];

const tabs = [
  { label: 'Overview', id: 'details' },
  { label: 'Fees', id: 'fees' },
  { label: 'Location', id: 'location' },
  // { label: 'Schools', id: 'schools' },
  { label: 'Nearby', id: 'nearby' }
];

const Field = ({ field, location }) => {
  const val = field.value ? field.value(location) : location?.[field.uuid];
  const [policyOpen, setPolicyOpen] = React.useState();

  if (field.uuid === 'petRestrictions' && (val || location?.animalPolicy)) {
    return (
      <FieldWrapper>
        <Icon size={18} name={field.icon} />
        <div className="fieldBody">
          <div className="fieldLabel">{field.label}</div>
          <div className="fieldContent">
            {Array.isArray(val) ? val?.join(', ') : val}{' '}
            <>
              {location?.animalPolicy && (
                <>
                  {val && <br />}
                  <Button
                    style={{ fontSize: val ? 12 : undefined }}
                    variant="link"
                    onClick={() => setPolicyOpen(true)}>
                    Pet Policy
                  </Button>
                </>
              )}
            </>
          </div>
        </div>
        {policyOpen && (
          <PetPolicyModal content={location?.animalPolicy} onClose={() => setPolicyOpen(false)} />
        )}
      </FieldWrapper>
    );
  }

  if (!val) return <></>;

  return (
    <FieldWrapper>
      <Icon size={18} name={field.icon} />
      <div className="fieldBody">
        <div className="fieldLabel">{field.label}</div>
        <div className="fieldContent">{Array.isArray(val) ? val?.join(', ') : val}</div>
      </div>
    </FieldWrapper>
  );
};

export default ({
  orgId,
  location = {},
  nearbyLocations,
  nearbySchools,
  hideFloorplans,
  hideFees,
  hideUnits,
  isFullPage
}) => {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const [showDetail, setShowDetail] = React.useState();
  const { displayInfo } = location;
  const { activeTab, setActiveTab, onScrollEnd } = useAnchorTab({
    anchors: ['details', 'fees', 'location', 'schools', 'nearby'],
    container: 'scrollableDetails'
  });

  const detailItems = React.useMemo(() => {
    const firstRowItems = [];
    if (displayInfo.bedCount) {
      firstRowItems.push(
        <>
          <strong>{displayInfo.bedCount}</strong> beds
        </>
      );
    }
    if (displayInfo.bathCount) {
      firstRowItems.push(
        <>
          <strong>{displayInfo.bathCount}</strong> baths
        </>
      );
    }
    if (displayInfo.squareFeet) {
      firstRowItems.push(
        <>
          <strong>{displayInfo.squareFeet}</strong> sf
        </>
      );
    }

    return firstRowItems;
  }, [location]);

  const displayPromoDetail =
    !location.aptlyScreening?.active && location.showPromoBanner && location.promoDetail;

  return (
    <Wrapper id="scrollableDetails" onScroll={onScrollEnd}>
      <div id="details" />
      {isMobile && <Gallery location={location} isSlider />}
      <Header>
        {!!displayInfo.title && <div className="title">{displayInfo.title}</div>}
        {(!displayPromoDetail || showDetail) &&
          !!displayInfo.address &&
          displayInfo.title !== displayInfo.address && (
            <div className="subtitle">{displayInfo.address}</div>
          )}
        {displayPromoDetail && !showDetail && (
          <Tooltip title={location.promoDetail}>
            <div className="subtitle promoDetailPreview" onClick={() => setShowDetail(true)}>
              <span>{location.promoDetail}</span>
            </div>
          </Tooltip>
        )}
        <div className="priceRow">
          {!!displayInfo.formattedPrice && (
            <div className="price">
              {displayInfo.formattedPrice}/<span>month</span>
            </div>
          )}
          <div className="amenities">
            {detailItems.map((detailItem, ix) => (
              <span key={ix} className="amenities-item">
                {detailItem}
                {ix === detailItems.length - 1 || (ix + 1) % 3 === 0 ? '' : ' | '}
              </span>
            ))}
          </div>
        </div>
        {displayPromoDetail && showDetail && (
          <div className="promoDetail">
            <span>{location.promoDetail}</span>
          </div>
        )}
      </Header>
      <Tabs>
        {tabs.map((tab) => (
          <Button
            key={tab.id}
            className={tab.id === activeTab ? 'active' : ''}
            onClick={() => setActiveTab(tab.id)}>
            {tab.label}
          </Button>
        ))}
      </Tabs>
      <Row>
        <FieldsGrid>
          {fields.map((f, i) => (
            <Field key={i} field={f} location={location} />
          ))}
        </FieldsGrid>
      </Row>
      {location?.displayInfo?.amenities?.length > 0 && (
        <Row>
          <Details className="details">
            <div className="detailsLabel">OTHER AMENITIES</div>
            <Amenities>
              {location?.displayInfo?.amenities?.map((am) => (
                <div key={am}>{am}</div>
              ))}
            </Amenities>
          </Details>
        </Row>
      )}
      {!!location?.marketingDescription && (
        <Row>
          <Details className="details">
            <div className="detailsLabel">DETAILS</div>
            <div>
              <div dangerouslySetInnerHTML={{ __html: location?.marketingDescription }} />
            </div>
          </Details>
        </Row>
      )}
      <Row>
        <ContactOrg location={location} />
      </Row>
      <div className="line" />
      {!hideFees && <Fees location={location} />}
      {!hideFloorplans && !!location?.floorplans?.length && (
        <Section label="WHAT'S AVAILABLE">
          <WhatsAvailable orgId={orgId} location={location} isFullPage={isFullPage} />
        </Section>
      )}
      {!hideUnits && !!location?.units?.length && (
        <Section label="WHAT'S AVAILABLE">
          <MultiUnitFee orgId={orgId} location={location} isFullPage={isFullPage} />
        </Section>
      )}
      <Location
        location={location}
        nearbySchools={nearbySchools}
        nearbyLocations={nearbyLocations}
      />
      <Nearby nearbySchools={nearbySchools} />
      <Section>
        <Benefits location={location} />
      </Section>
      {!isFullPage && <NearbyHomes orgId={orgId} nearbyLocations={nearbyLocations} />}
    </Wrapper>
  );
};
