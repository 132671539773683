import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import Input from 'components/input';
import FormGroup from 'components/form-group';
import Text from 'components/text';
import Button from 'components/button';
import CurrencyInput from 'components/currency-input';
import InputDate from 'components/input-date';
import Checkbox from 'components/checkbox';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
const moneyToNumber = (amount) =>
  Number.isFinite(amount)
    ? amount
    : amount?.replace
    ? Number(amount?.replace(/[a-zA-Z,$ ]/gi, ''))
    : undefined;
const formatMoney = (amount = '') => {
  const val = `${amount || ''}`.replace(/[a-zA-Z,$ ]/gi, '');
  return moneyFormatter.format(val);
};

const incomeSchemas = (t) => ({
  employment: yup.object({
    uuid: yup.string().required(),
    type: yup.string().required(),
    payPeriod: yup.string().required(),
    current: yup.boolean(),
    future: yup.boolean(),
    companyName: yup.string().required(`${t('incomecAndConsent.validate.companyName')}`),
    position: yup.string().required(`${t('incomecAndConsent.validate.position')}`),
    income: yup
      .string()
      .matches(/^-?\d*\.?\d*$/, `${t('incomecAndConsent.validate.income')}`)
      .required(`${t('incomecAndConsent.validate.salary')}`),
    startDate: yup.date().required(`${t('incomecAndConsent.validate.startDate')}`),
    endDate: yup.date().when('current', {
      is: false,
      then: yup.date().required(`${t('incomecAndConsent.validate.endDate')}`)
    }),
    contactInfo: yup.object().shape({
      consentToContact: yup.boolean(),
      firstName: yup.string().when('consentToContact', {
        is: true,
        then: yup.string().required(`${t('incomecAndConsent.validate.firstName')}`)
      }),
      lastName: yup.string().when('consentToContact', {
        is: true,
        then: yup.string().required(`${t('incomecAndConsent.validate.lastName')}`)
      }),
      email: yup.string().when('consentToContact', {
        is: true,
        then: yup.string().email(`${t('incomecAndConsent.validate.invalidEmail')}`)
      }),
      phone: yup.object().when('consentToContact', {
        is: true,
        then: yup.object({
          number: yup.string().phone(`${t('incomecAndConsent.validate.invalidPhone')}`)
        })
      })
    }),
    leavingSoon: yup.boolean()
  }),
  self: yup.object({
    uuid: yup.string().required(),
    current: yup.boolean(),
    companyName: yup.string().required(`${t('incomecAndConsent.validate.companyName')}`),
    position: yup.string().required(`${t('incomecAndConsent.validate.position')}`),
    income: yup
      .string()
      .matches(/^-?\d*\.?\d*$/, `${t('incomecAndConsent.validate.income')}`)
      .required(`${t('incomecAndConsent.validate.salary')}`),
    comments: yup.string(),
    startDate: yup.date().required(`${t('incomecAndConsent.validate.startDate')}`),
    endDate: yup.date().when('current', {
      is: false,
      then: yup.date().required(`${t('incomecAndConsent.validate.endDate')}`)
    })
  }),
  misc: yup.object({
    uuid: yup.string().required(),
    companyName: yup.string().required(`${t('incomecAndConsent.validate.companyName')}`),
    income: yup
      .string()
      .matches(/^-?\d*\.?\d*$/, `${t('incomecAndConsent.validate.income')}`)
      .required(`${t('incomecAndConsent.validate.salary')}`),
    comments: yup.string()
  })
});

const defaultsValues = {
  employment: {
    type: 'employment',
    payPeriod: 'PER_MONTH',
    current: true,
    companyName: '',
    position: '',
    income: 0,
    startDate: '',
    endDate: '',
    contactInfo: {
      firstName: '',
      lastName: '',
      email: '',
      phone: { number: '' },
      consentToContact: false
    },
    leavingSoon: false
  },
  self: {
    type: 'self',
    current: true,
    companyName: '',
    position: '',
    income: 0,
    startDate: '',
    endDate: '',
    comments: ''
  },
  misc: {
    type: 'misc',
    companyName: '',
    income: 0,
    comments: ''
  }
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  overflow: hidden;
`;

const Scrollable = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 56px;
  background-color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: 1.13em;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  justify-content: flex-end;
  gap: 15px;
  button {
    padding: 7px 12px !important;
    text-transform: uppercase;
  }
`;

const ErrorText = styled.span`
  font-size: 0.81em;
  margin-top: 5px;
  color: red;
`;

const BadgeList = styled.div`
  display: flex;
  gap: 10px;
`;

const Badge = styled.div`
  padding: 5px 12px;
  border: 1px solid #ddd;
  border-radius: 25px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &.selected {
    background-color: #d8f1fa;
    border: 1px solid #00a1ff;
  }
`;

const Income = ({ onClose, location, income: initialIncome, onSave }) => {
  const { t } = useTranslation();
  const formProps = React.useMemo(() => {
    const initialValues = { ...defaultsValues[initialIncome.type], ...initialIncome };
    const validationSchema = incomeSchemas(t)[initialIncome.type];
    return { initialValues, validationSchema };
  }, [initialIncome]);

  const futureDate = React.useMemo(() => {
    const futureDate = new Date();
    futureDate.setFullYear(futureDate.getFullYear() + 1);
    return futureDate;
  }, []);

  const { values, touched, errors, setFieldValue, handleChange, handleBlur, handleSubmit } =
    useFormik({
      ...formProps,
      validate: (submittedValues) => {
        const errors = {};
        if (
          !!location?.companyInfo?.requireEmployerReference &&
          submittedValues?.current &&
          values.type === 'employment' &&
          !submittedValues?.contactInfo?.consentToContact
        ) {
          errors.consentToContact = t('incomecAndConsent.validate.consentToContact');
        }

        if (
          submittedValues?.contactInfo?.consentToContact &&
          location?.companyInfo?.requireEmployerReferenceEmail &&
          !submittedValues?.contactInfo?.email
        ) {
          errors.email = t('form.validate.email');
        }
        if (
          submittedValues?.contactInfo?.consentToContact &&
          !submittedValues?.contactInfo?.email &&
          !submittedValues?.contactInfo?.phone?.number
        ) {
          errors.emailOrPhoneRequired = t('form.validate.emailOrPhoneRequired');
        }
        return errors;
      },
      onSubmit: (values) => {
        onSave({
          ...values,
          income: values?.income?.replace
            ? values?.income?.replace(/[a-zA-Z,$ ]/gi, '')
            : values?.income
        });
        onClose();
      }
    });

  return (
    <Wrapper>
      <Header>{t('income_edition.title')}</Header>
      <Scrollable>
        <FormGroup label={t('income_edition.employType')}>
          <BadgeList>
            <Badge
              className={!values.current && !values.future ? 'selected' : undefined}
              onClick={() => {
                setFieldValue(`current`, false);
                setFieldValue(`future`, false);
              }}>
              {t('income_edition.pastEmploy')}
            </Badge>
            <Badge
              className={values.current && !values.future ? 'selected' : undefined}
              onClick={() => {
                setFieldValue(`current`, true);
                setFieldValue(`future`, false);
              }}>
              {t('income_edition.currentEmploy')}
            </Badge>
            <Badge
              className={values.future ? 'selected' : undefined}
              onClick={() => {
                setFieldValue(`current`, true);
                setFieldValue(`future`, true);
              }}>
              {t('income_edition.futureEmploy')}
            </Badge>
          </BadgeList>
        </FormGroup>
        {['misc'].includes(values.type) && (
          <FormGroup
            label={t('income_edition.source')}
            errorText={touched.companyName && errors.companyName}>
            <Input
              value={values.companyName}
              name="companyName"
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
        )}
        {['employment', 'self'].includes(values.type) && (
          <>
            <FormGroup
              label={t('income_edition.companyName')}
              errorText={touched.companyName && errors.companyName}>
              <Input
                value={values.companyName}
                name="companyName"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label={t('income_edition.position')}
              errorText={touched.position && errors.position}>
              <Input
                value={values.position}
                name="position"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup
              label={t('income_edition.startDate')}
              errorText={touched.startDate && errors.startDate}>
              <InputDate
                name={'startDate'}
                value={values.startDate}
                onChange={(e) => {
                  e.target.value = e.target.value || '';
                  handleChange(e);
                }}
                onBlur={handleBlur}
                disabledDays={(date) => {
                  if (!values.future) return date > new Date();
                  return date > futureDate;
                }}
              />
            </FormGroup>
            {(!values.current || values.leavingSoon) && (
              <FormGroup
                label={t('income_edition.endDate')}
                errorText={touched.endDate && errors.endDate}>
                <InputDate
                  name={'endDate'}
                  value={values.endDate}
                  onChange={(e) => {
                    e.target.value = e.target.value || '';
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  disabledDays={(date) => {
                    if (values.startDate && date <= values.startDate) {
                      return true;
                    }
                    return false;
                  }}
                />
              </FormGroup>
            )}
          </>
        )}
        <FormGroup label={t('income_edition.income')} errorText={touched.income && errors.income}>
          <CurrencyInput
            value={formatMoney(values.income)}
            name="income"
            onChange={(e) =>
              handleChange({ ...e, target: { ...e.target, value: moneyToNumber(e.target.value) } })
            }
            onBlur={handleBlur}
          />
        </FormGroup>
        <FormGroup
          label={t('income_edition.comments')}
          errorText={touched.comments && errors.comments}>
          <Text
            value={values.comments}
            name="comments"
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        {['employment'].includes(values.type) && (
          <>
            {!!values.current && !values.future && (
              <FormGroup errorText={touched.leavingSoon && errors.leavingSoon}>
                <Checkbox
                  label={t('income_edition.leavingSoon')}
                  checked={values.leavingSoon}
                  name="leavingSoon"
                  onChange={() => setFieldValue(`leavingSoon`, !values.leavingSoon)}
                />
              </FormGroup>
            )}
            <FormGroup
              errorText={
                touched.contactInfo?.consentToContact && errors.contactInfo?.consentToContact
              }>
              <Checkbox
                label={t('income_edition.consentToContact')}
                checked={values.contactInfo?.consentToContact}
                name="contactInfo.consentToContact"
                onChange={() =>
                  setFieldValue(
                    `contactInfo.consentToContact`,
                    !values.contactInfo?.consentToContact
                  )
                }
              />
              {errors.consentToContact && <ErrorText>{errors.consentToContact}</ErrorText>}
            </FormGroup>
            {values.contactInfo?.consentToContact && (
              <>
                <FormGroup
                  label={t('income_edition.firstName')}
                  errorText={touched.contactInfo?.firstName && errors.contactInfo?.firstName}>
                  <Input
                    value={values.contactInfo?.firstName}
                    name="contactInfo.firstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <FormGroup
                  label={t('income_edition.lastName')}
                  errorText={touched.contactInfo?.lastName && errors.contactInfo?.lastName}>
                  <Input
                    value={values.contactInfo?.lastName}
                    name="contactInfo.lastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <FormGroup
                  label={t('income_edition.email')}
                  errorText={touched.contactInfo?.email && errors.contactInfo?.email}>
                  <Input
                    value={values.contactInfo?.email}
                    name="contactInfo.email"
                    onChange={(e) => {
                      e.target.value = (e.target.value || '').trim(); // force whitespace trim
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                  />
                  {(errors.emailOrPhoneRequired || errors.email) && (
                    <ErrorText>{errors.email || errors.emailOrPhoneRequired}</ErrorText>
                  )}
                </FormGroup>
                <FormGroup
                  label={t('income_edition.phone')}
                  errorText={
                    touched.contactInfo?.phone?.number && errors.contactInfo?.phone?.number
                  }>
                  <Input
                    value={values.contactInfo?.phone?.number}
                    name="contactInfo.phone.number"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.emailOrPhoneRequired && (
                    <ErrorText>{errors.emailOrPhoneRequired}</ErrorText>
                  )}
                </FormGroup>
              </>
            )}
          </>
        )}
      </Scrollable>
      <Actions>
        <Button variant="text" onClick={onClose} radius="25px">
          {t('buttonsActions.cancel')}
        </Button>
        <Button variant="primary" radius="25px" onClick={handleSubmit}>
          {t('buttonsActions.save')}
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default ({ open, onClose, ...props }) => (
  <Modal closeOnOutsideClick={false} open={open} onClose={onClose} width="600px" height="80%">
    <Income onClose={onClose} {...props} />
  </Modal>
);
