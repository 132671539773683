import { POST, GET } from './utils';

export const LIST_APPLICATION_ITEMS = ({ userContext, filterCriteria }) => {
  const searchParams =
    filterCriteria && Object.keys(filterCriteria).length > 0
      ? new URLSearchParams(filterCriteria)
      : undefined;

  return GET({
    url: `applicationItems/list${searchParams ? `?${searchParams.toString()}` : ''}`,
    token: userContext?.accessToken?.token
  });
};

export const GET_APPLICATION_ITEM = ({ userContext, objectId }) =>
  GET({ url: `applicationItems/${objectId}`, token: userContext?.accessToken?.token });

export const SAVE_APPLICATION_ITEM = ({ userContext, objectId, data }) =>
  POST({
    url: `applicationItems/${objectId ? objectId : ''}`,
    data,
    token: userContext?.accessToken?.token
  });

export const PROMOTE_APPLICATION_ITEM = ({ userContext, objectId, data }) =>
  POST({
    url: `applicationItems/promote/${objectId ? objectId : ''}`,
    data,
    token: userContext?.accessToken?.token
  });
