/**
 * Doc: https://www.kirilv.com/canvas-confetti/
 * Currently installed via a script include
 */
const flairTypes = {
  confetti: () => {
    confetti && confetti({ particleCount: 100, spread: 150, origin: { y: 0.55 } });
  },
  fireworks: () => {
    if (!confetti) {
      return;
    }
    const duration = 5 * 1000;
    const animationEnd = Date.now() + duration;
    const defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 };

    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }

    const interval = setInterval(function () {
      const timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      const particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }
        })
      );
      confetti(
        Object.assign({}, defaults, {
          particleCount,
          origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }
        })
      );
    }, 250);
  }
};

export const showFlair = (flair) => {
  if (flair && flairTypes[flair]) {
    flairTypes[flair]();
  }
};
