import React from 'react';
import styled from 'styled-components';

import { randomId } from 'dal/utils';
import Icon from 'components/icon';
import Button from 'components/button';

import { SingleContactModal } from './single';
import { defaultContact } from './common';

const Wrapper = styled.div`
  &:last-children {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  display: flex;
  gap: 10px;
  .title {
    flex: 1;
    font-weight: bold;
    font-size: 1.3em;
  }
  .subtitle {
    font-size: 0.94em;
    font-weight: normal;
  }
`;

const List = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
`;

const MemberWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  > div {
    flex: 1;
  }
  .actions {
    display: flex;
    width: 75px;
    max-width: 75px;
    min-width: 75px;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #444;
      font-size: 0.88em;
    }
  }
`;

const Member = ({
  name = '',
  firstName = '',
  lastName = '',
  phone = [],
  email,
  onRemove,
  onEdit
}) => {
  return (
    <MemberWrapper>
      <div>{[name, firstName, lastName].filter((name) => name).join(' ')}</div>
      <div>
        {phone.map((z, ix) => (
          <div key={ix}>{z.number}</div>
        ))}
      </div>
      <div>{email}</div>
      <div className="actions">
        {onRemove && (
          <Button onClick={onRemove} title="Remove">
            <Icon name="fa-solid fa-trash" />
          </Button>
        )}
        {onEdit && (
          <Button onClick={onEdit} title="Edit">
            <Icon name="fa-solid fa-pen-to-square" />
          </Button>
        )}
      </div>
    </MemberWrapper>
  );
};

export default ({ name, onChange, value: initialValue = [] }) => {
  const [selectedContact, setSelectedContact] = React.useState();
  const hasMembers = initialValue?.length > 0;

  const wrappedChange = (values) => onChange({ target: { name, value: values } });

  const onAdd = () => setSelectedContact({ uuid: randomId(), ...defaultContact });
  const onEdit = (member) => setSelectedContact(member);
  const onRemove = (member) => wrappedChange(initialValue.filter((v) => v.uuid !== member.uuid));

  return (
    <Wrapper>
      {!!selectedContact && (
        <SingleContactModal
          open={true}
          onClose={() => setSelectedContact(null)}
          value={selectedContact}
          onSave={(contact) => {
            if (initialValue.some((t) => t.uuid === contact.uuid)) {
              //edit
              wrappedChange(initialValue.map((v) => (v.uuid === contact.uuid ? contact : v)));
            } else {
              //add
              wrappedChange(initialValue.concat(contact));
            }
            setSelectedContact(null);
          }}
        />
      )}
      <Header>
        <Button className="" variant="link" onClick={onAdd}>
          <Icon name="Add" size={9} />
          &nbsp; Add Contact
        </Button>
      </Header>
      {hasMembers && (
        <List>
          <MemberWrapper>
            <div>
              <strong>Name</strong>
            </div>
            <div>
              <strong>Phone</strong>
            </div>
            <div>
              <strong>Email</strong>
            </div>
            <div className="actions">&nbsp;</div>
          </MemberWrapper>
          {initialValue.map((member) => (
            <Member
              key={member.uuid}
              {...member}
              onEdit={() => onEdit(member)}
              onRemove={() => onRemove(member)}
            />
          ))}
        </List>
      )}
    </Wrapper>
  );
};
