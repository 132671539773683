import React from 'react';
import Modal from 'components/modal';
import styled from 'styled-components';
import FormGroup from 'components/form-group';
import Input from 'components/input';
import Checkbox from 'components/checkbox';
import Button from 'components/button';
import * as yup from 'yup';
import { randomId } from 'dal/utils';
import { useFormik } from 'formik';
import Select from 'components/select';
import StateSelector from 'components/stateSelector';

const vehicleSchema = yup.object({
  uuid: yup.string(),
  type: yup.string().required(''),
  make: yup.string().required('Make is required'),
  model: yup.string().required('Model is required'),
  year: yup
    .number()
    .typeError('Provide a valid 4 digit year')
    .required('Provide a valid 4 digit year')
    .min(1672, 'Provide a valid 4 digit year number greater than 1672')
    .max(
      new Date().getFullYear() + 5,
      `Provide a valid 4 digit year before ${new Date().getFullYear() + 5}`
    ),
  color: yup.string().required('Color is required'),
  licensePlate: yup.string().required('License plate is required'),
  oversized: yup.boolean()
});

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 56px;
  background-color: #fff;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  font-size: 1.13em;
  text-transform: capitalize;
`;

const Body = styled.div`
  flex: 1;
  padding: 20px;
  overflow-y: auto;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0 20px;
  justify-content: flex-end;
  gap: 15px;
  button {
    padding: 7px 12px !important;
  }
`;

const defaultVehicle = {
  type: '',
  make: '',
  model: '',
  year: '',
  color: '',
  licensePlate: '',
  licensePlateState: '',
  oversized: false
};

const Vehicle = ({ onClose, vehicle, onSave, t, country, vehicleOptions }) => {
  const { values, errors, touched, handleChange, handleBlur, setFieldValue, handleSubmit } =
    useFormik({
      initialValues: { ...defaultVehicle, ...vehicle },
      validationSchema: vehicleSchema,
      onSubmit: (values) => {
        onSave({ uuid: randomId(), ...values });
        onClose();
      }
    });

  return (
    <Wrapper>
      <Header>{vehicle.uuid ? `${t('edit')} ${values.type}` : `${t('ADD')} ${values.type}`}</Header>
      <Body>
        <FormGroup label={t('vehicleStep.form.type')} errorText={touched.type && errors.type}>
          <Select name="type" onChange={handleChange} onBlur={handleBlur} value={values.type}>
            {vehicleOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup label={t('vehicleStep.form.make')} errorText={touched.make && errors.make}>
          <Input name="make" value={values.make} onChange={handleChange} onBlur={handleBlur} />
        </FormGroup>
        <FormGroup label={t('vehicleStep.form.model')} errorText={touched.model && errors.model}>
          <Input name="model" value={values.model} onChange={handleChange} onBlur={handleBlur} />
        </FormGroup>
        <FormGroup label={t('vehicleStep.form.year')} errorText={touched.year && errors.year}>
          <Input name="year" value={values.year} onChange={handleChange} onBlur={handleBlur} />
        </FormGroup>
        <FormGroup label={t('vehicleStep.form.color')} errorText={touched.color && errors.color}>
          <Input name="color" value={values.color} onChange={handleChange} onBlur={handleBlur} />
        </FormGroup>
        <FormGroup
          label={t('vehicleStep.form.licensePlateState')}
          errorText={touched.licensePlateState && errors.licensePlateState}>
          <StateSelector
            name="licensePlateState"
            value={values.licensePlateState}
            onChange={handleChange}
            onBlur={handleBlur}
            country={country}
          />
        </FormGroup>
        <FormGroup
          label={t('vehicleStep.form.license')}
          errorText={touched.licensePlate && errors.licensePlate}>
          <Input
            name="licensePlate"
            value={values.licensePlate}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormGroup>
        {values.type === 'car' && (
          <FormGroup>
            <Checkbox
              label={t('vehicleStep.form.checkboxDescription')}
              checked={values.oversized}
              onChange={() => setFieldValue('oversized', !values.oversized)}
            />
          </FormGroup>
        )}
      </Body>
      <Actions>
        <Button variant="primary" radius="25px" onClick={handleSubmit}>
          {t('buttonsActions.save')}
        </Button>
      </Actions>
    </Wrapper>
  );
};

export default ({ open, onClose, ...props }) => (
  <Modal closeOnOutsideClick={false} open={open} onClose={onClose} width="600px" height="70%">
    <Vehicle onClose={onClose} {...props} />
  </Modal>
);
