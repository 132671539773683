import React from 'react';
import styled from 'styled-components';
import { useDocumentContext } from '../../state';
import { ellipsis } from 'polished';
import Icon from 'components/icon';
import Menu from 'components/menu';

const Wrapper = styled.div`
  position: relative;
`;

const SignatureContainer = styled.div`
  overflow: hidden;
  width: 150px;
  .name {
    font-size: 12px;
    font-style: italic;
    ${ellipsis()}
  }
`;

const Signature = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 80px;
  padding: 10px;
  border: 1px solid #000;
  margin-bottom: 5px;
  box-sizing: border-box;
  font-size: 12px;
  background-color: #fcffcc;
  cursor: pointer;
  padding: 5px;
  &.signed {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #000;
  }
  &:hover {
    border: 2px solid blue;
  }
  .image-signature {
    flex: 1;
    background-image: url(${({ image }) => image});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
  }
`;

export default ({ page, type, signatureKey }) => {
  const { ui, user } = useDocumentContext();

  const handleSign = () => {
    if (!ui.signature) return ui.updateUi({ showPad: signatureKey });
    ui.signPage({ pageUuid: page.uuid, zoneKey: signatureKey });
  };

  const signed = ui?.signature && ui.signedStatus[page.uuid][signatureKey] === true;

  const handleActions = ({ id }) => {
    if (id === 'clear') ui.removeSignedPage({ pageUuid: page.uuid, zoneKey: signatureKey });
    if (id === 'change') ui.updateUi({ showPad: signatureKey });
  };

  const isInitials = type === 'initial';

  return (
    <Wrapper>
      <SignatureContainer>
        <div style={{ fontSize: 12, fontStyle: 'italic' }}>
          {!isInitials ? 'Signature' : 'Initials'}
        </div>
        <Signature
          onClick={!signed && handleSign}
          className={signed ? 'signed' : ''}
          image={isInitials ? ui?.signature?.initialsImage : ui?.signature?.signature}>
          {signed ? (
            <Menu
              onItemClick={handleActions}
              options={[
                { label: 'Change', id: 'change' },
                { label: 'Clear', id: 'clear' }
              ]}
              autoWidth>
              {(provided) => <div className="image-signature" {...provided} />}
            </Menu>
          ) : (
            <>
              <div>{!isInitials ? 'Sign here' : 'Initials here'}</div>
              <Icon name="fa-regular fa-arrow-down-to-line" size={20} />{' '}
            </>
          )}
        </Signature>
        <div className="name">{user.name}</div>
      </SignatureContainer>
    </Wrapper>
  );
};
