import React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { darken, cssVar } from 'polished';

const ellipsisKf = keyframes`
  to {
    text-indent: 5px;
  }
`;

const Button = styled.button`
  box-shadow: none;
  border: none;
  cursor: pointer;
  background: transparent;
  &:disabled {
    opacity: 0.4;
  }
  &.warning {
    padding: 5px 10px;
    background-color: var(--color-warning);
    border-radius: 15px;
    color: #fff;
    font-weight: 500;
    &:hover {
      background-color: ${darken(0.1, cssVar('--color-warning', '#ff8787'))};
    }
  }
  &.secondary {
    padding: 5px 10px;
    border-radius: 25px;
    border: solid 1px var(--color-primary);
    background-color: #fff;
    color: var(--color-primary);
    font-weight: 500;
    &:hover {
      background-color: #f4f4f4;
    }
    @media (min-width: 1400px) {
      //font-size: 1.2em;
    }
  }
  &.primary {
    padding: 5px 10px;
    background-color: var(--color-primary);
    border-radius: 15px;
    color: #fff;
    font-weight: 500;
    &:hover {
      background-color: ${darken(0.1, cssVar('--color-primary', '#007cff'))};
    }
    @media (min-width: 1400px) {
      //font-size: 1.2em;
    }
  }
  &.caps {
    text-transform: uppercase;
  }
  &.text {
    padding: 5px 10px;
    background-color: transparent;
    border-radius: 6px;
    font-weight: 500;
    &:hover {
      background-color: #eee;
    }
  }
  &.link {
    padding: 0px;
    color: var(--color-primary);
    background-color: transparent;
  }
  &.link:hover {
    color: ${darken(0.4, cssVar('--color-link', '#007cff'))};
  }
  ${({ height }) => (height ? `height:${height}px;` : '')}
  ${({ width }) => (width ? `width:${width}px;` : '')}
  ${({ marginLeft }) => (marginLeft ? `margin-left:${marginLeft}px;` : '')}
  ${({ marginRight }) => (marginRight ? `margin-right:${marginRight}px;` : '')}
  ${({ radius }) => (radius ? `border-radius:${radius} !important;` : '')}  ${(props) =>
    props.isLoading &&
    css`
      :after {
        display: inline-block;
        overflow: hidden;
        vertical-align: bottom;
        content: '\\2026';
        width: 10px;
        text-indent: -20px;
        margin-left: 2px;
        animation: ${ellipsisKf} steps(4, end) 1s infinite;
      }
    `};
`;

export default React.forwardRef(
  ({ className = '', variant = '', height, loading, ...props }, ref) => (
    <Button
      ref={ref}
      height={height}
      className={`${className} ${variant}`}
      {...props}
      isLoading={loading}
    />
  )
);
