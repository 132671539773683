import styled from 'styled-components';
import React from 'react';
import Button from 'components/button';
import Icon from 'components/icon';
import Menu from 'components/menu';
import ErrorBoundary from 'components/errorBoundary';
import BorderedBox from 'components/bordered-box';
import { darken } from 'polished';
import { primary } from 'styles/colors';
import { isString, isFunction } from 'lodash';

const Wrapper = styled.div`
  margin-top: 10px;
`;
const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 8px;
  font-weight: bold;
  > div > i {
    margin: 0 10px;
  }
`;
const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  td,
  th {
    padding: 0 10px;
    &.menuCol {
      text-align: right;
      width: 20px;
      padding: 0 10px 0 0;
    }
    &.capitalize {
      text-transform: capitalize;
    }
    &.iconCol {
      text-align: center;
      width: 20px;
      padding: 0;
    }
    &.centerIconCol {
      text-align: center;
      > div {
        margin: auto;
      }
    }
  }
  td {
    &.clickable {
      cursor: pointer;
      font-weight: bold;
      color: ${({ theme }) => theme?.colors?.primary || primary};
      &:hover {
        color: ${({ theme }) => darken(0.05, theme?.colors?.primary || primary)};
      }
    }
  }
  tr {
    height: 40px;
  }
  thead {
    tr {
      border-bottom: 1px solid #ddd;
    }
    th {
      color: #000;
      font-weight: bold;
      font-size: 12px;
      text-transform: uppercase;
      text-align: left;
      &.capitalize {
        text-transform: uppercase;
      }
      &.centerIconCol {
        text-align: center;
        > div {
          margin: auto;
        }
      }
    }
  }
  tbody {
    tr {
      border-bottom: 1px solid #ddd;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .highlight {
    color: #49b22f;
  }
  .totalRow {
    margin-top: 10px;
    border-top: #c8d6df 3px solid;
  }
`;
const IconCol = styled.td`
  position: relative;
  .statusIndicator {
    position: absolute;
    left: 3px;
    top: 3px;
  }
`;
const NoData = styled.div`
  padding: 20px;
  margin: auto;
  text-align: center;
`;
const StyledButton = styled(Button)`
  display: flex;
  gap: 5px;
  &:hover {
    color: ${({ theme }) => darken(0.05, theme?.colors?.primary || primary)};
  }
`;

export const Section = ({ id, icon, className, children, title, actions = [], onAction }) => {
  return (
    <Wrapper className={className} id={id}>
      <Title className="avoidBreak sectionTitleBar">
        <div className="sectionTitle" title={isString(title) ? title : undefined}>
          {icon && <Icon name={icon} />}
          {title}
        </div>
        {actions.length > 0 && (
          <Actions className="no-print">
            {actions.map((a, i) => (
              <StyledButton variant="link" onClick={() => onAction(a)} key={i}>
                {a.icon && <Icon name={a.icon} />}
                {a.label}
              </StyledButton>
            ))}
          </Actions>
        )}
      </Title>
      {children}
    </Wrapper>
  );
};
export const BoxSection = ({
  id,
  className,
  children,
  title,
  titleIcon,
  actions,
  handleActions
}) => {
  return (
    <Section
      id={id}
      icon={titleIcon}
      className={className}
      title={title}
      actions={actions}
      onAction={handleActions}>
      <ErrorBoundary>
        <BorderedBox>{children}</BorderedBox>
      </ErrorBoundary>
    </Section>
  );
};
export const TableSection = ({
  id,
  className,
  childPlacement = 'post',
  children,
  data = [],
  totalRow,
  columns = [],
  title,
  titleIcon,
  actions,
  handleActions,
  menuOptions,
  noDataMessage = 'No records specified'
}) => {
  return (
    <BoxSection
      id={id}
      titleIcon={titleIcon}
      className={className}
      title={title}
      actions={actions}
      handleActions={handleActions}>
      {childPlacement === 'pre' ? children : <></>}
      <Table>
        <thead>
          <tr>
            {columns?.map((col, ix) => (
              <th key={ix} className={col.className}>
                {col.label}
              </th>
            ))}
            {menuOptions?.length > 0 && <th className="menuCol no-print" />}
          </tr>
        </thead>
        <tbody>
          {data?.map((rowData, rix) => (
            <tr key={rix}>
              {columns?.map((col, ix) => {
                return (
                  <td
                    key={ix}
                    onClick={
                      col.onClick ? (e) => col.onClick(e, rowData, handleActions) : undefined
                    }
                    className={
                      !col.className && !col.onClick
                        ? undefined
                        : `${col.className || ''} ${col.onClick ? 'clickable' : ''}`
                    }>
                    {col.render ? col.render(rowData, handleActions) : rowData[col.uuid]}
                  </td>
                );
              })}
              {menuOptions?.length > 0 && (
                <IconCol className="menuCol no-print">
                  <Menu
                    options={isFunction(menuOptions) ? menuOptions(rowData) : menuOptions}
                    onItemClick={(e) => handleActions({ id: e.action, data: rowData })}>
                    {(provided) => (
                      <Button className="icon-btn" {...provided}>
                        <Icon name="fa-regular fa-ellipsis-vertical" />
                      </Button>
                    )}
                  </Menu>
                </IconCol>
              )}
            </tr>
          ))}
          {!!totalRow && (
            <tr className="totalRow">
              {columns?.map((col, ix) => {
                return (
                  <td key={ix} className={!col.className ? undefined : `${col.className || ''}`}>
                    {totalRow[col.uuid]}
                  </td>
                );
              })}
              {menuOptions?.length > 0 && <IconCol className="menuCol no-print" />}
            </tr>
          )}
        </tbody>
      </Table>
      {data.length === 0 && <NoData>{noDataMessage}</NoData>}
      {!childPlacement || childPlacement === 'post' ? children : <></>}
    </BoxSection>
  );
};
