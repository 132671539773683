import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from './select';
import { getStateList } from 'utils/stateHelpers';

export default ({ className = '', country = 'US', ...props }) => {
  const { t } = useTranslation();
  const stateOptions = getStateList(country);
  return (
    <Select className={className} {...props}>
      <option value="">{t('components.stateSelector.empty')}</option>
      {stateOptions.map((opt, ix) => (
        <option value={opt.abbreviation} key={ix}>
          {opt.name}
        </option>
      ))}
    </Select>
  );
};
