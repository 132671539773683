import React from 'react';
import { useParams } from 'react-router-dom';
import { useNavigateWithContext } from 'state';
import { UPDATE_APPLICATION } from 'dal/applications';
import { UserContext } from 'state';

export default ({ onRefresh } = {}) => {
  const { userContext } = React.useContext(UserContext);
  const params = useParams();
  const navigate = useNavigateWithContext();

  return ({ section, step, status, refresh } = {}) => {
    const nav = () => {
      if (refresh && onRefresh) onRefresh();
      navigate(`app/applications/form/v2/${params.applicationId}`);
    };

    if (section && (step || status)) {
      return UPDATE_APPLICATION({
        userContext,
        applicationId: params.applicationId,
        data: { stepProgress: { [section]: { step, status } } }
      })
        .catch((err) => console.error({ errData: err.data, err }))
        .then(nav);
    }
    nav();
  };
};
