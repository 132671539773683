import React from 'react';
import { Route } from 'react-router-dom';
import Login from '../login';
import ResetPassword from '../login/reset-password';
import Locations from '../public-locations';
import LocationPreview from '../location';
import Profile from '../profile';
import Support from '../support';

export const LoginRoutes = (
  <Route key="home" path="home">
    <Route path="signup">
      <Route index={true} element={<Login />} />
      <Route path="reset" element={<ResetPassword />} />
      <Route path=":loginView" element={<Login />} />
    </Route>
    <Route path="*" index={true} element={<Login />} />
  </Route>
);

export const ProfileRoutes = <Route path="profile" element={<Profile />} />;
export const SupportRoutes = <Route path="support" element={<Support />} />;

export const ListingRoutes = (
  <Route path="listing">
    <Route path=":locationId" element={<LocationPreview />} />
  </Route>
);

export const LocationRoutes = (
  <Route path="locations">
    <Route index={true} element={<Locations />} />
    <Route path=":locationId" element={<LocationPreview />} />
  </Route>
);
