import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';

const ProgressBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  .bar {
    display: flex;
    height: 25px;
    border-radius: 10px;
    flex-grow: 1;
    > div:first-child {
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
    }
    > div:last-child {
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
    }
  }
  .counter {
    flex-grow: 0;
    flex-shrink: 0;
    margin-right: 10px;
    line-height: normal;
    padding: 0px 10px;
    border: 1px solid #ccc;
    height: 25px;
    border-radius: 15px;
    display: flex;
    align-items: center;
  }
`;

const BarSegment = styled.div`
  width: ${({ size }) => size || 0}%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  background-color: ${({ color }) => color};
  color: #fff;
  gap: 5px;
  align-items: center;
  padding: 0;
`;

const ProgressCaret = styled(Icon)`
  position: absolute;
  font-size: 30px;
  top: -18px;
  left: calc(${({ caretPosition }) => caretPosition || 0}% - 9px);
`;

/**
 * expects segments as [{color:'#123', count:20},{color:'#123', count:50}]
 */
export default ({ segments = [], showCount, value }) => {
  const total = segments?.reduce((tot, seg) => (seg.count || 0) + tot, 0);
  if (!total) {
    return (
      <ProgressBar>
        <div className="bar" />
      </ProgressBar>
    );
  }

  return (
    <ProgressBar>
      {Number.isFinite(value) ? (
        <ProgressCaret caretPosition={value} name="fa-solid fa-caret-down" />
      ) : (
        <></>
      )}
      <div className="bar">
        {segments
          .filter((seg) => !!seg.count)
          .map((seg, ix) => {
            return (
              <BarSegment key={ix} color={seg.color} size={Math.round(100 * (seg.count / total))}>
                {seg.iconProps && <Icon {...seg.iconProps} />}
                {showCount && <span>{seg.count}</span>}
              </BarSegment>
            );
          })}
      </div>
    </ProgressBar>
  );
};
