import { useMediaQuery } from 'react-responsive';

export const devicesMaxWidth = { mobile: 835, desktop: 1600 };

export default () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 835 });
  const isPortrait = useMediaQuery({ orientation: 'portrait' });

  return { isTabletOrMobile, isPortrait };
};
