import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { renderPrettyDate } from 'utils/dateHelpers';
import Button from 'components/button';
import Avatar from 'components/avatar';
import Icon from 'components/icon';
import ShadowRender from 'components/shadow-render';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 290px;
  overflow: hidden;
  background-color: #fff;
  border: 1px solid #ddd;
  .data-list {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  margin-bottom: 15px;
`;

const Content = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 45px;
  @media (max-width: 960px) {
    margin: 25px 20px;
  }
  &.share-data {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  &.property-data {
    display: flex;
    align-items: flex-start;
    gap: 20px;
  }
  &.actions {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 25px 45px;
    padding-top: 0px;
    gap: 10px;
    button:last-child {
      margin-left: auto;
    }
    @media (max-width: 960px) {
      padding: 25px 20px;
      padding-top: 0px;
    }
  }
`;

const Title = styled.div`
  flex: 1;
  font-weight: bold;
  font-size: 1.25em;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  > :hover {
    text-decoration: underline;
    color: #000;
  }
`;
const ExpirationBubble = styled.div`
  width: 100%;
  margin-top: 20px;
  padding: 12px;
  border-radius: 5px;
  border: solid 1px #f7b500;
  background-color: #fffaeb;
  text-align: center;
`;
const ChatWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
`;
const ChatBubble = styled.div`
  flex: 1;
  width: 100%;
  .bubble {
    padding: 16px;
    border-radius: 5px;
    background-color: #e3ebf1;
    max-height: 500px;
    overflow-y: auto;
  }
  .name {
    margin-top: 5px;
    text-align: right;
  }
`;
const AvatarWrapper = styled.div``;
const Logo = styled.div`
  width: 120px;
  height: 80px;
  border-radius: 2px;
  border: none;
  background-image: url(${({ image }) => image}), url('/assets/location_placeholder.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const DataGroup = styled.div`
  display: flex;
  flex-direction: column;
  &.centered {
    align-items: center;
  }
  .label {
    font-size: 0.81em;
    text-transform: uppercase;
    color: #a0a0a0;
    margin-bottom: 5px;
  }
  .data {
    font-weight: bold;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }
  &:last-child {
    border-right: none;
  }
`;

export default ({ share, handleActions }) => {
  const { t } = useTranslation();
  const timeString = share?.createdAt ? renderPrettyDate(share?.createdAt) : undefined;
  const expirationDate = share?.expirationDate
    ? renderPrettyDate(share?.expirationDate)
    : undefined;

  const app = share.shareDetails.application;
  const location = app?.location;
  const image =
    location?.applicationConfig?.coverPhoto?.[0] ||
    location?.applicationConfig?.marketingImages?.[0];

  return (
    <Wrapper>
      <Content>
        <Row>
          <Title
            onClick={() => {
              handleActions({ id: 'application-select', data: share });
            }}>
            <div>{share.shareDetails?.shareName}</div>
            <Button>
              View&nbsp;
              <Icon name="fa-solid fa-chevron-right" />
            </Button>
          </Title>
        </Row>

        {share.shareDetails?.application && (
          <Row className="property-data">
            <Logo image={image} />
            <div>
              <div className="data-list">
                <DataGroup>
                  <div className="label">{t('aplicationItem.availableDate')}</div>
                  <div className="data">
                    {location?.applicationConfig?.availableDate || 'No data'}
                  </div>
                </DataGroup>
                <DataGroup>
                  <div className="label">Address</div>
                  <div className="data">{location.address?.formattedAddress || location.name}</div>
                </DataGroup>
              </div>
              <div className="data-list">
                <DataGroup>
                  <div className="label">Primary Applicant</div>
                  <div className="data">{`${app.applicant?.firstName} ${app.applicant?.lastName}`}</div>
                </DataGroup>
              </div>
            </div>
          </Row>
        )}
        <Row className="property-data">
          <ChatWrapper>
            <ChatBubble>
              <div className="bubble">
                <ShadowRender
                  html={share.shareDetails?.message || 'Please review this application'}
                />
              </div>
              <div className="name">
                <strong>{share?.shareDetails?.sharedBy?.name || 'Someone'}</strong>
                {timeString ? ` on ${timeString}` : ''}
              </div>
            </ChatBubble>
            {(!!share?.shareDetails?.sharedBy?.duogram ||
              !!share?.shareDetails?.sharedBy?.image) && (
              <AvatarWrapper>
                <Avatar
                  size={50}
                  imageUrl={share?.shareDetails?.sharedBy?.image}
                  duogram={share?.shareDetails?.sharedBy?.duogram}
                />
              </AvatarWrapper>
            )}
          </ChatWrapper>
        </Row>
        {expirationDate && (
          <Row>
            <ExpirationBubble>
              The link to view this application will expire at {expirationDate}
            </ExpirationBubble>
          </Row>
        )}
        <Row className="actions" />
      </Content>
    </Wrapper>
  );
};
