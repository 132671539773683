import React from 'react';
import { TableSection } from 'components/section';

export default ({ className, application, handleActions }) => {
  const columns = [
    { label: 'Name', uuid: 'name', className: 'capitalize' },
    {
      label: 'Age',
      uuid: 'age',
      render: (data) =>
        data?.DOB?.toLocaleDateString
          ? `${data?.age} (${data?.DOB.toLocaleDateString()})`
          : data?.age
    },
    { label: 'Added By', uuid: 'addedBy', className: 'capitalize' }
  ];
  return (
    <TableSection
      id="application-minors"
      className={className}
      handleActions={handleActions}
      data={application?.minors}
      columns={columns}
      title="MINORS"
      noDataMessage="No minors specified"
      titleIcon="fa-duotone fa-people-roof"
    />
  );
};
