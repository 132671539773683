import { POST } from './utils';

const convertToken = (token) => {
  return token
    ? {
        ...token,
        expiration:
          token.expiration && !token.expiration.getTime ? new Date(token.expiration) : undefined
      }
    : undefined;
};

export const VALIDATE_PHONE = ({ email, phone, orgId, verifyType }) =>
  POST({ url: 'register/confirmPhone', data: { phone, email, companyId: orgId, verifyType } });

// SERVER will validate data
export const REGISTER = ({
  email,
  email_confirm,
  firstName,
  lastName,
  phone,
  auth,
  sso,
  phoneCode,
  companyId,
  audience
}) =>
  POST({
    url: 'register',
    data: {
      email: email?.trim(),
      email_confirm: email_confirm?.trim(),
      firstName: firstName?.trim(),
      lastName: lastName?.trim(),
      phone,
      auth,
      sso,
      phoneCode,
      companyId,
      audience
    }
  }).then((result) => {
    const loggedInUser = {
      ...(result?.user || {}),
      accessToken: convertToken(result?.user?.accessToken)
    };

    SET_CACHED_USER(loggedInUser);
    return loggedInUser;
  });

// CORE login/logout methods
export const LOGIN_INIT = ({ email, auth, sso }) =>
  POST({ url: 'login/init', data: { email: email?.trim(), auth, sso } });
export const LOGIN_SSO = ({ sso }) => POST({ url: 'login/sso', data: { sso } });
export const RESEND_2FA = ({ token, verifyType, orgId }) =>
  POST({ url: 'login/resendCode', token, data: { verifyType, companyId: orgId } });
export const LOGIN_COMPLETE = ({ token, challenge }) =>
  POST({ url: 'login/complete', data: { challenge }, token }).then((result) => {
    const loggedInUser = {
      ...(result?.user || {}),
      accessToken: convertToken(result?.user?.accessToken)
    };

    SET_CACHED_USER(loggedInUser);
    return loggedInUser;
  });
export const FORGOT_PASSWORD = ({ email, locationId, orgId, audience }) =>
  POST({
    url: 'login/forgotPassword',
    data: { email: email?.trim(), locationId, companyId: orgId, audience }
  });
export const RESET_PASSWORD = ({ auth, resetCode }) =>
  POST({ url: 'login/resetPassword', data: { auth, resetCode } });
export const LOGOUT = ({ userContext }) =>
  POST({ url: 'logout', token: userContext.accessToken.token })
    .then(() => SET_CACHED_USER(''))
    .catch((err) => SET_CACHED_USER(''));
export const UPDATE_USER = ({ userContext, firstName, lastName, phone, email }) =>
  POST({
    url: 'user',
    data: { firstName, lastName, phone, email },
    token: userContext?.accessToken?.token
  }).then((result) => {
    const loggedInUser = { ...userContext, ...(result?.user || {}) };
    SET_CACHED_USER(loggedInUser);
    return loggedInUser;
  });

//---------------- the access token is time scoped, so a timer should be run to alert the user to reauth before it expires.
//call before the token expires to prevent failed api calls
export const REFRESH_TOKEN = ({ userContext }) => {
  return POST({ url: 'token/refresh', token: userContext.accessToken.token });
};

export const SET_CACHED_USER = (user) => {
  if (!user) {
    localStorage.removeItem('userContext');
    return;
  }
  localStorage.setItem('userContext', JSON.stringify(user));
};
const getTokenFromLocalStorage = () => {
  const result = localStorage.getItem('userContext');
  try {
    let parsedResult = JSON.parse(result);
    parsedResult = {
      ...parsedResult,
      accessToken: convertToken(parsedResult?.accessToken)
    };
    return parsedResult;
  } catch (err) {
    console.error(err);
  }
  return null;
};
export const GET_CACHED_USER = () => {
  try {
    const parsedResult = getTokenFromLocalStorage();
    if (VALID_USER_SESSION(parsedResult?.accessToken)) {
      return REFRESH_TOKEN({ userContext: parsedResult }).then((response) => {
        if (response.token) {
          parsedResult.accessToken = convertToken(response.token);
          SET_CACHED_USER(parsedResult);
        } else {
          SET_CACHED_USER(undefined);
        }
        return parsedResult;
      });
    }
  } catch (err) {
    console.error(err);
  }
  return Promise.resolve(undefined);
};

export const VALID_USER_SESSION = (token) => {
  return (
    !!token?.token &&
    !!token?.expiration?.getTime &&
    token.expiration.getTime() > new Date().getTime()
  );
};

export const TOKEN_HORIZON = () => {
  try {
    const parsedResult = getTokenFromLocalStorage();
    if (
      !parsedResult?.accessToken?.expiration?.getTime ||
      parsedResult.accessToken.expiration.getTime() < new Date()
    ) {
      return { expired: true };
    }
    return { expiresAt: parsedResult.accessToken.expiration };
  } catch (err) {
    console.error(err);
  }
};
