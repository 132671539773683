import React, { useContext, useState } from 'react';
import useAudience from 'hooks/useAudience';
import { AUDIENCES } from 'helpers/enums';
import styled from 'styled-components';
import { CompanyContext } from '../state';
import Icon from 'components/icon';
import { useTranslation } from 'react-i18next';
import LocaleContext from 'LocaleContext';
import i18n from 'i18n';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(isMobile) => (isMobile ? 'center' : '')};
  gap: 10px;
  color: #007cff;
  cursor: pointer;
  i {
    margin-bottom: 3px;
  }
  p {
    font-weight: bold;
  }
  @media (max-width: 740px) {
    margin-right: 0px;
    position: relative;
    height: 30px;
    z-index: 1 !important;
  }
`;
export default ({ rightAdjust = 115, isMobile }) => {
  const [audience] = useAudience();
  const { companyContext } = React.useContext(CompanyContext);
  const { t } = useTranslation();
  const { locale } = useContext(LocaleContext);
  const [handleLanguage, setHandleLanguage] = useState(true);
  function changeLocale(l) {
    const language = l ? 'en' : 'es';
    if (locale !== language) {
      i18n.changeLanguage(language);
    }
  }

  if (!companyContext?.enableSpanish) {
    return <></>;
  }

  // we don't support spanish on the approver portal yet...
  if (audience?.uuid === AUDIENCES.approver.uuid) {
    return <></>;
  }

  return (
    <Wrapper
      rightAdjust={rightAdjust}
      isMobile={isMobile}
      onClick={() => {
        setHandleLanguage(!handleLanguage);
        changeLocale(handleLanguage);
      }}>
      <Icon name="fa-duotone fa-language" paths={2} size={'1.56em'} />
      <p> {t('language')}</p>
    </Wrapper>
  );
};
