import React from 'react';
import styled from 'styled-components';
import InputBlock from './input';
import TextBlock from './text';
import Checkbox from './checkbox';
import Dropdown from './dropdown';
import Signature from './signature';
import Initials from './initials';
import { useDocumentContext } from 'e-signature/state';
import Shape from './shape';
import Image from './image';
import RichText from './richtext';
import SimpleText from './simple-text';
import DateBlock from './date';
import MergeField from './merge-field';
import Video from './video';
import Phone from './phone';
import Icon from 'components/icon';

const Wrapper = styled.div`
  background-color: transparent;
  position: absolute;
  font-family: monospace;
  box-sizing: border-box;
  &.blockWrapper-date {
    .block-content {
      overflow: visible;
    }
  }
  &.blockHighlighted {
    outline: 2px solid #000;
  }
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Required = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  font-size: 12px;
`;

export const BLOCKS = {
  simpleText: {
    label: 'Simple Text',
    type: 'simpleText',
    icon: 'fa-kit fa-esign-simple-text',
    component: SimpleText,
    rect: { width: 300, height: 60, top: 0, left: 0 },
    category: 'content'
  },
  signature: {
    label: 'Signature',
    type: 'signature',
    icon: 'fa-solid fa-signature',
    component: Signature,
    rect: { width: 200, height: 80, top: 0, left: 0 },
    category: 'fillable',
    backgroundColor: '#ffeb37'
  },
  initials: {
    label: 'Initials',
    type: 'initials',
    icon: 'fa-duotone fa-feather',
    component: Initials,
    rect: { width: 200, height: 80, top: 0, left: 0 },
    category: 'fillable',
    backgroundColor: '#ffeb37'
  },
  input: {
    label: 'Single Line Text',
    type: 'input',
    icon: 'fa-solid fa-input-text',
    component: InputBlock,
    rect: { width: 200, height: 32, top: 0, left: 0 },
    category: 'fillable',
    backgroundColor: '#c6ffce'
  },
  checkbox: {
    label: 'Checkbox',
    type: 'checkbox',
    icon: 'fa-regular fa-square-check',
    component: Checkbox,
    rect: { top: 0, left: 0, width: 30, height: 30 },
    category: 'fillable',
    backgroundColor: '#F9DEC9'
  },
  text: {
    label: 'Long Text',
    type: 'text',
    icon: 'fa-solid fa-text',
    component: TextBlock,
    rect: { width: 200, height: 100, top: 0, left: 0 },
    category: 'fillable',
    backgroundColor: '#FFCF96'
  },
  dropdown: {
    label: 'Dropdown',
    type: 'dropdown',
    icon: 'fa-duotone fa-list-dropdown',
    component: Dropdown,
    rect: { width: 200, height: 32, left: 0, top: 0 },
    category: 'fillable',
    backgroundColor: '#9EDDFF'
  },
  shape: {
    label: 'Rectangle',
    type: 'shape',
    icon: 'fa-solid fa-rectangle',
    component: Shape,
    rect: { width: 200, height: 100, left: 0, top: 0 },
    backgroundColor: '#00B2FF',
    borderRadius: 0,
    borderWidth: 0,
    borderColor: '#000',
    category: 'content'
  },
  image: {
    label: 'Image',
    type: 'image',
    icon: 'fa-solid fa-image',
    component: Image,
    rect: { width: 300, height: 300, left: 0, top: 0 },
    objectFit: 'cover',
    category: 'content'
  },
  richtext: {
    label: 'Rich Text (Merge Fields)',
    type: 'richtext',
    icon: 'fa-kit fa-esig-rich-text',
    component: RichText,
    rect: { width: 300, height: 300, left: 0, top: 0 },
    category: 'content'
  },
  date: {
    label: 'Date',
    type: 'date',
    icon: 'fa-duotone fa-calendar',
    component: DateBlock,
    rect: { width: 200, height: 32, left: 0, top: 0 },
    category: 'fillable',
    backgroundColor: '#EBEF95'
  },
  mergeField: {
    label: 'Merge Field',
    type: 'mergeField',
    icon: 'fa-duotone fa-merge',
    component: MergeField,
    rect: { width: 200, height: 32, left: 0, top: 0 },
    category: 'content',
    backgroundColor: '#9EB8D9',
    fontFamily: 'sans-serif'
  },
  video: {
    label: 'Video',
    type: 'video',
    icon: 'fa-duotone fa-video',
    component: Video,
    rect: { width: 300, height: 300, left: 0, top: 0 },
    category: 'content',
    backgroundColor: '',
    fontFamily: 'sans-serif'
  },
  phone: {
    label: 'Phone number',
    type: 'phone',
    icon: 'fa-duotone fa-phone',
    component: Phone,
    rect: { width: 200, height: 32, top: 0, left: 0 },
    category: 'fillable',
    backgroundColor: '#c6ffce',
    fieldMappingTypes: ['tel']
  }
};

export default ({
  isActive,
  updateBlock,
  block,
  page,
  users = [],
  focusedBlock,
  setFocusedBlock
}) => {
  const { user } = useDocumentContext();
  const Component = BLOCKS[block.type]?.component;
  const owner = users.find((u) => u.uuid === block.ownerUuid);
  const mode =
    user.requireSignature && !user.hasSigned && block.ownerUuid === user.uuid ? 'edit' : 'view';

  const className = `block block_${block.uuid} block-${mode} ${
    isActive ? 'active' : 'inactive'
  } blockWrapper-${block.type} ${
    focusedBlock === `${block.uuid}_${page.uuid}` ? 'blockHighlighted' : ''
  }`;

  const blockStyle = {
    width: block.rect.width,
    height: block.rect.height,
    top: block.rect.top,
    left: block.rect.left,
    fontSize: block.fontSize || 14
  };

  if (block.fontFamily) {
    blockStyle.fontFamily = block.fontFamily;
  }

  if (!Component) return 'missing block';

  return (
    <Wrapper
      onClick={() => {
        if (block.category !== 'fillable') return;
        setFocusedBlock?.(`${block.uuid}_${page.uuid}`);
      }}
      id={`${block.uuid}_${page.uuid}`}
      style={blockStyle}
      className={className}>
      <Content className="block-content">
        <Component block={block} mode={mode} updateBlock={updateBlock} />
      </Content>
      {mode === 'edit' && (['signature', 'initials'].includes(block.type) || block.required) && (
        <Required title="Field required">
          <Icon name="fa-solid fa-asterisk" />
        </Required>
      )}
    </Wrapper>
  );
};
