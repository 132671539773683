import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: none repeat scroll 0 0 #fff;
  z-index: 1000001;
  position: fixed;
  left: 0;
  top: 0;
  opacity: 0.5;
`;
const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  opacity: 1;
  z-index: 1000002;
`;

export default ({ className, color, ...props }) => {
  return (
    <>
      <Wrapper className={className}>
        <SpinnerWrapper>
          <CircularProgress style={{ color }} {...props} />
        </SpinnerWrapper>
      </Wrapper>
    </>
  );
};
