import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  &.empty {
    background-color: #eee;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  border: none;
`;

export default ({ block }) => {
  const style = {
    objectFit: block.objectFit || 'initial',
    borderRadius: block.borderRadius || '0'
  };
  return (
    <Wrapper className={!block.value ? 'empty' : ''} radius={block.borderRadius}>
      {block.value ? (
        <Image style={style} src={block.value} />
      ) : (
        <i className="fa-solid fa-image" />
      )}
    </Wrapper>
  );
};
