export const DOG_BREEDS = [
  {
    value:
      'Pit bull (American Pit Bull Terrier, American Staffordshire Terrier, Staffordshire Terrier, and American Bulldog)',
    label:
      'Pit bull (American Pit Bull Terrier, American Staffordshire Terrier, Staffordshire Terrier, and American Bulldog)'
  },
  { value: 'German Shepherd', label: 'German Shepherd' },
  { value: 'Akita', label: 'Akita' },
  { value: 'Rottweiler', label: 'Rottweiler' },
  { value: 'Boxer', label: 'Boxer' },
  { value: 'Doberman Pinscher', label: 'Doberman Pinscher' },
  { value: 'Mastiff Breed', label: 'Mastiff Breed' },
  { value: 'Malamute', label: 'Malamute' },
  { value: 'Cane Corso', label: 'Cane Corso' },
  { value: 'Husky', label: 'Husky' },
  { value: 'Chow Chow', label: 'Chow Chow' },
  { value: 'Wolf Hybrid', label: 'Wolf Hybrid' },
  { value: 'Great Danes', label: 'Great Danes' },
  { value: 'other', label: 'Other [Specify]' }
];
