import React from 'react';
import styled from 'styled-components';
import Modal, { ModalHeader, ModalScrollable, ModalTitle } from 'components/modal';
import Input from 'components/input';
import FormGroup, { RowGroup } from 'components/form-group';
import { SignatureCanvas } from 'components/signature-pad';
import Button from 'components/button';
import { darken } from 'polished';
import { useFormik } from 'formik';
import { useDocumentContext } from './state';
import * as yup from 'yup';
import { textToImage } from 'helpers/textToImage';

const validationSchema = yup.object().shape({
  fullName: yup.string().max(50, 'Name too long (Max 50 characters)').required('Name is required'),
  initials: yup
    .string()
    .max(5, 'Initials too long (Max 5 characters)')
    .required('Initials are required'),
  signature: yup.string().required('Adopt your signature')
});

const StyledInput = styled(Input)`
  background-color: #fff;
  border: 2px solid #ddd;
  &:focus {
    border-color: #f16261;
  }
  &.initials {
    text-transform: uppercase;
  }
`;

const CanvasWrapper = styled.div`
  display: flex;
  height: 300px;
  border: 2px solid #ddd;
  border-radius: 6px;
  margin-bottom: 10px;
`;

const Disclaimer = styled.p`
  font-size: 13px;
  color: #777;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px;
  gap: 10px;
  button {
    padding: 8px 16px;
    border-radius: 4px;
    color: #fff;
    &.secondary-action {
      background-color: #b3bdc5;
      &:hover {
        background-color: ${darken(0.15, '#b3bdc5')};
      }
    }
    &.primary-action {
      background-color: #f16261;
      &:hover {
        background-color: ${darken(0.15, '#f16261')};
      }
    }
  }
`;

export default ({ onSave, onCancel }) => {
  const pad = React.useRef(null);
  const { ui, user, doc } = useDocumentContext();

  const onSubmit = (values) => {
    const padState = pad.current.getPadState();
    if (doc.builder === 'html') {
      const pageUuid = ui.currentPage.uuid;
      ui.signPage({ pageUuid: pageUuid, zoneKey: ui.showPad });
    }
    ui.updateUi({
      signature: {
        ...values,
        initials: values.initials.toUpperCase(),
        initialsImage: textToImage(values.initials.toUpperCase())
      },
      padState,
      showPad: false
    });
    onSave?.();
  };

  const [firstName = '', lastName = ''] = user.name?.split(' ') ?? [];
  const initials = `${firstName[0] ?? ''}${lastName[0] ?? ''}`;

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: {
        fullName: user.name || '',
        initials,
        signature: '',
        ...(ui.signature || {})
      },
      validationSchema,
      onSubmit,
      validateOnBlur: false,
      validateOnChange: false
    });

  const onConfirm = () => {
    const signature = pad.current.getImage();
    setFieldValue('signature', signature);
    setTimeout(() => {
      handleSubmit();
    }, 100);
  };

  const handleCancel = () => {
    if (onCancel) return onCancel();
    ui.updateUi({ showPad: false });
  };

  return (
    <Modal
      open
      height="auto"
      width="900px"
      onClose={onCancel ? onCancel : () => ui.updateUi({ showPad: false })}
      closeOnOutsideClick={false}>
      <ModalHeader>Confirm your name, initials, and signature.</ModalHeader>
      <ModalScrollable>
        <div style={{ fontFamily: 'Alex Brush', position: 'fixed', top: -200, left: -700 }}>.</div>
        <RowGroup columns="2fr 1fr">
          <FormGroup label="Full Name*" errorText={touched.fullName && errors.fullName}>
            <StyledInput
              name="fullName"
              value={values.fullName}
              onChange={handleChange}
              onBlur={handleBlur}
              autoFocus
            />
          </FormGroup>
          <FormGroup label="Initials*" errorText={touched.initials && errors.initials}>
            <StyledInput
              className="initials"
              name="initials"
              value={values.initials}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
        </RowGroup>
        <FormGroup label="Signature*" errorText={errors.signature}>
          <CanvasWrapper>
            <SignatureCanvas ref={pad} initialState={ui.padState} />
          </CanvasWrapper>
        </FormGroup>
        <Disclaimer>
          By selecting Adopt and Sign, I agree that the signature and initials will be the
          electronic representation of my signature and initials for all purposes when I (or my
          agent) use them on documents, including legally binding contracts - just the same as a
          pen-and-paper signature or initial.
        </Disclaimer>
      </ModalScrollable>
      <Footer>
        <Button className="secondary-action" onClick={handleCancel}>
          CANCEL
        </Button>
        <Button className="primary-action" onClick={onConfirm}>
          ADOPT AND SIGN
        </Button>
      </Footer>
    </Modal>
  );
};
