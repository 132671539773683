import React from 'react';
import styled from 'styled-components';
import { Row, Title, PageContainer, PageWrapper, Description } from '../common-styles';
import Button from '../../../components/button';
import VehicleEdition from './vehicle-edition';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useTranslation } from 'react-i18next';
import CarList from './car-list';
import { CompanyContext } from 'state';

const OptionsWrapper = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  flex-shrink: 0;
  align-items: flex-start;
  .Option_label {
    font-size: 0.94em;
    text-transform: capitalize;
    color: var(--color-primary);
  }
`;

const OPTIONS = (t) => {
  return [
    {
      value: 'car',
      label: `${t('vehicleStep.type.car')}`,
      icon: {
        name: 'fa-duotone fa-car'
      }
    },
    {
      value: 'trailer',
      label: `${t('vehicleStep.type.trailer')}`,
      icon: {
        name: 'fa-duotone fa-caravan-simple'
      }
    },
    {
      value: 'motorcycle',
      label: `${t('vehicleStep.type.motorcycle')}`,
      icon: {
        name: 'fa-duotone fa-motorcycle'
      }
    },
    {
      value: 'other',
      label: `${t('vehicleStep.type.other')}`,
      icon: {
        name: 'fa-duotone fa-moped'
      }
    }
  ];
};
export default ({ location, application, saveApplicationPart, onNext, onBack }) => {
  const { companyContext } = React.useContext(CompanyContext);
  const country = companyContext?.primaryCountry || 'US';
  const [vehicleEdit, setVehicleEdit] = React.useState();
  const otherVehicles =
    application?.relatedAppData?.vehicles?.map((r) => ({ ...r, readOnly: true })) || [];
  const [vehicles, setVehicles] = React.useState(() => application.vehicles || []);

  const dialog = useConfirmDialog();
  const { t } = useTranslation();

  const vehicleOptions = OPTIONS(t);

  const handleAdd = (vehicle) => {
    const isEdit = vehicles.some((v) => v.uuid === vehicle.uuid);
    setVehicles(
      isEdit
        ? vehicles.map((v) => {
            if (v.uuid !== vehicle.uuid) return v;
            return vehicle;
          })
        : [...vehicles, vehicle]
    );
  };
  const handleRemove = ({ uuid, make, model }) => {
    dialog({
      title: `Are you sure you want to remove ${make} ${model} `,
      onConfirm: () => {
        setVehicles(vehicles.filter((p) => p.uuid !== uuid));
      }
    });
  };

  const handleNext = () => {
    saveApplicationPart({ vehicles }, false).then(() => {
      onNext();
    });
  };

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('vehicleStep.title')} </Title>
        </Row>
        {location?.applicationConfig?.parkingBlurb && (
          <Description
            dangerouslySetInnerHTML={{
              __html: location.applicationConfig.parkingBlurb
            }}
          />
        )}
        <Row>
          <CarList
            title={t('vehicleStep.my_vehicles')}
            onAdd={() => setVehicleEdit({ type: 'car' })}
            items={vehicles}
            otherVehicles={otherVehicles}
            onEdit={setVehicleEdit}
            onRemove={handleRemove}
            country={country}
            properties={vehicleOptions.reduce(
              (final, item) => ({ ...final, [item.value]: item }),
              {}
            )}
          />
        </Row>
        <Row className="center">
          <p>{t('addLater')}.</p>
        </Row>
        <Row className="center flex gap-10">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.cancel')}
          </Button>
          <Button radius="25px" variant="primary" width={90} onClick={handleNext}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
      <VehicleEdition
        open={!!vehicleEdit}
        vehicle={vehicleEdit}
        onSave={handleAdd}
        onClose={() => setVehicleEdit(undefined)}
        vehicleOptions={vehicleOptions}
        country={country}
        t={t}
      />
    </PageWrapper>
  );
};
