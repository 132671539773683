import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import { borderLight, primary } from 'styles/colors';
import LastComment from './lastComment';

const NotesCol = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  > div {
    min-height: 25px;
  }
`;

const QuestionsWrapper = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  gap: 10px;
  > div {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
  }
  select {
    font-weight: normal;
    height: 25px;
  }
`;

const CheckWrapper = styled.div`
  background-color: #fff;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #eee;
  &.bordered {
    border: 2px solid ${borderLight};
  }
  &.checkbox-editable {
    cursor: pointer;
    &:hover {
      border: 2px solid ${({ theme }) => theme?.colors?.primary || primary};
    }
  }
  i {
    color: ${({ theme }) => theme?.colors?.primary || primary};
  }
`;

export const CustomQuestionNotes = ({ applicant, condition }) => {
  const customValues = applicant.qualityScore?.customQuestions || {};
  const customQuestions =
    applicant.qualityProfile?.conditions?.find((cond) => cond.ruleUuid === 'custom')?.rules || [];

  return (
    <QuestionsWrapper>
      {customQuestions?.map((cq) => {
        const value = customValues[cq.uuid]?.value;
        if (cq.conditionType === 'check') {
          return (
            <div>
              <CheckWrapper className={'checkbox-editable'}>
                {value && <Icon name="Save" size={13} />}
              </CheckWrapper>
              {cq.label}
            </div>
          );
        }
        if (cq.conditionType === 'dropdown') {
          return (
            <div>
              {cq.label}:{' '}
              {(value ? cq.options?.find((opt) => opt.uuid === value)?.label : '') || 'N/A'}
            </div>
          );
        }
        return <></>;
      })}
      <NoteLists condition={condition} applicant={applicant} showAddComment={true} />
    </QuestionsWrapper>
  );
};

export const NoteLists = ({ condition, applicant, showAddComment }) => {
  if (!(applicant.notes?.[condition.ruleUuid] || [])?.length) return <></>;
  return (
    <NotesCol>
      <LastComment
        showAddComment={showAddComment}
        comments={applicant.notes?.[condition.ruleUuid] || []}
      />
    </NotesCol>
  );
};
