import React from 'react';
import styled from 'styled-components';
import { primary } from 'styles/colors';
import { darken, cssVar } from 'polished';
import Button from 'components/button';
import Icon from 'components/icon';
import RichText from 'components/rich-text-editor';
import ChatRecord from 'pages/approver/components/chat-record';
import { APPLICATION_STATUS } from 'pages/approver/share/detail/application-view/helpers';

const FeedbackWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column;
  gap: 5px;
  > strong {
    font-size: 18px;
  }
  > div {
    padding: 20px;
    border: solid 1px #0091ff;
    background-color: #eef7ff;
    border-top: solid 3px #0091ff;
  }
`;
const ActionRow = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  .response {
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    > strong {
      display: flex;
      align-items: center;
      gap: 5px;
    }
    &:hover {
      color: ${darken(0.4, cssVar('--color-link', '#007cff'))};
    }
  }
  > button {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    min-width: 350px;
    padding: 7px 15px;
    color: #fff;
    margin: 0;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 25px;
    text-align: left;
    &.action-bordered {
      border: 1px solid ${({ theme }) => theme?.colors?.primary || primary};
      color: ${({ theme }) => theme?.colors?.primary || primary};
      height: 35px;
      &:hover {
        background-color: #fafafa;
      }
    }
    &.approve {
      background-color: ${APPLICATION_STATUS.approve.color};
    }
    &.conditional {
      background-color: ${APPLICATION_STATUS.conditional.color};
    }
    &.decline {
      background-color: ${APPLICATION_STATUS.decline.color};
    }
  }
`;
const Spacer = styled.hr`
  margin-top: 20px;
  margin-bottom: 20px;
  color: #ccc;
`;
const ResponseRow = styled.div`
  display: flex;
  gap: 5px;
  > div {
    flex: 1;
  }
  .public-DraftEditor-content {
    width: 100%;
    min-height: 100px !important;
    height: 100px !important;
    max-height: 100px !important;
  }
  .sendButton {
    flex: 0;
    &:hover {
      color: ${darken(0.4, cssVar('--color-link', '#007cff'))};
    }
  }
`;
const CommentWrapper = styled.div`
  display: flex;
  flex-flow: column;
  max-height: 350px;
  overflow-y: auto;
`;

const ApprovalFlow = ({ shareDetails, handleActions, applicationConfig }) => {
  const [processing, setProcessing] = React.useState(false);
  const [showButtons, setShowButtons] = React.useState(!shareDetails.shareDetails?.status);

  if (applicationConfig?.disabledSections?.approvalFlow) return <></>;

  if (!showButtons) {
    return (
      <ActionRow>
        <div className="response" onClick={() => setShowButtons(true)}>
          Your response:
          {shareDetails.shareDetails?.status?.status === 'approved' ? (
            <strong>
              <Icon
                name={APPLICATION_STATUS.approve.iconProps.name}
                color={APPLICATION_STATUS.approve.color}
              />
              Approved
            </strong>
          ) : (
            <strong>
              <Icon
                name={APPLICATION_STATUS.decline.iconProps.name}
                color={APPLICATION_STATUS.decline.color}
              />
              Denied
            </strong>
          )}
        </div>
      </ActionRow>
    );
  }

  const setStatus = (status) => {
    if (processing) return;
    setProcessing(true);
    handleActions({
      id: 'share-set-status',
      data: { shareId: shareDetails.shareId, status }
    }).then(() => {
      setProcessing(false);
      setShowButtons(false);
    });
  };

  return (
    <ActionRow>
      <Button className={`approve`} onClick={() => setStatus('approved')}>
        <Icon name={APPLICATION_STATUS.approve.iconProps.name} />
        <span>Approve</span>
      </Button>
      <Button className={`decline`} onClick={() => setStatus('declined')}>
        <Icon name={APPLICATION_STATUS.decline.iconProps.name} />
        <span>Deny</span>
      </Button>
    </ActionRow>
  );
};

export default ({ shareDetails, handleActions, applicationConfig }) => {
  const messagesWrapperRef = React.useRef(null);
  const [comment, setComment] = React.useState('');
  const [processing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    messagesWrapperRef.current.scrollTop = messagesWrapperRef.current?.scrollHeight;
    //    bottomRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [shareDetails.shareDetails.comments.length]);

  return (
    <FeedbackWrapper>
      <strong>YOUR FEEDBACK</strong>
      <div>
        <ApprovalFlow
          handleActions={handleActions}
          shareDetails={shareDetails}
          applicationConfig={applicationConfig}
        />

        <CommentWrapper ref={messagesWrapperRef}>
          <ChatRecord
            createdAt={shareDetails?.createdAt}
            content={shareDetails.shareDetails?.message}
            {...shareDetails.shareDetails}
          />
          {shareDetails.shareDetails.comments?.map((comment, ix) => (
            <ChatRecord key={ix} {...comment} />
          ))}
        </CommentWrapper>
        <Spacer />
        <ResponseRow>
          <RichText
            placeholder={'Leave a comment...'}
            value={comment}
            resetToken={!processing}
            onChange={(e) => {
              setComment(e.target.value);
            }}
          />
          <Icon
            clickable={true}
            name="fa-solid fa-paper-plane sendButton"
            onClick={() => {
              if (processing) return;
              setProcessing(true);

              handleActions({
                id: 'share-add-comment',
                data: { shareId: shareDetails.shareId, comment: comment.trim() }
              }).then((result) => {
                if (result.success) setComment('');
                setProcessing(false);
              });
            }}
          />
        </ResponseRow>
      </div>
    </FeedbackWrapper>
  );
};
