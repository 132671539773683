import React from 'react';
import styled from 'styled-components';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { VALIDATE_PHONE, RESEND_2FA } from 'dal/user';
import { useOrgId } from 'hooks/useSessionVariables';
import Modal2FA from './modal-2fa';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;
`;

const ActionLink = styled.div`
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  text-align: center;
  color: var(--color-link);
  cursor: pointer;
  font-weight: bold;
`;

const setResubmitDate = () => {
  let d = new Date();
  d.setSeconds(d.getSeconds() + 5);
  return d;
};

export default ({
  email,
  phone,
  userContext,
  type,
  onSuccess,
  verifyType = 'sms',
  setVerifyType
}) => {
  const orgId = useOrgId();
  const [resendTime, setResendTime] = React.useState();
  const [verifyTypeModal, setVerifyTypeModal] = React.useState(false);
  const { t } = useTranslation();

  const Resend = ({ verifyType: providedType }) => {
    if (resendTime?.getTime && resendTime?.getTime() > new Date().getTime()) {
      toast.error('Please allow 5 seconds to elapse before resending codes.');
      return;
    }
    setResendTime(setResubmitDate());

    if (type === 'validation') {
      return VALIDATE_PHONE({ email, phone, verifyType: providedType || verifyType, orgId })
        .then(() => {
          toast.success('Code sent');
        })
        .catch((err) => {
          console.error({ errData: err.data, err });
          toast.error(err.data?.message ? err.data?.message : err.message || err.name);
        });
    }

    return RESEND_2FA({
      token: userContext?.challenge?.token,
      verifyType: providedType || verifyType,
      orgId
    })
      .then((response) => {
        onSuccess && onSuccess({ ...userContext, ...response });
        toast.success('Code has been resent');
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error('Failed to resend 2FA. Please try logging in again.');
      });
  };

  return (
    <Wrapper>
      {verifyTypeModal && (
        <Modal2FA
          userContext={userContext}
          onClose={() => setVerifyTypeModal(false)}
          onSelect={(type) => {
            setVerifyType(type);
            setVerifyTypeModal(false);
            Resend({ verifyType: type });
          }}
        />
      )}
      <ActionLink onClick={Resend}>{t('phoneVerefication.resend-code')}</ActionLink>
      <ActionLink onClick={() => setVerifyTypeModal(true)}>
        {t('components.2fa.actionLink')}
      </ActionLink>
    </Wrapper>
  );
};
