import styled from 'styled-components';

const Wrapper = styled.div`
  flex-shrink: 0;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  font-size: ${({ size }) => Math.round(size / 2.2)}px;
  border-radius: 50%;
  background-color: ${({ color }) => color || '#ddd'};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ onClick }) => (onClick ? 'cursor:pointer;' : '')}
  ${({ imageUrl, defaultImageUrl }) =>
    imageUrl
      ? `background: url(${imageUrl})${
          defaultImageUrl ? `, url(${defaultImageUrl})` : ''
        }; background-size: cover;`
      : ''}
`;

export default ({
  size = 35,
  className = '',
  firstName = '',
  lastName = '',
  imageUrl,
  duogram,
  onClick,
  color,
  defaultImageUrl
}) => {
  const dg = duogram || `${(firstName[0] || '').toUpperCase()}${(lastName[0] || '').toUpperCase()}`;
  return (
    <Wrapper
      className={`${className} avatar`}
      size={size}
      imageUrl={imageUrl}
      defaultImageUrl={defaultImageUrl}
      onClick={onClick}
      color={color}>
      {!imageUrl && dg}
    </Wrapper>
  );
};
