import React from 'react';
import styled from 'styled-components';

const HtmlRender = ({ html }) => {
  const previewRef = React.useRef(null);
  const shadow = React.useRef();

  React.useEffect(() => {
    if (!shadow.current) {
      shadow.current = previewRef.current.attachShadow({ mode: 'open' });
    }
    shadow.current.innerHTML = html;
  }, [html]);

  return <div ref={previewRef} className="preview-html" />;
};

export default HtmlRender;
