import React from 'react';
import { useFormik } from 'formik';

import { useAPIData } from 'dal/useAPIData';
import { displayLocationInfo, isValidLat, isValidLong } from 'helpers/location';
import { defaultFilters } from './filters/constants';

// const peakOrg = 'xWztkTDJQN2pdMQds';
// const riseOrg = 'LWfuv6HK4SSpAnS3Y';

const MAX_ITEMS = 50; // had to put a limit because my local pulls in 700+ and crashes the browser. In general anything over 50 markers seems to suck
const AUDIENCE = 'applicant';

const useSearchLogic = ({ orgId, mapReady: initialMapReady = false } = {}) => {
  const [mapReady, setMapReady] = React.useState(initialMapReady);
  const [visibleMapBounds, setVisibleMapBounds] = React.useState();
  const [visibleLocations, setVisibleLocations] = React.useState();
  const [filters, setFilters] = React.useState(defaultFilters);
  const { values, setFieldValue, setValues } = useFormik({
    initialValues: {
      sort: { col: 'availability', dir: 1 },
      pageNumber: 0,
      pageLength: MAX_ITEMS
    }
  });

  // on filter changes set the page to 0 and trigger a refresh. we don't trigger directly off of filters to prevent duplicate hits.
  React.useEffect(() => {
    setValues({ ...values, pageNumber: 0, triggerRefresh: new Date().getTime() });
  }, [filters]);

  const [orgInfo, orgInProgress, orgDataFetched, orgFailedFetch] = useAPIData(
    'context.LOAD_ORG_CONTEXT',
    !orgId ? undefined : { contextId: orgId },
    {
      reFetchWhen: [orgId],
      disableEmptyArgs: true,
      onSuccess: (data) => {
        //set initial start
        if (data?.aptlyListings?.mapBounds?.bounds?.east) {
          setVisibleMapBounds(data?.aptlyListings?.mapBounds?.bounds);
        }
      }
    }
  );
  const orgFetched = orgDataFetched || orgFailedFetch;
  const searchTerm =
    filters.autocomplete?.type !== 'googleSuggestion' ? filters?.autocomplete?.label || '' : '';

  const bounds = visibleMapBounds?.east
    ? [
        visibleMapBounds?.north,
        visibleMapBounds?.east,
        visibleMapBounds?.south,
        visibleMapBounds?.west
      ]
    : undefined;

  //don't load until after the map & org are ready as it could result in redundant hits
  const payload =
    !orgFetched || !mapReady
      ? undefined
      : {
          orgId: orgId,
          filters: {
            search: searchTerm,
            audience: AUDIENCE,
            minBed: filters.beds,
            //  maxBed: '',
            minBath: filters.baths,
            // maxBath: '',
            petsAllowed: filters.petsAllowed,
            rent: `${filters.price?.min || ''}-${filters.price?.max || ''}`,
            amenities: filters.amenities,
            homeType: filters.homeType,
            moveInDate: filters.moveInDate,
            // if set the search will only return items in the bounds
            geoBounds: !searchTerm ? bounds : undefined,
            // geopoint: filters.geopoint,
            // geozoom: filters.geozoom,
            page: values.pageNumber,
            size: values.pageLength,
            sortCol: values.sort?.col,
            sortDir: values.sort?.dir
          }
        };
  const refetchKey = searchTerm ? 'search' : (bounds || []).join('.');

  const [apiData, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_LISTINGS',
    payload,
    {
      disableEmptyArgs: true,
      wait: 500,
      reFetchWhen: [values, refetchKey, orgFetched, mapReady],
      onSuccess: (val) => {
        const mappedLocations = (val?.data || [])
          .map((l) => displayLocationInfo(l))
          .map((a) => {
            if (!a?.address?.geopoint?.[0]) return a;

            //ensure valid geo coordinate
            if (!isValidLong(a?.address?.geopoint?.[0]) || !isValidLat(a?.address?.geopoint?.[1])) {
              delete a.address.geopoint;
              return a;
            }
            return a;
          });
        setVisibleLocations(mappedLocations);
      }
    }
  );

  return {
    filters,
    setFilters,
    setMapReady,
    pageNumber: values.pageNumber,
    pageLength: values.pageLength,
    sort: values.sort,
    setPageNumber: (v) => setFieldValue('pageNumber', v),
    setPageLength: (v) => setFieldValue('pageLength', v),
    setSort: (v) => setFieldValue('sort', v),
    locations: visibleLocations || [],
    locationCount: apiData?.total || 0,
    hasFetched: (dataFetched || failedFetch) && (orgDataFetched || orgFailedFetch),
    orgFetched,
    locationsFetched: dataFetched || failedFetch,
    failedFetch: failedFetch,
    loading: fetchInProgress,
    listingsDisabled: !!orgInfo?.aptlyListings?.enabled,
    orgInfo: orgInfo,
    visibleMapBounds,
    onCameraChanged: (mapDetails) => {
      setVisibleMapBounds(mapDetails?.bounds);
    }
  };
};

export const useSearch = ({ orgId, ...params } = {}) => {
  if (!orgId) return { locations: [], loading: false };

  return useSearchLogic({ orgId, ...params });
};
