import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';
import { borderLight } from '../styles/colors';
import useMobile, { devicesMaxWidth } from '../hooks/useMobile';

const Close = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
`;

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ zIndex = 1200 }) => zIndex};
  &.only-overlay {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const Backdrop = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: ${({ backdropBgColor = 'rgba(0, 0, 0, 0.4)' }) => backdropBgColor};
`;

const ModalBody = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${({ color = '#ffffff' }) => color};
  width: ${({ width = '600px' }) => width};
  height: ${({ height = '400px' }) => height};
  max-height: ${({ maxHeight = '95%' }) => maxHeight};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}` : '')};
  border-radius: ${({ radius }) => radius}px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.23);
  z-index: 2;
  overflow: hidden;
  ${({ styles }) => (styles ? styles : '')}
  @media screen and (max-width: ${devicesMaxWidth.mobile}px) {
    max-height: 100%;
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    box-shadow: none;
  }
`;

export const ModalScrollable = styled.div`
  padding: ${({ padding = 20 }) => `${padding}px`};
  flex: 1;
  overflow-y: auto;
  &::-webkit-scrollbar-thumb {
    background-color: hsla(0, 0%, 0%, 0.35);
    background-clip: padding-box;
    border: 3px solid rgba(0, 0, 0, 0);
    border-radius: 6px;
    min-height: 36px;
  }
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: 20px;
  border-bottom: 1px solid ${borderLight};
  flex-grow: 0;
  flex-shrink: 0;
  &.no-border {
    border: none;
  }
  &.caps {
    text-transform: uppercase;
  }
`;

export const ModalFooter = styled.div`
  margin: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: ${({ justifyContent = 'flex-end' }) => justifyContent};
  padding: 10px 20px;
  flex-grow: 0;
  flex-shrink: 0;
  &.no-border {
    border: none;
  }
`;

export const ModalTitle = styled.h3`
  margin: 0px;
`;

const Modal = ({
  children,
  closeOnOutsideClick = true,
  onClose,
  hideClose,
  width,
  height,
  color,
  className,
  radius = 4,
  maxHeight,
  maxWidth,
  zIndex,
  backdropBgColor,
  anchorEl,
  anchorDirection = 'top',
  onlyOverlay,
  closeElement
}) => {
  const { isTabletOrMobile } = useMobile();

  const anchorStyle = React.useMemo(() => {
    if (!anchorEl || isTabletOrMobile) return '';
    const { left, top } = anchorEl.getBoundingClientRect();

    switch (anchorDirection) {
      case 'bottom':
        return `position: absolute;top: ${top - height.replace('px', '')}px;left: ${
          left - width.replace('px', '')
        }px;`;
      case 'topRight':
        return `position: absolute;top: ${top}px;left: ${left - width.replace('px', '')}px;`;
      case 'top':
      default:
        return `position: absolute;top: ${top}px;left: ${left}px;`;
    }
  }, [anchorEl]);

  const fontStyle = React.useMemo(() => {
    const textScale = Number(localStorage.getItem('textScale'));
    if (!textScale || !Number.isFinite(textScale)) return;
    return `font-size: ${textScale}em;`;
  }, []);

  return (
    <Wrapper zIndex={zIndex} className={onlyOverlay ? 'only-overlay' : ''}>
      <Backdrop onClick={closeOnOutsideClick ? onClose : null} backdropBgColor={backdropBgColor} />
      {onlyOverlay ? (
        children
      ) : (
        <ModalBody
          className={className}
          width={width}
          height={height}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          color={color}
          radius={radius}
          styles={[]
            .concat(anchorStyle)
            .concat(fontStyle)
            .filter((z) => z)
            .join(' ')}>
          {children}
          {onClose &&
            !hideClose &&
            (closeElement ? (
              closeElement
            ) : (
              <Close onClick={onClose}>
                <i className="fa-solid fa-xmark" />
              </Close>
            ))}
        </ModalBody>
      )}
    </Wrapper>
  );
};

export default ({ open, ...props }) => {
  if (!open) return null;
  return createPortal(<Modal {...props} />, document.body);
};
