import React from 'react';
import styled from 'styled-components';
import { useDocumentContext } from './state';
import { useMediaQuery } from 'react-responsive';
import Button from 'components/button';
import Icon from 'components/icon';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 260px;
  border-right: 1px solid #ddd;
  height: 100%;
  padding: 20px 0px;
  flex-shrink: 0;
  background-color: #fff;
  &.menuPages_floating {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
  padding: 0 15px;
  border-radius: 6px;
  cursor: pointer;
  margin-bottom: 5px;
  &:hover {
    background-color: #f5f5f5;
  }
  &.active {
    background-color: #f16261;
    color: #fff;
  }
`;

const Items = styled.div`
  gap: 5px;
  padding: 0 15px;
  flex: 1;
  overflow-y: auto;
`;

const Title = styled.div`
  font-weight: bold;
  padding: 0 30px;
  margin-bottom: 10px;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 99;
`;

const Close = styled(Button)`
  position: absolute;
  top: 5px;
  right: -40px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #ddd;
`;

const PaneOverlay = ({ enabled, children, onClick }) =>
  enabled ? <Overlay onClick={onClick}>{children}</Overlay> : children;

const MenuItem = ({ label, isActive, onClick }) => (
  <Item onClick={onClick} className={isActive ? 'active' : ''}>
    {label}
  </Item>
);

export default () => {
  const { doc, ui } = useDocumentContext();

  const floatingPane = useMediaQuery({ maxWidth: 1150 });
  React.useEffect(() => {
    ui.updateUi({
      floatingMenuPages: floatingPane ? true : false,
      showMenuPages: floatingPane ? false : true
    });
  }, [floatingPane]);

  if (!ui.showMenuPages) return null;

  return (
    <PaneOverlay
      enabled={ui.floatingMenuPages}
      onClick={() => ui.updateUi({ showMenuPages: false })}>
      <Wrapper
        className={floatingPane ? 'menuPages_floating' : ''}
        onClick={(event) => event.stopPropagation()}>
        <Title>PAGES</Title>
        <Items>
          {doc.pages.map((page, i) => (
            <MenuItem
              key={page.uuid}
              isActive={!ui.showSignatures && ui.currentPage?.uuid === page.uuid}
              label={doc.builder === 'pdf' ? `Page ${i + 1}` : page.name}
              onClick={() => {
                ui.setActivePage(page.uuid);
                ui.floatingMenuPages && ui.updateUi({ showMenuPages: false });
              }}
            />
          ))}
          <MenuItem
            label="Signatures"
            isActive={ui.showSignatures}
            onClick={() => {
              ui.updateUi({ showSignatures: true });
              ui.floatingMenuPages && ui.updateUi({ showMenuPages: false });
            }}
          />
        </Items>
        {ui.floatingMenuPages && (
          <Close onClick={() => ui.updateUi({ showMenuPages: false })}>
            <Icon name="fa-regular fa-xmark" />
          </Close>
        )}
      </Wrapper>
    </PaneOverlay>
  );
};
