import Members from 'pages/application-form-v2/members';
import Identity from 'pages/application-form-v2/identity';
import YourInfo from 'pages/application-form-v2/your-info';
import MoveInDate from 'pages/application-form-v2/move-in-date';
import HousingHistory from 'pages/application-form-v2/housing-history';
import Pets from 'pages/application-form-v2/pets';
import Vehicles from 'pages/application-form-v2/vehicles';
import BackgroundCheck from 'pages/application-form-v2/backgroud-check';
import Section8 from 'pages/application-form-v2/section-8';
import Consent from 'pages/application-form-v2/consent';
import OrgTerms from 'pages/application-form-v2/org-terms';
import AppFee from 'pages/application-form-v2/app-fee';
import AppFeeSuccess from 'pages/application-form-v2/app-fee-success';
import Income from 'pages/application-form-v2/income/income';
import IncomeSources from 'pages/application-form-v2/income-sources';
import IncomeDocuments from 'pages/application-form-v2/income-documents';
import Questions from 'pages/application-form-v2/questions';
import Success from 'pages/application-form-v2/success';
import EmergencyContact from 'pages/application-form-v2/emergency-contact';
import SkipIncome from 'pages/application-form-v2/skip-income-verification';
import { STEP_STATES } from 'helpers/enums';

//these are the states from the backend
const STEP_STATUS = { pending: 0, done: 1, skipped: -1 };
const isStepDone = (key, steps) =>
  [STEP_STATES.done, STEP_STATES.completed].includes(
    steps?.find((step) => step.key === key)?.status?.state || STEP_STATES.notStarted
  );
const defaultStatus = (t) => ({
  label: t('buttonsActions.add'),
  actionEnabled: true,
  state: STEP_STATES.notStarted
});

const applicationStepStatus = {
  identity: ({ appStep, t }) => {
    const status = { ...defaultStatus(t) };
    if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
      status.state = STEP_STATES.done;
      status.label = t('buttonsActions.done');
      status.actionEnabled = false;
    }
    return status;
  },
  ['org-terms']: ({ appStep, t, location }) => {
    const status = { ...defaultStatus(t) };
    if (!location?.applicationConfig?.applicationConditionsTemplateId) {
      status.label = t('buttonsActions.skipped');
      status.state = STEP_STATES.done;
      status.icon = 'fa-duotone fa-arrow-right-long-to-line';
      return status;
    }

    if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
      status.state = STEP_STATES.done;
      status.label = t('buttonsActions.done');
      status.actionEnabled = false;
    }
    return status;
  },
  appFee: ({ appStep, t }) => {
    const status = { ...defaultStatus(t), label: t('buttonsActions.pay') };
    if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
      status.label = t('buttonsActions.paid');
      status.state = STEP_STATES.done;
      status.actionEnabled = false;
    }
    return status;
  },
  backgroundCheck: ({ appStep, t }) => {
    const status = { ...defaultStatus(t) };
    if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
      status.label = t('buttonsActions.done');
      status.state = STEP_STATES.done;
      //   status.actionEnabled = false;
    }
    return status;
  },
  incomeVerification: ({ application, appStep, t }) => {
    const status = { ...defaultStatus(t) };
    if (
      !application?.verifastBankComplete &&
      ([STEP_STATUS.skipped].includes(appStep?.status) ||
        application?.autoIncomeSkipDetails?.skipped)
    ) {
      status.label = t('buttonsActions.skipped');
      status.state = STEP_STATES.done;
      status.icon = 'fa-duotone fa-arrow-right-long-to-line';
    } else if (
      application?.verifastBankComplete &&
      [STEP_STATUS.skipped, STEP_STATUS.done].includes(appStep?.status)
    ) {
      status.label = t('buttonsActions.done');
      status.state = STEP_STATES.done;
      status.actionEnabled = false;
    }
    return status;
  },
  income: ({ appStep, t }) => {
    const status = { ...defaultStatus(t) };
    if ([STEP_STATUS.skipped].includes(appStep?.status)) {
      status.label = t('buttonsActions.skipped');
      status.state = STEP_STATES.done;
      status.icon = 'fa-duotone fa-arrow-right-long-to-line';
    }

    if ([STEP_STATUS.done].includes(appStep?.status)) {
      status.label = t('buttonsActions.update');
      status.state = STEP_STATES.completed;
    }
    return status;
  },
  submit: ({ appStep, t }) => {
    const status = { ...defaultStatus(t) };
    if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
      status.state = STEP_STATES.done;
      status.label = t('buttonsActions.done');
      status.actionEnabled = false;
    }
    return status;
  }
};

const getApplicationStepStatus = ({ key, application, location, appStep, step, t }) => {
  const status = { ...defaultStatus(t) };
  if (applicationStepStatus[key]) {
    return applicationStepStatus[key]({ application, location, appStep, step, t });
  }

  if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
    status.label = t('buttonsActions.update');
    status.state = STEP_STATES.completed;
  }

  return status;
};

export default ({ translate: t, application, location, companyContext }) => {
  const steps = [
    {
      key: 'personalInfo',
      title: t('overviewStep.steps.personalInfo.title'),
      description: t('overviewStep.steps.personalInfo.description'),
      path: 'about-you',
      routes: [
        {
          path: 'about-you',
          Component: YourInfo
        }
      ]
    },
    {
      key: 'leaseInfo',
      title: t('overviewStep.steps.leaseInfo.title'),
      description:
        !location?.companyInfo?.requireEmergencyContact || ['guarantor'].includes(application?.type)
          ? t('overviewStep.steps.leaseInfo.description')
          : t('overviewStep.steps.leaseInfo.descriptionWithEC'),
      path: 'move-in',
      routes: [
        {
          path: 'move-in',
          Component: MoveInDate,
          nextPath: 'emergency-contact'
        },
        {
          path: 'emergency-contact',
          Component: EmergencyContact,
          backPath: 'move-in',
          hidden:
            !location?.companyInfo?.requireEmergencyContact ||
            ['guarantor'].includes(application?.type)
        }
      ],
      isLocked: (app, steps) => {
        return !isStepDone('personalInfo', steps);
      }
    },
    {
      key: 'org-terms',
      title: t('overviewStep.steps.orgTerms.title'),
      description: t('overviewStep.steps.orgTerms.description'),
      path: 'org-terms',
      hidden: !location?.applicationConfig?.applicationConditionsTemplateId,
      isLocked: (app) => !isStepDone('leaseInfo', steps),
      routes: [{ path: 'org-terms', Component: OrgTerms }]
    },
    {
      key: 'identity',
      title: t('overviewStep.steps.identity.title'),
      description: t('overviewStep.steps.identity.description'),
      path: 'verify-identity',
      routes: [
        {
          path: 'verify-identity',
          Component: Identity
        }
      ],
      isLocked: (app, steps) => {
        return (
          app?.verifiedIdentity ||
          !isStepDone('org-terms', steps) ||
          !isStepDone('leaseInfo', steps)
        );
      }
    },
    {
      key: 'housing',
      title: t('overviewStep.steps.housing.title'),
      description: t('overviewStep.steps.housing.description'),
      path: 'housing-history',
      routes: [
        {
          path: 'housing-history',
          Component: HousingHistory,
          nextPath: 'questions'
        },
        {
          path: 'questions',
          Component: Questions,
          nextPath: 'section-8',
          backPath: 'housing-history'
        },
        {
          path: 'section-8',
          Component: Section8,
          hidden: !location?.companyInfo?.enableSection8,
          backPath: 'questions'
        }
      ],
      isLocked: (app, steps) => {
        return !isStepDone('identity', steps);
      }
    },
    {
      key: 'backgroundCheck',
      title: t('overviewStep.steps.backgroundCheck.title'),
      description: t(
        `ssnInfo.${companyContext?.primaryCountry || 'US'}.backgroundCheck_description`
      ),
      path: 'background-check',
      routes: [
        {
          path: 'background-check',
          Component: BackgroundCheck
        }
      ],
      isLocked: (app, steps) => {
        return !isStepDone('housing', steps);
      }
    },
    {
      key: 'appFee',
      title: t('overviewStep.steps.appFee.title'),
      description: t('overviewStep.steps.appFee.description'),
      path: 'fee-details',
      hidden: !application?.chargeApplicants,
      routes: [
        {
          path: 'fee-details',
          Component: AppFee,
          hidden: !application?.chargeApplicants,
          nextPath: 'fee-success'
        },
        {
          path: 'fee-success',
          Component: AppFeeSuccess,
          hidden: !application?.chargeApplicants,
          backPath: 'fee-details'
        }
      ],
      isLocked: (app, steps) => {
        return !isStepDone('backgroundCheck', steps);
      }
    },
    {
      key: 'household',
      title: t('overviewStep.steps.household.title'),
      description: t('overviewStep.steps.household.description'),
      path: 'members',
      hidden: ['guarantor'].includes(application?.type),
      routes: [
        {
          path: 'members',
          Component: Members,
          hidden: ['guarantor'].includes(application?.type)
        }
      ],
      isLocked: (app, steps) => {
        if (app?.chargeApplicants && !app?.paid) return true;
        return !isStepDone('backgroundCheck', steps);
      }
    },
    {
      key: 'pets',
      title: t('overviewStep.steps.pets.title'),
      description: t('overviewStep.steps.pets.description'),
      path: 'pets',
      hidden: ['guarantor'].includes(application?.type),
      routes: [
        {
          path: 'pets',
          Component: Pets,
          hidden: ['guarantor'].includes(application?.type),
          disabled: true
        }
      ],
      isLocked: (app, steps) => {
        return !isStepDone('household', steps);
      }
    },
    {
      key: 'vehicles',
      title: t('overviewStep.steps.vehicles.title'),
      description: t('overviewStep.steps.vehicles.description'),
      path: 'vehicles',
      hidden: ['guarantor'].includes(application?.type),
      routes: [
        {
          path: 'vehicles',
          Component: Vehicles,
          hidden: ['guarantor'].includes(application?.type)
        }
      ],
      isLocked: (app, steps) => {
        return !isStepDone('pets', steps);
      }
    },
    {
      key: 'incomeVerification',
      title: t('overviewStep.steps.incomeVerification.title'),
      description: t('overviewStep.steps.incomeVerification.description'),
      path: 'income-details',
      hidden: !!location?.companyInfo?.disableBankScreening,
      isLocked: (app) => {
        if (app?.chargeApplicants && !app?.paid) return true;
        const prevStep = app?.type === 'guarantor' ? 'backgroundCheck' : 'vehicles';
        return !isStepDone(prevStep, steps) || !app?.consent?.consented;
      },
      routes: [
        {
          path: 'income-details',
          Component: Income,
          hidden: location?.companyContext?.disableBankScreening
        },
        {
          path: 'skip-income',
          Component: SkipIncome,
          backPath: 'income-details'
        }
      ]
    },
    {
      key: 'income',
      title: t('overviewStep.steps.income.title'),
      description: t('overviewStep.steps.income.description'),
      path: 'manual-income-details',
      isLocked: (app) => {
        const prevStep = !location?.companyInfo?.disableBankScreening
          ? 'incomeVerification'
          : app?.type === 'guarantor'
          ? 'backgroundCheck'
          : 'vehicles';
        return !isStepDone(prevStep, steps) || !app?.consent?.consented;
      },
      routes: [
        {
          path: 'manual-income-details',
          Component: IncomeSources,
          nextPath: 'manual-income-documents'
        },
        {
          path: 'manual-income-documents',
          Component: IncomeDocuments,
          backPath: 'manual-income-details'
        }
      ]
    },
    {
      key: 'submit',
      title: t('overviewStep.steps.submit.title'),
      description: t('overviewStep.steps.submit.description'),
      path: 'consent-submit',
      isLocked: (app) => {
        return (
          !isStepDone('income', steps) ||
          (!location?.companyInfo?.disableBankScreening &&
            !isStepDone('incomeVerification', steps)) ||
          !app?.consent?.consented
        );
      },
      routes: [
        {
          path: 'consent-submit',
          Component: Consent,
          nextPath: 'success'
        },
        {
          path: 'success',
          Component: Success
        }
      ]
    }
  ].map((step) => {
    const appStep = application?.steps?.find((st) => st.key === step.key);
    return {
      ...step,
      status: getApplicationStepStatus({ key: step.key, application, location, appStep, step, t })
    };
  });

  return steps.map((step) => {
    step.status.hidden = !!step.hidden;
    step.status.locked = step?.isLocked ? step.isLocked(application, steps) : false;
    return step;
  });
};
