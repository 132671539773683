import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import { TableSection } from 'components/section';

const ESAWrapper = styled.div`
  display: flex;
  gap: 5px;
  ${({ onClick }) => (onClick ? 'cursor:pointer;' : '')}
`;
const ESAHighlight = styled.div`
  color: #49b22f;
`;
const PetRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .petScreening {
    height: 20px;
  }
`;
const Wrapper = styled.div`
  .fidoScore {
    width: 100px;
  }
`;

export default ({ className, application, handleActions }) => {
  let columns = [
    {
      label: 'Type',
      uuid: 'type',
      className: 'capitalize',
      render: (pet) => (
        <PetRow>
          <div>{pet.type}</div>
        </PetRow>
      )
    },
    {
      label: 'Name',
      uuid: 'name',
      className: 'capitalize'
    },
    { label: 'Breed', uuid: 'breed', className: 'capitalize' },
    {
      label: 'Weight',
      uuid: 'weight',
      render: (pet) => (Number.isFinite(Number(pet.weight)) ? `${pet.weight} lbs` : pet.weight)
    },
    {
      label: 'ESA?',
      uuid: 'serviceAnimal',
      render: (pet) => {
        return pet.serviceAnimal ? (
          <ESAWrapper
            onClick={
              !pet.files?.length
                ? undefined
                : () => {
                    handleActions({
                      id: 'files-view',
                      applicant: application.applicants.find(
                        (app) => app.applicationId === pet.applicationId
                      ),
                      title: `View ESA Docs for ${pet.name}`,
                      data: { files: pet.files, selectedFile: pet.files[0], type: 'animal' }
                    });
                  }
            }>
            {!!pet.files?.length && <Icon name="fa-duotone fa-paperclip" />}
            <ESAHighlight>Yes</ESAHighlight>
          </ESAWrapper>
        ) : (
          <div>No</div>
        );
      }
    },
    { label: 'Added By', uuid: 'addedBy', className: 'capitalize' }
  ];

  return (
    <Wrapper>
      <TableSection
        id="application-animals"
        className={className}
        handleActions={handleActions}
        data={application?.pets}
        columns={columns}
        title="ANIMALS"
        noDataMessage="No animals specified"
        titleIcon="fa-duotone fa-dog"
      />
    </Wrapper>
  );
};
