import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AUDIENCES } from 'helpers/enums';
import { useOrgId } from 'hooks/useSessionVariables';
import useAudience from 'hooks/useAudience';

export const AppUiContext = React.createContext({});
export const useUiState = () => React.useContext(AppUiContext);
export const AppUiStateProvider = ({ children }) => {
  const [uiState, setState] = React.useState({
    menuOpen: false
  });
  const updateUiState = React.useCallback((update) => {
    setState((prev) => ({ ...prev, ...update }));
  }, []);

  return (
    <AppUiContext.Provider value={{ uiState, updateUiState }}>{children}</AppUiContext.Provider>
  );
};

export const UserContext = React.createContext({
  userContext: {},
  setUserContext: () => {},
  updateUserContext: () => {}
});
export const CompanyContext = React.createContext({
  companyContext: {},
  setCompanyContext: () => {}
});

export const ApplicationContext = React.createContext({
  applicationContext: {},
  setApplicationContext: () => {}
});

export const useNavigateWithContext = () => {
  const [audience] = useAudience();
  const navigate = useNavigate();
  const orgId = useOrgId();

  let contextId = orgId;

  // if we don't have a contextID try to grab it from url. it should always be the first segment
  // http://localhost:3030/fN93aWspqiFy8kPqG/applicant/app/applications
  if (!contextId || contextId === 'undefined') {
    const parts = window.location.pathname.split('/').filter((z) => z) || [];
    contextId = parts[0];
  }

  return (url, audienceSwap) => {
    const newAudience = !audienceSwap
      ? undefined
      : Object.values(AUDIENCES).find((aud) => aud.uuid === audienceSwap);
    let navAudience = newAudience?.routePrefix || audience?.routePrefix || '';

    return navigate(`/${contextId}/${navAudience}${url || ''}`);
  };
};
