import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';
import { getStateFromAbbreviation } from 'utils/stateHelpers';

const Wrapper = styled.div`
  border: 1px solid #ddd;
  margin-bottom: 10px;
  &:last-children {
    margin-bottom: 0;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 20px;
  display: flex;
  gap: 10px;
  .title {
    flex: 1;
    font-weight: bold;
    font-size: 1.3em;
  }
`;

const List = styled.div`
  padding: 0px 20px 20px 20px;
  display: grid;
  gap: 10px;
`;

const MemberWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  background-color: #f5f5f5;
  padding: 2px 20px;
  position: relative;
  .body {
    padding: 10px 0 10px;
  }
  .actions {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: flex-end;
    padding: 10px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 30px;
      background-color: #fff;
      border: 1px solid #ddd;
      border-radius: 4px;
      color: #444;
      font-size: 0.88em;
    }
  }
`;

const Item = ({
  make = '',
  model = '',
  year = [],
  color,
  licensePlate,
  licensePlateState,
  addedBy,
  onRemove,
  onEdit,
  readOnly,
  country = 'US',
  icon
}) => {
  return (
    <MemberWrapper>
      <div className="icon">{icon && <Icon name={icon?.name} size={25} />}</div>
      <div className="body">
        <div>
          <div>
            <strong>{make}</strong> {model}
          </div>
          <div>
            <strong>Year:</strong> {year}
          </div>
          <div>
            <strong>Color:</strong> {color}
          </div>
          {licensePlateState && (
            <div>
              <strong>License Plate State:</strong>{' '}
              {getStateFromAbbreviation(licensePlateState, country)?.name || licensePlateState}
            </div>
          )}
          <div>
            <strong>License Plate:</strong> {licensePlate}
          </div>
        </div>
        {addedBy && (
          <div className="addedBy">
            <p>Added by: {addedBy}</p>
          </div>
        )}
      </div>

      {!readOnly && (
        <div className="actions">
          <Button onClick={onRemove} title="Remove">
            <Icon name="fa-solid fa-trash" />
          </Button>
          <Button onClick={onEdit} title="Edit">
            <Icon name="fa-solid fa-pen-to-square" />
          </Button>
        </div>
      )}
    </MemberWrapper>
  );
};

export default ({
  title,
  items = [],
  otherVehicles = [],
  onAdd,
  onRemove,
  onEdit,
  icon,
  country,
  properties
}) => {
  const hasItems = items.length > 0 || otherVehicles.length > 0;
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Header>
        <Icon
          name={hasItems ? 'fa-duotone fa-circle-check' : 'fa-light fa-circle'}
          color={hasItems ? 'green' : '#ccc'}
        />
        {icon && <Icon name={icon.name} />}
        <div className="title">{title}</div>
        <Button variant="primary" onClick={onAdd}>
          {t('buttonsActions.add')}
        </Button>
      </Header>
      {hasItems && (
        <List>
          {otherVehicles.map((item) => (
            <Item
              key={item.uuid}
              country={country}
              {...item}
              onEdit={() => onEdit(item)}
              onRemove={() => onRemove(item)}
              icon={properties?.[item.type]?.icon}
            />
          ))}
          {items.map((item) => (
            <Item
              key={item.uuid}
              country={country}
              {...item}
              onEdit={() => onEdit(item)}
              onRemove={() => onRemove(item)}
              icon={properties?.[item.type]?.icon}
            />
          ))}
        </List>
      )}
    </Wrapper>
  );
};
