import React from 'react';
import styled from 'styled-components';
import InputDate from 'components/input-date';
import { format, utcToZonedTime } from 'date-fns-tz';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  &.mode-view {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
  }
  .DayPickerInput-OverlayWrapper {
    z-index: 999;
  }
  input {
    height: 100%;
    width: 100%;
    background-color: #fff;
    border: 1px solid #000 !important;
    box-shadow: none;
    border-radius: 0;
    box-sizing: border-box;
    font-size: ${({ fontSize = 14 }) => fontSize}px;
    background-color: #fff175;
  }
`;

export default ({ block, mode, updateBlock }) => {
  return (
    <Wrapper className={`mode-${mode}`} fontSize={block.fontSize}>
      {mode === 'view' && <div>{block.value && format(new Date(block.value), 'MM/dd/yyyy')}</div>}
      {mode === 'edit' && (
        <InputDate
          value={block.value}
          onChange={(event) => updateBlock({ uuid: block.uuid, value: event.target.value })}
          autoScroll={false}
          placeholder={block.name}
        />
      )}
    </Wrapper>
  );
};
