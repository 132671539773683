import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div``;

const InputWrapper = styled.div`
  display: flex;
  position: relative;
`;

const ReadOnlySelect = styled.div`
  display: flex;
  align-items: center;
  padding: 0 15px;
  flex: 1;
  background-color: var(--color-bg-primary);
  height: 32px;
  border-radius: 0px;
  outline: none;
  border: 2px solid transparent;
  padding-right: 40px;
  max-width: 100%;
  &:focus {
    border-color: var(--color-primary);
  }
  &.icon-left {
    padding-left: 40px;
  }
  -webkit-appearance: none;
`;
const Select = styled.select`
  padding: 0 15px;
  flex: 1;
  background-color: var(--color-bg-primary);
  height: 32px;
  border-radius: 0px;
  outline: none;
  border: 1px solid #ddd;
  padding-right: 40px;
  max-width: 100%;
  color: #1d3843;
  &:focus {
    border-color: var(--color-primary);
  }
  &.icon-left {
    padding-left: 40px;
  }
  -webkit-appearance: none;
`;

const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 40px;
  pointer-events: none;
  &.arrow {
    right: 0;
    top: 0;
    left: initial;
    font-size: 1.13em;
    color: #777;
    user-select: none;
  }
`;

export default ({ icon, className = '', readOnly, ...props }) => {
  return (
    <Wrapper className="Aptly_Select">
      <InputWrapper>
        {icon && (
          <Icon>
            <i className={icon} />
          </Icon>
        )}
        {!readOnly && <Select {...props} className={`${icon ? 'icon-left' : ''} ${className}`} />}
        {readOnly && (
          <ReadOnlySelect className={`${icon ? 'icon-left' : ''} ${className}`}>
            {props.value}
          </ReadOnlySelect>
        )}
        {!readOnly && (
          <Icon className="arrow">
            <i className="fa-light fa-angle-down" />
          </Icon>
        )}
      </InputWrapper>
    </Wrapper>
  );
};
