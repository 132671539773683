import Icon from 'components/icon';
import Button from 'components/button';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  padding: 5px 0px;
  .name {
    text-transform: capitalize;
    strong {
      color: var(--color-primary);
    }
  }
  .body {
    flex: 1;
  }
  button {
    margin: 0;
    padding: 0;
    margin-left: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export default ({ address, onEdit, onRemove, t }) => {
  const {
    formattedAddress,
    address: addr = '',
    unit = '',
    city = '',
    state = '',
    postal_code = '',
    country,
    startDate,
    endDate
  } = address;

  const fullAddress =
    formattedAddress?.formattedAddress ||
    `${addr} ${unit} ${city}, ${state} ${postal_code} ${country}`.trim();
  const dates = `${startDate ? new Date(startDate).renderPretty(false) : ''} to ${
    address.current ? 'now' : endDate ? new Date(endDate).renderPretty(false) : ''
  }`;

  return (
    <ItemWrapper>
      <div className="body">
        <div>
          {t('addressPreview.title')} {dates}
        </div>
        <div className="name">{fullAddress}</div>
        <br />
        {address.type === 'R' && (
          <>
            {address?.rentalReference?.pastRent && (
              <div>
                <Trans i18nKey="addressPreview.monthlyRent">
                  <strong>Monthly Rent</strong>
                </Trans>

                <br />
                <span>${address?.rentalReference?.pastRent}</span>
              </div>
            )}
            {!!address.rentalReference?.consentToContact && (
              <>
                <br />
                {(address?.rentalReference?.name || address?.rentalReference?.firstName) && (
                  <>
                    <div>
                      <Trans i18nKey="addressPreview.landlord">
                        <strong>Landlord</strong> <br />
                      </Trans>
                      {address?.rentalReference?.name
                        ? address?.rentalReference?.name
                        : `${address?.rentalReference?.firstName} ${address?.rentalReference?.lastName}`}
                      <br />
                      {address?.rentalReference?.email} <br />
                      {address?.rentalReference?.phone}
                    </div>
                    <br />
                  </>
                )}
                {address.current && (
                  <div>
                    <div>
                      {t('addressPreview.mylandlord')}{' '}
                      {address.rentalReference?.awareOfMove ? `${t('yes')}` : 'No'}{' '}
                    </div>
                    <br />
                  </div>
                )}
                <div>
                  <Icon
                    color={address.rentalReference?.consentToContact ? 'green' : 'red'}
                    name={
                      !address.rentalReference?.consentToContact
                        ? 'fa-duotone fa-circle-xmark'
                        : 'fa-duotone fa-circle-check'
                    }
                  />
                  &nbsp;{t('addressPreview.iConsent')}
                </div>
              </>
            )}
          </>
        )}
      </div>
      <Actions>
        <Button onClick={onRemove}>
          <Icon name="fa-duotone fa-trash" color={'#ff0000'} />
        </Button>
        <Button variant="secondary" onClick={onEdit}>
          {t('buttonsActions.update')}
        </Button>
      </Actions>
    </ItemWrapper>
  );
};
