import React from 'react';
import Select, { StylesConfig } from 'react-select';
import { readableColor } from 'polished';

const dot = (color = 'transparent') => ({
  alignItems: 'center',
  display: 'flex',
  ':before': {
    backgroundColor: color,
    borderRadius: 2,
    content: '" "',
    display: 'block',
    marginRight: 8,
    height: 10,
    width: 10,
    border: '1px solid #ddd'
  }
});

const colorStyles = {
  container: (styles) => ({ ...styles }),
  control: (styles) => ({ ...styles, borderColor: '#ddd' }),
  option: (styles, { data }) => ({
    ...styles,
    borderColor: '#ddd',
    color: '#000',
    ...dot(data.color)
  }),
  singleValue: (styles, { data }) => ({ ...styles, color: '#000', ...dot(data.color) }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#fff',
      color: readableColor(data.color || '#fff'),
      borderRadius: 4
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: readableColor(data.color || '#fff')
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    ':hover': {
      backgroundColor: 'rgba(0,0,0,.2)'
    }
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 999
  }),
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 999
  })
};

export default ({ name, value, field, onChange, ...props }) => {
  const options = (field.data || []).map(({ label, color = '#fff' }) => ({
    label,
    value: label,
    color
  }));

  const isMulti = field.type === 'multiselect' ? true : false;
  const selections = [].concat(value || '');
  const selected = options.filter((o) => selections.includes(o.value));

  return (
    <Select
      value={isMulti ? selected : selected[0]}
      options={options}
      styles={colorStyles}
      onChange={(selections) => {
        const value = isMulti ? selections.map((s) => s.value) : selections.value;
        onChange && onChange({ target: { value, name } });
      }}
      isMulti={isMulti}
      controlShouldRenderValue
      hideSelectedOptions
      captureMenuScroll={false}
      closeMenuOnSelect={!isMulti}
      maxMenuHeight={300}
      menuPlacement="auto"
      menuPosition="fixed"
      menuShouldScrollIntoView
      {...props}
    />
  );
};
