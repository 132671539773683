import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';
import Section from './section';
import { getFullName } from 'utils/stringHelpers';

const DataList = styled.div`
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
`;
const DataBox = styled.div`
  font-size: 0.88em;
  .dataBox-label {
    font-weight: bold;
  }
`;

const Row = styled.div`
  margin: 20px 0;
  &:first-child {
    margin-top: 0;
  }
`;

const VerificationInfo = styled.div`
  background-color: #f5f5f5;
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 10px;
  .title {
    font-weight: bold;
    font-size: 1.13em;
  }
  > div:first-child {
    border-right: 1px solid #ddd;
  }
  > div {
    padding: 15px;
  }
`;
const NoData = styled.div`
  padding: 5px 0;
  font-size: 0.88em;
`;

const IncomeSections = styled.div`
  display: flex;
  flex-flow: column;
  gap: 15px;
`;
const IncomeSection = styled(Section)`
  padding: 15px 0;
  .sectionContent {
    display: flex;
    flex-flow: column;
    gap: 15px;
  }
  .sectionHeader {
    margin-bottom: 15px;
    height: auto;
  }
`;

export default ({ application, t }) => {
  const income = application?.income || [];

  if (application.noRentContribution) {
    return (
      <Section title={<Trans i18nKey="summary.income.title">INCOME VERIFICATION</Trans>}>
        <NoData>
          <div>
            <strong>
              <Trans i18nKey="summary.income.not_contributing">Will not contribute to rent</Trans>
              {application.noRentReason?.length
                ? `: ${application.noRentReason
                    .map((r) => t(`skip_income_modal.reason_${r}`))
                    .join(', ')}`
                : ''}
            </strong>
          </div>
          {application.noRentComments && (
            <div>
              <strong>
                <Trans i18nKey="comments">Comments</Trans>:
              </strong>
              <div>{application.noRentComments}</div>
            </div>
          )}
        </NoData>
      </Section>
    );
  }

  if (!income.length) {
    return (
      <Section title={<Trans i18nKey="summary.income.title">INCOME VERIFICATION</Trans>}>
        <NoData>
          <Trans i18nKey="summary.income.none">None specified</Trans>
        </NoData>
      </Section>
    );
  }

  const EMPLOYMENT_TYPES = {
    misc: `${t('income_source.options.other')}`,
    self: `${t('income_source.options.contrating')}`,
    employment: `${t('income_source.options.fullTime')}`
  };

  return (
    <Section title={<Trans i18nKey="summary.income.title">INCOME VERIFICATION</Trans>}>
      {/* <Row>
        <div>Automated Verification Complete!</div>
        <VerificationInfo>
          <div className="">
            <div className="title">Verified Income</div>
            <DataList>
              <DataBox>
                <div className="dataBox-label">Annual Income Before Taxes</div>
                <div className="dataBox-text">$72,000 </div>
              </DataBox>
              <DataBox>
                <div className="dataBox-label">Avg Monthly Income</div>
                <div className="dataBox-text">$6,134.50 </div>
              </DataBox>
            </DataList>
          </div>
          <div className="">
            <div className="title">Verified Balances</div>
            <DataList>
              <DataBox>
                <div className="dataBox-label">Total Cash Available</div>
                <div className="dataBox-text">$72,000 </div>
              </DataBox>
              <DataBox>
                <div className="dataBox-label">Avg Monthly Closing Balance</div>
                <div className="dataBox-text">$6,134.50 </div>
              </DataBox>
            </DataList>
          </div>
        </VerificationInfo>
      </Row> */}
      <IncomeSections>
        {income.map((inc, ix) => {
          return (
            <IncomeSection
              title={<Trans i18nKey="summary.income.source">Income Source</Trans>}
              icon="none"
              key={ix}>
              <DataList>
                <DataBox>
                  <div className="dataBox-label">
                    <Trans i18nKey="summary.income.col.type">Type</Trans>
                  </div>
                  <div className="dataBox-text">
                    {EMPLOYMENT_TYPES[inc.type]} (
                    {inc.current ? 'Current' : inc.future ? 'Future' : 'Past'})
                  </div>
                </DataBox>
                {inc.companyName && (
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.income.col.company">Company</Trans>
                    </div>
                    <div className="dataBox-text">{inc.companyName}</div>
                  </DataBox>
                )}
                {inc.position && (
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.income.col.position">Position</Trans>
                    </div>
                    <div className="dataBox-text">{inc.position}</div>
                  </DataBox>
                )}
                {inc.startDate && (
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.income.col.dates">Dates</Trans>
                    </div>
                    <div className="dataBox-text">
                      {new Date(inc.startDate).renderStringRange(inc.endDate)}
                    </div>
                  </DataBox>
                )}
                {inc.income && (
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.income.col.monthly_income">
                        Monthly Income (pre-tax)
                      </Trans>
                    </div>
                    <div className="dataBox-text">{inc.income.renderPrettyCurrency()}/mo.</div>
                  </DataBox>
                )}
                {inc.comments && (
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.income.col.comments">Comments</Trans>
                    </div>
                    <div className="dataBox-text">{inc.comments}</div>
                  </DataBox>
                )}
              </DataList>
              {!!(
                inc.contactInfo?.firstName ||
                inc.contactInfo?.lastName ||
                inc.contactInfo?.name
              ) && (
                <DataList>
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.income.col.supervisor">Supervisor Contact Info</Trans>
                    </div>
                    <div className="dataBox-text">
                      <div>{getFullName(inc.contactInfo)}</div>
                      {!!inc.contactInfo.phone?.number && (
                        <div>{inc.contactInfo.phone?.number}</div>
                      )}
                      {!!inc.contactInfo.email && <div>{inc.contactInfo.email}</div>}
                    </div>
                  </DataBox>
                </DataList>
              )}
            </IncomeSection>
          );
        })}
      </IncomeSections>
    </Section>
  );
};
