import Modal from 'components/modal';
import React from 'react';
import styled from 'styled-components';
import FormGroup, { RowGroup } from 'components/form-group';
import Input from 'components/input';
import Select from 'components/select';
import Button from 'components/button';
import Checkbox from 'components/checkbox';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { randomId } from 'dal/utils';
import InputDate from 'components/input-date';
import { useTranslation, Trans } from 'react-i18next';
import Address from 'components/address';
import { Row, Title } from '../common-styles';

const addressesSchema = (t) => {
  return yup.object().shape({
    uuid: yup.string(),
    current: yup.boolean(),
    unit: yup.string(),
    startDate: yup.date().required(`${t('form.validate.startDate')}`),
    endDate: yup.date().when('current', {
      is: false,
      then: yup.date().required(`${t('form.validate.endDate')}`)
    }),
    formattedAddress: yup
      .object({
        streetNumber: yup.string().required(`${t('form.validate.houseNumber')}`),
        address: yup.string().required(`${t('form.validate.address')}`),
        city: yup.string().required(`${t('form.validate.city')}`),
        stateCode: yup.string().required(`${t('form.validate.state')}`),
        postalCode: yup.string().when('country', {
          is: (c) => ['IL'].includes(c),
          then: yup.string(`${t('form.validate.postal_code')}`),
          otherwise: yup.string().required(`${t('form.validate.postal_code')}`)
        }),
        country: yup
          .string()
          //  .oneOf(['US', 'CA', 'MX'], `${t('form.validate.countrys')}`)
          .required(`${t('form.validate.country')}`)
      })
      .required(`${t('form.validate.address')}`),
    type: yup
      .string()
      .oneOf(['R', 'O', 'S'])
      .required(`${t('form.validate.rent')}`),
    mortgageCompany: yup.string().when('type', {
      is: (type) => type === 'O',
      then: yup.string().required(`${t('housingHistory.validate.mortgage')}`)
    }),
    housingStatus: yup.string().when('type', {
      is: (type) => type === 'S',
      then: yup.string().required(`${t('housingHistory.validate.housingStatus')}`)
    }),
    rentalReference: yup.object().when('type', {
      is: (type) => type === 'R',
      then: yup.object().shape({
        consentToContact: yup.boolean(),
        name: yup.string(),
        awareOfMove: yup.boolean(),
        pastRent: yup
          .number()
          .required(`${t('form.validate.pastRent')}`)
          .typeError(`${t('form.validate.validNumber')}`)
          .min(0, `${t('form.validate.validNumber')}`),
        email: yup.string().email(`${t('form.validate.provideEmail')}`),
        phone: yup.string().phone(`${t('form.validate.invalidPhone')}`)
      }),
      otherwise: (s) => s.strip()
    })
  });
};

const currentDate = new Date();

const defaultAddress = {
  current: false,
  unit: '',
  rentalReference: { name: '', consentToContact: true },
  startDate: '',
  endDate: '',
  type: '',
  formattedAddress: {},
  mortgageCompany: '',
  housingStatus: ''
};

const Wrapper = styled.div`
  .required {
    color: red;
  }
  .row-checkbox {
    display: flex;
    align-items: center;
    flex-flow: column;
    align-items: flex-start;
  }
  .check {
    -webkit-appearance: none; /*hides the default checkbox*/
    height: 20px;
    width: 20px;
    transition: 0.1s;
    background-color: #ffff;
    text-align: center;
    font-weight: 600;
    color: white;
    border-radius: 4px;
    outline: none;
    border: 1px solid red;
  }
  .check:checked {
    background-color: #ffff;
  }
  .check:checked:before {
    content: '✔';
    color: red;
  }

  .check:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const ErrorText = styled.span`
  font-size: 0.81em;
  margin-top: 5px;
  color: red;
`;

const createCompliantInitialVal = ({ initialAddress, requireRentalReference }) => {
  const base = { ...defaultAddress, ...initialAddress };
  if (!base.rentalReference?.consentToContact && requireRentalReference) {
    base.rentalReference = { ...base.rentalReference, consentToContact: true };
  }
  return base;
};

export const AddressEdition = ({ onClose, address: initialAddress, location, onSave }) => {
  const { t } = useTranslation();
  const requireRentalReference = location?.companyInfo?.requireRentalReference;
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: createCompliantInitialVal({ initialAddress, requireRentalReference }),
      validationSchema: addressesSchema(t),
      validate: (vals) => {
        const errors = {};
        if (
          !!location?.companyInfo?.requireRentalReference &&
          vals?.type === 'R' &&
          !vals?.rentalReference?.consentToContact
        ) {
          errors.consentToContact = t('form.validate.consentToContactLandlord');
        }

        if (
          vals?.rentalReference?.consentToContact &&
          location?.companyInfo?.requireRentalReferenceEmail &&
          !vals.rentalReference.email
        ) {
          errors.email = t('form.validate.email');
        }
        if (
          vals?.rentalReference?.consentToContact &&
          !vals.rentalReference.email &&
          !vals.rentalReference.phone
        ) {
          errors.emailOrPhoneRequired = t('form.validate.emailOrPhoneRequired');
        }
        if (vals?.rentalReference?.consentToContact && !vals.rentalReference.name) {
          errors.rentalReference = {
            ...errors.rentalReference,
            name: t('form.validate.landlorName')
          };
        }
        return errors;
      },
      onSubmit: (values) => {
        onSave({ uuid: randomId(), ...values });
        onClose();
      }
    });

  const addressErrors = Object.keys(errors.formattedAddress || {})
    .map((z) => errors.formattedAddress[z])
    .join(', ');

  return (
    <Wrapper>
      <Row className="center">
        <Title>
          {!values.current ? (
            <Trans i18nKey="housingHistory.previous-title">Previous Address</Trans>
          ) : (
            <Trans i18nKey="housingHistory.current-title">Current Address</Trans>
          )}
        </Title>
      </Row>
      <Row className="center text-center">
        <Trans
          i18nKey={
            values.current
              ? 'housingHistory.currentDescription'
              : 'housingHistory.previousDescription'
          }
        />
      </Row>
      <Row>
        <RowGroup>
          <FormGroup
            label={t('housingHistory.formattedAddress')}
            errorText={touched.formattedAddress && addressErrors}>
            <Address
              value={values.formattedAddress}
              name={`formattedAddress`}
              onBlur={handleBlur}
              onChange={(address) => {
                setFieldValue(`formattedAddress`, address);
              }}
            />
          </FormGroup>
        </RowGroup>
        <RowGroup>
          <FormGroup label={t('housingHistory.form.unit')} errorText={touched.unit && errors.unit}>
            <Input
              name="unit"
              value={values.unit || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
        </RowGroup>
        {!values.current && (
          <RowGroup columns="1fr 1fr">
            <FormGroup
              label={t('housingHistory.form.startDate')}
              errorText={touched.startDate && errors.startDate}>
              <InputDate
                name="startDate"
                value={values.startDate}
                onChange={(e) => {
                  e.target.value = e.target.value || '';
                  handleChange(e);
                }}
                onBlur={handleBlur}
                disabledDays={(date) => {
                  if (values.endDate && date >= values.endDate) {
                    return true;
                  }
                  return date > currentDate;
                }}
              />
            </FormGroup>
            <FormGroup
              label={t('housingHistory.form.endDate')}
              errorText={touched.endDate && errors.endDate}>
              <InputDate
                name="endDate"
                value={values.endDate}
                onChange={(e) => {
                  e.target.value = e.target.value || '';
                  handleChange(e);
                }}
                onBlur={handleBlur}
                disabledDays={(date) => {
                  if (values.startDate && date <= values.startDate) {
                    return true;
                  }
                  return date > currentDate;
                }}
              />
            </FormGroup>
          </RowGroup>
        )}
        {values.current && (
          <FormGroup
            label={t('housingHistory.form.startDate')}
            errorText={touched.startDate && errors.startDate}>
            <InputDate
              name="startDate"
              value={values.startDate}
              onChange={(e) => {
                e.target.value = e.target.value || '';
                handleChange(e);
              }}
              onBlur={handleBlur}
              disabledDays={(date) => {
                return date > currentDate;
              }}
            />
          </FormGroup>
        )}
        <FormGroup
          label={t('housingHistory.form.type.title')}
          errorText={touched.type && errors.type}>
          <Select
            name="type"
            value={values.type}
            onChange={(event) => {
              handleChange(event);
              if (['R'].includes(event.target.value)) {
                setFieldValue('rentalReference', {
                  name: '',
                  pastRent: '',
                  email: '',
                  phone: '',
                  awareOfMove: undefined,
                  consentToContact: true
                });
              } else {
                setFieldValue('rentalReference', null);
              }
            }}
            onBlur={handleBlur}>
            <option value="">{t('buttonsActions.selectButoon')}</option>
            <option value="R">{t('housingHistory.form.type.rent')}</option>
            <option value="O">{t('housingHistory.form.type.own')}</option>
            <option value="S">{t('housingHistory.form.type.Staying')}</option>
          </Select>
        </FormGroup>
        {values.type === 'O' && (
          <>
            <div className="title">
              <Trans i18nKey="housingHistory.loan">
                <strong>WHO IS YOUR LOAN WITH?</strong>
              </Trans>
            </div>
            <FormGroup
              label={t('housingHistory.form.mortgage')}
              errorText={touched.mortgageCompany && errors.mortgageCompany}>
              <Input
                name="mortgageCompany"
                value={values.mortgageCompany}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </>
        )}
        {values.type === 'S' && (
          <FormGroup
            label={t('housingHistory.form.explainHousing')}
            errorText={touched.housingStatus && errors.housingStatus}>
            <Input
              name="housingStatus"
              value={values.housingStatus}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormGroup>
        )}
        {values.type === 'R' && (
          <>
            <FormGroup
              label={t('housingHistory.form.monthlyRent')}
              errorText={touched.rentalReference?.pastRent && errors.rentalReference?.pastRent}>
              <Input
                name="rentalReference.pastRent"
                value={`$${values.rentalReference.pastRent}`}
                onChange={(event) => {
                  event.target.value = (event.target.value || '').replace('$', '').trim();
                  handleChange(event);
                }}
                onBlur={handleBlur}
              />
              <span style={{ fontSize: '0.81em', color: '#777', marginTop: 5 }}>
                {t('housingHistory.form.monthlyRentText')}
              </span>
            </FormGroup>
            <br />
            {!requireRentalReference ? (
              <div className="title">
                <Checkbox
                  label={<strong>{t('housingHistory.form.landlordConsent')}</strong>}
                  checked={!!values.rentalReference?.consentToContact}
                  name="rentalReference.consentToContact"
                  onChange={() =>
                    setFieldValue(
                      `rentalReference.consentToContact`,
                      !values.rentalReference?.consentToContact
                    )
                  }
                />
              </div>
            ) : (
              <div className="title">
                <strong>{t('housingHistory.form.renting')}</strong>
              </div>
            )}
            {(requireRentalReference || !!values.rentalReference?.consentToContact) && (
              <>
                <br />
                <FormGroup
                  label={t('housingHistory.form.landlord')}
                  errorText={touched.rentalReference?.name && errors.rentalReference?.name}>
                  <Input
                    name="rentalReference.name"
                    value={values.rentalReference.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                </FormGroup>
                <RowGroup columns="1fr 1fr">
                  <FormGroup
                    label={t('housingHistory.form.email')}
                    errorText={touched.rentalReference?.email && errors.rentalReference?.email}>
                    <Input
                      icon="Email"
                      name="rentalReference.email"
                      value={values.rentalReference.email}
                      onChange={(e) => {
                        e.target.value = (e.target.value || '').trim(); // force whitespace trim
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />
                    {(errors.emailOrPhoneRequired || errors.email) && (
                      <ErrorText>{errors.email || errors.emailOrPhoneRequired}</ErrorText>
                    )}
                  </FormGroup>
                  <FormGroup
                    label={t('housingHistory.form.phone')}
                    errorText={touched.rentalReference?.phone && errors.rentalReference?.phone}>
                    <Input
                      icon="Calls-SMS"
                      name="rentalReference.phone"
                      value={values?.rentalReference.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </FormGroup>
                </RowGroup>
                {values.current && (
                  <FormGroup
                    label={t('housingHistory.form.awareOfMove')}
                    errorText={
                      touched.rentalReference?.awareOfMove && errors.rentalReference?.awareOfMove
                    }>
                    <Select
                      value={
                        values.rentalReference?.awareOfMove === true
                          ? 'yes'
                          : values.rentalReference?.awareOfMove === false
                          ? 'no'
                          : 'no'
                      }
                      onChange={(e) =>
                        setFieldValue(
                          'rentalReference.awareOfMove',
                          e.target.value ? (e.target.value === 'yes' ? true : false) : undefined
                        )
                      }>
                      <option value="yes">{t('housingHistory.form.yes')}</option>
                      <option value="no">No</option>
                    </Select>
                  </FormGroup>
                )}
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <p>{t('housingHistory.form.consentHistory')}</p>
                </div>
              </>
            )}
          </>
        )}
      </Row>
      <br />
      <Row className="flex gap-10 center">
        <Button variant="secondary" onClick={onClose} width={95}>
          {t('buttonsActions.backButton')}
        </Button>
        <Button variant="primary" radius="25px" type="submit" onClick={handleSubmit} width={95}>
          {t('buttonsActions.next')}
        </Button>
      </Row>
    </Wrapper>
  );
};

export default ({ open, onClose, ...props }) => (
  <Modal
    closeOnOutsideClick={false}
    open={open}
    onClose={onClose}
    width="600px"
    height="800px"
    zIndex={700}>
    <AddressEdition onClose={onClose} {...props} />
  </Modal>
);
