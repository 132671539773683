import styled from 'styled-components';

const Text = styled.textarea`
  padding: 15px;
  background-color: var(--color-bg-primary);
  border-radius: 8px;
  outline: none;
  border: 2px solid transparent;
  min-height: 100px;
  resize: vertical;
  &:focus {
    border-color: var(--color-primary);
  }
`;

export default Text;
