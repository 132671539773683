import React from 'react';
import styled from 'styled-components';
import SearchInput from './search-input';
import Button from 'components/button';
import Icon from 'components/icon';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 999;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 0 0;
`;

const Search = styled(Button)`
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  color: #fff;
  background-color: #00a1ff;
  padding: 12px 16px;
  width: 90%;
`;

const BackButton = styled(Button)`
  padding: 0 20px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ({ onChange, onClose, orgId }) => {
  return (
    <Wrapper>
      <SearchWrapper>
        <BackButton onClick={onClose}>
          <Icon name="fa-solid fa-arrow-left" />
        </BackButton>
        <SearchInput orgId={orgId} onChange={onChange} autoFocus />
      </SearchWrapper>
    </Wrapper>
  );
};
