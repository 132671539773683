import React from 'react';
import styled from 'styled-components';
import Section from './section';
import Icon from 'components/icon';
import { addressCodeType } from 'helpers/enums';
import { renderPrettyDate } from 'utils/dateHelpers';
import { Trans } from 'react-i18next';

const DataList = styled.div`
  display: flex;
  gap: 30px;
  margin-top: 15px;
  flex-wrap: wrap;
`;
const DataBox = styled.div`
  font-size: 0.88em;
  .dataBox-label {
    font-weight: bold;
  }
`;

const List = styled.div`
  display: grid;
  gap: 15px;
`;

export default ({ application = {} }) => {
  const { addresses = [] } = application;
  return (
    <Section title={<Trans i18nKey="summary.housing.title">HOUSING HISTORY</Trans>}>
      <List>
        {addresses.filter(Boolean).map((address) => {
          return (
            <div key={address.uuid}>
              <div>
                <Icon name="fa-duotone fa-house-building" />{' '}
                <strong>
                  {address.current ? (
                    <Trans i18nKey="summary.housing.address_current">Current Address</Trans>
                  ) : (
                    <Trans i18nKey="summary.housing.address_past">Past Address</Trans>
                  )}
                </strong>
              </div>
              <DataList>
                <DataBox>
                  <div className="dataBox-label">
                    <Trans i18nKey="summary.housing.type">Type</Trans>
                  </div>
                  <div className="dataBox-text">{addressCodeType[address.type]}</div>
                </DataBox>
                <DataBox>
                  <div className="dataBox-label">
                    <Trans i18nKey="summary.housing.address">Address</Trans>
                  </div>
                  <div className="dataBox-text">
                    {address.formattedAddress?.formattedAddress || address?.address}
                  </div>
                </DataBox>
                <DataBox>
                  <div className="dataBox-label">
                    <Trans i18nKey="summary.housing.resided">Resided</Trans>
                  </div>
                  <div className="dataBox-text">
                    {renderPrettyDate(address.startDate, false)} -{' '}
                    {!address.current && address.endDate
                      ? renderPrettyDate(address.endDate, false)
                      : `Current`}
                  </div>
                </DataBox>
                {address.mortgageCompany && (
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.housing.mortgage_company">Mortgage Company</Trans>
                    </div>
                    <div className="dataBox-text">{address.mortgageCompany}</div>
                  </DataBox>
                )}
                {address.housingStatus && (
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.housing.housing_details">Housing Details</Trans>
                    </div>
                    <div className="dataBox-text">{address.housingStatus}</div>
                  </DataBox>
                )}
                {address.rentalReference?.pastRent && (
                  <DataBox>
                    <div className="dataBox-label">
                      <Trans i18nKey="summary.housing.monthly_rent">Monthly Rent</Trans>
                    </div>
                    <div className="dataBox-text">
                      {address.rentalReference?.pastRent?.renderPrettyCurrency()}
                    </div>
                  </DataBox>
                )}
              </DataList>
              {!!address.rentalReference &&
                (address.rentalReference?.name || address.rentalReference?.firstName) && (
                  <DataList>
                    <DataBox>
                      <div className="dataBox-label">
                        <Trans i18nKey="summary.housing.landlord">
                          Landlord/Management Company
                        </Trans>
                      </div>
                      <div className="dataBox-text">
                        <div>
                          {address.rentalReference?.name
                            ? address.rentalReference.name
                            : `${address.rentalReference?.firstName} ${address.rentalReference?.lastName}`}{' '}
                        </div>
                        {address?.rentalReference?.phone && (
                          <div>
                            <Trans i18nKey="summary.housing.phone">Phone</Trans>:{' '}
                            {address?.rentalReference?.phone}{' '}
                          </div>
                        )}
                        {address.rentalReference?.email && (
                          <div>
                            <Trans i18nKey="summary.housing.email">Email</Trans>:{' '}
                            {address.rentalReference?.email}
                          </div>
                        )}
                      </div>
                    </DataBox>
                  </DataList>
                )}
            </div>
          );
        })}
      </List>
    </Section>
  );
};
