import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

import { requiredFileCount } from 'utils/applicationHelper';
import Button from 'components/button';
import FormGroup from 'components/form-group';
import ShadowRender from 'components/shadow-render';

import { Row, Title, PageContainer, PageWrapper, Description } from '../common-styles';
import FileUpload from '../upload';

const StyledFormGroup = styled(FormGroup)`
  .group-label {
    font-weight: bold;
    font-size: 0.94em;
  }
`;
const TypeWarning = styled(Description)`
  color: red;
`;

const PDF_TYPES = ['application/pdf'];
const OTHER_TYPES = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

const Upload = ({
  label,
  application,
  field,
  requirePDF,
  required,
  localFiles = {},
  setLocalFiles
}) => {
  const existingFile = (application?.files || {})[field];
  return (
    <FileUpload
      required={required}
      autoUpload={true}
      localFiles={localFiles}
      setLocalFiles={setLocalFiles}
      locationId={application.locationId}
      applicationId={application._id}
      field={field}
      existingFile={existingFile}
      legacy={false}
      label={label}
      enforceFileTypes={true}
      fileTypes={requirePDF ? PDF_TYPES : PDF_TYPES.concat(OTHER_TYPES)}
    />
  );
};

const MAX_FILE_COUNT = 7;

export const IncomeFiles = ({
  application,
  location,
  manualVerification,
  onBack,
  onNext,
  onRefresh,
  hideBack
}) => {
  const [localFiles, setLocalFiles] = React.useState(application.files || {});

  const handleNext = () => {
    manualVerification({ type: 'income' }).then((success) => {
      if (!success) return;
      onRefresh();
      onNext();
    });
  };

  const requiredTaxDocCount = requiredFileCount('tax', application, location);
  const requiredBankStatementCount = requiredFileCount('bank', application, location);
  const requiredPayStubCount = requiredFileCount('paystub', application, location);
  const requirePDF = !!location?.companyInfo?.requirePDFUploads;

  const { stubUploadCount, bankUploadCount } = Object.keys(localFiles || {}).reduce(
    (counts, key) => {
      if (key.startsWith('pay') && localFiles[key]) {
        counts.stubUploadCount = counts.stubUploadCount + 1;
      }
      if (key.startsWith('bank') && localFiles[key]) {
        counts.bankUploadCount = counts.bankUploadCount + 1;
      }
      return counts;
    },
    { stubUploadCount: 0, bankUploadCount: 0 }
  );

  const requirementsMet =
    (!requiredPayStubCount ||
      application.files?.[`pay${requiredPayStubCount}`] ||
      localFiles?.[`pay${requiredPayStubCount}`]) &&
    (!requiredBankStatementCount ||
      application.files?.[`bank${requiredBankStatementCount}`] ||
      localFiles?.[`bank${requiredBankStatementCount}`]) &&
    (!requiredTaxDocCount ||
      application.files?.[`tax${requiredTaxDocCount}`] ||
      localFiles?.[`tax${requiredTaxDocCount}`]);

  return (
    <PageWrapper>
      <PageContainer>
        <TypeWarning>
          {t('income_documents.subDescription')}
          {!requirePDF && (
            <>
              <br />
              {t('income_documents.subDescription2')}
            </>
          )}
        </TypeWarning>
        <Row>
          <StyledFormGroup
            label={`${t('income_documents.paystub')} ${
              requiredPayStubCount ? `${stubUploadCount}/${requiredPayStubCount}` : ''
            }`}
            subText={
              requiredPayStubCount
                ? `${stubUploadCount} of ${requiredPayStubCount} required files uploaded`
                : ''
            }>
            <div>
              {Array.from({ length: MAX_FILE_COUNT }).map((unused, ix) => {
                const fileNum = ix + 1;
                return fileNum !== 1 &&
                  !localFiles?.[`pay${fileNum - 1}`] &&
                  !application.files?.[`pay${fileNum - 1}`] ? null : (
                  <Upload
                    key={fileNum}
                    label={`${t('income_documents.pay')} #${fileNum}`}
                    field={`pay${fileNum}`}
                    required={!!requiredPayStubCount && ix < requiredPayStubCount}
                    localFiles={localFiles}
                    setLocalFiles={setLocalFiles}
                    application={application}
                    requirePDF={requirePDF}
                  />
                );
              })}
            </div>
          </StyledFormGroup>
          <StyledFormGroup
            label={`${t('income_documents.bank')} ${
              requiredBankStatementCount ? `${bankUploadCount}/${requiredBankStatementCount}` : ''
            }`}
            subText={
              requiredBankStatementCount
                ? `${bankUploadCount} of ${requiredBankStatementCount} required files uploaded`
                : ''
            }>
            <div>
              {Array.from({ length: MAX_FILE_COUNT }).map((unused, ix) => {
                const fileNum = ix + 1;
                return fileNum !== 1 &&
                  !localFiles?.[`bank${fileNum - 1}`] &&
                  !application.files?.[`bank${fileNum - 1}`] ? null : (
                  <Upload
                    key={fileNum}
                    label={`${t('income_documents.bank')} #${fileNum}`}
                    field={`bank${fileNum}`}
                    required={!!requiredBankStatementCount && ix < requiredBankStatementCount}
                    localFiles={localFiles}
                    setLocalFiles={setLocalFiles}
                    application={application}
                    requirePDF={requirePDF}
                  />
                );
              })}
            </div>
          </StyledFormGroup>
          {requiredTaxDocCount > 0 && (
            <StyledFormGroup label={t('income_documents.tax')}>
              <div>
                {Array.from({ length: MAX_FILE_COUNT }).map((unused, ix) => {
                  const fileNum = ix + 1;
                  return fileNum !== 1 &&
                    !localFiles?.[`tax${fileNum - 1}`] &&
                    !application.files?.[`tax${fileNum - 1}`] ? null : (
                    <Upload
                      key={fileNum}
                      label={`${t('income_documents.tax')} #${fileNum}`}
                      field={`tax${fileNum}`}
                      required={!!requiredTaxDocCount && ix < requiredTaxDocCount}
                      localFiles={localFiles}
                      setLocalFiles={setLocalFiles}
                      application={application}
                      requirePDF={requirePDF}
                    />
                  );
                })}
              </div>
            </StyledFormGroup>
          )}
        </Row>
        <Row className="center flex gap-10">
          {!hideBack && (
            <Button radius="25px" width={90} variant="secondary" onClick={onBack}>
              {t('buttonsActions.backButton')}
            </Button>
          )}
          <Button
            disabled={!requirementsMet}
            width={90}
            radius="25px"
            variant="primary"
            onClick={handleNext}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};

export default (props) => {
  const { t } = useTranslation();

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('income_documents.title')}</Title>
        </Row>
        <Row>
          <Description className="text-center">
            {props?.location?.companyInfo?.fileUploadMessage ? (
              <ShadowRender html={props?.location?.companyInfo?.fileUploadMessage} />
            ) : (
              t('income_documents.description')
            )}
          </Description>
        </Row>
      </PageContainer>
      <IncomeFiles {...props} />
    </PageWrapper>
  );
};
