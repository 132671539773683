export default () => [
  {
    title: 'Who is VeriFast?',
    description:
      'Verifast is a Digital Verification Platform that allows consumers to securely share their banking, payroll, tax and government ID information for the purposes of quickly qualifying for a rental property.'
  },
  {
    title: 'Why do you need my bank login?',
    description:
      "To verify your income, we need to collect the deposit history from your bank. We require your permission to access this data through a secure connection provided by your bank. Verifast only has access to access this data and nothing else, with storing your credentials. Note: it's important that you login to your primary deposit account for income (i.e. payroll and other sources of income). We only have the ability to read bank statements and transaction info. We cannot login, make any charges, withdraw any money, make unauthorized transfers, or anything that would require your explicit permission. Once we are done collecting transaction history, we disconnect your bank account."
  },
  {
    title: 'How do I login to my bank account?',
    description:
      'When prompted to connect your bank accountOnce you find your banking institution from the list provided, click the bank icon and key in your banking Username and Password as prompted.  If you have 2 Factor Authentication enabled you will need to be prepared to receive a text code. You may need to contact your bank to make sure 2FA is properly enabled.'
  },
  {
    title: "I don't like sharing my personal information online. Is my financial data secure?",
    description:
      'We understand sharing sensitive financial information feels intimidating, which is why we don’t store any of your sensitive information. The data transmitted online when connecting to your bank is less than what’s traditionally shared by uploading a download or photo of your bank statement and pay stubs. Verifast’s online income verification using gold standard security practices such as HTTPS and 256‐bit encryption. Your user name and passwords are never displayed, viewed, or stored. Submitting your information through Verifast is more secure than emailing, faxing or mailing copies of your bank statements. This is the safest, fastest, and most convenient way for you to submit your information to a property manager for approval.'
  },
  {
    title: 'What information will be shared from my bank account?',
    description: (
      <ul>
        <li>Account holder</li>
        <li>Account balance</li>
        <li>Total recurring net income</li>
        <li>Average recurring monthly net income</li>
        <li>Total non-recurring net income</li>
        <li>Average non-recurring monthly net income</li>
        <li>Individual income streams, recurring and non-recurring</li>
        <li>Line item details for each deposit</li>
      </ul>
    )
  },
  {
    title: 'Can you verify income another way?',
    description:
      'Automated income verification from Verifast provides enhanced privacy, security, convenience, and speed for you and the property manager. By electronically submitting this information directly from your bank it increases accuracy and completeness, potentially qualifying faster for your desired home. Verifast does allow for you to upload banks statements manually, but applicants choosing this approach will be considered after others that provided this information by connecting to their bank.'
  },
  {
    title: 'What if I don’t have online banking?',
    description:
      'Most banks offer an easy online banking enrollment process that only takes a few minutes to complete, so you visit your bank’s website and enroll in its online banking service. Once you’re enrolled, return to the link provided and continue where you left off.'
  },
  {
    title: 'Why do I have to upload my ID and a photo of myself?',
    description:
      'As part of continuous efforts to avoid fraud and increase security, we have implemented an identity verification process to help ensure that you are you. Please note that any information collected from our users will not be sold, shared, or rented to others in in any manner.'
  },
  {
    title: 'What kind of ID do I need for verification?',
    description: (
      <div>
        At this time, you are required to use a current and valid US government-issued ID:
        <ul>
          <li>Driver’s License</li>
          <li>State ID</li>
          <li>Passport</li>
          <li>Passport Card</li>
          <li>Permanent Resident Card</li>
          <li>Employment Authorization Card</li>
        </ul>
        The following IDs will not be accepted:
        <ul>
          <li>Any expired ID</li>
          <li>Non-US ID</li>
          <li>US non-government-issued ID (school ID, bank card, company badge, etc.)</li>
          <li>Social Security card</li>
          <li>Temporary paper Driver’s License or State ID</li>
          <li>Any photocopies, electronic replicas, or screenshots</li>
        </ul>
      </div>
    )
  },
  {
    title: 'What happens if I fail the ID verification process?',
    description: (
      <div>
        If you fail identity verification during the application process, you will not be able to
        proceed. To avoid a failure, try the following: For a Government IDs:
        <ul>
          <li>Make sure the text on the ID is clear and readable</li>
          <li>Make sure the photo of your face on the ID is visible</li>
          <li>Reduce glare by moving away from direct light</li>
          <li>Make sure the ID is not cutoff or obstructed by your finger</li>
          <li>Make sure the image is not blurry</li>
          <li>Try another ID document type if available</li>
        </ul>
        For a Self Video:
        <ul>
          <li>Take off your glasses</li>
          <li>Reduce glare by moving away from direct light</li>
          <li>Make sure your face is visible and well lit</li>
          <li>Make sure you turn your face left and right</li>
        </ul>
      </div>
    )
  },
  {
    title: 'How do I enable camera permissions to take a selfie?',
    description: (
      <div>
        How do I enable camera permissions to take a selfie? Here are some ways to enable camera
        permissions in different browsers and different devices.
        <ol>
          <li>
            If you're on Chrome, please follow the steps in the relevant link below:
            <ul>
              <li>
                <a href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DDesktop&hl=en&oco=0">
                  For Desktop
                </a>
              </li>
              <li>
                <a href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DiOS&hl=en&oco=0">
                  For iPhone & iPad
                </a>
              </li>
              <li>
                <a href="https://support.google.com/chrome/answer/2693767?co=GENIE.Platform%3DAndroid&hl=en&oco=0">
                  For Android
                </a>
              </li>
            </ul>
          </li>
          <li>
            If you're on Firefox, please follow the steps in the link below:
            <ul>
              <li>
                <a href="https://support.mozilla.org/en-US/kb/how-manage-your-camera-and-microphone-permissions#w_using-firefoxaos-address-bar-to-clear-camera-or-microphone-permissions-for-a-site">
                  For Mobile or Desktop
                </a>
              </li>
            </ul>
          </li>

          <li>
            If you're on Safari, please follow the relevant steps below:
            <ul>
              <li>
                <a href="https://support.apple.com/guide/safari/websites-ibrwe2159f50/13.0/mac/10.15">
                  For Desktop
                </a>
              </li>
              <li>
                For mobile devices below iOS 13, check your "Camera & Microphone Access" in your
                Settings:
                <ul>
                  <li>Go to Settings on your device</li>
                  <li>Scroll down and open Safari</li>
                  <li>Ensure "Camera" is set to "Ask" or "Allow"</li>
                </ul>
              </li>
              <li>
                For mobile devices on iOS 13, tap the "AA" icon in URL bar in the top left. Then tap
                "Website Settings", which will enable you to set camera permissions to "Allow or
                Ask" instead of "Deny".
              </li>
            </ul>
          </li>
          <li>
            If you're on Microsoft Edge, please follow the steps in the link below:
            <li>
              <a href="https://support.microsoft.com/en-us/windows/windows-camera-microphone-and-privacy-a83257bc-e990-d54a-d212-b5e41beba857">
                For Desktop
              </a>
            </li>
          </li>

          <li>
            If you're viewing the Persona verification flow via an iOS or Android app, check your
            device settings:
            <ul>
              <li>Go to Settings on your device</li>
              <li>Go to Apps</li>
              <li>Find the app that you wish to enable camera permissions for</li>
              <li>Find the App Settings and allow camera permissions</li>
            </ul>
          </li>
        </ol>
      </div>
    )
  },
  {
    title: 'What are some tips for taking photos of my ID?',
    description: (
      <div>
        General Tips:
        <ul>
          <li>Make sure the lighting in the room is bright enough to capture the ID.</li>
          <li>If there is glare in your photo, move away from direct light to reduce glare.</li>
          <li>Make sure the text on your ID is readable (and not worn).</li>
          <li>Make sure you are not covering any part of the ID (e.g. with your finger).</li>
          <li>Make sure the photo of your face on the ID is visible.</li>
          <li>Make sure the image is not blurry.</li>
          <li>If your ID continues to fail, try another ID type if available.</li>
          <li>
            If your camera quality is poor (e.g. the image is grainy or small) or you do not have a
            camera on your device, you can upload a photo of your ID and make sure it meets the
            above requirements. You can also text message or e-mail yourself a link to continue on
            your phone with your phone's camera.
          </li>
        </ul>
        For ID Cards:
        <ul>
          <li>
            If possible, place the ID card on a flat surface (e.g. a table) with a dark background
            to reduce blur.
          </li>
          <li>
            Move the ID card as close to the camera as possible, while making sure all four corners
            of the ID are captured within the photo.
          </li>
        </ul>
        For Passports:
        <ul>
          <li>
            Hold the passport open to the main passport page (showing your photo and personal
            details).
          </li>
          <li>Make sure you are not blocking any part of the main passport page.</li>
          <li>
            Make sure the MRZ (the symbols and letters at the bottom of your passport page) is fully
            visible and can be read clearly in your photo.
          </li>
          <li>
            Because passport pages can be glossy, please make sure the lighting is good and move
            away from a direct lighting source to avoid glare or reflection
          </li>
        </ul>
      </div>
    )
  }
];
