import useSessionStorage from './useSessionStorage';

export const useContextId = () => {
  const [contextId] = useSessionStorage('contextId');
  return contextId;
};
export const useResetCode = () => {
  const [contextId] = useSessionStorage('resetCode');
  return contextId;
};
export const useRefCode = () => {
  const [contextId] = useSessionStorage('refCode');
  return contextId;
};
export const useOrgId = () => {
  const [contextId] = useSessionStorage('orgId');
  return contextId;
};
