import React from 'react';
import { useTranslation } from 'react-i18next';
import Select from './select';

const options = ['partner', 'friend', 'family', 'mentor', 'other'];

export default ({ className = '', ...props }) => {
  const { t } = useTranslation();

  return (
    <Select className={className} {...props}>
      <option value="">{t('components.relationship.empty')}</option>
      {options.map((opt, ix) => (
        <option value={opt} key={ix}>
          {t(`components.relationship.${opt}`)}
        </option>
      ))}
    </Select>
  );
};
