import React from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { UserContext, CompanyContext, useNavigateWithContext, useUiState } from 'state';
import Avatar from 'components/avatar';
import Icon from 'components/icon';
import Button from 'components/button';
import SelectLanguage from 'components/language-select';
import ContactUs from 'components/contactUs';
import useAudience from 'hooks/useAudience';
import Menu from '../menu';

const Wrapper = styled.header`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 60px;
  padding: 0 20px;
  flex-shrink: 0;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
  .companyLogo {
    max-width: 200px;
    max-height: 60px;
    padding: 10px 0px;
  }
`;

const Zone = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  &:nth-child(2) {
    justify-content: center;
  }
  &:nth-child(3) {
    justify-content: flex-end;
  }
`;

const MenuButton = styled(Button)`
  width: 40px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  margin: 0;
`;

// eslint-disable-next-line no-undef
const APTLY_CHAT_ACTIVE = `${process.env.REACT_APP_APTLY_CHAT}`.indexOf('on') >= 0;

export default () => {
  const [audience] = useAudience();
  const navigate = useNavigateWithContext();
  const isMobile = useMediaQuery({ maxWidth: 740 });
  const { userContext } = React.useContext(UserContext);
  const { companyContext } = React.useContext(CompanyContext);
  const [menuDetails, setMenuDetails] = React.useState(); //  anchorEl, anchorDirection, onClose, open/>
  const { uiState, updateUiState } = useUiState();
  if (uiState.hideHeader) return null;
  const navigateLocation = !userContext?._id ? audience?.paths?.public : audience?.paths?.home;
  const logoOnly =
    !userContext?.accessToken?.token &&
    !APTLY_CHAT_ACTIVE &&
    (isMobile || !companyContext?.enableSpanish);

  return (
    <Wrapper logoOnly={logoOnly}>
      <Zone>
        {userContext?.accessToken?.token && (
          <MenuButton onClick={(e) => setMenuDetails({ anchorEl: e.target })}>
            <Icon name="fa-light fa-bars-sort" size={20} />
          </MenuButton>
        )}
      </Zone>
      <Zone>
        {companyContext?.logo && (
          <img
            onClick={() => navigate(navigateLocation)}
            className="companyLogo"
            src={companyContext?.logo}
          />
        )}
        {!companyContext?.logo && companyContext?.companyName && (
          <span onClick={() => navigate(navigateLocation)}>{companyContext?.companyName}</span>
        )}
      </Zone>
      <Zone>
        {!isMobile && <SelectLanguage />}
        {APTLY_CHAT_ACTIVE && <ContactUs />}
        {!!userContext?.accessToken?.token && (
          <Avatar
            size={35}
            firstName={userContext.firstName}
            lastName={userContext.lastName}
            imageUrl={userContext.imageUrl}
            defaultImageUrl={
              'https://d19uz2b1vsd3mq.cloudfront.net/p/assets/images/avatars/ToyFaces_Colored_BG_111.jpg'
            }
            onClick={() => navigate('profile')}
          />
        )}
      </Zone>
      <Menu {...menuDetails} open={!!menuDetails} onClose={() => setMenuDetails()} />
    </Wrapper>
  );
};
