import React from 'react';
import styled from 'styled-components';
import { Row, Title, PageContainer, PageWrapper } from '../common-styles';
import { Trans } from 'react-i18next';
import { renderToString } from 'react-dom/server';
import { ServerStyleSheet, StyleSheetManager } from 'styled-components';
import Button from 'components/button';

const sheet = new ServerStyleSheet();

const Logo = styled.div`
  border-radius: 4px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ image }) => image});
  min-height: 75px;
  max-height: 150px;
  border-radius: 0px;
`;

const DataList = styled.div`
  display: flex;
  justify-content: center;
  gap: 50px;
  margin-top: 15px;
  > div {
    font-size: 0.81em;
    .label {
      color: #777;
      text-transform: uppercase;
    }
    .data {
      font-weight: bold;
      text-transform: lowercase;
    }
  }
`;

const ReceiptId = styled.div`
  font-size: 0.8em;
  color: #777;
`;

const Banner = styled.div`
  padding: 30px 20px;
  background-color: #f5f5f5;
  -webkit-print-color-adjust: exact;
  position: relative;

  .price {
    font-size: 5em;
    font-weight: bold;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 10px;
    -webkit-print-color-adjust: exact;
    background-size: 20px 20px;
    background-image: radial-gradient(circle at 10px -5px, transparent 12px, #fff 13px);
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 15px;
    -webkit-print-color-adjust: exact;
    background-size: 40px 20px;
    background-image: radial-gradient(circle at 10px 15px, #fff 12px, transparent 13px);
  }
`;
const handlePrint = (props) => {
  const content = renderToString(
    <StyleSheetManager sheet={sheet.instance}>
      <Receipt {...props} />
    </StyleSheetManager>
  );
  const styleTags = sheet.getStyleTags();
  const printLayout = `
    <html>
      <head>
        <title>Print Application</title>
        <style>
        @import url('https://fonts.googleapis.com/css2?family=Mukta:wght@200;300;400;500;600;700;800&display=swap');
        body {
          font-family: 'Mukta', sans-serif;
        }
        </style>
        ${styleTags}
        <script>
          window.onload = () => {
            setTimeout(() => {
              print();
            }, 300);
          }
        </script>
      <script src="https://kit.fontawesome.com/00cde30716.js"  crossorigin="anonymous"></script>
      </head>
      <body>
        ${content}
      </body>
    </html>`;
  const w = window.open('', 'print', `width=${screen.width},height=${screen.height}`);
  w.document.write(printLayout);
  w.document.close();
};

const Receipt = ({ application, location = {} }) => {
  if (!application.payments?.totalPaid) {
    return <Trans i18nKey="appFeeStep.noReceipt">No receipt on file</Trans>;
  }

  const { paidAt, totalPaid, id, currency } = application.payments || {};

  const { coverPhoto = [], marketingImages = [] } = location?.applicationConfig || {};

  const photo = location?.companyInfo?.logo || coverPhoto?.[0] || marketingImages?.[0];

  return (
    <PageWrapper>
      <PageContainer>
        <Banner>
          {photo && <Logo image={photo} />}
          <Row className="center text-center">
            <Title>
              <Trans i18nKey="appFeeStep.receiptPrefix">Application fee for</Trans> {location.name}
            </Title>
          </Row>
          {/* <DataList>
            {!!marketRent && (
              <div>
                <div className="label">
                  <Trans i18nKey="aplicationItem.rent" />
                </div>
                <div className="data">{marketRent}/mo</div>
              </div>
            )}
            {!!availableDate && (
              <div>
                <div className="label">
                  <Trans i18nKey="aplicationItem.availableDate" />
                </div>
                <div className="data">{availableDate}</div>
              </div>
            )}
          </DataList> */}
          <div className="receiptBox">
            <Row className="center">
              <div className="price">{(totalPaid / 100)?.renderPrettyCurrency(true, currency)}</div>
              {paidAt && (
                <div className="price-legend">{new Date(paidAt).renderLocale({ time: false })}</div>
              )}
            </Row>
            <Row className="center">
              <p style={{ textAlign: 'center' }}>
                <Trans i18nKey="appFeeStep.description" />
              </p>
            </Row>
            {!!id && (
              <Row className="center">
                <ReceiptId>
                  <Trans i18nKey="appFeeStep.verification_code">Receipt #:</Trans>
                  <br />
                  {id}
                </ReceiptId>
              </Row>
            )}
          </div>
        </Banner>
      </PageContainer>
    </PageWrapper>
  );
};

export default ({ application, location }) => {
  return (
    <Button variant="link" onClick={() => handlePrint({ application, location })}>
      <Trans i18nKey="summary.fee_details.download_receipt">Print Receipt</Trans>
    </Button>
  );
};
