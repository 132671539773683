import React from 'react';
import { isEmpty } from 'lodash';
import styled from 'styled-components';
import { BoxSection } from 'components/section';
import {
  getCreditScoreLabel,
  getCreditRatingPercent,
  getScreeningStatus,
  SCREENING_STEP_STATUSES_DONE,
  toMoneyString,
  hasLocationException,
  getCreditRatingRange
} from 'pages/approver/share/detail/application-view/helpers';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${({ gridSize = 5 }) =>
    [...Array(gridSize).keys()].map(() => '1fr').join(' ')};};
  gap: 15px;
`;

const Box = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  ${({ onClick }) => (onClick ? 'cursor:pointer;' : '')}
  ${({ hasFooter }) => (hasFooter ? 'padding-bottom:20px;' : '')}
  &.credit {
    align-items: center;
    max-width: 225px;
  }
  .title {
    text-transform: uppercase;
    color: #c8d6df;
    font-size: 14px;
  }
  .content {
    margin-top: 5px;
    font-weight: bold;
    font-size: 24px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .smallHighlight {
      font-size: 14px;
      font-weight: normal;
    }
    .creditScoreGrade {
      display: flex;
      align-items: baseline;
      gap: 5px;
    }
  }
  .footer {
    position: absolute;
    bottom: 0px;
  }
`;

const encodeData = ({ application, applicant, applicationConfig }) => {
  const locationInfo = location || application?.location;
  let creditScoreGrade, creditStatus, creditScoreGradePercent, creditPendingCount;
  let monthlyIncome, reportedGrossIncome, verifiedByVerifast;
  let criminalMsg, criminalPendingCount, criminalRun;
  let evictionMsg, evictionPendingCount, evictionRun;
  let creditDisabled = applicationConfig?.disabledSections?.credit;

  creditStatus = getScreeningStatus('credit', applicant);
  creditScoreGrade = applicant.creditRating;
  creditScoreGradePercent = !applicant.creditScore
    ? getCreditRatingPercent(creditScoreGrade)
    : undefined;
  if (applicant.creditScore) {
    const csl = getCreditScoreLabel(applicant.creditScore);
    creditScoreGrade = csl?.label;
    creditScoreGradePercent = csl?.percent;
  }

  reportedGrossIncome = applicant.reportedGrossIncome;
  monthlyIncome = applicant.monthlyNetIncome;
  verifiedByVerifast = applicant.verifiedByVerifast;

  const criminalFlags = applicant?.criminalRedFlags || [];
  criminalMsg =
    criminalFlags.length === 0 && !SCREENING_STEP_STATUSES_DONE.includes(applicant?.criminalStatus)
      ? 'Pending check'
      : criminalFlags.join(' ') || 'No records found';
  criminalRun = SCREENING_STEP_STATUSES_DONE.includes(applicant?.criminalStatus);

  const evictionFlags = applicant?.evictionRedFlags || [];
  evictionMsg =
    evictionFlags.length === 0 && !SCREENING_STEP_STATUSES_DONE.includes(applicant?.evictionStatus)
      ? 'Pending check'
      : evictionFlags.join(' ') || 'No records found';
  evictionRun = SCREENING_STEP_STATUSES_DONE.includes(applicant?.evictionStatus);

  //load up any applicant specific exceptions
  if (!creditDisabled && applicationConfig?.enableRegionalScreeningFilters && !isEmpty(applicant)) {
    creditDisabled = hasLocationException({
      address: locationInfo?.address,
      category: 'credit',
      application: applicant
    });
  }

  const creditRange = getCreditRatingRange(creditScoreGrade);

  return {
    creditRange,
    creditDisabled,
    creditScoreGrade,
    creditStatus,
    creditPendingCount,
    monthlyIncome,
    reportedGrossIncome,
    criminalRun,
    criminalMsg,
    criminalPendingCount,
    evictionRun,
    evictionMsg,
    evictionPendingCount
  };
};

export default ({
  applicant,
  application,
  title,
  handleActions,
  applicationConfig,
  className,
  location
}) => {
  if (!applicant) return <></>;

  const {
    creditRange,
    creditDisabled,
    creditScoreGrade,
    creditStatus,
    creditPendingCount,
    monthlyIncome,
    reportedGrossIncome,
    criminalRun,
    criminalMsg,
    criminalPendingCount,
    evictionRun,
    evictionMsg,
    evictionPendingCount
  } = encodeData({
    application,
    applicant,
    location,
    applicationConfig
  });

  const gridSize = [
    !creditDisabled,
    !applicationConfig?.disabledSections?.criminal,
    !applicationConfig?.disabledSections?.eviction,
    !applicationConfig?.disabledSections?.netIncome && !!monthlyIncome,
    !applicationConfig?.disabledSections?.grossIncome && !!reportedGrossIncome
  ].filter((z) => z).length;

  //no data, so nothing to show
  if (!gridSize) {
    return null;
  }

  return (
    <BoxSection title={title || 'APPLICANT SUMMARY'}>
      <Wrapper gridSize={gridSize} className={className}>
        {!creditDisabled && (
          <Box>
            <div className="title">CREDIT</div>
            <div className="content">
              {!creditScoreGrade ? (
                <div>
                  {!creditStatus?.done && !creditScoreGrade
                    ? 'PENDING RESULTS'
                    : 'NO SCORE AVAILABLE'}
                </div>
              ) : (
                <div className="creditScoreGrade">
                  <span>{creditScoreGrade}</span>
                  {!!creditRange && (
                    <span className="smallHighlight">
                      {creditRange ? `(${creditRange.min} - ${creditRange.max})` : ''}
                    </span>
                  )}
                </div>
              )}
              {creditPendingCount > 0 && (
                <div style={{ textAlign: 'center' }}>
                  *{creditPendingCount} applicant{creditPendingCount !== 1 ? 's' : ''} pending
                  credit check
                </div>
              )}
            </div>
          </Box>
        )}
        {!applicationConfig?.disabledSections?.netIncome && !!monthlyIncome && (
          <Box>
            <div className="title">Verified Net Income</div>
            <div className="content">
              <div>
                {toMoneyString(monthlyIncome)} <span className="smallHighlight">/ MONTH</span>
              </div>
            </div>
          </Box>
        )}
        {!applicationConfig?.disabledSections?.grossIncome && !!reportedGrossIncome && (
          <Box onClick={() => handleActions({ id: 'scroll-to', data: 'work-history' })}>
            <div className="title">Reported Gross Income</div>
            <div className="content">
              <div>{toMoneyString(reportedGrossIncome)}</div>
            </div>
          </Box>
        )}
        {!applicationConfig?.disabledSections?.criminal && (
          <Box
            onClick={
              criminalRun
                ? () => handleActions({ id: 'scroll-to', data: 'criminal-history' })
                : undefined
            }>
            <div className="title">Criminal</div>
            <div className="content">{criminalMsg}</div>
            {creditPendingCount > 0 && (
              <div>
                *{criminalPendingCount} applicant{criminalPendingCount !== 1 ? 's' : ''} pending
                criminal check
              </div>
            )}
          </Box>
        )}
        {!applicationConfig?.disabledSections?.eviction && (
          <Box
            onClick={
              evictionRun
                ? () => handleActions({ id: 'scroll-to', data: 'eviction-history' })
                : undefined
            }>
            <div className="title">EVICTION</div>
            <div className="content">{evictionMsg}</div>
            {evictionPendingCount > 0 && (
              <div>
                *{evictionPendingCount} applicant{evictionPendingCount !== 1 ? 's' : ''} pending
                eviction check
              </div>
            )}
          </Box>
        )}
      </Wrapper>
    </BoxSection>
  );
};
