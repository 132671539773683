import React from 'react';
import styled from 'styled-components';
import Modal, { ModalScrollable, ModalHeader } from 'components/modal';
import Icon from 'components/icon';
import { useTranslation, Trans } from 'react-i18next';
import useConfirmDialog from 'hooks/useConfirmDialog';
import LocationList from '../../locations-list';
import { LocationPreview } from '../../location';
import { CHANGE_APP_LOCATION } from 'dal/applications';
import { UserContext } from 'state';
import toast from 'react-hot-toast';

const StyledModal = styled(Modal)`
  margin: 20px;
  max-width: 1300px;
`;
const Warning = styled.div`
  border: 1px solid #ddd;
  display: flex;
  padding: 20px;
  gap: 10px;
  line-height: 1.4;
  background-color: #fffed1;
  border-color: #f7b500;
  margin-bottom: 20px;
`;

const StyledLocations = styled(LocationList)`
  .locationsGrid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .gridLocationItem {
    width: 350px;
  }
`;
const PreviewHeader = styled.div`
  position: absolute;
  top: 60px;
  padding: 20px;
  cursor: pointer;
`;
const PreviewBody = styled(ModalScrollable)`
  margin-top: 60px;
  padding: 0 20px 20px;
`;

export default ({ open = false, onClose, application, location, onRefresh, setLoading }) => {
  const [step, setStep] = React.useState('initial');
  const [selection, setSelection] = React.useState();
  const { userContext } = React.useContext(UserContext);
  const { t } = useTranslation();
  const confirmDialog = useConfirmDialog();

  const locationName = location?.name || location?.applicationConfig?.marketingTitle || '';

  const changeLocation = (newLocation) => {
    const newName = newLocation?.name || newLocation?.applicationConfig?.marketingTitle || '';
    confirmDialog({
      width: '700px',
      title: (
        <span>
          <Trans i18nKey="components.change_location.confirmationTitle">
            Are you sure you want to change your application from "{locationName}" to "{newName}"?
          </Trans>
        </span>
      ),
      cancelLabel: t('no'),
      confirmLabel: t('yes'),
      description: (
        <span>
          <Trans i18nKey="components.change_location.confirmation">
            Changing your location may affect your approval odds and will remove you from
            consideration at "{locationName}". If there are a large number of applicants for "
            {locationName}" then you may lose your spot! If you have any questions about our
            policies around property transfers please contact our office.
          </Trans>
        </span>
      ),
      onConfirm: () => {
        setLoading(true);
        CHANGE_APP_LOCATION({
          userContext,
          locationId: application.locationId,
          applicationId: application._id,
          newLocationId: newLocation._id
        })
          .then(() => {
            onRefresh();
            setLoading(false);
            onClose();
          })
          .catch((err) => {
            setLoading(false);
            console.error({ errData: err.data, err });
            toast.error(err.data?.message ? err.data?.message : err.message || err.name);
          });
      }
    });
  };

  const selectContent = (
    <div>
      <Trans i18nKey="components.change_location.select">SELECT</Trans>
    </div>
  );

  //Don't allow location change by sub apps
  if (['guarantor', 'co-occupant'].includes(application?.type) || application?.parentId) {
    return (
      <StyledModal
        closeOnOutsideClick={true}
        open={open}
        onClose={() => {
          onClose && onClose();
        }}
        width="auto"
        height="auto">
        <ModalHeader>
          <Trans i18nKey="components.change_location.title">Change Location</Trans>
        </ModalHeader>
        <ModalScrollable>
          <Trans i18nKey="components.change_location.forbidden">
            Changing your application location may only be done by the primary applicant
            {application.relatedAppData?.parentApplication?.name
              ? ` (${application.relatedAppData?.parentApplication?.name})`
              : ''}
            .
          </Trans>
        </ModalScrollable>
      </StyledModal>
    );
  }

  return !open ? null : (
    <StyledModal
      closeOnOutsideClick={true}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      width="100%"
      height="100%">
      <ModalHeader>
        <Trans i18nKey="components.change_location.title">Change Location</Trans>
      </ModalHeader>
      {step === 'initial' && (
        <ModalScrollable>
          <Warning>
            <Trans i18nKey="components.change_location.confirmation">
              Changing your location may affect your approval odds and will remove you from
              consideration at "{locationName}". If there are a large number of applicants for "
              {locationName}" then you may lose your spot! If you have any questions about our
              policies around property transfers please contact our office.
            </Trans>
          </Warning>
          <StyledLocations
            hideHeader={true}
            selectContent={selectContent}
            onSelect={(location) => {
              setSelection(location);
              changeLocation(location);
            }}
            onPreview={(location) => {
              setSelection(location);
              setStep('preview');
            }}
          />
        </ModalScrollable>
      )}
      {step === 'preview' && (
        <>
          <PreviewHeader onClick={() => setStep('initial')}>
            <Icon name="fa-solid fa-chevron-left" />{' '}
            <Trans i18nKey="components.change_location.preview_breadcrumb">ALL LOCATIONS </Trans>
          </PreviewHeader>
          <PreviewBody>
            <LocationPreview
              selectContent={selectContent}
              locationId={selection?._id}
              onSelect={(location) => changeLocation(location)}
            />
          </PreviewBody>
        </>
      )}
    </StyledModal>
  );
};
