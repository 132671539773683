import styled from 'styled-components';

const Progress = styled.div`
  height: ${({ height }) => height}px;
  width: 100%;
  border-radius: 25px;
  overflow: hidden;
  background-color: var(--color-bg-primary);
  margin: 5px 0px;
  &::after {
    display: block;
    content: '';
    height: 100%;
    width: ${({ progress }) => progress}%;
    border-radius: 25px;
    background-color: ${({ color = '#00a1ff' }) => color};
  }
`;

export default ({ height = 10, progress = 0, color }) => (
  <Progress height={height} progress={progress} color={color} />
);
