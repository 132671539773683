import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  padding: 5px;
  iframe {
    width: 100%;
    height: 100%;
    border: none;
  }
`;

export default ({ block, mode }) => {
  return (
    <Wrapper className={`mode-${mode}`}>
      {block.value && <iframe src={block.value} frameBorder="0" allowFullScreen />}
    </Wrapper>
  );
};
