import React from 'react';
import toast from 'react-hot-toast';

import { UserContext, useNavigateWithContext } from 'state';
import useAudience from 'hooks/useAudience';
import { VALID_USER_SESSION } from 'dal/user';
import LoginContainer from './login-container';
import InfoContainer from './info-container';
import TwoPaneLayout from '../layout/two-pane';

export default () => {
  const [audience] = useAudience();
  const { userContext } = React.useContext(UserContext);
  const navigate = useNavigateWithContext();

  //auto login if session is good
  React.useEffect(() => {
    if (VALID_USER_SESSION(userContext?.accessToken)) {
      navigate(audience.paths.home);
    }
  }, []);

  //alert whatever message we have from inside...
  React.useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get('msg')) {
      toast.error(queryParams.get('msg'));
    }
  }, []);

  return <TwoPaneLayout leftPane={<InfoContainer />} rightPane={<LoginContainer />} />;
};
