import React from 'react';
import styled from 'styled-components';
import { Routes, Route } from 'react-router-dom';
import Search from './search';
import { Property } from './property';
import { APIProvider } from '@vis.gl/react-google-maps';
const API_KEY = 'AIzaSyDwNJevslox8yiM06faMhmlBhC9_jLpjq4';

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  *:not(i) {
    box-sizing: border-box;
  }
`;

export default () => {
  return (
    <APIProvider apiKey={API_KEY}>
      <Wrapper>
        <Routes>
          <Route
            path=":orgId/property/:propertyId/:floorplanId"
            element={<Property isFullPage={true} />}
          />
          <Route path=":orgId/property/:propertyId" element={<Property isFullPage={true} />} />
          <Route path=":orgId/*" element={<Search />} />
        </Routes>
      </Wrapper>
    </APIProvider>
  );
};
