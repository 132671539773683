import React from 'react';
import styled from 'styled-components';
import { Context, getRollbarFromContext } from '@rollbar/react';
import { t } from 'i18next';
const ErrorWrapper = styled.div`
  font-size: 1.5em;
  text-align: center;
  margin: auto;
`;
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }
  static contextType = Context;

  static getDerivedStateFromError(error) {
    console.error(error);
    return { hasError: true };
  }

  componentDidMount() {
    this.rollbar = getRollbarFromContext(this.context);
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
    const rollbar = getRollbarFromContext(this.context);
    if (rollbar) {
      rollbar.error(error, errorInfo);
    }
  }
  render() {
    if (this.state.hasError) {
      const { fallback } = this.props;
      return fallback ? (
        fallback
      ) : (
        <ErrorWrapper
          dangerouslySetInnerHTML={{
            __html: this.props.errorMessage || `&#9785;<br /> ${t('somethingError')}`
          }}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
