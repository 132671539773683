import React from 'react';
import styled from 'styled-components';
import { lighten } from 'polished';
import { alert as alertColor } from 'styles/colors';
import { Card, CardDataGroup, CardDataDisplay } from './common';
import Icon from 'components/icon';

const WrappableContent = styled.div`
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  > div {
    min-width: 200px;
  }
`;
const AlertMessage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  background-color: ${lighten(0.15, alertColor)};
  padding: 10px;
  border-radius: 6px;
  margin-top: 5px;
  margin-bottom: 5px;
  > i {
    font-size: 25px;
  }
`;

const forceString = (val) => `${val}`;
const CRIMINAL_FIELD_MAPPING = {
  fullName: (value) => ({ label: 'Full Name', value: forceString(value) }),
  firstName: (value) => ({ label: 'First Name', value: forceString(value) }),
  middleName: (value) => ({ label: 'Middle Name', value: forceString(value) }),
  lastName: (value) => ({ label: 'Last Name', value: forceString(value) }),
  description: (value) => ({ label: 'Description', value: forceString(value) }),
  statute: (value) => ({ label: 'Statute', value: forceString(value) }),
  disposition: (value) => ({ label: 'Disposition', value: forceString(value) }),
  dispositionDate: (value) => ({ label: 'Disposition Date', value: forceString(value) }),
  offenseDate: (value) => ({ label: 'Offense Date', value: forceString(value) }),
  offenseCode: (value) => ({ label: 'Offense Code', value: forceString(value) }),
  commitmentDate: (value) => ({ label: 'Commitment Date', value: forceString(value) }),
  releaseDate: (value) => ({ label: 'Release Date', value: forceString(value) }),
  category: (value, detail) => ({
    label: 'Category',
    value: detail?.unconfirmed ? 'UNCONFIRMED' : forceString(value)
  }),
  caseType: (value) => ({ label: 'Case Type', value: forceString(value) }),
  court: (value) => ({ label: 'Court', value: forceString(value) }),
  source: (value) => ({ label: 'Source', value: forceString(value) }),
  jurisdiction: (value) => ({ label: 'Jurisdiction', value: forceString(value) }),
  fillingDate: (value) => ({ label: 'Filling Date', value: forceString(value) }),
  filingDate: (value) => ({ label: 'Filing Date', value: forceString(value) }),
  arrestingAgency: (value) => ({ label: 'Arresting Agency', value: forceString(value) }),
  address: (value) => ({ label: 'Address', value: forceString(value) }),
  state: (value) => ({ label: 'State', value: forceString(value) }),
  caseNumber: (value) => ({ label: 'Case Number', value: forceString(value) }),
  comments: (value) => ({ label: 'Comments', value: forceString(value) })
};

export const Criminal = ({ detail }) => {
  const CriminalItem = ({ fieldName }) => {
    const val = detail[fieldName];
    const mapping = CRIMINAL_FIELD_MAPPING[fieldName]
      ? CRIMINAL_FIELD_MAPPING[fieldName](val, detail)
      : undefined;

    return !val ? (
      <></>
    ) : (
      <CardDataGroup>
        <CardDataDisplay label={mapping.label} description={mapping.value} />
      </CardDataGroup>
    );
  };

  return (
    <Card>
      {detail.unconfirmed && (
        <AlertMessage>
          <Icon name="fa-duotone fa-triangle-exclamation" />
          <div>
            This record is lacking key information from the reporting source and is likely a light
            infraction or traffic violation. It has been automatically excluded from any criminal
            alerts and scoring rules.
          </div>
        </AlertMessage>
      )}
      <WrappableContent>
        <CriminalItem fieldName="fullName" />
        <CriminalItem fieldName="firstName" />
        <CriminalItem fieldName="middleName" />
        <CriminalItem fieldName="lastName" />
        <CriminalItem fieldName="caseNumber" />
        <CriminalItem fieldName="court" />
        <CriminalItem fieldName="arrestingAgency" />
        <CriminalItem fieldName="source" />
        <CriminalItem fieldName="jurisdiction" />
        <CriminalItem fieldName="address" />
        <CriminalItem fieldName="state" />
        <CriminalItem fieldName="category" />
        <CriminalItem fieldName="caseType" />
        <CriminalItem fieldName="statute" />
        <CriminalItem fieldName="comments" />
        <CriminalItem fieldName="description" />
        <CriminalItem fieldName="disposition" />
        <CriminalItem fieldName="dispositionDate" />
        <CriminalItem fieldName="filingDate" />
        <CriminalItem fieldName="fillingDate" />
        <CriminalItem fieldName="offenseDate" />
        <CriminalItem fieldName="offenseCode" />
        <CriminalItem fieldName="commitmentDate" />
        <CriminalItem fieldName="releaseDate" />
      </WrappableContent>
    </Card>
  );
};
