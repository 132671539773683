import React from 'react';
import { darken } from 'polished';
import styled, { css } from 'styled-components';
import Button from 'components/button';
import Icon, { IconShape } from 'components/icon';
import BorderedBox from 'components/bordered-box';
import Menu from 'components/menu';
import { primary } from 'styles/colors';

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 10px;
  .body {
    flex: 1;
    .title {
      display: flex;
      align-items: center;
      font-weight: bold;
      gap: 5px;
      font-size: 18px;
      text-transform: capitalize;
    }
    .subtitle {
      font-size: 12px;
    }
  }
  .card-tag {
    font-size: 12px;
    font-weight: normal;
    border: 1px solid #ccc;
    padding: 1px 4px;
  }
`;
const DataDisplayWrapper = styled.div`
  font-size: 14px;
  ${({ onClick, theme }) =>
    onClick
      ? css`
          color: ${theme?.colors?.primary || primary};
          cursor: pointer;
          &:hover {
            color: ${({ theme }) => darken(0.05, theme?.colors?.primary || primary)};
          }
        `
      : ''}
  .data-label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000;
    font-weight: bold;
  }
`;
const IconCol = styled.div`
  position: relative;
  .statusIndicator {
    position: absolute;
    left: 3px;
    top: 3px;
  }
  .icon-btn {
    font-size: 20px;
    color: ${({ theme }) => theme?.colors?.primary || primary};
    &:hover {
      color: ${({ theme }) => darken(0.05, theme?.colors?.primary || primary)};
    }
  }
`;

export const RowData = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 7px;
  .data-label {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000;
    font-weight: bold;
    flex: 1;
  }
  .data-description {
    flex: 1;
  }
`;

export const CardDataGroup = styled.div`
  ${({ grid, gridSize = 4 }) =>
    grid
      ? `display: grid;grid-template-columns: ${[...Array(gridSize).keys()]
          .map(() => '1fr')
          .join(' ')};`
      : 'display:flex;'}
  margin-bottom: 15px;
  > div {
    flex: 1;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0px;
  gap: 10px;
  button {
    flex-shrink: 0;
    height: 40px;
    padding: 0px 16px;
    border: 1px solid ${({ theme }) => theme?.colors?.primary || primary};
    color: ${({ theme }) => theme?.colors?.primary || primary};
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 25px;
    &:hover {
      background-color: #f5f5f5;
    }
    &.icon-btn {
      padding: 0px;
      justify-content: center;
      width: 40px;
      border-radius: 50%;
    }
  }
`;
export const flagColors = {
  fail: '#db0000',
  pass: '#4cc92b',
  pending: '#c8c8c8',
  warn: '#c98a2b'
};

export const applicantTypes = {
  primary: 'Primary Applicant',
  resident: 'Resident',
  roommate: 'Resident',
  partner: 'Resident',
  guarantor: 'Guarantor'
};

export const CardDataDisplay = ({ className, label, description, children, onClick }) => {
  if (!description && !children) return null;
  return (
    <DataDisplayWrapper onClick={onClick} className={`avoidBreak ${className || ''}`}>
      {children ? (
        children
      ) : (
        <>
          <div className="avoidBreak data-label">{label}</div>
          <div>{description}</div>
        </>
      )}
    </DataDisplayWrapper>
  );
};

export const CardRowData = ({ label, description, dot }) => (
  <RowData className="avoidBreak">
    <div className="avoidBreak data-label">
      {dot && <IconShape size={12} color={dot} />} {label}
    </div>
    <div className="avoidBreak data-description">{description}</div>
  </RowData>
);

export const Card = ({ icon, tag, title, subtitle, children, menuOptions, className }) => {
  return (
    <BorderedBox className={className}>
      {(!!icon || tag || title || subtitle || menuOptions?.length > 0) && (
        <Header className="avoidBreak">
          {icon && <Icon size={20} name={icon} />}
          <div className="body">
            <div className="title">
              {title} {tag && <span className="card-tag">{tag}</span>}
            </div>
            <div className="subtitle">{subtitle}</div>
          </div>
          {menuOptions?.length > 0 && (
            <IconCol className="menuCol no-print">
              <Menu
                options={menuOptions}
                onItemClick={(e) => (e.onClick ? e.onClick(e) : undefined)}>
                {(provided) => (
                  <Button className="icon-btn" {...provided}>
                    <Icon name="fa-regular fa-ellipsis" />
                  </Button>
                )}
              </Menu>
            </IconCol>
          )}
        </Header>
      )}
      <div>{children}</div>
    </BorderedBox>
  );
};
