import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Button from 'components/button';
import Modal, { ModalFooter } from 'components/modal';
import Icon from 'components/icon';
import ShadowRender from 'components/shadow-render';
import { bankConnectRequired } from 'utils/applicationHelper';

import { Row, Title, PageContainer, PageWrapper } from '../common-styles';
import VerifastModal from '../verifast-modal';
import SkipIncome from '../income/skip-income';

const Step = styled.div`
  padding: 10px;
  background-color: var(--color-bg-primary);
  border-radius: 6px;
  li {
    margin-bottom: 7px;
  }
`;

const TitleRow = styled.div`
  padding: 5px 10px;
  background-color: #00aeef;
  color: white;
  font-size: 0.88em;
  > strong {
    margin: 10px 0px;
    display: block;
    text-align: center;
    font-size: 1.25em;
  }
`;
const BodyWrapper = styled.div`
  padding: 10px;
  margin: 10px;
  flex-grow: 1;
`;

const CardInfo = styled.div`
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  padding: 50px 20px;
  border-radius: 4px;
  .separator {
    flex: 1;
    border-bottom: 5px dotted #ccc;
    margin-bottom: 40px;
  }
  .verify-step {
    width: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .icon-wrapper {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: rgba(215, 236, 249, 0.45);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #00a1ff;
      font-size: 1.56em;
      margin-bottom: 10px;
      &.shield {
        width: 80px;
        height: 80px;
        font-size: 2.5em;
      }
    }
  }
  @media (max-width: 600px) {
    padding: 50px 0px;
    background-color: #fff;
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  gap: 15px;
`;
const Action = styled(Button)`
  padding: 7px 12px;
  width: 100px;
  margin-bottom: 10px;
  border-radius: 25px;
  &.action-primary {
    background-color: var(--color-primary);
    color: #fff;
  }
  &.action-secondary {
    border: 1px solid var(--color-primary);
  }
`;

const ManualIncome = ({ open, onNext, onClose, saveApplicationPart, t }) => {
  const onContinue = () => {
    saveApplicationPart({ manualVerification: true }).then(() => onNext());
  };

  return (
    <Modal closeOnOutsideClick={false} open={open} onClose={onClose} height="auto">
      <TitleRow>
        <Trans i18nKey="incomeStep.manual_income.title">
          <strong>Manual Income Verification</strong>
        </Trans>
      </TitleRow>
      <BodyWrapper>
        <Row>
          <Step>{t('incomeStep.manual_income.description')}</Step>
        </Row>
      </BodyWrapper>
      <ModalFooter>
        <Button
          variant="primary"
          onClick={() => {
            onClose();
          }}>
          {t('buttonsActions.SwitchToInstantVerification')}
        </Button>
        <Button style={{ marginLeft: '20px' }} variant="secondary" onClick={() => onContinue()}>
          {t('buttonsActions.continueManualUpload')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ({
  onNext,
  onBack,
  application,
  location,
  saveApplicationPart,
  navigateRoute,
  backToOverview
}) => {
  const { t } = useTranslation();

  const [openModal, setOpenModal] = React.useState(false);
  const [isManual, setIsManual] = React.useState(false);

  const requireBankConnect = bankConnectRequired(application, location);

  React.useEffect(() => {
    if (location?.companyInfo?.disableBankScreening) {
      onNext();
      return;
    }
    if (application.verifastBankComplete) {
      onNext();
      return;
    }
    if (application.autoIncomeSkipDetails?.skipped) {
      navigateRoute('skip-income');
      return;
    }
  }, []);

  return (
    <PageWrapper>
      <ManualIncome
        open={isManual}
        onNext={onNext}
        onClose={() => setIsManual(false)}
        saveApplicationPart={saveApplicationPart}
        t={t}
      />
      <PageContainer>
        {openModal && (
          <VerifastModal
            applicationId={application?._id}
            open={openModal}
            requestType="bank"
            onClose={() => {
              setOpenModal(false);
            }}
            onComplete={(result) => {
              setOpenModal(false);
              onNext({ application: result?.application });
            }}
          />
        )}
        <Row className="center text-center">
          <Title>{t('incomeStep.title')}</Title>
        </Row>
        <Row className="center">{t('incomeStep.description')}</Row>
        <Row>
          <CardInfo>
            <div className="verify-step">
              <div className="icon-wrapper">
                <Icon name="fa-solid fa-building-columns" />
              </div>
              <div className="verify-label">{t('incomeStep.cardInfo1')}</div>
            </div>
            <div className="separator" />
            <div className="verify-step">
              <div className="icon-wrapper">
                <Icon name="fa-regular fa-briefcase" />
              </div>
              <div className="verify-label">{t('incomeStep.cardInfo2')}</div>
            </div>
            <div className="separator" />
            <div className="verify-step">
              <div className="icon-wrapper shield">
                <Icon name="fa-solid fa-shield-check" />
              </div>
              <div className="verify-label">{t('incomeStep.cardInfo3')}</div>
            </div>
          </CardInfo>
        </Row>
        {location?.companyInfo?.bankConnectMessage && (
          <ShadowRender html={location?.companyInfo?.bankConnectMessage} />
        )}
        <Row className="center">
          <ActionWrapper>
            <Action className="action-secondary" onClick={onBack}>
              {t('buttonsActions.backButton')}
            </Action>
            <Action className="action-primary" onClick={() => setOpenModal(true)}>
              {t('buttonsActions.next')}
            </Action>
          </ActionWrapper>
          {!requireBankConnect && (
            <Button variant="link" height={40} onClick={() => navigateRoute('skip-income')}>
              {t('buttonsActions.skipIncomeVerification')}
            </Button>
          )}
          {requireBankConnect && (
            <SkipIncome
              saveApplicationPart={saveApplicationPart}
              application={application}
              onNext={onNext}
              backToOverview={backToOverview}
            />
          )}
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
