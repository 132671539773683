import React from 'react';
import styled, { keyframes } from 'styled-components';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  font-family: 'Mukta', sans-serif;
  &.absolute {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
  }
  .loader {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 1px solid #000;
    box-sizing: border-box;
    animation: ${animation} 1s linear infinite;
  }
  .loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 4px;
    top: 4px;
    border: 2px solid blue;
    width: 12px;
    height: 12px;
    border-radius: 50%;
  }
  &.dark {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const Placeholder = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 20px;
  border-radius: 4px;
  gap: 20px;
`;

export default ({ text, className = '', variant }) => {
  return (
    <Wrapper className={`${className} ${variant}`}>
      <Placeholder>
        <span className="loader" />
        {text}
      </Placeholder>
    </Wrapper>
  );
};
