import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { CompanyContext, UserContext, useNavigateWithContext } from 'state';
import PendingLocation from 'components/pending-location';
import useAudience from 'hooks/useAudience';
import { useContextId, useOrgId } from 'hooks/useSessionVariables';
import { useAPIData } from 'dal/useAPIData';

import Location from './location';
import Filters from './filters';

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow-y: auto;
  background: #fff;
`;

const Header = styled.div`
  padding: 50px 40px;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0;
`;

const Grid = styled.div`
  display: grid;
  padding: 0 40px;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding-bottom: 20px;
  @media (min-width: 3200px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media (max-width: 3200px) {
    grid-template-columns: repeat(7, 1fr);
  }
  @media (max-width: 2900px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 2600px) {
    grid-template-columns: repeat(5, 1fr);
  }
  @media (max-width: 2400px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 1200px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 760px) {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 20px;
  }
  .selected {
    border: 1px solid #c5e4ed;
    padding: 5px;
    background-color: #c5e4ed85;
  }
`;
const MoreLocationsWrapper = styled.div`
  min-width: 290px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  text-align: center;
  > div {
    padding: 20px;
    border: 1px solid #c5e4ed;
    padding: 5px;
    background-color: #c5e4ed85;
  }
`;

export default () => {
  const [audience] = useAudience();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [filters, setFilters] = React.useState({});
  const { companyContext } = React.useContext(CompanyContext);
  const { userContext } = React.useContext(UserContext);
  const orgId = useOrgId();
  const contextId = useContextId();
  const navigate = useNavigateWithContext();
  const { t } = useTranslation();

  const onSelectWrapper = (location) => {
    if (location?.isLocked) {
      return setModalOpen(true);
    }
    navigate(`locations/${location._id}`);
  };

  const [locationList = []] = useAPIData(
    'context.LOAD_LOCATIONS',
    {
      userContext,
      orgId,
      filters: filters
        ? {
            ...filters,
            audience: audience?.uuid,
            minBath: '' // TODO: bugged data causes this to often times be string and not work on the server side filters
          }
        : filters
    },
    {
      disableEmptyArgs: true,
      reFetchWhen: [filters]
    }
  );
  const [defaultLocation] = useAPIData(
    'context.LOAD_CONTEXT',
    !contextId ? undefined : { contextId: contextId, audience: audience?.uuid },
    { reFetchWhen: [contextId], disableEmptyArgs: true }
  );

  const { otherLocations, locationCount, generalLocations } = React.useMemo(() => {
    return {
      otherLocations: (!contextId ? locationList : locationList.filter((c) => c._id !== contextId))
        .filter((g) => !g.applicationConfig?.general)
        .slice(0, 50)
        .sort((a, b) =>
          a?.name?.localeCompare(b?.name || '', 'en', {
            sensitivity: 'base'
          })
        ),
      locationCount: (!contextId
        ? locationList
        : locationList.filter((c) => c._id !== contextId)
      ).filter((g) => !g.applicationConfig?.general).length,
      generalLocations: (!contextId
        ? locationList
        : locationList.filter((c) => c._id !== contextId)
      )
        .filter((g) => g.applicationConfig?.general)
        .sort((a, b) =>
          a?.name?.localeCompare(b?.name || '', 'en', {
            sensitivity: 'base'
          })
        )
    };
  }, [locationList?.length]);

  const showMoreLocations = otherLocations?.length < locationCount;

  return (
    <Wrapper>
      {modalOpen && (
        <PendingLocation open={modalOpen} onClose={() => setModalOpen(false)} location={location} />
      )}
      <Header>
        <Title>{t('locationList.title')}</Title>
        <div>{t('locationList.description')}</div>
      </Header>
      <Filters filters={filters} setFilters={setFilters} />
      <Grid>
        {defaultLocation && (
          <Location
            className="selected"
            location={defaultLocation}
            onPreview={onSelectWrapper}
            onClick={() => onSelectWrapper(defaultLocation)}
            companyContext={companyContext}
          />
        )}
        {generalLocations?.map((location) => (
          <Location
            key={location._id}
            location={location}
            onPreview={onSelectWrapper}
            onClick={() => onSelectWrapper(location)}
            companyContext={companyContext}
          />
        ))}
        {otherLocations?.map((location) => (
          <Location
            key={location._id}
            location={location}
            onPreview={onSelectWrapper}
            onClick={() => onSelectWrapper(location)}
            companyContext={companyContext}
          />
        ))}
        {showMoreLocations && (
          <MoreLocationsWrapper>
            <div>
              + {locationList?.length - otherLocations?.length}{' '}
              {t('locationList.moreLocations.otherPlaces')}
              <br />
              {t('locationList.moreLocations.useFiltersFind')}
            </div>
          </MoreLocationsWrapper>
        )}
      </Grid>
    </Wrapper>
  );
};
