import { createContext, useContext } from 'react';
import { useUiState } from './hooks';

const DocumentContext = createContext({});

export const useDocumentContext = () => useContext(DocumentContext);

export const DocumentProvider = ({ children, documentState }) => {
  const uiState = useUiState({ doc: documentState.document, user: documentState.user });
  return (
    <DocumentContext.Provider
      value={{
        params: documentState.params,
        documentData: documentState,
        doc: documentState.document,
        user: documentState.user,
        ui: uiState,
        history: documentState.history
      }}>
      {children}
    </DocumentContext.Provider>
  );
};
