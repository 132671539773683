import { CONTACT_SCHEMA } from 'components/contact/common';
import * as yup from 'yup';

export const getValidationSchemaForField = ({ type, name, required }) => {
  const requiredMsg = `${name} is required.`;
  switch (type) {
    case 'string':
      return yup
        .string()
        .trim()
        .when([], {
          is: () => required === true,
          then: yup.string().required(requiredMsg)
        });
    case 'rich-text':
      return yup
        .string()
        .trim()
        .when([], {
          is: () => required === true,
          then: yup.string().required(requiredMsg)
        });
    case 'email':
      return yup
        .string()
        .trim()
        .email('Invalid email')
        .when([], {
          is: () => required === true,
          then: yup.string().required(requiredMsg)
        });
    case 'url':
      return yup
        .string()
        .trim()
        .url('Enter a valid url (e.g. https://www.google.com/)')
        .when([], {
          is: () => required === true,
          then: yup.string().required(requiredMsg)
        });
    case 'date':
      return yup.date().when([], {
        is: () => required === true,
        then: yup.date().required(requiredMsg)
      });
    case 'datetime-local':
      return yup.date().when([], {
        is: () => required === true,
        then: yup.date().required(requiredMsg)
      });
    case 'tel':
      return yup
        .string()
        .trim()
        .phone('Invalid phone')
        .when([], {
          is: () => required === true,
          then: yup.string().required(requiredMsg)
        });
    case 'address':
      return yup.object().when([], {
        is: () => required === true,
        then: yup.object().required(requiredMsg)
      });
    case 'number':
      return yup
        .number()
        .typeError('Please provide a valid number')
        .when([], {
          is: () => required === true,
          then: yup.number('Please provide a valid number').required(requiredMsg)
        });
    case 'money':
      return yup.string().when([], {
        is: () => required === true,
        then: yup.string('Please provide a valid amount').required(requiredMsg)
      });
    case 'files':
      return yup.array().when([], {
        is: () => required === true,
        then: yup.array().min(1, requiredMsg).required(requiredMsg)
      });
    case 'file':
      return yup
        .mixed()
        .nullable()
        .when([], {
          is: () => required === true,
          then: yup.object().required(requiredMsg)
        });
    case 'signature':
      return yup.string().required('Please add your signature!');
    case 'relatedAptlets':
      return yup.array().when([], {
        is: () => required === true,
        then: yup.array().required(requiredMsg)
      });
    case 'singleselect':
      return yup
        .string()
        .trim()
        .when([], {
          is: () => required === true,
          then: yup.string().required(requiredMsg)
        });
    case 'multiselect':
      return yup.array().when([], {
        is: () => required === true,
        then: yup.array().required(requiredMsg)
      });
    case 'person':
      return yup.object().when([], {
        is: () => required === true,
        then: CONTACT_SCHEMA.required(requiredMsg)
      });
    case 'persons':
      return yup.array().when([], {
        is: () => required === true,
        then: yup.array().min(1, requiredMsg).required(requiredMsg)
      });
  }
};
