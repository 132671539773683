import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import toast from 'react-hot-toast';
import styled from 'styled-components';

import Button from 'components/button';
import { LOGIN_COMPLETE } from 'dal/user';
import { UserContext, useNavigateWithContext } from 'state';
import useAudience from 'hooks/useAudience';
import AlternateVerification from './alternate-verification';
import { LoginField } from '../../common';

const Wrapper = styled.main``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 935px;
  margin: 0 auto;
  align-items: center;
  padding: 20px;
`;

const Title = styled.h1`
  text-align: center;
`;

const Description = styled.p`
  font-weight: 200;
  text-align: center;
`;

const InputWrapper = styled.div`
  max-width: 400px;
  margin-top: 50px;
  label {
    justify-content: center;
    font-size: 1em !important;
  }
  input {
    text-align: center;
    min-width: 280px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
`;

const Actions = styled.div`
  margin-top: 70px;
  display: flex;
  .primary {
    border-radius: 25px;
  }
  @media (max-width: 500px) {
    margin-top: 0px;
    bottom: 0;
    button {
      width: 125px;
    }
    padding: 20px 0px;
  }
`;
const DEBUG = styled.div`
  color: #9c653e;
  background: #ffc;
  border: 1px dotted #fc6;
  padding: 10px;
`;
export default ({ handleActions }) => {
  const [audience] = useAudience();
  let navigate = useNavigateWithContext();
  const { userContext, setUserContext } = React.useContext(UserContext);
  const [verifyCode, setVerifyCode] = React.useState('');
  const [verifyType, setVerifyType] = React.useState('sms');
  const [codeError, setCodeError] = React.useState();
  const { t } = useTranslation();

  const onBack = () => handleActions({ id: 'setView', data: 'login' });

  const Login = (t) => {
    LOGIN_COMPLETE({ token: userContext?.challenge?.token, challenge: verifyCode })
      .then((response) => {
        setUserContext(response);

        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.get('redir')) {
          window.location.href = queryParams.get('redir');
        } else {
          navigate(audience.paths.home);
        }
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        if (err.data?.reason === 'jwt expired') {
          toast.error(`${t('phoneVerification2fa.error')}`);
          onBack();
          return;
        }
        toast.error(`${t('phoneVerification2fa.codeInvalid')}`);
        setCodeError(`${t('phoneVerification2fa.codeInvalid')}`);
      });
  };

  return (
    <Wrapper>
      <Container>
        <Title>
          {verifyType === 'email' ? (
            <Trans i18nKey="phoneVerification2fa.title">WE JUST SENT YOU A MESSAGE</Trans>
          ) : (
            <Trans i18nKey="phoneVerification2fa.title">
              WE JUST SENT YOU AN SMS <br /> PLEASE VERIFY YOUR PHONE NUMBER
            </Trans>
          )}
        </Title>
        <Description>
          {verifyType === 'email' ? (
            'PLEASE CHECK YOUR EMAIL FOR THE CODE WE JUST SENT YOU AND ENTER IT BELOW'
          ) : (
            <>
              {t('phoneVerification2fa.descrip-1')}{' '}
              <strong>{userContext?.challenge?.phonePostfix}</strong> <br />
              {t('phoneVerification2fa.descrip-2')}
            </>
          )}
        </Description>
        <InputWrapper>
          <LoginField
            label={t('phoneVerefication.verificaionCode')}
            errorText={codeError}
            value={verifyCode}
            onChange={(e) => setVerifyCode(e.target.value.toUpperCase())}
            onKeyDown={(e) => {
              //login on enter
              if (e.key === 'Enter') {
                Login(t);
              }
            }}
          />
          <AlternateVerification
            type="2fa"
            verifyType={verifyType}
            setVerifyType={setVerifyType}
            userContext={userContext}
            onSuccess={(data) => {
              setUserContext({ ...userContext, ...data });
            }}
          />
          {!!userContext?.challenge?.debug?.challenge && (
            <DEBUG>
              {t('phoneVerefication.debug-code')}:
              <br />
              {userContext?.challenge?.debug?.challenge}
            </DEBUG>
          )}
        </InputWrapper>
        <Actions>
          <Button width={200} height={40} variant="secondary" onClick={onBack}>
            {t('buttonsActions.backButton')}
          </Button>
          <Button
            width={200}
            height={40}
            marginLeft={20}
            variant="primary"
            onClick={() => Login(t)}
            disabled={!verifyCode}>
            {t('phoneVerefication.verify-button')}
          </Button>
        </Actions>
      </Container>
    </Wrapper>
  );
};
