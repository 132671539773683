import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import { useDocumentContext } from './state';
import { format, formatRelative } from 'date-fns';
import Icon from 'components/icon';
import { ellipsis } from 'polished';

const Wrapper = styled.div`
  height: 60px;
  border-bottom: 1px solid #ddd;
  display: flex;
  align-items: center;
  padding: 0 30px;
  gap: 10px;
  overflow: hidden;
  @media (max-width: 500px) {
    padding: 0 15px;
  }
`;

const TitleWrapper = styled.div`
  flex: 1;
  overflow: hidden;
  .title {
    font-size: 20px;
    ${ellipsis()}
  }
  .status {
    font-size: 13px;
    span {
      color: green;
    }
  }
`;

const Action = styled(Button)`
  padding: 8px 16px;
  border-radius: 4px;
  color: #fff;
  &.secondary-action {
    background-color: #b3bdc5;
  }
  &.primary-action {
    background-color: #f16261;
  }
`;

const MenuBtn = styled(Button)``;

export default () => {
  const { doc, user, params, ui } = useDocumentContext();

  const handleDownload = async () => {
    // eslint-disable-next-line no-undef
    const baseUrl = `${process.env.REACT_APP_API_URL}`.replace('api/portal', '');
    window.open(`${baseUrl}edocument/download?d=${params.documentId}&t=${params.token}`, '_blank');
  };

  return (
    <Wrapper>
      {ui.floatingMenuPages && (
        <MenuBtn onClick={() => ui.updateUi({ showMenuPages: !ui.showMenuPages })}>
          <Icon name="fa-light fa-bars" />
        </MenuBtn>
      )}
      <TitleWrapper>
        <div className="title">{doc.name}</div>
        {user.hasSigned && (
          <div className="status">
            {doc.counterSignAt ? (
              `Complete by all parts at ${format(new Date(doc.counterSignAt), 'MMM d')} `
            ) : user.signedAt ? (
              <>
                <span>Completed</span> on {format(new Date(user.signedAt), 'MMM d')}{' '}
              </>
            ) : (
              `Expires on ${formatRelative(new Date(doc.expirationDate), new Date())} `
            )}
          </div>
        )}
      </TitleWrapper>
      {/* <Action className="secondary-action">More Actions</Action> */}
      <Action className="primary-action" onClick={handleDownload}>
        Download
      </Action>
    </Wrapper>
  );
};
