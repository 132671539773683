import React from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useFormik } from 'formik';

import FormGroup from 'components/form-group';
import Button from 'components/button';
import Text from 'components/text';
import { ActionsRow } from '../common';

const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;
const QuestionList = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;
  .question-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    .question {
      flex: 1;
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px 12px;
        border-radius: 25px;
        font-weight: bold;
        color: #00a1ff;
        border: solid 1px #00a1ff;
        background-color: #fff;
        &:hover {
          color: #fff;
          border: solid 1px #fff;
          background-color: #00a1ff;
        }
        &.active {
          color: #fff;
          border: solid 1px #fff;
          background-color: #00a1ff;
        }
      }
    }
  }
  .response {
    width: 100%;
    display: flex;
    textarea {
      flex-grow: 1;
    }
  }
`;

const Question = ({
  question = {},
  value = {},
  errors = {},
  handleChange,
  handleBlur,
  setFieldValue,
  t
}) => {
  const updateKey = `${question.uuid}`;

  return (
    <div>
      <div className="question-row">
        <div className="question">{question.question}</div>
        {question.type === 'bool' && (
          <div className="actions">
            <Button
              onClick={() => setFieldValue(`${updateKey}.value`, true)}
              className={value.value === true ? 'active' : ''}>
              Yes
            </Button>
            <Button
              onClick={() => setFieldValue(`${updateKey}.value`, false)}
              className={value.value === false ? 'active' : ''}>
              No
            </Button>
          </div>
        )}
      </div>
      {(question.type === 'text' || value.value === true) && (
        <FormGroup errorText={errors.notes}>
          <div className="response">
            <Text
              placeholder={t('questionStep.explain')}
              name={`${updateKey}.notes`}
              value={value.notes || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </FormGroup>
      )}
    </div>
  );
};

const typeValidations = {
  bool: yup.object({
    value: yup.boolean().required('Please choose an option'),
    notes: yup
      .string()
      .when('value', { is: true, then: yup.string().required('Notes are required') })
  }),
  text: yup.object({
    notes: yup.string().required('Notes are required')
  })
};

export default ({ onBack, action, onSubmit, location }) => {
  const { t } = useTranslation();

  const questions = location?.showingConfig?.questionnaire || [];
  const initialValues = action.questionnaire || {};
  const { values, errors, touched, setFieldValue, handleSubmit, handleBlur, handleChange } =
    useFormik({
      isInitialValid: Object.keys(initialValues).length > 0,
      initialValues,
      validationSchema: yup
        .object()
        .shape(
          questions.reduce(
            (final, q) => Object.assign(final, { [q.uuid]: typeValidations[q.type] }),
            {}
          )
        ),
      onSubmit: (vals) => {
        onSubmit({ questionnaire: vals });
      }
    });

  return (
    <div>
      <QuestionList>
        {questions.map((q, i) => (
          <Question
            key={i}
            value={values[q.uuid]}
            question={q}
            setFieldValue={setFieldValue}
            handleChange={handleChange}
            handleBlur={handleBlur}
            errors={errors?.[q.uuid]}
            touched={touched?.[q.uuid]}
            t={t}
          />
        ))}
      </QuestionList>
      <ActionsRow>
        <StyledButton variant="secondary" onClick={onBack}>
          Back
        </StyledButton>
        <StyledButton variant="primary" onClick={handleSubmit}>
          Next
        </StyledButton>
      </ActionsRow>
    </div>
  );
};
