import React, { useState } from 'react';
import styled from 'styled-components';
import { ADD_FILE, GET_FILE, REMOVE_FILE } from 'dal/applications';
import FileUpload from './fileUpload';
import { UserContext } from 'state';
import toast from 'react-hot-toast';
import { randomId } from '../../../dal/utils';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useTranslation } from 'react-i18next';
const FileSaver = require('file-saver');

const Wrapper = styled.div``;
const ExistingFiles = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
  margin-bottom: 20px;
`;

export default ({
  className,
  application = {},
  field,
  referenceId,
  hideExisting,
  editable = true,
  minFiles = 0,
  maxFiles = 100,
  fileTypes,
  onChange,
  onUploadComplete
}) => {
  const { t } = useTranslation();
  const confirmDialog = useConfirmDialog();
  const { userContext } = React.useContext(UserContext);
  const [pendingFiles, setPendingFiles] = useState([]);
  const [hiddenFiles, setHiddenFiles] = useState([]);

  const files = React.useMemo(
    () =>
      (application.fileRefs || [])
        .filter(
          (fl) =>
            fl.type === field && fl.referenceId === referenceId && !hiddenFiles.includes(fl.uuid)
        )
        .map((fl) => {
          fl.status = 'success';
          return fl;
        }),
    [field, referenceId, application.fileRefs, hiddenFiles]
  );

  const { locationId, _id: applicationId } = application;
  const onPreview = (file) => {
    GET_FILE({ userContext, locationId, applicationId, field, fileUuid: file.uuid })
      .then((fileInfo) => {
        if (fileInfo.url) {
          FileSaver.saveAs(fileInfo.url, file.name);
        }
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(
          `${file?.name || 'UNKNOWN FILE'} ${t('upload_labels.failed')}: ${
            err.data?.message ? err.data?.message : err.message || err.name
          }`
        );
      });
  };

  const onRemove = (file, pendingList) =>
    confirmDialog({
      title: `Are you sure you want to remove ${file.name}?`,
      onConfirm: () => {
        const removeFromLocal = () => {
          if (pendingList) {
            setPendingFiles((existing) => existing.filter((fl) => fl.uuid !== file.uuid));
          } else {
            setHiddenFiles((existing) => existing.concat(file.uuid));
          }
        };

        if (pendingList && file.status !== 'success') {
          return removeFromLocal();
        }

        REMOVE_FILE({ userContext, locationId, applicationId, field, fileUuid: file.uuid })
          .then(() => {
            removeFromLocal();
          })
          .catch((err) => {
            console.error({ errData: err.data, err });
            toast.error(
              `${file?.name || 'UNKNOWN FILE'} ${t('upload_labels.failed')}: ${
                err.data?.message ? err.data?.message : err.message || err.name
              }`
            );
          });
      }
    });

  const onUpload = React.useCallback((file) => {
    file.status = 'pending';
    file.uuid = randomId();
    setPendingFiles(pendingFiles.concat(file));

    ADD_FILE({ userContext, locationId, applicationId, field, referenceId, file: file })
      .then((v) => {
        toast.success(`${file.name} ${t('upload_labels.successfully')}`);

        const ogFileUuid = file.uuid;
        file.status = 'success';
        file.uuid = v.file?.uuid || ogFileUuid; // pull in new fileId

        //IF we can refresh from server then we can remove, otherwise keep this value ---   setPendingFiles((existing) => existing.filter((fl) => fl.uuid !== file.uuid));
        setPendingFiles((existing) => existing.map((fl) => (fl.uuid !== ogFileUuid ? fl : file)));

        onUploadComplete && onUploadComplete(file);
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(
          `${file.name} ${t('upload_labels.failed')}: ${
            err.data?.message ? err.data?.message : err.message || err.name
          }`
        );

        //add in an error message
        file.status = 'failed';
        file.statusDetails = err.data?.message
          ? err.data?.message
          : err.message || err.name || `${t('upload_labels.failed')}`;
        setPendingFiles((existing) => existing.map((fl) => (fl.uuid !== file.uuid ? fl : file)));
      });
  });

  React.useEffect(() => {
    if (onChange) {
      onChange(
        []
          .concat(files)
          .concat(pendingFiles)
          .filter((f) => f)
      );
    }
  }, [pendingFiles, files]);

  return (
    <Wrapper className={className}>
      {!hideExisting && files?.length > 0 && (
        <ExistingFiles>
          {files.map((fl, ix) => (
            <FileUpload
              fileTypes={fileTypes}
              key={ix}
              file={fl}
              label={fl.name}
              editable={editable}
              onPreview={onPreview}
              onRemove={onRemove}
              required={false}
            />
          ))}
        </ExistingFiles>
      )}
      {pendingFiles?.length > 0 && (
        <ExistingFiles>
          {pendingFiles.map((fl, ix) => (
            <FileUpload
              fileTypes={fileTypes}
              key={ix}
              file={fl}
              label={fl.name}
              editable={editable}
              onPreview={onPreview}
              onRemove={(file) => onRemove(file, 'pending')}
              required={false}
            />
          ))}
        </ExistingFiles>
      )}
      {editable && maxFiles > files?.length && (
        <FileUpload
          fileTypes={fileTypes}
          editable={editable}
          label={'Select a file ...'}
          onUpload={onUpload}
          required={minFiles > files?.length}
          options={{ chooseOnTitleClick: true }}
        />
      )}
    </Wrapper>
  );
};
