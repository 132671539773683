import styled from 'styled-components';
import { darken, cssVar } from 'polished';
import Icon from './icon';

export const OptionsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
  gap: ${({ gap = 20 }) => gap}px;
  &.center {
    align-items: center;
    justify-content: center;
  }
`;

const OptionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  background-color: var(--color-bg-primary);
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 8px;
  &:hover {
    background-color: ${darken(0.1, cssVar('--color-bg-primary', '#ecf6f9'))};
  }
  &.selected {
    border-color: var(--color-primary);
  }
  i {
    font-size: ${({ size }) => size / 1.7}px;
  }
`;

const Option = styled.div`
  width: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .sub_label {
    font-style: italic;
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.mobile {
    @media (max-width: 600px) {
      i {
        font-size: 2.5em;
      }
      .Option_label {
        font-size: 0.75em;
      }
    }
  }
`;

const Image = styled.img`
  width: 70%;
`;

const OptionLabel = styled.span`
  font-size: 0.88em;
  font-weight: bold;
  margin-top: 10px;
  text-align: center;
`;

export default ({
  img,
  isSelected,
  onClick,
  label,
  sublabel,
  icon,
  shrink = false,
  size = 120,
  disabled
}) => {
  return (
    <Option className={`${disabled ? 'disabled' : ''} ${shrink ? 'mobile' : ''}`}>
      <OptionContent
        className={isSelected ? 'selected Option_Content' : 'Option_Content'}
        onClick={onClick}
        size={size}>
        {icon ? <Icon {...icon} /> : <Image src={img} />}
      </OptionContent>
      <OptionLabel className="Option_label">{label}</OptionLabel>
      {sublabel && <OptionLabel className="Option_label sub_label">{sublabel}</OptionLabel>}
    </Option>
  );
};
