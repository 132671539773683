import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  font-size: 15px;
  background-color: #fff5d6;
`;

const BoxMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
  .message {
    font-size: 3em;
    margin-top: 40px;
  }
`;

export default () => {
  return (
    <Wrapper>
      <BoxMessage>
        <Icon color="#dc0100" name="fa-duotone fa-link-slash" size={60} />
        <div className="message">This link is not longer valid.</div>
        <p>A new link has been sent to your email.</p>
      </BoxMessage>
    </Wrapper>
  );
};
