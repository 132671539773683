import React from 'react';
import styled from 'styled-components';
import { BLOCKS } from '../page/pdf/block';

const Wrapper = styled.div`
  .checkboxControl {
    i {
      background-color: #fff !important;
    }
    display: block;
  }
  .textControl,
  .longTextControl,
  .dropdownPreview {
    padding: 0 10px !important;
    width: 100%;
    background-color: #fff !important;
    height: 32px;
    border-radius: 4px !important;
    border: 1px solid #ddd !important;
  }
  input {
    background-color: #fff;
    border-radius: 4px;
    height: 32px;
    border: 1px solid #ddd !important;
  }

  .editPreview {
    background-color: #fff !important;
  }
  .longTextControl {
    height: 150px;
  }
  .initialsEmpty,
  .signatureEmpty {
    width: 180px;
    height: 80px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  .signaturePlaceholder {
    width: 180px;
    height: 80px;
    background-color: #fff;
  }
`;

export default ({ block, updateBlock }) => {
  const Component = BLOCKS[block.type]?.component;
  return (
    <Wrapper>
      <Component block={block} mode="edit" updateBlock={updateBlock} />
    </Wrapper>
  );
};
