import React from 'react';
import styled from 'styled-components';
import { Trans } from 'react-i18next';

import { UserContext, useNavigateWithContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import Button from 'components/button';
import Icon from 'components/icon';
import useMobile from 'hooks/useMobile';
import { useOrgId } from 'hooks/useSessionVariables';
import ShowingItem from './showing-item';
import toast from 'react-hot-toast';

const Wrapper = styled.main`
  height: 100%;
  flex: 1;
  overflow-y: auto;
  .loading {
    display: block;
    margin: 30px auto;
  }
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 30px 30px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Title = styled.h2`
  font-size: 1em;
  margin: 20px 0 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  > span {
    font-size: 1.5em;
  }
  > button {
    padding: 5px 20px !important;
  }
`;

const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
  > div {
    width: 400px;
    padding: 20px;
    border: solid 1px #dfe7ed;
    overflow-y: auto;
  }
  &.mobile-grid {
    > div {
      width: auto;
    }
  }
`;

export default () => {
  const { isTabletOrMobile } = useMobile();
  const orgId = useOrgId();
  const { userContext } = React.useContext(UserContext);
  const [refreshKey, setRefreshKey] = React.useState(new Date().getTime());
  let navigate = useNavigateWithContext();

  // this loads all applications for the user
  const [showingList, listFetchInProgress, dataFetched, failedFetch] = useAPIData(
    'applicationItems.LIST_APPLICATION_ITEMS',
    { userContext, filterCriteria: { type: 'showing', companyId: orgId } },
    {
      disableEmptyArgs: true,
      reFetchWhen: [refreshKey],
      onSuccess: (data) => {
        if (!data.list?.length) {
          navigate(`app/showing/create`);
        }
      },
      onError: (err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      }
    }
  );

  if (!dataFetched) {
    return (
      <Wrapper>
        <Container>
          <Icon name="fa-duotone fa-spinner-third fa-spin loading" size={100} />
        </Container>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Container>
        <Header>
          <Title>
            <span>
              <Trans i18nKey="showing.list.title">My Tours</Trans>
            </span>
            <Button variant="primary" onClick={() => navigate(`app/showing/create`)}>
              <Trans i18nKey="showing.actions.newTour">+ New Tour</Trans>
            </Button>
          </Title>
        </Header>
        <Grid className={isTabletOrMobile ? 'mobile-grid' : undefined}>
          {showingList?.list
            ?.sort((a, b) => {
              let firstDate = new Date(a?.updatedAt).getTime();
              let secondDate = new Date(b?.updatedAt).getTime();
              if (!isNaN(firstDate) && !isNaN(secondDate)) {
                return secondDate - firstDate;
              }
              if (isNaN(firstDate) && isNaN(secondDate)) {
                return 0;
              }
              return isNaN(firstDate) ? -1 : 1;
            })
            ?.map((showing, ix) => (
              <ShowingItem
                key={ix}
                showing={showing}
                navigate={navigate}
                refreshList={() => setRefreshKey(new Date().getTime())}
              />
            ))}
        </Grid>
      </Container>
    </Wrapper>
  );
};
