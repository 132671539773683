import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import AddressPin from 'components/addressPin';
import PendingLocation from 'components/pending-location';

const Wrapper = styled.div`
  min-width: 290px;
  overflow: hidden;
  background-color: #fff;
  //box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border-radius: 10px;
  //border: 1px solid #eee;
  &:hover {
    //box-shadow: rgba(17, 12, 46, 0.3) 0px 48px 100px 0px;
  }
`;

const Logo = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  border: none;
  background-image: url(${({ image }) => image}), url('/assets/location_placeholder.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
`;

const Details = styled.div`
  display: flex;
  margin: 15px 0px;
  line-height: 1.4;
  .title {
    font-weight: bold;
  }
  .price {
    margin-top: 5px;
    font-weight: bold;
  }
`;

const Info = styled.div`
  flex: 1;
  .title {
    font-size: 1.25em;
  }
`;

const InfoRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  color: #777;
  margin-left: 10px;
`;

const Status = styled.div`
  height: 30px;
  padding: 0 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
  font-size: 0.75em;
  border: 1px solid #fff;
`;
const Preview = styled.div`
  cursor: pointer;
  height: 30px;
  padding: 0 10px;
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-weight: bold;
  color: #fff;
  font-size: 0.75em;
  border: 1px solid #fff;
`;

export default ({ location, companyContext, className, onPreview }) => {
  const [modalOpen, setModalOpen] = React.useState(false);
  if (!location) {
    return null;
  }
  const { applicationConfig, name, address } = location;

  const title = name || applicationConfig?.marketingTitle || '';
  const price = applicationConfig?.marketRentValue
    ? (applicationConfig?.marketRentValue || 0) / 100
    : undefined;
  const image =
    location?.applicationConfig?.coverPhoto?.[0] ||
    location?.applicationConfig?.marketingImages?.[0];

  let reqIncome = null;
  let requiredIncomeMessage = null;
  if (!companyContext?.disableRequiredIncomeNotice) {
    reqIncome = applicationConfig?.incomeToRent
      ? ((applicationConfig?.marketRentValue || 0) / 100) * applicationConfig?.incomeToRent
      : null;
    requiredIncomeMessage = location?.applicationConfig?.requiredIncomeMessage;
  }

  return (
    <Wrapper
      className={className}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (!location?.isLocked) {
          return onPreview(location);
        }
        if (!modalOpen) {
          setModalOpen(true);
        }
      }}>
      {modalOpen && (
        <PendingLocation open={modalOpen} onClose={() => setModalOpen(false)} location={location} />
      )}
      <Logo image={image}>
        {applicationConfig.availableDate && <Status>{applicationConfig.availableDate}</Status>}
        {onPreview && <Preview>MORE DETAILS</Preview>}
      </Logo>
      <Details>
        <Info>
          {!!title && <div className="title">{title}</div>}
          {!!address && <AddressPin address={address} />}
          {!!price && <div className="price">Rent: {price.renderPrettyCurrency(false)}/mo</div>}
          {reqIncome ? (
            <div>Req Mo. Income: {reqIncome.renderPrettyCurrency(false)}/mo</div>
          ) : requiredIncomeMessage ? (
            <div className="req-income">Req Mo. Income: {requiredIncomeMessage}</div>
          ) : (
            <></>
          )}
        </Info>
        <InfoRight>
          <div>
            {!!location.bedCount && (
              <div className="location">
                <i className="fa-solid fa-bed" /> {location.bedCount} Bed
                {location.bedCount !== 1 ? 's' : ''}
              </div>
            )}

            {!!location.bathCount && (
              <div className="location">
                <i className="fa-solid fa-bath" /> {location.bathCount} Bath
                {location.bathCount !== 1 ? 's' : ''}
              </div>
            )}
            {!!location.squareFeet && (
              <div className="location">
                <i className="fa-solid fa-ruler-triangle" /> {location.squareFeet} SF
              </div>
            )}
          </div>

          {/* {location?.isLocked ? (
            <Button variant="primary" onClick={() => setModalOpen(true)}>
              <i className={'fa-solid fa-diamond-exclamation'} />
              &nbsp;&nbsp;PENDING
            </Button>
          ) : (
            <Button variant="primary" onClick={onClick}>
              +APPLY
            </Button>
          )} */}
        </InfoRight>
      </Details>
    </Wrapper>
  );
};
