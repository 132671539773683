import { isValidNumber, parsePhoneNumber, getCountryCallingCode } from 'libphonenumber-js';
const default_countryCode = 'US';

export const validatePhoneNumber = (phone, countryCode) => {
  return isValidNumber(phone, countryCode || default_countryCode);
};

const default_allowedCountries = ['US', 'CA', 'PR', 'BM', 'CU', 'DO', 'MX'];
export const isAllowedCountry = (phone, countryCode, countries = default_allowedCountries) => {
  try {
    if (!phone || !isValidNumber(phone, countryCode || default_countryCode)) return false;
    const num = parsePhoneNumber(phone, countryCode || default_countryCode);
    return !!(countries || default_allowedCountries).includes(num?.country);
  } catch (err) {
    console.error(err);
  }
  return false;
};

const lookupCountryCode = function (countryCode) {
  // exit w/ default
  if (!countryCode) {
    return default_countryCode;
  }

  // confirm code is valid
  try {
    return getCountryCallingCode(countryCode) ? countryCode : default_countryCode;
  } catch (err) {
    //invalid or unsupported country code - use default
  }

  return default_countryCode;
};

export const formatPhone = (phone, countryCode) => {
  try {
    return parsePhoneNumber(phone, lookupCountryCode(countryCode)).formatNational();
  } catch (err) {
    //invalid or unsupported - formatter just returns the native value
  }
  return phone;
};
