import React from 'react';
import styled from 'styled-components';
import Modal, { ModalHeader, ModalTitle, ModalScrollable } from 'components/modal';

export default ({ content, onClose }) => {
  return (
    <Modal width="900px" maxHeight="95%" open onClose={onClose}>
      <ModalHeader>
        <ModalTitle>Pet Policy</ModalTitle>
      </ModalHeader>
      <ModalScrollable>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </ModalScrollable>
    </Modal>
  );
};
