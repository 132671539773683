import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import Icon from 'components/icon';
import Checkbox from 'components/checkbox';
import Button from 'components/button';
import { useDocumentContext } from './state';
import { UPDATE_DOCUMENT } from 'dal/eSignature';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: 100vw;
  background: #fff;
  z-index: 3;
  padding: 30px;
  overflow: hidden;
  box-sizing: border-box;
  .title {
    font-size: 22px;
    margin-bottom: 15px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Accept = styled(Button)`
  padding: 7px 16px;
  background-color: #dc0100;
  color: #fff;
  border-radius: 6px;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  gap: 10px;
  a {
    color: red;
  }
`;

const Message = styled.div``;

export default () => {
  const { doc, user, ui, params } = useDocumentContext();
  const [agree, setAgree] = React.useState(false);

  const handleElectronicAgreement = async () => {
    try {
      const data = await UPDATE_DOCUMENT({
        token: params.token,
        documentId: doc._id,
        action: 'acceptElectronicDisclosure'
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleContinue = () => {
    if (!agree) return;
    handleElectronicAgreement();
    ui.updateUi({ showAgreement: false });
  };

  return (
    <Modal width="100%" height="100%" onlyOverlay open>
      <Wrapper>
        <Message>
          <div className="title">
            Hello {user.name}, review and sign the following document: <strong>{doc.name}</strong>
          </div>
          <DisclaimerWrapper>
            <Icon name="fa-solid fa-square-arrow-right" color="#DC0100" />
            <Checkbox checked={agree} onChange={() => setAgree(!agree)} />
            <p>
              Please read the{' '}
              <a href="https://www.getaptly.com/terms/" target="_blank" rel="noreferrer">
                Electronic Document and Signature Disclosure
              </a>
              . <br /> I agree to use electronic records and signatures to complete this document.
            </p>
          </DisclaimerWrapper>
        </Message>
        <Accept disabled={!agree} onClick={handleContinue}>
          CONTINUE
        </Accept>
      </Wrapper>
    </Modal>
  );
};
