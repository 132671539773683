import React from 'react';
import styled from 'styled-components';
import Tooltip from '@material-ui/core/Tooltip';
import Icon from 'components/icon';
import { makeStyles } from '@material-ui/core/styles';

const useTooltipStyles = makeStyles({
  arrow: {
    color: '#000'
  },
  tooltip: {
    backgroundColor: '#000',
    fontSize: '13px'
  }
});

const Help = styled.span`
  cursor: ${({ cursor }) => cursor || 'pointer'};
  ${({ color }) => (color ? `color:${color};` : '')}
`;

const TooltipText = ({
  text,
  cursor,
  className,
  children,
  size = 11,
  iconName = 'Info',
  color = ''
}) => {
  const classes = useTooltipStyles();

  return !text ? (
    <Help className={className} color={color}>
      {children ? children : <Icon name={iconName} size={size} />}
    </Help>
  ) : (
    <Tooltip title={text} classes={classes}>
      <Help className={className} color={color} cursor={cursor}>
        {children ? children : <Icon name={iconName} size={size} />}
      </Help>
    </Tooltip>
  );
};

export default TooltipText;
