import React from 'react';
import styled from 'styled-components';
import ToolTip from 'components/tooltip';
import { IconShape } from 'components/icon';
import {
  toMoneyString,
  NO_RENT_REASONS,
  VERIFICATION_STATUS,
  baseScreeningInfo
} from 'pages/approver/share/detail/application-view/helpers';

const IncomeInfo = styled.div`
  cursor: pointer;
  padding-right: 3px;
`;
const NoRentWrapper = styled.div`
  display: inline-flex;
`;
const type = 'income';
export default ({ rowData, handleActions }) => {
  let { description, icon } = baseScreeningInfo({ type, rowData });

  //will not contribute to rent
  if (rowData?.noRentContribution && !Number.isFinite(Number(rowData.monthlyNetIncome))) {
    return (
      <NoRentWrapper>
        <ToolTip
          size={13}
          iconName={'Info'}
          text={
            <div>
              <div>Applicant stated they will not contribute to income.</div>
              {rowData.noRentReason?.length > 0 && (
                <div>
                  Reason:{' '}
                  {rowData.noRentReason
                    .map((r) => NO_RENT_REASONS.find((z) => z.uuid === r)?.label || r)
                    .join(', ')}
                </div>
              )}
              {!!rowData.noRentComments && <div>Comments: {rowData.noRentComments} </div>}
            </div>
          }>
          <IconShape
            color="#ccc"
            iconProps={{
              name: 'fa-solid fa-ban',
              color: '#fff'
            }}
          />
        </ToolTip>
      </NoRentWrapper>
    );
  }

  //no income yet
  if (!rowData?.bankSource || rowData.bankSource === 'none') {
    description = 'Income not yet completed';
    icon = VERIFICATION_STATUS.pending;
  }

  return Number.isFinite(Number(rowData.monthlyNetIncome)) ? (
    <IncomeInfo>{toMoneyString(rowData.monthlyNetIncome, null, null, 0)}</IncomeInfo>
  ) : (
    <ToolTip text="Income not yet available for this applicant">N/A</ToolTip>
  );
};
