import React from 'react';
import { Row, Title, PageContainer, PageWrapper } from './common-styles';
import FormGroup, { RowGroup } from 'components/form-group';
import Button from 'components/button';
import Relationship from 'components/relationship';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Input from 'components/input';
import { useTranslation } from 'react-i18next';
import Phone from 'components/phone';
import { randomId } from 'dal/utils';

export default ({ onBack, onNext, application, saveApplicationPart }) => {
  const { t } = useTranslation();
  const contact = application?.otherContacts?.find((c) => c.type === 'emergencyContact') || {};

  const initialValues = {
    uuid: randomId(),
    type: 'emergencyContact',
    firstName: '',
    lastName: '',
    phone: [{ number: '', type: 'mobile' }],
    email: '',
    ...contact
  };

  const handleContinue = (values) => {
    setSaveInProgress(true);
    const prevContacts = application?.otherContacts?.filter((c) => c.uuid !== values.uuid) || [];
    saveApplicationPart({
      otherContacts: [...prevContacts, values]
    })
      .then(() => {
        setSaveInProgress(false);
        onNext();
      })
      .catch(() => setSaveInProgress(false));
  };

  const applicantInfoSchema = yup.object().shape({
    type: yup.string(),
    uuid: yup.string(),
    firstName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.name')}`)
      .matches(/^[a-zA-Z_0-9\s]+$/, `${t('form.validate.wordCharactersOnly')}`),
    lastName: yup
      .string()
      .max(50, `${t('form.validate.long')}`)
      .required(`${t('form.validate.lastName')}`)
      .matches(/^[a-zA-Z_0-9\s]+$/, `${t('form.validate.wordCharactersOnly')}`),
    email: yup
      .string()
      .email(`${t('form.validate.invalidEmail')}`)
      .required(`${t('form.validate.email')}`),
    phone: yup
      .array(
        yup.object({
          number: yup
            .string()
            .phone(`${t('form.validate.invalidPhone')}`)
            .required(`${t('form.validate.phone')}`),
          type: yup.string()
        })
      )
      .required(`${t('form.validate.phone')}`),
    relationship: yup.string().required(`${t('form.validate.relationship')}`)
  });

  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const { values, touched, errors, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues,
      validationSchema: applicantInfoSchema,
      onSubmit: handleContinue
    });

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center">
          <Title>{t('emergencyContactStep.title')}</Title>
        </Row>
        <Row className="text-left">
          <div>{t('emergencyContactStep.description')}</div>
        </Row>

        <Row spacing={30}>
          <Row>
            <RowGroup columns="1fr 1fr">
              <FormGroup
                label={t('form.firstName')}
                errorText={touched.firstName && errors.firstName}>
                <Input
                  name="firstName"
                  value={values.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
              <FormGroup label={t('form.lastName')} errorText={touched.lastName && errors.lastName}>
                <Input
                  name="lastName"
                  value={values.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </FormGroup>
            </RowGroup>
            <FormGroup
              label={t('form.relationship')}
              errorText={touched.relationship && errors.relationship}>
              <Relationship
                name={`relationship`}
                value={values.relationship}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
            <FormGroup label={t('form.email')} errorText={touched.email && errors.email}>
              <Input
                icon="fa-regular fa-at"
                type="email"
                name="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup label={t('form.phone')}>
              <Phone
                errors={touched.phone && errors.phone}
                name="phone"
                value={values.phone}
                onChange={handleChange}
              />
            </FormGroup>
          </Row>
        </Row>
        <Row className="center flex gap-10 action-buttons" onClick={onBack}>
          <Button variant="secondary" width={90}>
            {t('buttonsActions.backButton')}
          </Button>
          <Button
            radius="25px"
            variant="primary"
            disabled={saveInProgress}
            onClick={handleSubmit}
            width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
