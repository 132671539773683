import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';
import FontSelect from 'components/font-select';
import { useParams } from 'react-router-dom';
import { useNavigateWithContext } from 'state';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { CompanyContext } from 'state';
import { SupportModal } from 'components/contactUs';

const NavBar = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  background-color: #fff;
  .companyLogo {
    max-width: 70px;
    max-height: 55px;
    padding: 10px 0px;
  }
  .back-button {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
  .change-text-size {
    display: flex;
    align-items: baseline;
    gap: 10px;
  }
  .actions-right {
    margin-left: auto;
    display: flex;
    gap: 15px;
    align-item: center;
  }
  &.mobile {
    border-bottom: 1px solid #ddd;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    > div {
      display: flex;
      align-items: center;
    }
    > div:nth-child(2) {
      justify-content: center;
    }
    > div:nth-child(3) {
      justify-content: flex-end;
    }
  }
`;
const ActionWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export default ({
  isMobile,
  data,
  layout,
  showTextSizeChanger,
  onPrint,
  onDelete,
  onBack,
  backContext
}) => {
  const { t } = useTranslation();
  const navigate = useNavigateWithContext();
  const { companyContext } = React.useContext(CompanyContext);
  const [showModal, setShowModal] = React.useState();
  const [activeModal, setActiveModal] = React.useState();

  const isOverview = layout.isOverview(data);
  const isCreate = layout.isCreate(data);
  let backDestination = isOverview || isCreate ? layout.routes.home : layout.routes.overview;
  let backTitle = isOverview || isCreate ? layout.text.goHome : layout.text.goOverview;
  if (backContext) {
    backDestination = backContext === 'home' ? layout.routes.home : layout.routes.overview;
    backTitle = backContext === 'home' ? layout.text.goHome : layout.text.goOverview;
  }

  const goBack = () => {
    if (onBack) return onBack();
    navigate(backDestination);
  };

  return (
    <NavBar className={isMobile ? 'mobile' : ''}>
      {!isMobile && (
        <>
          <Button className="back-button" onClick={goBack}>
            <Icon name="fa-solid fa-chevron-left" />
            <div className="title">{backTitle}</div>
          </Button>
          <div className="actions-right">
            {onDelete && <Button variant="warning">{t('buttonsActions.delete')}</Button>}
            {onPrint && (
              <Button variant="secondary" onClick={onPrint}>
                {t('buttonsActions.print')}
              </Button>
            )}
            {showTextSizeChanger && (
              <Button
                className="change-text-size"
                onClick={(e) =>
                  setActiveModal({
                    modal: 'textSize',
                    anchorEl: e.target,
                    anchorDirection: 'topRight'
                  })
                }>
                <Icon name="fa-duotone fa-text-size" />
                <div className="title">{t('sidebar.textSize')}</div>
              </Button>
            )}
          </div>
        </>
      )}
      {isMobile && (
        <>
          <div className="">
            <Button className="back-button" onClick={goBack}>
              <Icon name="fa-solid fa-chevron-left" />
              <div className="title">{backTitle}</div>
            </Button>
          </div>
          <div className="logo">
            <img className="companyLogo" src={companyContext?.logo} />
          </div>
          <ActionWrapper>
            <Button
              className="change-text-size"
              title={t('sidebar.textSize')}
              onClick={(e) =>
                setActiveModal({
                  modal: 'textSize',
                  anchorEl: e.target,
                  anchorDirection: 'topRight'
                })
              }>
              <Icon name="fa-duotone fa-text-size" color="#00a1ff" size={20} />
            </Button>
            <Button onClick={() => setShowModal(true)}>
              <Icon name="fa-solid fa-messages-question" color="#00a1ff" size={20} />
            </Button>
          </ActionWrapper>
        </>
      )}
      <SupportModal
        width="600px"
        height="620px"
        modalOpen={showModal}
        setModalOpen={() => setShowModal(false)}
        isContactUs={true}
      />
      {activeModal?.modal === 'textSize' && (
        <FontSelect
          open={true}
          anchorEl={activeModal?.anchorEl}
          anchorDirection={activeModal?.anchorDirection}
          onClose={() => setActiveModal('')}
          onChange={() => (isMobile ? setActiveModal('') : undefined)}
        />
      )}
    </NavBar>
  );
};
