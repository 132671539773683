import toast from 'react-hot-toast';
// eslint-disable-next-line no-undef
const getAPIURL = (url) => `${process.env.REACT_APP_API_URL}/${url}`;

const handleResponse = async (response) => {
  let responseObj;

  try {
    responseObj = await response.json();
  } catch (err) {
    // intentionally left blank
  }

  if (response.ok) {
    return responseObj;
  }

  const e = new Error(responseObj?.reason || JSON.stringify(response.json()));
  e.data = responseObj;
  throw e;
};

const handleError = (err, { autoLogOut = true }) => {
  if (err?.data?.reason === 'jwt expired') {
    if (autoLogOut) {
      toast.error('Your session has expired. Please login again.');
      //load up orgid from the url
      const parts = window.location.pathname.split('/').filter((z) => z);
      const orgId = parts[0];
      window.location.href = `/${orgId}/home/login?redir=${encodeURIComponent(
        window.location.pathname
      )}`;
    }
  }
  if (err) {
    throw err;
  }
  throw new Error('Unhandled error');
};

export const POST = ({
  raw_url,
  url,
  data,
  body,
  token,
  autoLogOut,
  contentType = 'application/json'
}) => {
  const headers = {
    access_token: token,
    'Content-Type': contentType,
    boundary: 'MyBoundary'
  };
  if (contentType === false) {
    delete headers['Content-Type'];
  }
  return fetch(raw_url || getAPIURL(url), {
    method: 'POST',
    headers: headers,
    body: data ? JSON.stringify(data) : body,
    mode: 'cors',
    accept: 'application/json'
  })
    .then(handleResponse)
    .catch((e) => handleError(e, { autoLogOut }));
};
export const GET = ({ raw_url, url, token, autoLogOut }) => {
  return fetch(raw_url || getAPIURL(url), {
    headers: { access_token: token, 'Content-Type': 'application/json' },
    method: 'GET',
    mode: 'cors',
    accept: 'application/json'
  })
    .then(handleResponse)
    .catch((e) => handleError(e, { autoLogOut }));
};
export const DELETE = ({ raw_url, url, token, autoLogOut }) => {
  return fetch(raw_url || getAPIURL(url), {
    headers: { access_token: token, 'Content-Type': 'application/json' },
    method: 'DELETE',
    mode: 'cors',
    accept: 'application/json'
  })
    .then(handleResponse)
    .catch((e) => handleError(e, { autoLogOut }));
};

// THIS is a copy of what occurs in meteor for random.id
export const randomId = () => {
  const UNMISTAKABLE_CHARS = '23456789ABCDEFGHJKLMNPQRSTWXYZabcdefghijkmnopqrstuvwxyz';
  const LENGTH = 17;
  const fraction = () => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    return array[0] * 2.3283064365386963e-10; // 2^-32
  };
  const choice = (arrayOrString) => {
    const index = Math.floor(fraction() * arrayOrString.length);
    if (typeof arrayOrString === 'string') {
      return arrayOrString.substr(index, 1);
    }
    return arrayOrString[index];
  };
  let result = '';
  for (let i = 0; i < LENGTH; i++) {
    result += choice(UNMISTAKABLE_CHARS);
  }
  return result;
};
