import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../login';
import Code from '../code';
import Applications from '../applications';
import CreateApp from '../application-form-create';
import FormV2 from '../application-form-v2';
import ErrorPage from '../errors';
import LocationList from '../locations-list';
import LocationPreview from '../location';
import { useLocation } from 'react-router-dom';
import { UserContext } from 'state';
import { VALID_USER_SESSION } from 'dal/user';

const AUTHENTICATED_ROUTES = ['app'];

// Mid to long term these routes should be completely removed from the system
export default ({ updateChatClient }) => {
  const location = useLocation();
  const { userContext, bounceUser } = React.useContext(UserContext);

  //Need to bounce the user to login if they are missing a user context
  React.useEffect(() => {
    const pathTier = ((location?.pathname || '').split('/')[2] || '').toLowerCase();
    if (AUTHENTICATED_ROUTES.includes(pathTier)) {
      if (!VALID_USER_SESSION(userContext?.accessToken)) {
        bounceUser();
      }
    }
  }, [location]);

  return (
    <Routes>
      <Route path="app">
        <Route path="code">
          <Route index={true} element={<Code />} />
          <Route path=":codeValue" element={<Code />} />
        </Route>
        <Route path="applications">
          <Route index={true} element={<Applications />} />
          <Route
            path="view/:applicationId/*"
            element={<FormV2 updateChatClient={updateChatClient} />}
          />
          <Route
            path="form/v2/:applicationId/*"
            element={<FormV2 updateChatClient={updateChatClient} />}
          />
          <Route path="form/create" element={<CreateApp />} />
        </Route>
        <Route path="location-list">
          <Route index={true} element={<LocationList />} />
          <Route path=":locationId" element={<LocationPreview />} />
        </Route>
      </Route>
      <Route path="*" element={<ErrorPage errorValue={'No matching route!'} />} />
      <Route index={true} element={<Login />} />
    </Routes>
  );
};
