import React from 'react';
import toast from 'react-hot-toast';
import { UserContext, useNavigateWithContext } from 'state';
import { UPDATE_SHARE_DETAILS } from 'dal/applications';

export const useActions = () => {
  const { userContext } = React.useContext(UserContext);
  const navigate = useNavigateWithContext();
  const [refreshKey, setRefreshKey] = React.useState(new Date().getTime());
  const [selectedApplicant, setSelectedApplicant] = React.useState();

  const handleActions = (action) => {
    switch (true) {
      case action.id === 'refresh':
        setRefreshKey(new Date().getTime());
        return;
      case action.id === 'all-shares':
        return navigate(`shares/list`);
      case action.id === 'application-select':
        return navigate(`shares/detail/${action.data?.shareId}`);
      case action.id === 'applicant-view':
        return setSelectedApplicant(action.data?.uuid);
      case action.id === 'share-set-status':
        return UPDATE_SHARE_DETAILS({
          userContext,
          shareId: action.data.shareId,
          data: { status: action.data.status }
        })
          .then(() => {
            setRefreshKey(new Date().getTime());
            return { success: true };
          })
          .catch((err) => {
            toast.error(
              err.data?.message
                ? err.data?.message
                : err.message || err.name || 'Failed to update status'
            );

            return { success: false };
          });
      case action.id === 'share-add-comment':
        return UPDATE_SHARE_DETAILS({
          userContext,
          shareId: action.data.shareId,
          data: { comment: action.data.comment }
        })
          .then(() => {
            setRefreshKey(new Date().getTime());
            return { success: true };
          })
          .catch((err) => {
            toast.error(
              err.data?.message
                ? err.data?.message
                : err.message || err.name || 'Failed to add comment message'
            );

            return { success: false };
          });
      default:
        return;
    }
  };

  return { handleActions, refreshKey, selectedApplicant };
};
