import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const PageWrapper = styled.div`
  > p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

export default ({ name }) => {
  const { t } = useTranslation();
  return <PageWrapper>Consent terms here...</PageWrapper>;
};
