import React from 'react';
import styled, { keyframes } from 'styled-components';
import toast from 'react-hot-toast';
import Icon from '../../components/icon';
import { TOKEN_HORIZON, LOGOUT } from '../../dal/user';

const WarnToast = styled.div`
  position: relative;
`;

const grow = keyframes`
  from {
    width: 0%;
  }
  to {
    width:100%
  }
`;
const Loader = styled.div`
  display: flex;
  gap: 3px;
  position: absolute;
  z-index: -5;
  top: -13px;
  left: -20px;
  border-radius: 7px 7px 0px 0px;
  height: 10px;
  width: calc(100% + 40px);
  background-color: var(--color-bg-primary);
  border-bottom: 1px solid #ccc;
  height: 15px;
  color: var(--color-primary);
  font-size: 0.81em;
  > div {
    border-radius: 7px 0px 0px 0px;
    height: 15px;
    min-width: 8px;
    width: 5%;
    animation: ${grow} ${({ animationTime }) => animationTime || 3000}ms linear;
    background-color: var(--color-primary);
  }
`;

const Content = styled.div`
  cursor: pointer;
  margin-top: 10px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const EXPIRATION_INTERVAL_CHECK = 15000; // interval at which the ui checks on its token's expiration time to warn
const WARN_TIME = -1000 * 60 * 5; // how long the UI will give the user to refresh their token
let intervalId;

const computeWarnTime = (expiresAt) => new Date(expiresAt.getTime() + WARN_TIME);
const resetInterval = (specifiedInterval) => {
  (specifiedInterval || intervalId) && clearInterval(specifiedInterval || intervalId);
};

export const useTokenExpirationWarning = ({
  userContext,
  refetchToken,
  setUserContext,
  bounceUser
} = {}) => {
  const activeUserToken = React.useRef(userContext?.accessToken?.token);
  const warningVisible = React.useRef(false);
  //setup interval when we have a user Id
  React.useEffect(() => {
    if (
      !userContext?.accessToken?.token ||
      activeUserToken.current === userContext?.accessToken?.token
    ) {
      activeUserToken.current = '';
      return;
    }
    activeUserToken.current = userContext?.accessToken?.token;
    warningVisible.current = false;

    //if there is an active interval then lets nuke it
    resetInterval(intervalId);

    const tokenHorizon = TOKEN_HORIZON();
    if (tokenHorizon?.expired || !tokenHorizon.expiresAt?.getDate) {
      return;
    }

    console.log(`Session will expire at ${tokenHorizon.expiresAt?.toLocaleTimeString()}`);
    console.log(`Will warn at ${computeWarnTime(tokenHorizon.expiresAt)?.toLocaleTimeString()}`);
    intervalId = setInterval(() => {
      const tokenHorizon = TOKEN_HORIZON();
      const warnAt = tokenHorizon?.expiresAt ? computeWarnTime(tokenHorizon.expiresAt) : undefined;
      const show =
        !tokenHorizon?.expired &&
        tokenHorizon?.expiresAt?.getDate &&
        warnAt?.getTime() <= new Date().getTime();

      // log out user....
      if (tokenHorizon?.expired) {
        resetInterval(intervalId);
        LOGOUT({ userContext })
          .then(() => {
            setUserContext && setUserContext({});
            bounceUser && bounceUser('Your session has expired. Please login again.');
          })
          .catch((err) => {
            console.error({ errData: err.data, err });
            toast.error(err.data?.message ? err.data?.message : err.message || err.name);
          });

        return;
      }

      if (show !== warningVisible.current) {
        warningVisible.current = show;

        if (show) {
          const animationTime = tokenHorizon.expiresAt.getTime() - new Date().getTime();
          toast(
            (t) => {
              return (
                <WarnToast>
                  <Content>
                    <Icon name="fa-duotone fa-lock" />
                    <div
                      onClick={() => {
                        refetchToken && refetchToken();
                        toast.dismiss(t.id);
                      }}>
                      <div>
                        Session will expire at {tokenHorizon.expiresAt?.toLocaleTimeString()}
                      </div>
                      <div>Click to stay logged in...</div>
                    </div>
                  </Content>
                  <Loader animationTime={animationTime}>
                    <div />
                    <Icon name="fa-solid fa-rabbit-running" />
                  </Loader>
                </WarnToast>
              );
            },
            {
              id: 'session-warning',
              icon: null,
              duration: animationTime
            }
          );
        }
      }
    }, EXPIRATION_INTERVAL_CHECK);
  }, [userContext?.accessToken?.token]);
};
