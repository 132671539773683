import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';

const Wrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid #ddd;
`;

const Action = styled(Button)`
  padding: 8px 16px;
  border-radius: 4px;
  color: #fff;
  background-color: #f16261;
`;

export default ({ onDone }) => {
  return (
    <Wrapper>
      <div className="title">Fields to complete</div>
      <Action onClick={onDone}>DONE</Action>
    </Wrapper>
  );
};
