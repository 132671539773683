import { GET } from './utils';

//this loads the data context for the landing page
export const LOAD_CONTEXT = ({ contextId, bypass, audience }) => {
  let filters = audience || bypass ? {} : undefined;
  if (audience) filters.audience = audience;
  if (bypass) filters.bp = bypass;
  const searchParams =
    filters && Object.keys(filters).length > 0 ? new URLSearchParams(filters) : undefined;

  return GET({ url: `context/${contextId}${searchParams ? `?${searchParams.toString()}` : ''}` });
};

export const LOAD_ORG_CONTEXT = ({ contextId, bypass }) =>
  GET({ url: `company/${contextId}${bypass ? `?bp=${encodeURIComponent(bypass)}` : ''}` });

export const LOAD_LOCATIONS = ({ orgId, userContext, filters }) => {
  const searchParams =
    filters && Object.keys(filters).length > 0 ? new URLSearchParams(filters) : undefined;
  return GET({
    url: `locations/${orgId}${searchParams ? `?${searchParams.toString()}` : ''}`,
    token: userContext?.accessToken?.token
  });
};
export const LOAD_LISTINGS = ({ orgId, filters }) => {
  const searchParams =
    filters && Object.keys(filters).length > 0 ? new URLSearchParams(filters) : undefined;
  return GET({
    url: `listings/${orgId}${searchParams ? `?${searchParams.toString()}` : ''}`
  });
};
export const LOAD_LISTING = ({ locationId, filters }) => {
  const searchParams =
    filters && Object.keys(filters).length > 0 ? new URLSearchParams(filters) : undefined;
  return GET({
    url: `listing/${locationId}${searchParams ? `?${searchParams.toString()}` : ''}`
  });
};

export const LOAD_SCHOOLS = ({ locationId, distance = 25, limit = 10 }) => {
  const searchParams = new URLSearchParams({ distance, limit });
  return GET({
    url: `schools/${locationId}${searchParams ? `?${searchParams.toString()}` : ''}`
  });
};
