export const hasHousingSupport = (app) => !!(app?.section8?.active || app?.section8?.otherSupport);

export const showAlternativeCreditOptions = (app, location) =>
  hasHousingSupport(app) &&
  !!location?.applicationConfig?.regionalExceptions?.includes(
    'housingAssistance-alternativeCredit'
  );

export const hasAlternativeCreditSelected = (app, location) => {
  return (
    showAlternativeCreditOptions(app, location) && app?.consent?.creditHistoryType === 'records'
  );
};

export const bankConnectRequired = (app, location) =>
  location?.companyInfo?.requireBankConnect ||
  (hasHousingSupport(app) &&
    showAlternativeCreditOptions(app, location) &&
    app?.consent?.creditHistoryType === 'records');

const isFileRequired = (fileType, application, location) => {
  switch (fileType) {
    case 'tax':
      if (!application.income?.some((s) => s?.type === 'self')) return false; // no self, so not required
      if (!location?.companyInfo?.manualUploadOptionalTaxDocuments) return true; // always require
      return (
        !application.verifastBankComplete && location?.companyInfo?.autoUploadRequiredTaxDocuments
      ); // only require when bank skipped
    case 'bank':
      if (!location?.companyInfo?.manualUploadOptionalBankStatements) return true; // always require

      // if requiring bank statements when bank skipped OR using alternative credit THEN its required IF no bank connected
      if (
        location?.companyInfo?.autoUploadRequiredBankStatements ||
        hasAlternativeCreditSelected(application, location)
      ) {
        return !application.verifastBankComplete;
      }

      return false;
    case 'paystub':
      if (!location?.companyInfo?.manualUploadOptionalPayStubs) return true; // always require

      // if requiring paystubs when bank skipped OR using alternative credit THEN its required IF no bank connected
      if (
        location?.companyInfo?.autoUploadRequiredPayStubs ||
        hasAlternativeCreditSelected(application, location)
      ) {
        return !application.verifastBankComplete;
      }

      return false;
  }
};

export const requiredFileCount = (fileType, application, location) => {
  const isRequired = isFileRequired(fileType, application, location);

  if (!isRequired) return 0;

  let fileCount = 0;
  switch (fileType) {
    case 'tax':
      fileCount = parseInt(location?.companyInfo?.taxDocumentCount || 1);
      break;
    case 'bank':
      fileCount = parseInt(location?.companyInfo?.bankStatementCount || 1);
      break;
    case 'paystub':
      fileCount = parseInt(location?.companyInfo?.payStubCount || 1);
      break;
  }

  return fileCount >= 0 ? fileCount : 0; //ensure its greater than zero
};
