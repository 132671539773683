import React from 'react';
import styled, { css } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { toast } from 'react-hot-toast';

import { AUDIENCES } from 'helpers/enums';
import { UserContext, useNavigateWithContext } from 'state';
import PersonalInformation from 'pages/showing/components/personal-info';
import ExistingShowing from 'pages/showing/components/existing-showing';
import { useContextId, useOrgId } from 'hooks/useSessionVariables';
import { SAVE_APPLICATION_ITEM } from 'dal/application-items';
import PropertyContentLayout from '../../layout/property-content';
import LocationList from '../../locations-list';
import LocationNotAvailable from 'components/location-not-available';

const Wrapper = styled.div`
  ${({ isMobile }) => {
    isMobile
      ? css`
          .locationsGrid {
            padding: 0;
            margin: 0;
          }
        `
      : css`
          .locationsGrid {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
          }
          .gridLocationItem {
            max-width: 400px;
            width: 400px;
          }
        `;
  }}
`;

export default ({ locationId: initialLocationId }) => {
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const orgId = useOrgId();
  const contextId = useContextId();
  const navigate = useNavigateWithContext();
  const { userContext } = React.useContext(UserContext);
  const [locationId, setLocationId] = React.useState(initialLocationId || contextId);
  const [showLocationList, setShowLocation] = React.useState(!locationId);
  const [saveInProgress, setSaveInProgress] = React.useState(false);
  const [existingShowing, setExistingShowing] = React.useState(false);
  const [warningModalOpen, setWarningModalOpen] = React.useState();
  const handleContinue = (values) => {
    if (saveInProgress) {
      return;
    }

    setSaveInProgress(true);
    SAVE_APPLICATION_ITEM({
      userContext,
      data: {
        step: 'personalInfo',
        content: {
          locationId: locationId,
          companyId: orgId,
          applicationType: AUDIENCES.showing.applicationType,
          ...values
        }
      }
    })
      .then((showing) => {
        setSaveInProgress(false);
        if (showing?.item?._id && showing?.state === 'pre-existing') {
          setExistingShowing(showing.item);
        } else {
          navigate(`app/showing/${showing?.item?._id}/summary`);
        }
      })
      .catch((err) => {
        setSaveInProgress(false);
        if (err.data?.code === 'INACTIVE_LINK') {
          setWarningModalOpen(true);
          return setShowLocation(true);
        }
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };

  return (
    <PropertyContentLayout
      locationId={locationId}
      hideWhenNoLocation={true}
      onChangeProperty={() => setShowLocation(true)}
      maxWidth={'100%'}>
      <Wrapper isMobile={isMobile} className="wrapper">
        {warningModalOpen && (
          <LocationNotAvailable
            open={warningModalOpen}
            onClose={() => setWarningModalOpen(false)}
          />
        )}
        {showLocationList ? (
          <LocationList
            hideDefaultLocation={false}
            selectContent={'Request showing'}
            onBack={locationId ? () => setShowLocation(false) : undefined}
            onSelect={(location) => {
              setLocationId(location._id);
              setShowLocation(false);
            }}
          />
        ) : existingShowing ? (
          <ExistingShowing
            showing={existingShowing}
            onShowView={() => navigate(`app/showing/${existingShowing._id}/summary`)}
            onChangeProperty={() => {
              setShowLocation(true);
              setExistingShowing(null);
            }}
          />
        ) : (
          <PersonalInformation onSubmit={handleContinue} />
        )}
      </Wrapper>
    </PropertyContentLayout>
  );
};
