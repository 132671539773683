import React from 'react';
import styled from 'styled-components';
import Canvas from 'react-signature-canvas';
import Modal from './modal';
import Button from './button';
import Input from './input';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

const CanvasWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  canvas {
    width: 100%;
    height: 100%;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  gap: 10px;
  button {
    border-bottom: 2px solid transparent;
    height: 30px;
    &.active {
      border-color: #000;
    }
    transition: all ease-in-out 0.2s;
  }
`;

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 20px;
  gap: 10px;
`;

const WriteWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 30px;
  input {
    flex-shrink: 0;
  }
  .type-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    font-family: 'Alex Brush', cursive;
    font-size: 4.38em;
  }
`;

const PlaceHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 500px;
  height: 200px;
  border: 4px solid #eee;
  background-color: #fff;
  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

const Clear = styled(Button)`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;

const textToImage = (text) => {
  if (!text) return '';
  const canvas = document.getElementById('signature-canvas');
  const ctx = canvas.getContext('2d');
  ctx.font = '80px Alex Brush';
  const textWidth = ctx.measureText(text).width;
  canvas.width = textWidth;
  ctx.font = '80px Alex Brush';
  ctx.fillText(text, canvas.width / 2 - textWidth / 2, 100);
  return canvas.toDataURL();
};

export const SignatureCanvas = React.forwardRef(({ initialState }, ref) => {
  const { t } = useTranslation();
  const [mode, setMode] = React.useState(initialState?.mode ?? 'draw');
  const [typedVal, setTypedVal] = React.useState(initialState?.typedVal ?? '');
  const pad = React.useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 960 });

  React.useEffect(() => {
    if (initialState?.image && initialState?.mode === 'draw') {
      pad.current.fromDataURL(initialState.image);
    }
  }, []);

  const handleClear = () => {
    if (mode === 'draw') return pad.current.clear();
    setTypedVal('');
  };

  const getImage = () => {
    let image = '';
    if (mode === 'type') image = textToImage(typedVal);
    if (mode === 'draw') {
      const isEmpty = pad.current.isEmpty();
      const trim = pad.current.getTrimmedCanvas();
      image = isEmpty ? undefined : trim.toDataURL();
    }
    return image;
  };

  const getPadState = () => {
    return {
      mode,
      typedVal
      //image: getImage()
    };
  };

  React.useImperativeHandle(ref, () => {
    return {
      clear: handleClear,
      getImage,
      getPadState
    };
  });

  return (
    <Wrapper>
      <Header>
        <Button onClick={() => setMode('draw')} className={mode === 'draw' ? 'active' : ''}>
          {t('signatureModal.draw')}
        </Button>
        <Button onClick={() => setMode('type')} className={mode === 'type' ? 'active' : ''}>
          {t('signatureModal.type')}
        </Button>
      </Header>
      <CanvasWrapper className={`canvas-${mode}`}>
        {mode === 'draw' && <Canvas ref={pad} clearOnResize={isMobile ? false : true} />}
        {mode === 'type' && (
          <WriteWrapper>
            <div>
              <Input
                placeholder={t('signatureModal.typeLabel')}
                value={typedVal}
                onChange={(event) => setTypedVal(event.target.value)}
                autoFocus
              />
            </div>
            <div className="type-holder">{typedVal}</div>
            <canvas style={{ display: 'none' }} id="signature-canvas" />
          </WriteWrapper>
        )}
      </CanvasWrapper>
      <Clear variant="link" onClick={() => handleClear()}>
        Clear
      </Clear>
    </Wrapper>
  );
});

export default ({ value, name, onChange, className }) => {
  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = React.useState();
  const [base64Image, setBase64Image] = React.useState(value);
  const pad = React.useRef(null);

  const handleChange = () => {
    const image = pad.current.getImage();
    setBase64Image(image);
    setModalOpen(false);
  };

  const handleClear = () => pad.current.clear();

  React.useEffect(() => {
    if (base64Image === value) return;
    onChange && onChange({ target: { name, value: base64Image } });
  }, [base64Image]);

  return (
    <>
      <PlaceHolder className={className} onClick={() => setModalOpen(true)}>
        {base64Image ? (
          <img src={base64Image} alt="" />
        ) : (
          <Button variant="link">
            <i className="fa-solid fa-paintbrush-fine" />
            &nbsp; {t('signatureModal.title')}
          </Button>
        )}
      </PlaceHolder>
      <Modal open={modalOpen} width="700px" height="500px" onClose={() => setModalOpen(false)}>
        <SignatureCanvas ref={pad} />
        <Footer>
          <Button width={80} variant="secondary" onClick={handleClear}>
            {t('buttonsActions.clear')}
          </Button>
          <Button width={80} variant="primary" onClick={handleChange}>
            {t('buttonsActions.accept')}
          </Button>
        </Footer>
      </Modal>
    </>
  );
};
