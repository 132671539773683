import React from 'react';
import styled from 'styled-components';
import Select from 'components/select';
import SearchInput from 'components/search-input';
import Slider from 'components/sliderPrice';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

const Wrapper = styled.div`
  display: flex;
  position: sticky;
  top: 10px;
  z-index: 300;
  background-color: #fff;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 10px 15px;
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
  border: 1px solid #eee;
  border-radius: 25px;
  gap: 10px;
  .Aptly_Select {
    border-right: 1px solid #eee;
  }
  select {
    flex: 1;
    border: none;
    background-color: #fff;
    border-radius: 0px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const RowSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const WrapperMobile = styled.div`
  position: sticky;
  top: 10px;
  z-index: 300;
  background-color: #fff;
  margin-bottom: 10px;
  padding: 0 20px 30px;
  border-radius: 5px;
  gap: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  .input-search {
    border: 1px solid #eee;
    padding: 0 5px;
  }
  .mobileSlider {
    margin-right: 0;
    .container {
      margin-right: 0;
      width: 100%;
      position: relative;
      .thumb {
        width: 100%;
      }
      .slider {
        width: 100%;
      }
    }
  }
`;

const RowSelectMobile = styled.div`
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
const newFilters = ({ filters, setFilters, selectedPrice, changePrice }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 700 });

  return (
    <>
      {isMobile ? (
        <WrapperMobile>
          <SearchInput
            placeholder={t('filterLocation.searchLabel')}
            value={filters?.address}
            borderRight={false}
            onChange={(e) => {
              setFilters({ ...filters, address: e.target.value });
            }}
          />
          <RowSelectMobile>
            <RowSelect>
              <div>{t('filterLocation.beds')}:</div>
              <Select
                placeHolder=""
                value={filters?.minBed}
                onChange={(e) => {
                  setFilters({ ...filters, minBed: e.target.value });
                }}>
                <option value="">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Select>
            </RowSelect>
            <RowSelect>
              <div>{t('filterLocation.baths')}:</div>
              <Select
                placeHolder=""
                value={filters?.minBath}
                onChange={(e) => {
                  setFilters({ ...filters, minBath: e.target.value });
                }}>
                <option value="">0</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
              </Select>
            </RowSelect>
          </RowSelectMobile>
          <Slider
            selectedPrice={selectedPrice}
            changePrice={changePrice}
            isMobile={isMobile}
            className="mobileSlider"
          />
        </WrapperMobile>
      ) : (
        <Wrapper>
          <SearchInput
            placeholder={t('filterLocation.searchLabel')}
            value={filters?.address}
            onChange={(e) => {
              setFilters({ ...filters, address: e.target.value });
            }}
          />
          <RowSelect>
            <div>{t('filterLocation.beds')}:</div>
            <Select
              placeHolder=""
              value={filters?.minBed}
              onChange={(e) => {
                setFilters({ ...filters, minBed: e.target.value });
              }}>
              <option value="">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Select>
          </RowSelect>
          <RowSelect>
            <div>{t('filterLocation.baths')}:</div>
            <Select
              placeHolder=""
              value={filters?.minBath}
              onChange={(e) => {
                setFilters({ ...filters, minBath: e.target.value });
              }}>
              <option value="">0</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
            </Select>
          </RowSelect>
          <Slider selectedPrice={selectedPrice} changePrice={changePrice} />
        </Wrapper>
      )}
    </>
  );
};

export default newFilters;
