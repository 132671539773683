import React from 'react';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import Icon from 'components/icon';

const Progress = styled(CircularProgress)`
  margin-right: 5px;
  && {
    color: #007cff;
  }
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  border-right: ${({ borderRight = '1px solid rgb(230, 230, 230)' }) => borderRight};
  border-radius: ${({ borderRadius = '0px' }) => borderRadius};
  height: ${({ height = 40 }) => height}px;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  input {
    flex: 1;
    height: 100%;
    background-color: transparent;
    margin-left: 10px;
    outline: none;
    border: none;
    box-shadow: none;
    &::placeholder {
      color: #777;
    }
  }
  &.light {
    border: 1px solid rgb(230, 230, 230);
  }
  svg {
    flex-shrink: 0;
  }
  &.full-width {
    flex: 1;
  }
`;

export default React.forwardRef(
  (
    {
      className = '',
      icon,
      loading,
      variant = '',
      placeholder = 'Search',
      showSearchTermClear = false,
      onChange,
      value,
      wrapperRef,
      height,
      borderRadius,
      borderRight,
      ...props
    },
    ref
  ) => {
    const [isFocused, setFocused] = React.useState();

    return (
      <SearchWrapper
        ref={wrapperRef}
        className={`${isFocused ? 'focused' : ''} ${className} ${variant} input-search`}
        height={height}
        borderRight={borderRight}
        borderRadius={borderRadius}>
        {icon ? icon : <Icon name="fa-duotone fa-magnifying-glass" size={18} />}
        <input
          ref={ref}
          type="text"
          placeholder={placeholder}
          autoComplete="off"
          onChange={onChange}
          value={value}
          onFocus={(event) => {
            setFocused(true);
            props.onFocus && props.onFocus(event);
          }}
          onBlur={(event) => {
            setFocused(false);
            props.onBlur && props.onBlur(event);
          }}
          {...props}
        />
        {showSearchTermClear && value && (
          <Icon
            name="fa-solid fa-xmark"
            size={14}
            onClick={() => {
              onChange({ target: { value: '' } });
            }}
          />
        )}
        {loading && <Progress size={20} thickness={4} />}
      </SearchWrapper>
    );
  }
);
