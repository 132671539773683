import React from 'react';
import styled from 'styled-components';
import Modal, { ModalHeader, ModalTitle, ModalScrollable, ModalFooter } from 'components/modal';
import Icon from 'components/icon';
import Button from 'components/button';

const Option = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 30px;
  border-bottom: 1px solid #ddd;
  gap: 10px;
  i {
    color: #ccc;
  }
  &:last-child {
    border: none;
  }
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
  }
  &.selected {
    i {
      color: green;
    }
  }
`;

const ModalWrapper = styled(Modal)`
  max-height: 80%;
  min-height: 200px;
`;

const Scroll = styled(ModalScrollable)`
  padding: 0px;
`;

const Accept = styled(Button)`
  width: 100%;
  padding: 8px 16px;
  color: #fff;
  background-color: #f16261;
  border-radius: 6px;
  &.btnCancel {
    background-color: #b3bdc5;
  }
`;

const Actions = styled(ModalFooter)`
  gap: 10px;
  margin: 0px;
  padding: 20px;
`;

export default ({ value: initialValue, options = [], onChange, onClose, multi }) => {
  const [value, setValue] = React.useState(initialValue ? [].concat(initialValue) : []);

  const handleSelection = (option) => {
    if (!multi) {
      setValue(!option.value ? [] : value[0] === option.value ? [] : [option.value]);
      return;
    }
    setValue((prev) =>
      prev.includes(option.value)
        ? prev.filter((o) => o !== option.value)
        : [...prev, option.value || '']
    );
  };

  const handleContinue = () => {
    onChange?.(multi ? value : value[0]);
    onClose();
  };

  return (
    <ModalWrapper width="500px" height="auto" onClose={onClose} open>
      <ModalHeader>
        <ModalTitle>{multi ? 'Choose the options' : 'Please choose'}</ModalTitle>
      </ModalHeader>
      <Scroll>
        {options.map((o, i) => (
          <Option
            key={i}
            onClick={() => handleSelection(o)}
            className={value.includes(o.value) ? 'selected' : ''}>
            <span>{o.label}</span>
            <Icon
              name={value.includes(o.value) ? 'fa-solid fa-circle-check' : 'fa-regular fa-circle'}
            />
          </Option>
        ))}
      </Scroll>
      <Actions>
        <Accept className="btnCancel" onClick={onClose}>
          CANCEL
        </Accept>
        <Accept onClick={handleContinue}>CONTINUE</Accept>
      </Actions>
    </ModalWrapper>
  );
};
