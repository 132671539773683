var React = require('react');
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';

const animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
const Wrapper = styled.div`
  padding: 20px;
  background-color: var(--color-bg-primary);
  border-radius: 8px;

  .loader {
    float: left;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    border: 1px solid #000;
    box-sizing: border-box;
    animation: ${animation} 1s linear infinite;
  }
  .loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 2px;
    top: 2px;
    border: 2px solid blue;
    width: 8px;
    height: 8px;
    border-radius: 50%;
  }
`;
const Content = styled.div`
  display: flex;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;
const FileInfo = styled.div`
  display: flex;
  flex-flow: column;
  gap: 10px;
  .title {
    font-weight: 300;
    width: 170px;
  }
  .selectedFile {
    font-weight: 200 !important;
    margin: 0px !important;
    font-size: 0.88em;
    display: flex;
    flex-flow: column;
    gap: 10px;
  }
  &.failed {
    color: #f01616;
  }
  &.pending {
    color: #f01616;
  }
  &.clickableInfo {
    cursor: pointer;
    flex: 1;
  }
`;
const Required = styled.div`
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: 0.81em;
  color: red;
`;
const Optional = styled.div`
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: 0.81em;
  color: gray;
  font-style: italic;
`;
const ActionItems = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  gap: 10px;
`;
const Action = styled.div`
  font-size: 0.88em;
  text-transform: uppercase;
  font-weight: bold;
  color: #007cff;
  background-color: transparent !important;
  cursor: pointer;

  &.change {
    color: #00c903;
  }
  &.remove {
    color: #f01616;
  }
`;

export default ({
  onUpload,
  onPreview,
  onRemove,
  label,
  file,
  editable,
  required,
  fileTypes,
  options = { fileName: false, chooseOnTitleClick: false }
}) => {
  const { t } = useTranslation();
  const fileInput = React.useRef();

  const selectFile = () => {
    if (!editable) return;
    fileInput.current.click();
  };

  const fileSelected = (event) => {
    onUpload((event.target?.files || [])[0]);
  };

  return (
    <Wrapper>
      <Content>
        <input
          type="file"
          name="file"
          onChange={fileSelected}
          style={{ display: 'none' }}
          ref={fileInput}
          accept={fileTypes?.length ? fileTypes.join(',') : undefined}
        />
        <FileInfo
          className={
            options?.chooseOnTitleClick ? `clickableInfo ${file?.status || ''}` : file?.status
          }
          onClick={options?.chooseOnTitleClick ? selectFile : undefined}>
          {label && <div className="title">{label}</div>}
          {!!file?.name && options?.fileName && (
            <div className={`selectedFile file-name ${file.status}`}>
              {t('upload_labels.fileName')} {file.name}
            </div>
          )}
          {!!file?.statusDetails && (
            <div className={`selectedFile file-name ${file.status}`}>
              {t('upload_labels.uploadFiled')} {file.statusDetails}
            </div>
          )}
          {required && !file && <Required>{t('required')}</Required>}
          {!required && !file && <Optional>{t('optional')}</Optional>}
        </FileInfo>
        {file?.status === 'pending' ? (
          <div className="loader" />
        ) : (
          <ActionItems>
            {file && onPreview && (!file?.status || file?.status === 'success') && (
              <Action onClick={() => onPreview(file)}>Preview</Action>
            )}
            {editable && !file && <Action onClick={selectFile}>Upload</Action>}
            {editable && file && onRemove && (
              <Action className="remove" onClick={() => onRemove(file)}>
                Remove
              </Action>
            )}
          </ActionItems>
        )}
      </Content>
    </Wrapper>
  );
};
