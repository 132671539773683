import React from 'react';
import styled from 'styled-components';
import Button from 'components/button';
import Icon from 'components/icon';
import { useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import Avatar from 'components/avatar';
import { useTranslation } from 'react-i18next';
import { SupportModal } from 'components/contactUs';
import ChangeLocation from '../modal-change-location';

const Location = styled.div`
  width: 370px;
  padding: 20px;
  @media (max-width: 960px) {
    width: 100%;
    padding: 0px;
    background-color: #ddebf5;
    padding: 20px 0;
    .title {
      padding: 0 20px;
      font-size: 1.25em;
      margin-bottom: 20px;
    }
    button {
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
  .title {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  .title-mobile {
    font-weight: bold;
    text-align: center;
    margin-top: 15px;
    font-size: 1.13em;
    padding: 0 20px;
  }
  .rental-app-title {
    font-size: 1.5em;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
`;

const PropertyActions = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 25px;
  font-size: 0.81em;
  button {
    background-color: #ddecf5;
    padding: 7px 12px;
    display: flex;
    align-items: center;
    flex: 1;
    border-radius: 4px;
    color: #00a1ff;
    gap: 10px;
  }
  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;

const Logo = styled.div`
  width: 100%;
  height: 300px;
  background-color: #f5f5f5;
  border-radius: 4px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ image }) => image});
  @media (max-width: 960px) {
    height: 160px;
    border-radius: 0px;
  }
`;

const DataList = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 15px;
  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;

const Data = styled.div`
  font-size: 0.81em;
  flex: 1;
  .misc {
    text-transform: uppercase;
  }
  .label {
    color: #777;
    text-transform: uppercase;
  }
  .data {
    font-weight: bold;
    text-transform: lowercase;
  }
`;

const Agent = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  display: flex;
  gap: 10px;
  .agent-name {
    font-weight: bold;
  }
  .agent-contact {
    font-size: 0.88em;
  }
  .avatar {
    border: 1px solid #ddd;
    background-color: #eee;
  }
  @media (max-width: 960px) {
    padding: 0 20px;
  }
`;

export default ({
  application,
  applicationLocked,
  location = {},
  hidePropertyChanger = false,
  onLocationChange,
  forceDisplay,
  setLoading,
  onRefresh
}) => {
  const [showContactModal, setShowModal] = React.useState();
  const [showChangeLocationModal, setShowChangeLocationModal] = React.useState();
  const params = useParams();
  const isOverview = !params['*'];
  const isMobile = useMediaQuery({ maxWidth: 960 });
  const { t } = useTranslation();
  if (isMobile && !isOverview && !forceDisplay) return null;
  const {
    coverPhoto = [],
    marketingImages = [],
    marketRent,
    agent,
    availableDate
  } = location.applicationConfig || {};

  const photo = coverPhoto?.[0] || marketingImages?.[0];

  return (
    <Location>
      {isMobile && <div className="rental-app-title">Rental Application</div>}
      {!isMobile && <div className="title">{location.name}</div>}
      {photo && <Logo image={photo} />}
      {isMobile && <div className="title-mobile">{location.name}</div>}
      <DataList>
        {!!marketRent && (
          <Data>
            <div className="label">{t('aplicationItem.rent')}</div>
            <div className="data">{marketRent}/mo</div>
          </Data>
        )}
        {!!availableDate && (
          <Data>
            <div className="label">{t('aplicationItem.availableDate')}</div>
            <div className="data">{availableDate}</div>
          </Data>
        )}
        {(!!location.bedCount || !!location.bathCount || !!location.squareFeet) && (
          <Data>
            <div className="misc">
              <div>
                {[
                  location.bedCount ? `${location.bedCount} BD` : '',
                  location.bathCount ? `${location.bathCount} BA` : ''
                ]
                  .filter((z) => z)
                  .join(' | ')}
              </div>
              {!!location.squareFeet && <div>{location.squareFeet} SF</div>}
            </div>
          </Data>
        )}
      </DataList>
      {agent && (
        <Agent>
          <Avatar imageUrl={agent.photo} duogram={agent.duogram} />
          <div className="agent-details">
            <div className="agent-name">{agent.name}</div>
            <div className="agent-contact">{agent.phone}</div>
            <div className="agent-contact">{agent.email}</div>
          </div>
        </Agent>
      )}
      {!isMobile && (
        <PropertyActions>
          <Button onClick={() => setShowModal(true)}>
            <Icon name="fa-duotone fa-messages-question" />
            {t('buttonsActions.askQuestion')}
          </Button>
          {!hidePropertyChanger && !applicationLocked && (
            <Button
              onClick={
                onLocationChange ? onLocationChange : () => setShowChangeLocationModal(true)
              }>
              <Icon name="fa-light fa-arrows-retweet" />
              {t('buttonsActions.changeProperty')}
            </Button>
          )}
        </PropertyActions>
      )}
      {showContactModal && (
        <SupportModal
          width="600px"
          height="620px"
          modalOpen={showContactModal}
          setModalOpen={() => setShowModal(false)}
          isContactUs={true}
        />
      )}
      {showChangeLocationModal && (
        <ChangeLocation
          onRefresh={onRefresh}
          setLoading={setLoading}
          location={location}
          application={application}
          open={showChangeLocationModal}
          onClose={() => setShowChangeLocationModal(false)}
        />
      )}
    </Location>
  );
};
