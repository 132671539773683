import { useState } from 'react';

//Usage: const [name, setName] = useSessionStorage("name", "Bob");
export const setMultipleKeys = (obj) => {
  Object.keys(obj).forEach((key) => {
    // Save to local storage
    if (typeof window !== 'undefined' && JSON.stringify(obj[key])) {
      window.sessionStorage.setItem(key, JSON.stringify(obj[key]));
    }
  });
};
export const getMultipleKeys = (obj) => {
  return Object.keys(obj).reduce((final, key) => {
    let item = obj[key];
    // Save to local storage
    if (typeof window !== 'undefined') {
      item = window.sessionStorage.getItem(key);
      item = item ? JSON.parse(item) : obj[key];
    }
    return Object.assign(final, { [key]: item });
  }, {});
};
export default (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      // Get from local storage by key
      const item = window.sessionStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to sessionStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      if (typeof window !== 'undefined' && valueToStore) {
        window.sessionStorage.setItem(key, JSON.stringify(valueToStore));
      }
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.error(error);
    }
  };
  return [storedValue, setValue];
};
