import React from 'react';
import styled, { css } from 'styled-components';
import { useTranslation, Trans } from 'react-i18next';

import { UserContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import { GET_PAYMENT_SESSION } from 'dal/payments';
import Modal, { ModalScrollable, ModalFooter } from 'components/modal';
import Input from 'components/input';
import Button from 'components/button';
import { FieldGroup, Title, SubTitle, ActionsRow } from '../common';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  > div,
  > button {
    flex: 1;
    text-align: center;
  }
  ${({ isMobile }) =>
    !isMobile
      ? css`
          padding: 10px 20px 20px;
        `
      : ''}
  .price {
    font-size: 5em;
    font-weight: bold;
  }
`;

const TitleRow = styled.div`
  padding: 5px 10px;
  background-color: #00aeef;
  color: white;
  font-size: 0.88em;
  > strong {
    margin: 10px 0px;
    display: block;
    text-align: center;
    font-size: 1.25em;
  }
`;
const BodyWrapper = styled.div`
  padding: 10px;
  margin: 10px;
  flex-grow: 1;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;

export default ({ onClose, provideCouponCode }) => {
  const [couponCode, setCouponCode] = React.useState('');
  const onContinue = () => provideCouponCode(couponCode);
  return (
    <Modal closeOnOutsideClick={false} open={true} onClose={onClose} height="475">
      <TitleRow>
        <strong>
          <Trans i18nKey="appFeeStep.couponTitle">Enter a coupon code </Trans>
        </strong>
      </TitleRow>
      <ModalScrollable padding={0}>
        <BodyWrapper>
          <div>
            <Trans i18nKey="appFeeStep.couponDescription">
              If you have a coupon code you may enter it below.
            </Trans>
          </div>
          <Input
            placeholder="Please enter your code..."
            value={couponCode}
            onChange={(e) => setCouponCode(e.target.value)}
            onKeyDown={(e) => {
              //login on enter
              if (e.key === 'Enter') {
                onContinue();
              }
            }}
          />
          <ActionsRow>
            <StyledButton variant="secondary" onClick={onClose}>
              <Trans i18nKey="buttonsActions.cancel">CANCEL</Trans>
            </StyledButton>
            <StyledButton variant="primary" onClick={() => provideCouponCode(couponCode)}>
              <Trans i18nKey="buttonsActions.continue">CONTINUE</Trans>
            </StyledButton>
          </ActionsRow>
        </BodyWrapper>
      </ModalScrollable>
    </Modal>
  );
};
