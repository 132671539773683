import React from 'react';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import FormGroup from 'components/form-group';
import Button from 'components/button';
import Icon from 'components/icon';
import Text from 'components/text';
import { UserContext } from 'state';
import { useFormik } from 'formik';
import { useAPIData } from 'dal/useAPIData';
import { Row, Title, PageWrapper, PageContainer } from './common-styles';
import { useTranslation } from 'react-i18next';

const QuestionRow = styled(Row)`
  width: 100%;
  .group-label {
    text-transform: initial !important;
  }
`;

const QuestionWrapper = styled.div`
  border: 1px solid #ddd;
  background-color: #f5f5f5;
  padding: 20px;
  margin-bottom: 10px;
  .question-row {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    .question {
      flex: 1;
    }
    .actions {
      display: flex;
      align-items: center;
      gap: 10px;
      button {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px 12px;
        border-radius: 25px;
        border: 1px solid #ddd;
        &:hover {
          background-color: #eee;
        }
        &.active {
          background-color: #d8f1fa;
          border: 1px solid #00a1ff;
        }
      }
    }
  }
  .response {
    textarea {
      width: 100%;
      background-color: #fff;
    }
  }
`;

const Question = ({
  question = {},
  value = {},
  errors = {},
  handleChange,
  handleBlur,
  setFieldValue,
  t
}) => {
  const updateKey = `${question.uuid}`;

  return (
    <QuestionWrapper>
      <div className="question-row">
        <div className="question">{question.question}</div>
        {question.type === 'bool' && (
          <div className="actions">
            <Button
              onClick={() => setFieldValue(`${updateKey}.value`, true)}
              className={value.value === true ? 'active' : ''}>
              <Icon name="fa-duotone fa-thumbs-up" />
              Yes
            </Button>
            <Button
              onClick={() => setFieldValue(`${updateKey}.value`, false)}
              className={value.value === false ? 'active' : ''}>
              <Icon name="fa-duotone fa-thumbs-down" />
              No
            </Button>
          </div>
        )}
      </div>
      {(question.type === 'text' || value.value === true) && (
        <FormGroup errorText={errors.notes}>
          <div className="response">
            <Text
              placeholder={t('questionStep.explain')}
              name={`${updateKey}.notes`}
              value={value.notes || ''}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </div>
        </FormGroup>
      )}
    </QuestionWrapper>
  );
};

const typeValidations = {
  bool: yup.object({
    value: yup.boolean().required('Please choose an option'),
    notes: yup
      .string()
      .when('value', { is: true, then: yup.string().required('Notes are required') })
  }),
  text: yup.object({
    notes: yup.string().required('Notes are required')
  })
};

export default ({ onNext, onBack, application, saveApplicationPart }) => {
  const { userContext } = React.useContext(UserContext);
  const { t } = useTranslation();

  const [location] = useAPIData(
    'context.LOAD_CONTEXT',
    !application?.locationId
      ? undefined
      : { userContext, contextId: application?.locationId, bypass: true },
    {
      disableEmptyArgs: true,
      reFetchWhen: [application?._id, application?.locationId]
    }
  );

  const questions = location?.applicationConfig?.questionnaire || [];
  const initialValues = application.questionnaire || {};
  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleSubmit,
    isValid,
    handleBlur,
    handleChange
  } = useFormik({
    isInitialValid: Object.keys(initialValues).length > 0,
    initialValues,
    validationSchema: yup
      .object()
      .shape(
        questions.reduce(
          (final, q) => Object.assign(final, { [q.uuid]: typeValidations[q.type] }),
          {}
        )
      ),
    onSubmit: (values) => {
      const promise = saveApplicationPart({ questionnaire: values }).then(() => onNext());
      toast.promise(promise, {
        loading: 'Saving...',
        success: `Great!..let's continue`,
        error: (err) => {
          return err?.data?.reason || 'Oops! something went wrong 😐 ';
        }
      });
    }
  });

  const handleContinue = () => {
    handleSubmit();
  };

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center">
          <Title>{t('questionStep.title')}</Title>
        </Row>
        <Row>
          {questions.map((q, i) => (
            <Question
              key={i}
              value={values[q.uuid]}
              question={q}
              setFieldValue={setFieldValue}
              handleChange={handleChange}
              handleBlur={handleBlur}
              errors={errors?.[q.uuid]}
              touched={touched?.[q.uuid]}
              t={t}
            />
          ))}
        </Row>
        <Row className="center flex gap-10">
          <Button radius="25px" variant="secondary" onClick={onBack} width={90}>
            {t('buttonsActions.backButton')}
          </Button>
          <Button
            disabled={!isValid}
            radius="25px"
            variant="primary"
            onClick={handleContinue}
            width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
