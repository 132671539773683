import React from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { usePopper } from 'react-popper';
import { ellipsis } from 'polished';
import Icon from './icon';

const PortalWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  top: 0;
  left: 0;
`;

const Backdrop = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  width: 220px;
  max-height: 400px;
  background-color: #fff;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px, rgb(0 0 0 / 12%) 0px 15px 30px 0px;
  border-radius: 10px;
  padding: 10px 5px;
  overflow-y: auto;
  overflow-x: hidden;
  user-select: none;
  z-index: 2;
  &.width-auto {
    width: auto;
  }
  border: 1px solid #ddd;
`;

const Group = styled.div`
  margin-bottom: 15px;
  .group-title {
    padding: 0 16px;
    font-weight: bold;
    font-size: 0.81em;
  }
  .group-options {
    padding-left: 25px;
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  height: 35px;
  padding: 0 15px;
  cursor: pointer;
  &:hover {
    background-color: #f5f5f5;
    border-radius: 6px;
  }
  &.disabled {
    opacity: 0.4;
    cursor: not-allowed;
    .item-label {
      opacity: 0.4;
      cursor: not-allowed;
    }
  }
  .option-icon {
    margin-right: 10px;
  }
  .item-label {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    span {
      ${ellipsis()}
    }
  }
  &.group-item {
    font-weight: bold;
  }
`;

const RenderOptions = ({ options = [], renderItem, onItemClick, close, iconColor, itemProps }) => {
  return (
    <>
      {options
        .filter(({ visible = true }) => visible === true)
        .map((option, i) => {
          if (option?.options?.length > 0) {
            return (
              <Group key={i}>
                <MenuItem
                  className={`group-item ${option.disabled ? 'disabled' : ''} ${
                    option.className || ''
                  }`}>
                  {(option.icon || option.iconProps) && (
                    <Icon
                      name={option.icon}
                      size={15}
                      color={iconColor}
                      className="option-icon"
                      {...(option.iconProps || {})}
                    />
                  )}
                  <div className="item-label">{option.label}</div>
                </MenuItem>
                <div className="group-options">
                  <RenderOptions
                    options={option.options}
                    renderItem={renderItem}
                    onItemClick={onItemClick}
                    close={close}
                    iconColor={iconColor}
                  />
                </div>
              </Group>
            );
          }
          return (
            <div key={i}>
              {option.renderOption ? (
                option.renderOption({ close, onItemClick, option, ...itemProps })
              ) : renderItem ? (
                renderItem({ ...option, close, onItemClick, option, ...itemProps })
              ) : (
                <MenuItem
                  className={`${option.disabled ? 'disabled' : ''} ${option.className || ''}`}
                  onClick={(event) => {
                    event.stopPropagation();
                    if (option.disabled) {
                      return;
                    }
                    option.onClick
                      ? option.onClick(option, event)
                      : onItemClick && onItemClick(option, event);
                    close();
                  }}>
                  {(option.icon || option.iconProps) && (
                    <Icon
                      name={option.icon}
                      size={15}
                      color={iconColor}
                      className="option-icon"
                      {...(option.iconProps || {})}
                    />
                  )}
                  <div className="item-label">{option.label || option.title}</div>
                </MenuItem>
              )}
            </div>
          );
        })}
    </>
  );
};

const Menu = ({ children, options, autoWidth, ...props }) => {
  const [isOpen, setIsOpen] = React.useState();
  const [referenceElement, setReferenceElement] = React.useState(null);
  const [popperElement, setPopperElement] = React.useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement);
  const closeMenu = () => setIsOpen(false);

  return (
    <>
      {children({
        ref: setReferenceElement,
        onClick: () => setIsOpen(!isOpen)
      })}
      {isOpen &&
        ReactDOM.createPortal(
          <PortalWrapper>
            <Backdrop onClick={closeMenu} />
            <Content
              ref={setPopperElement}
              style={styles.popper}
              className={autoWidth ? 'width-auto' : ''}
              {...attributes.popper}>
              <RenderOptions close={closeMenu} options={options} {...props} />
            </Content>
          </PortalWrapper>,
          document.body
        )}
    </>
  );
};

export default Menu;
