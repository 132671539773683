const abbreviationForState = ({ state }) => {
  let retVal = '';
  switch (state) {
    case 'Alabama': {
      retVal = 'AL';
      break;
    }
    case 'Alaska': {
      retVal = 'AK';
      break;
    }
    case 'Arizona': {
      retVal = 'AZ';
      break;
    }
    case 'Arkansas': {
      retVal = 'AR';
      break;
    }
    case 'California': {
      retVal = 'CA';
      break;
    }
    case 'Colorado': {
      retVal = 'CO';
      break;
    }
    case 'Connecticut': {
      retVal = 'CT';
      break;
    }
    case 'Delaware': {
      retVal = 'DE';
      break;
    }
    case 'Florida': {
      retVal = 'FL';
      break;
    }
    case 'Georgia': {
      retVal = 'GA';
      break;
    }
    case 'Hawaii': {
      retVal = 'HI';
      break;
    }
    case 'Idaho': {
      retVal = 'ID';
      break;
    }
    case 'Illinois': {
      retVal = 'IL';
      break;
    }
    case 'Indiana': {
      retVal = 'IN';
      break;
    }
    case 'Iowa': {
      retVal = 'IA';
      break;
    }
    case 'Kansas': {
      retVal = 'KS';
      break;
    }
    case 'Kentucky': {
      retVal = 'KY';
      break;
    }
    case 'Louisiana': {
      retVal = 'LA';
      break;
    }
    case 'Maine': {
      retVal = 'ME';
      break;
    }
    case 'Maryland': {
      retVal = 'MD';
      break;
    }
    case 'Massachusetts': {
      retVal = 'MA';
      break;
    }
    case 'Michigan': {
      retVal = 'MI';
      break;
    }
    case 'Minnesota': {
      retVal = 'MN';
      break;
    }
    case 'Mississippi': {
      retVal = 'MI';
      break;
    }
    case 'Missouri': {
      retVal = 'MS';
      break;
    }
    case 'Montana': {
      retVal = 'MT';
      break;
    }
    case 'Nebraska': {
      retVal = 'NE';
      break;
    }
    case 'Nevada': {
      retVal = 'NV';
      break;
    }
    case 'New Hampshire': {
      retVal = 'NH';
      break;
    }
    case 'New Jersey': {
      retVal = 'NJ';
      break;
    }
    case 'New Mexico': {
      retVal = 'NM';
      break;
    }
    case 'New York': {
      retVal = 'NY';
      break;
    }
    case 'North Carolina': {
      retVal = 'NC';
      break;
    }
    case 'North Dakota': {
      retVal = 'ND';
      break;
    }
    case 'Ohio': {
      retVal = 'OH';
      break;
    }
    case 'Oklahoma': {
      retVal = 'OK';
      break;
    }
    case 'Oregon': {
      retVal = 'OR';
      break;
    }
    case 'Pennsylvania': {
      retVal = 'PA';
      break;
    }
    case 'Rhode Island': {
      retVal = 'RI';
      break;
    }
    case 'South Carolina': {
      retVal = 'SC';
      break;
    }
    case 'South Dakota': {
      retVal = 'SD';
      break;
    }
    case 'Tennessee': {
      retVal = 'TN';
      break;
    }
    case 'Texas': {
      retVal = 'TX';
      break;
    }
    case 'Utah': {
      retVal = 'UT';
      break;
    }
    case 'Vermont': {
      retVal = 'VT';
      break;
    }
    case 'Virginia': {
      retVal = 'VA';
      break;
    }
    case 'Washington': {
      retVal = 'WA';
      break;
    }
    case 'West Virginia': {
      retVal = 'WV';
      break;
    }
    case 'Wisconsin': {
      retVal = 'WI';
      break;
    }
    case 'Wyoming': {
      retVal = 'WY';
      break;
    }
  }
  return retVal;
};

const EXCEPTION_TYPES = {
  eviction: {
    uuid: 'eviction',
    label: 'Disables eviction screening',
    description: 'Eviction background is inadmissable',
    household: true,
    category: 'eviction',
    active: () => true
  },
  guarantors: {
    uuid: 'guarantors',
    label: 'Disables adding guarantors to applications',
    description: 'Guarantors are not allowed',
    household: true,
    category: 'guarantors',
    active: () => true
  },
  ['housingAssistance-noCredit']: {
    uuid: 'housingAssistance-noCredit',
    label: 'Disables credit reports if an applicant receives housing assistance',
    description: 'Credit background is inadmissable if an applicant receives housing assistance',
    household: false, // when false it means this should only apply to the applicant
    category: 'credit',
    active: ({ application }) => {
      return !!(application?.section8?.active || application?.section8?.otherSupport);
    }
  }
};

const REGIONAL_EXCEPTIONS = {
  NY: ['eviction'],
  GA: ['guarantors'],
  CO: ['housingAssistance-noCredit']
};

const getLocationExceptions = (address) => {
  if (!address?.state && !address?.stateCode) return [];
  const state =
    abbreviationForState({ state: address?.state }) || address?.stateCode || address?.state;

  return REGIONAL_EXCEPTIONS[state] || [];
};

const locationExceptions = ({ address, application, category }) => {
  return getLocationExceptions(address)
    ?.map((uuid) => EXCEPTION_TYPES[uuid])
    ?.filter((ex) => ex.category === category && ex.active({ application }));
};

export const hasLocationException = ({ address, application, category }) => {
  return locationExceptions({ address, application, category })?.length > 0;
};
