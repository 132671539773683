import React from 'react';
import debounce from 'lodash/debounce';
import * as USER_API from './user';
import * as APPLICATION_API from './applications';
import * as CONTEXT_API from './context';
import * as FORMS_API from './forms';
import * as APPLICATION_ITEMS from './application-items';

// create a name value pair for the api functions
const API_LIST = {
  user: USER_API,
  application: APPLICATION_API,
  context: CONTEXT_API,
  forms: FORMS_API,
  applicationItems: APPLICATION_ITEMS
};
const API = Object.keys(API_LIST).reduce((finalApi, contextKey) => {
  return Object.keys(API_LIST[contextKey]).reduce(
    (final, curAPI) =>
      Object.assign(final, { [`${contextKey}.${curAPI}`]: API_LIST[contextKey][curAPI] }),
    finalApi
  );
}, {});

const useIsMounted = () => {
  const isMounted = React.useRef(true);
  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );
  return isMounted;
};

export const useAPIData = (
  fnName,
  args,
  { reFetchWhen = [], disableEmptyArgs = false, onSuccess, onError, onBeforeFetch, wait = 0 } = {}
) => {
  const [data, setData] = React.useState();
  const [fetchInProgress, setFetchInProgress] = React.useState(false);
  const [dataFetched, setDataFetched] = React.useState(false);
  const [failedFetch, setFailedFetch] = React.useState(false);
  const isMounted = useIsMounted();

  const makeCall = React.useCallback(!wait ? (fn) => fn() : debounce((fn) => fn(), wait), []);

  const fetchData = async () => {
    try {
      onBeforeFetch && onBeforeFetch();
      setFetchInProgress(true);
      const data = await API[fnName](args);
      if (!isMounted.current) return; // prevent setState on unmounted components
      setFetchInProgress(false);
      setData(data);
      onSuccess && onSuccess(data);
      setDataFetched(true);
      setFailedFetch(false);
    } catch (error) {
      setFetchInProgress(false);
      setFailedFetch(true);
      try {
        console.error({ errData: error?.data, error });
        onError && onError(error);
      } catch (err) {
        console.error({ errData: err?.data, error: err });
      }
    }
  };

  React.useEffect(() => {
    if (disableEmptyArgs && Object.keys(args || {}).length === 0) {
      return;
    }
    makeCall(fetchData);
  }, reFetchWhen);

  return [data, fetchInProgress, dataFetched, failedFetch];
};
