import React from 'react';
import { Row, Title, Description, PageWrapper, PageContainer } from '../common-styles';
import Button from '../../../components/button';
import NewMember from './new-member';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useTranslation } from 'react-i18next';
import MembersList from './members-list';

export default ({ application, location, saveApplicationPart, onNext, onBack }) => {
  const { t } = useTranslation();
  const [editMember, setEditMember] = React.useState();
  const [members, setMembers] = React.useState(() =>
    []
      .concat(application.otherContacts)
      .concat(application.minors?.map((z) => ({ ...z, type: 'minor' })))
      .filter((z) => z)
  );

  const canAddOccupants = !['guarantor', 'co-occupant'].includes(application?.type);
  const dialog = useConfirmDialog();
  const hideGuarantors = location?.applicationConfig?.regionalExceptions?.includes('guarantors');

  const OPTIONS = (t) => {
    const opts = [];
    if (canAddOccupants) {
      opts.push({
        value: 'resident',
        label: `${t('members.options.adults')}`,
        subtitle: `${t('members.options.adultsSubtitle')}`,
        types: ['partner', 'resident']
      });
    }

    opts.push({
      value: 'minor',
      label: `${t('members.options.kids')}`,
      subtitle: `${t('members.options.kidsSubtitle')}`,
      types: ['minor']
    });

    if (!hideGuarantors) {
      opts.push({
        value: 'guarantor',
        label: `${t('members.options.guarantor')}`,
        subtitle: `${t('members.alwaysInvite')}`,
        types: ['guarantor']
      });
    }

    return opts;
  };

  const handleAdd = (member) => {
    const isEdit = members.some((m) => m.uuid === member.uuid);
    const newMembers = isEdit
      ? members.map((m) => {
          if (m.uuid !== member.uuid) return m;
          return member;
        })
      : [...members, member];
    setMembers(newMembers);
  };

  const handleRemove = ({ uuid, firstName, lastName, application }) => {
    if (application) {
      dialog({
        title: 'Application in progress, cannot remove',
        description: `${firstName} ${lastName} has already started their application. If you would like to remove them from your application have them delete their existing application first. `,
        onConfirm: () => {},
        confirmLabel: 'OK',
        hideCancel: true
      });
      return;
    }
    dialog({
      title: `Are you sure you want to remove ${firstName} ${lastName} `,
      onConfirm: () => {
        setMembers(members.filter((m) => m.uuid !== uuid));
      }
    });
  };

  const handleContinue = () => {
    saveApplicationPart(
      {
        otherContacts: members.filter((z) => z.type !== 'minor'),
        minors: members.filter((z) => z.type === 'minor')
      },
      { sendInvites: true }
    ).then(() => onNext());
  };

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center">
          <Title>{t('members.title')}</Title>
        </Row>
        <Row>
          <Description>{t('members.description')}</Description>
        </Row>
        <Row>
          {!canAddOccupants && (
            <MembersList
              key={'resident'}
              title={'Other adults in this household'}
              types={['partner', 'resident']}
              subtitle={t('members.options.adultsSubtitle')}
              members={members.filter((m) => ['partner', 'resident'].includes(m.type))}
              application={application}
            />
          )}
          {OPTIONS(t).map((option) => (
            <MembersList
              key={option.value}
              title={option.label}
              types={option.types}
              subtitle={option.subtitle}
              members={members.filter((m) => option.types.includes(m.type))}
              onAdd={() => setEditMember({ type: option.value })}
              onEdit={setEditMember}
              onRemove={handleRemove}
              application={application}
            />
          ))}
        </Row>
        <Row className="flex gap-10 center">
          <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
            {t('buttonsActions.cancel')}
          </Button>
          <Button radius="25px" variant="primary" onClick={handleContinue} width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
      <NewMember
        onSave={handleAdd}
        open={!!editMember}
        onClose={() => setEditMember(undefined)}
        member={editMember}
        location={location}
        application={application}
      />
    </PageWrapper>
  );
};
