import React from 'react';
import styled from 'styled-components';
import Modal, { ModalScrollable, ModalFooter } from 'components/modal';
import { Row, Title, PageContainer, PageWrapper, Description } from './common-styles';
import Input from 'components/input';
import Button from 'components/button';
import { useAPIData } from '../../dal/useAPIData';
import { GET_PAYMENT_SESSION } from '../../dal/payments';
import { UserContext } from '../../state';
import { useTranslation, Trans } from 'react-i18next';

const TitleRow = styled.div`
  padding: 5px 10px;
  background-color: #00aeef;
  color: white;
  font-size: 0.88em;
  > strong {
    margin: 10px 0px;
    display: block;
    text-align: center;
    font-size: 1.25em;
  }
`;
const BodyWrapper = styled.div`
  padding: 10px;
  margin: 10px;
  flex-grow: 1;
`;

const Banner = styled.div`
  padding: 30px 20px;
  background-color: #f5f5f5;
  position: relative;
  .price {
    font-size: 5em;
    font-weight: bold;
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 10px;
    background-size: 20px 20px;
    background-image: radial-gradient(circle at 10px -5px, transparent 12px, #fff 13px);
  }
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    background-repeat: repeat;
    height: 15px;
    background-size: 40px 20px;
    background-image: radial-gradient(circle at 10px 15px, #fff 12px, transparent 13px);
  }
`;

const CouponCodeModal = ({ onClose, provideCouponCode, onRefresh, onNext }) => {
  const [couponCode, setCouponCode] = React.useState('');
  const onContinue = () => {
    provideCouponCode(couponCode).then(() => {
      onRefresh();
      onNext();
    });
  };
  return (
    <Modal closeOnOutsideClick={false} open={true} onClose={onClose} height="475">
      <TitleRow>
        <strong>
          <Trans i18nKey="appFeeStep.couponTitle">Enter a coupon code </Trans>
        </strong>
      </TitleRow>
      <ModalScrollable padding={0}>
        <BodyWrapper>
          <Row>
            <Trans i18nKey="appFeeStep.couponDescription">
              If you have a coupon code you may enter it below.
            </Trans>
          </Row>
          <Row>
            <Input
              placeholder="Please enter your code..."
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              onKeyDown={(e) => {
                //login on enter
                if (e.key === 'Enter') {
                  onContinue();
                }
              }}
            />
          </Row>
        </BodyWrapper>
      </ModalScrollable>
      <ModalFooter>
        <Button variant="secondary" onClick={() => onClose()}>
          <Trans i18nKey="buttonsActions.cancel">CANCEL</Trans>
        </Button>
        <Button style={{ marginLeft: '20px' }} variant="primary" onClick={onContinue}>
          <Trans i18nKey="buttonsActions.continue">CONTINUE</Trans>
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ({ onNext, onBack, application, provideCouponCode, onRefresh }) => {
  const { userContext } = React.useContext(UserContext);
  const [couponCodeModal, setCouponCodeModal] = React.useState(false);
  const { t } = useTranslation();

  // if you've paid you shouldn't be here!
  React.useEffect(() => {
    if (application.paid) {
      onNext();
    }
  }, []);

  const [location] = useAPIData(
    'context.LOAD_CONTEXT',
    !application?.locationId
      ? undefined
      : { userContext, contextId: application?.locationId, bypass: true },
    {
      disableEmptyArgs: true,
      reFetchWhen: [application?._id, application?.locationId]
    }
  );

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('appFeeStep.title')}</Title>
        </Row>
        <Row>
          <Banner>
            <Row className="center">
              <div className="price">{location?.applicationConfig?.applicationFee}</div>
              <div className="price-legend">{t('appFeeStep.subtitle')}</div>
            </Row>
            <Row className="center">
              <p style={{ textAlign: 'center' }}>
                <Trans i18nKey="appFeeStep.description" />
              </p>
            </Row>
            <Row className="center">
              <Button variant="link" onClick={() => setCouponCodeModal(true)}>
                {t('appFeeStep.coupon')}
              </Button>
            </Row>
            <br />
            <br />
            <Row className="center flex gap-10">
              <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
                {t('buttonsActions.cancel')}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  GET_PAYMENT_SESSION({ userContext, applicationId: application?._id }).then(
                    (z) => {
                      window.location = z.paymentUrl;
                    }
                  );
                }}
                width={90}>
                {t('buttonsActions.next')}
              </Button>
            </Row>
          </Banner>
        </Row>
        {couponCodeModal && (
          <CouponCodeModal
            provideCouponCode={provideCouponCode}
            onRefresh={onRefresh}
            onNext={onNext}
            onClose={() => setCouponCodeModal(false)}
          />
        )}
      </PageContainer>
    </PageWrapper>
  );
};
