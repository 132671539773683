import React from 'react';
import styled from 'styled-components';
import { t } from 'i18next';

import Button from 'components/button';
import Icon from 'components/icon';
import { STEP_STATES } from 'helpers/enums';
import SectionModal from './section-modal';

const SectionWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px 30px;
  border: 1px solid #ddd;
  margin-bottom: 10px;
  @media (max-width: 960px) {
    margin: 0px;
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 15px 20px;
    &:last-child {
      border-bottom: none;
    }
  }
  gap: 10px;
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
  .body {
    flex: 1;
  }
  .section-title {
    font-weight: bold;
    text-transform: uppercase;
  }
`;
const MainAction = styled(Button)`
  padding: 7px 12px;
  color: #fff;
  background-color: var(--color-primary);
  display: flex;
  gap: 10px;
  align-items: baseline;
  justify-content: center;
  border-radius: 25px;
  text-transform: uppercase;
  min-width: 100px;

  &.completed,
  &.done,
  &.inProgress {
    color: var(--color-primary);
    background-color: #fff;
    border: 1px solid var(--color-primary);
  }
  &:disabled {
    background-color: #d4dae1 !important;
    color: #71828f !important;
    opacity: 1;
    border: none;
  }
  &.submitted {
    background-color: #ffa907 !important;
    color: #fff !important;
  }
  &.approved {
    border: 1px solid #5cd104 !important;
    background-color: #5cd104 !important;
    color: #fff !important;
  }
`;

const SectionsList = styled.div``;
const ButtonActionWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-end;
  gap: 5px;
`;
const SubText = styled.div`
  color: #71828f;
  font-size: 0.8em;
`;

const createSectionKey = (id) => `section_${id}`;
const Section = ({ title, id, description, onClick, isMobile, status }) => {
  const disabled = status?.locked || status.actionEnabled === false;

  return (
    <SectionWrapper
      id={createSectionKey(id)}
      title={status?.locked ? t('overviewStep.stepLockedTooltip') : undefined}
      onClick={() => {
        if (disabled) return;
        onClick();
      }}>
      {isMobile && (
        <>
          {[STEP_STATES.done, STEP_STATES.completed].includes(status.state) ? (
            <Icon name={status.icon || 'fa-duotone fa-circle-check'} color="#5bcd05" />
          ) : (
            <Icon name={status.icon || 'fa-sharp fa-light fa-circle'} color="#ccc" />
          )}
        </>
      )}
      <div className="body">
        <div className="section-title">{title}</div>
        <div className="section-description">{description}</div>
      </div>
      {isMobile ? (
        <>{status.actionEnabled && !disabled && <Icon name="fa-solid fa-chevron-right" />}</>
      ) : (
        <ButtonActionWrapper>
          <MainAction
            disabled={disabled}
            className={`${status.state || ''} ${status.className || ''}`}>
            {status.state === STEP_STATES.done && (
              <Icon name={status.icon || 'fa-duotone fa-circle-check'} />
            )}
            <span>{status.label}</span>
          </MainAction>
          {!!status.subtext && <SubText>{status.subtext}</SubText>}
        </ButtonActionWrapper>
      )}
    </SectionWrapper>
  );
};

export default ({ data, steps, location, locationFetched, handleActions, isMobile }) => {
  const [sectionModal, setSectionModal] = React.useState();

  const openSection = (key) => {
    setSectionModal(key);
  };

  return (
    <SectionsList>
      {steps
        ?.filter((s) => !s.hidden)
        .map((s, i) => (
          <Section
            key={i}
            id={s.key}
            {...s}
            onClick={() => openSection(s?.key)}
            isMobile={isMobile}
          />
        ))}
      <SectionModal
        open={!!sectionModal}
        modalType={sectionModal}
        data={data}
        location={location}
        locationFetched={locationFetched}
        steps={steps}
        handleActions={handleActions}
        isMobile={isMobile}
        onDone={() => {
          handleActions({ id: 'refresh' });
          setSectionModal();
        }}
        onClose={() => setSectionModal()}
      />
    </SectionsList>
  );
};
