import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { UserContext } from 'state';
import Modal from 'components/modal';
import {
  GET_VERIFAST_URL,
  UPDATE_VERIFAST_STATUS,
  GET_VERIFAST_STATUS
} from 'dal/incomeVerification';

const allowedDomains = [
  'https://sandbox.trustrentify.com',
  'https://app.verifast.com',
  'https://sandbox.verifast.com'
];

const Close = styled.div`
  position: absolute;
  top: 20px;
  left: 20px;
  cursor: pointer;

  @media (max-width: 600px) {
    top: 30px;
    left: 25px;
    color: #6d816a;
  }
`;
const StyledIFrame = styled.iframe`
  border: 0;
  width: 100%;
  height: 100%;
`;
const VerifastModal = ({ verifastData, updateStatus, onClose, onComplete, requestType }) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 600 });

  React.useEffect(() => {
    const handleTopMessage = (e) => {
      let iFrame = document.getElementById('iFrame');
      e.data.origin = e.origin;
      const currUrl = allowedDomains.find(
        (domain) => verifastData?.iFrameUrl?.toLowerCase().indexOf(domain.toLowerCase()) >= 0
      );
      if (currUrl) {
        iFrame.contentWindow.postMessage(e.data, currUrl);
      }
    };
    const handleMessage = (e) => {
      e.data.origin = e.origin;
      if (allowedDomains.includes(e.origin)) {
        updateStatus({ data: e?.data || {} });
        if (
          e?.data?.detail === 'complete'
          //||          e?.data?.detail === 'employment-verification-complete'
        ) {
          setTimeout(() => onComplete(), 3000);
        }
      }
    };
    window.top.addEventListener('message', handleTopMessage);
    window.addEventListener('message', handleMessage);

    return () => {
      window.top.removeEventListener('message', handleTopMessage);
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  if (!verifastData) {
    return null;
  }

  const verifastUrl = `${verifastData.iFrameUrl || ''}${
    verifastData.iFrameUrl?.indexOf('?') >= 0 ? '&' : '?'
  }isIframe=1`;

  return (
    <Modal
      closeOnOutsideClick={false}
      open={true}
      onClose={onClose}
      hideClose={false}
      closeElement={
        <Close onClick={onClose}>
          <i className="fa-solid fa-chevron-left" />
          &nbsp;{isMobile ? t('buttonsActions.overviewMobile') : t('breadcrumNav.myApplications')}
        </Close>
      }
      radius={requestType === 'identity' ? '0px' : undefined}
      maxHeight={requestType === 'identity' ? '100%' : undefined}
      width={requestType === 'identity' ? '100%' : '90%'}
      height={requestType === 'identity' ? '100%' : '90%'}>
      <StyledIFrame
        border="0"
        allow="camera"
        src={verifastUrl}
        id="iFrame"
        width="100%"
        height="100%"
      />
    </Modal>
  );
};

export default ({ actionId, open, onComplete, requestType, onClose }) => {
  const { userContext } = React.useContext(UserContext);
  const [verifastData, setVerifastData] = React.useState(null);

  const checkVerifastCompletion = (retry = false) =>
    GET_VERIFAST_STATUS({ userContext, actionId }).then((result) => {
      if (!result) return;

      // check if we already completed this step, if so move on
      switch (requestType) {
        case 'identity':
          if (result.verifiedIdentity) {
            return onComplete && onComplete();
          }
          break;
        case 'bank':
          if (result.verifiedBank) {
            return onComplete && onComplete();
          }
          break;
        default:
          if (result.verifiedBank && result.verifiedIdentity) {
            return onComplete && onComplete();
          }
          break;
      }

      // if (retry) {
      //   setTimeout(() => checkVerifastCompletion(false), 750);
      // }
    });

  const updateStatus = (data) => {
    UPDATE_VERIFAST_STATUS({
      userContext,
      actionId,
      verificationId: verifastData.uuid,
      data: data || {}
    });
  };

  // check on a redirect back if the verification steps have been wrapped up
  React.useEffect(() => {
    //HACK: bug in Verifast causes a complete webhook not to fire and records left unmarked.so lets always do this...
    checkVerifastCompletion();
  }, [actionId, open]);

  React.useEffect(() => {
    if (open && actionId) {
      GET_VERIFAST_URL({ userContext, actionId, requestType }).then((result) => {
        setVerifastData(result);
      });
    }
  }, [open, requestType, actionId]);

  return !open || !verifastData ? null : (
    <VerifastModal
      requestType={requestType}
      verifastData={verifastData}
      updateStatus={updateStatus}
      onClose={() => {
        //HACK: bug in Verifast causes a complete webhook not to fire and records left unmarked.so lets always do this...
        checkVerifastCompletion(true);
        onClose && onClose();
      }}
      onComplete={() => {
        checkVerifastCompletion(true);
        onComplete && onComplete();
      }}
    />
  );
};
