import toast from 'react-hot-toast';
import FileSaver from 'file-saver';
import { GET_FILE } from 'dal/applications';

export const fileIconFromName = (fileName) => {
  const extension = fileName?.split('.').pop()?.toLowerCase();

  if (['pdf'].includes(extension)) return 'fa-solid fa-file-pdf';
  if (['jpeg', 'jpg', 'png', 'gif', 'tiff', 'svg'].includes(extension)) return 'fa-solid fa-image';
  if (['doc', 'docx'].includes(extension)) return 'fa-solid fa-file-word';
  if (['mpg', 'mp4'].includes(extension)) return 'fa-solid fa-video';
  if (['xls', 'xlsx', 'csv'].includes(extension)) return 'fa-solid fa-file-excel';
  if (['ppt', 'pptx'].includes(extension)) return 'fa-solid fa-file-powerpoint';
  if (['txt', 'rtf'].includes(extension)) return 'fa-solid fa-file-lines';
  if (['tar', 'rar', 'zip'].includes(extension)) return 'fa-solid fa-file-zip';
  if (['mp3', 'wav'].includes(extension)) return 'fa-solid fa-volume';

  return 'fa-solid fa-file';
};

export const previewFile = ({ t, userContext, locationId, applicationId, file }) => {
  return GET_FILE({ userContext, locationId, applicationId, field: file.type, fileUuid: file.uuid })
    .then((fileInfo) => {
      if (fileInfo.url) {
        FileSaver.saveAs(fileInfo.url, file.name);
      }
    })
    .catch((err) => {
      console.error({ errData: err.data, err });
      toast.error(
        `${file?.name || 'UNKNOWN FILE'} ${t ? t('upload_labels.failed') : 'FAILED'}: ${
          err.data?.message ? err.data?.message : err.message || err.name
        }`
      );
    });
};
