import React from 'react';
import styled, { css } from 'styled-components';
import { Trans } from 'react-i18next';
import { t } from 'i18next';

import Menu from 'components/menu';
import ProgressBar from 'components/percent-bar';
import Button from 'components/button';
import Icon, { IconShape } from 'components/icon';
import { SupportModal } from 'components/contactUs';
import { SubTitle } from './common';

const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  ${({ isMobile }) => (isMobile ? `margin: 0 10px;` : '')}
  .progress-labels {
    flex-shrink: 0;
    .title {
      font-weight: bold;
    }
  }
`;
const ApprovedWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  > div {
    font-size: 1.75em !important;
    font-weight: normal !important;
    text-transform: uppercase;
    margin: 0 !important;
    padding: 0 !important;
  }

  ${({ isMobile }) =>
    isMobile
      ? css`
          margin: 10px 10px 0;
          flex-flow: column;
          text-align: center;
          > div {
            font-size: 1.25em !important;
          }
        `
      : ''}
`;
const ProceedButton = styled(Button)`
  padding: 7px 12px;
  color: #fff;
  background-color: var(--color-primary);
  display: flex;
  gap: 10px;
  align-items: baseline;
  justify-content: center;
  border-radius: 25px;
  text-transform: uppercase;
  min-width: 100px;

  border: 1px solid #5cd104;
  background-color: #5cd104;
  color: #fff !important;
`;
const SubmittedButton = styled(Button)`
  padding: 7px 12px;
  color: #fff;
  background-color: var(--color-primary);
  display: flex;
  gap: 10px;
  align-items: baseline;
  justify-content: center;
  border-radius: 25px;
  text-transform: uppercase;
  min-width: 100px;

  border: 1px solid #ffa907;
  background-color: #ffa907 !important;
  color: #fff !important;
`;
const StatusMobile = styled.div`
  position: sticky;
  bottom: 0;
  background-color: #fff;
  padding: 10px 20px;
  box-shadow: 1px -2px 6px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  gap: 10px;
  > div {
    flex: 1;
  }
  .title {
    font-weight: bold;
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .status {
    font-size: 0.75em;
    color: #777;
  }
  button {
    display: flex;
    align-items: center;
  }
`;

const ActionStatus = ({ action = {}, setShowModal }) => {
  if (action.status?.isApproved) {
    return (
      <ApprovedWrapper>
        <SubTitle>You've been approved!</SubTitle>
        <ProceedButton onClick={() => setShowModal(true)}>
          <Icon name="fa-duotone fa-party-horn" />
          Request an update
        </ProceedButton>
      </ApprovedWrapper>
    );
  }
  if (action.submitted) {
    return (
      <ApprovedWrapper>
        <SubTitle>Your request has been submitted</SubTitle>
        <SubmittedButton onClick={() => setShowModal(true)}>
          <Icon name="fa-duotone fa-paper-plane" />
          Request an update
        </SubmittedButton>
      </ApprovedWrapper>
    );
  }
  return (
    <ProgressWrapper>
      <div className="progress-labels">
        <div className="title">
          <Trans i18nKey={`layouts.${action.type}.overview.status`}>Tour Status</Trans>
        </div>
        <div className="status">
          {action.stepStatus?.complete} {t('overviewStep.of')} {action.stepStatus?.count}{' '}
          {t('overviewStep.complete')}
        </div>
      </div>
      <ProgressBar height={15} progress={action.stepStatus?.progress} />
    </ProgressWrapper>
  );
};

const ActionStatusMobile = ({ action = {}, handleActions, setShowModal }) => {
  const StatusMenu = () => (
    <Menu
      onItemClick={({ id }) => {
        if (id === 'update') return setShowModal(true);
        return handleActions({ id });
      }}
      options={[
        {
          label: action.submitted ? 'Request an update' : 'Ask a question',
          icon: 'fa-duotone fa-paper-plane',
          id: 'update'
        },
        { label: 'Change Property', icon: 'fa-duotone fa-arrows-retweet', id: 'change-property' }
      ]}>
      {(provided) => (
        <Button {...provided}>
          <Icon name="fa-solid fa-ellipsis" size={24} />
        </Button>
      )}
    </Menu>
  );

  if (action.status?.isApproved) {
    return (
      <StatusMobile>
        <div>
          <div className="title">
            <IconShape color="#5cd104" size={28} name="fa-duotone fa-party-horn" />
            You've been approved!
          </div>
        </div>
        <StatusMenu />
      </StatusMobile>
    );
  }
  if (action.submitted) {
    return (
      <StatusMobile>
        <div>
          <div className="title">
            <IconShape color="#ffa907" size={28} name="fa-duotone fa-inbox-full" />
            Your request has been submitted
          </div>
        </div>
        <StatusMenu />
      </StatusMobile>
    );
  }

  return (
    <StatusMobile>
      <div>
        <div className="title">
          <Trans i18nKey={`layouts.${action.type}.overview.status`}>Tour Status</Trans>
        </div>
        <ProgressBar height={8} color="#5bcd05" progress={action.stepStatus?.progress} />
        <div className="status">
          {action.stepStatus?.complete} {t('overviewStep.of')} {action.stepStatus?.count}{' '}
          {t('overviewStep.complete')}
        </div>
      </div>
      <StatusMenu />
    </StatusMobile>
  );
};

export default ({ isMobile, ...props }) => {
  const [showContactModal, setShowModal] = React.useState();

  return (
    <>
      {isMobile ? (
        <ActionStatusMobile setShowModal={setShowModal} {...props} />
      ) : (
        <ActionStatus setShowModal={setShowModal} {...props} />
      )}
      {showContactModal && (
        <SupportModal
          width="600px"
          height="620px"
          contactMode="pmc"
          modalOpen={showContactModal}
          setModalOpen={() => setShowModal(false)}
          isContactUs={true}
        />
      )}
    </>
  );
};
