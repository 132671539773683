import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import Button from 'components/button';

const Wrapper = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  flex-direction: column;
  font-size: 15px;
  background-color: #acc3a6;
`;

const BoxMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 60px;
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
  .message {
    font-size: 3em;
    margin-top: 40px;
  }
  button {
    margin-top: 40px;
    padding: 7px 16px;
    background-color: #dc0100;
    color: #fff;
    border-radius: 6px;
  }
`;

export default ({ onReturn }) => {
  return (
    <Wrapper>
      <BoxMessage>
        <Icon color="#69DC9E" name="fa-duotone fa-circle-check" size={60} />
        <div className="message">You’ve finished signing!</div>
        <p>You’ll receive an email copy once everyone has signed.</p>
        {/* <Button onClick={onReturn}>BACK TO THE DOCUMENT</Button> */}
      </BoxMessage>
    </Wrapper>
  );
};
