import React from 'react';
import { useFormik } from 'formik';
import styled from 'styled-components';
import { Row } from '../common-styles';
import Button from 'components/button';
import FormGroup from 'components/form-group';
import Modal, { ModalFooter } from 'components/modal';
import { Trans, useTranslation } from 'react-i18next';
import Checkbox from 'components/checkbox';
import Text from 'components/text';
import * as yup from 'yup';

const Step = styled.div`
  padding: 10px;
  background-color: var(--color-bg-primary);
  border-radius: 6px;
  li {
    margin-bottom: 7px;
  }
`;
const ExceptionRow = styled.div``;
const TitleRow = styled.div`
  padding: 5px 10px;
  background-color: #00aeef;
  color: white;
  font-size: 0.88em;
  > strong {
    margin: 10px 0px;
    display: block;
    text-align: center;
    font-size: 1.25em;
  }
`;
const BodyWrapper = styled.div`
  padding: 10px;
  margin: 10px;
  flex-grow: 1;
`;
const CheckWrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 5px;
  > label {
    display: flex;
    align-items: center;
  }
`;
const StyledText = styled(Text)`
  min-height: 50px;
`;

const NO_RENT_REASONS = [
  'student',
  'partiallyEmployed',
  'unemployed',
  'stayAtHome',
  'caregiver',
  'other'
];

const SkipIncome = ({ open, onClose, saveApplicationPart, backToOverview, t }) => {
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } =
    useFormik({
      initialValues: { reason: [], comments: '' },
      validationSchema: yup.object().shape({
        comments: yup.string().max(5000, `${t('form.validate.long')}`),
        reason: yup.array(yup.string())
      }),
      validate: (submittedValues) => {
        const errors = {};
        if (!submittedValues?.reason?.length) {
          errors.reason = t('skip_income_modal.validate.reason');
        }
        if (submittedValues?.reason?.includes('other') && !submittedValues?.comments?.length) {
          errors.comments = t('skip_income_modal.validate.comments');
        }
        return errors;
      },
      onSubmit: (values) => {
        saveApplicationPart({
          ...values,
          noRentContribution: true,
          stepProgress: { income: { status: -1 }, incomeVerification: { status: -1 } }
        }).then(() => backToOverview({ saveProgress: false }));
      }
    });

  return (
    <Modal closeOnOutsideClick={false} open={open} onClose={onClose} height="auto">
      <TitleRow>
        <Trans i18nKey="skip_income_modal.title">
          <strong>Skip Income Verification?</strong>
        </Trans>
      </TitleRow>
      <BodyWrapper>
        <Row>
          <Step>{t('skip_income_modal.descriptions')}</Step>
        </Row>
        <Row>
          <FormGroup label={t('skip_income_modal.reason')} errorText={errors.reason}>
            <CheckWrapper>
              {NO_RENT_REASONS.map((r) => (
                <Checkbox
                  key={r}
                  label={t(`skip_income_modal.reason_${r}`)}
                  checked={values?.reason?.includes(r)}
                  onChange={() =>
                    setFieldValue(
                      'reason',
                      values?.reason?.includes(r)
                        ? values?.reason.filter((z) => z !== r)
                        : []
                            .concat(values?.reason)
                            .concat(r)
                            .filter((z) => z)
                    )
                  }
                />
              ))}
            </CheckWrapper>
          </FormGroup>
          {values?.reason?.includes('other') && (
            <FormGroup label={t('skip_income_modal.comments')} errorText={errors.comments}>
              <StyledText
                name={'comments'}
                value={values.comments}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </FormGroup>
          )}
        </Row>
      </BodyWrapper>
      <ModalFooter>
        <Button variant="secondary" onClick={() => onClose()}>
          {t('buttonsActions.provideIncome')}
        </Button>
        <Button style={{ marginLeft: '20px' }} variant="primary" onClick={() => handleSubmit()}>
          {t('buttonsActions.continue')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ({ saveApplicationPart, application, onNext, backToOverview }) => {
  const [openSkipModal, setOpenSkipModal] = React.useState(false);
  const { t } = useTranslation();

  if (['guarantor'].includes(application?.type)) {
    return <></>;
  }
  return (
    <>
      <SkipIncome
        open={openSkipModal}
        onClose={() => setOpenSkipModal(false)}
        onNext={onNext}
        saveApplicationPart={saveApplicationPart}
        backToOverview={backToOverview}
        t={t}
      />
      <ExceptionRow>
        <Button variant="link" height={40} onClick={() => setOpenSkipModal(true)}>
          {t('buttonsActions.contributeToRent')}
        </Button>
      </ExceptionRow>
    </>
  );
};
