import zxcvbn from 'zxcvbn';

const passwordStrengthChecker = (password = '') => {
  const result = {};
  if (password.length < 9) {
    result.score = 'bad';
    result.suggestion = 'Password too short!';
  }
  if (password.length > 32) {
    result.score = 'bad';
    result.suggestion = 'Max number of characters is 32.';
  }

  const mandatory = {
    digits: /\d/.test(password),
    lower: /[a-z]/.test(password),
    upper: /[A-Z]/.test(password),
    nonWords: /\W/.test(password)
  };

  if (Object.keys(mandatory).some((key) => mandatory[key] === false)) {
    result.score = 'bad';
    result.suggestion = '';
  }

  if (result.score === 'bad') return result;

  const strength = zxcvbn(password);
  switch (strength.score) {
    case (0, 1):
      result.score = 'bad';
      break;
    case 2:
      result.score = 'weak';
      break;
    case 3:
      result.score = 'good';
      break;
    case 4:
      result.score = 'strong';
      break;
  }

  return result;
};

export default passwordStrengthChecker;
