import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';
import { primary } from 'styles/colors';
import { Section } from 'components/section';
import Icon from 'components/icon';
import { toMoneyString } from 'pages/approver/share/detail/application-view/helpers';
import { Card, CardDataDisplay, CardDataGroup } from './card';

const VAlignRow = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.6;
  > a {
    cursor: pointer;
    color: ${({ theme }) => theme?.colors?.primary || primary};
    &:hover {
      color: ${({ theme }) => darken(0.2, theme?.colors?.primary || primary)};
    }
  }
`;
const StyledCard = styled(Card)`
  .boxContent {
    > div {
      flex-flow: column;
      display: flex;
      gap: 15px;
      > div {
        gap: 10px;
        margin-bottom: 0px;
        margin-top: 0px;
        padding-bottom: 0px;
        padding-top: 0px;
      }
    }
  }
`;

const SectionTitle = styled.div`
  display: flex;
  gap: 10px;
  font-size: 16px;
  margin-bottom: 0px;
  padding-bottom: 0px;
  &.selected {
    border-bottom: 1px solid #ccc;
  }
`;

const Section8Info = ({ applicant }) => {
  return (
    <div>
      <SectionTitle className={applicant.section8?.active ? 'selected' : ''}>
        <Icon
          name={
            applicant.section8?.active ? 'fa-duotone fa-circle-check' : 'fa-duotone fa-circle-x'
          }
          color={applicant.section8?.active ? 'green' : ''}
          size={20}
        />
        <strong>
          {applicant.section8?.active
            ? 'Applicant is on Section 8'
            : applicant.section8?.active === false
            ? 'Applicant is not on Section 8'
            : 'Applicant has not completed Section 8 questionnaire'}
        </strong>
      </SectionTitle>
      {applicant.section8?.active && (
        <>
          <Section>
            <CardDataGroup grid={true} gridSize={4}>
              <CardDataDisplay label="Voucher Name" description={applicant.section8?.voucherName} />
              <CardDataDisplay label="Bed Count" description={applicant.section8?.bedCount} />
              <CardDataDisplay
                label="Approved Amount"
                description={toMoneyString(applicant.section8?.amountApproved || '')}
              />
              <CardDataDisplay label="Kids under 6" description={applicant.section8?.kidsUnder6} />
            </CardDataGroup>
          </Section>
          <Section gridSize={5}>
            <CardDataGroup grid={true} gridSize={5}>
              <CardDataDisplay
                label="Has RTA Package"
                description={
                  <VAlignRow>
                    <Icon
                      style={{ marginRight: '10px' }}
                      name={
                        applicant.section8?.rtaPacket
                          ? 'fa-duotone fa-circle-check'
                          : 'fa-duotone fa-circle-x'
                      }
                      color={applicant.section8?.rtaPacket ? 'green' : ''}
                      size={20}
                    />
                  </VAlignRow>
                }
              />
              <CardDataDisplay
                label="Has Budget Sheet"
                description={
                  <VAlignRow>
                    <Icon
                      style={{ marginRight: '10px' }}
                      name={
                        applicant.section8?.budgetSheet
                          ? 'fa-duotone fa-circle-check'
                          : 'fa-duotone fa-circle-x'
                      }
                      color={applicant.section8?.budgetSheet ? 'green' : ''}
                      size={20}
                    />
                  </VAlignRow>
                }
              />
              <CardDataDisplay
                label="Has SSI"
                description={
                  <VAlignRow>
                    <Icon
                      style={{ marginRight: '10px' }}
                      name={
                        applicant.section8?.ssi
                          ? 'fa-duotone fa-circle-check'
                          : 'fa-duotone fa-circle-x'
                      }
                      color={applicant.section8?.ssi ? 'green' : ''}
                      size={20}
                    />
                  </VAlignRow>
                }
              />
              <CardDataDisplay
                label="Receives Child Support"
                description={
                  <VAlignRow>
                    <Icon
                      style={{ marginRight: '10px' }}
                      name={
                        applicant.section8?.childSupport
                          ? 'fa-duotone fa-circle-check'
                          : 'fa-duotone fa-circle-x'
                      }
                      color={applicant.section8?.childSupport ? 'green' : ''}
                      size={20}
                    />
                  </VAlignRow>
                }
              />
              <CardDataDisplay
                label="Has a security agreement"
                description={
                  <VAlignRow>
                    <Icon
                      style={{ marginRight: '10px' }}
                      name={
                        applicant.section8?.securityAgreement
                          ? 'fa-duotone fa-circle-check'
                          : 'fa-duotone fa-circle-x'
                      }
                      color={applicant.section8?.securityAgreement ? 'green' : ''}
                      size={20}
                    />
                  </VAlignRow>
                }
              />
            </CardDataGroup>
          </Section>
        </>
      )}
    </div>
  );
};

const OtherSupportInfo = ({ applicant }) => {
  return (
    <div>
      <SectionTitle className={applicant.section8?.otherSupport ? 'selected' : ''}>
        <Icon
          name={
            applicant.section8?.otherSupport
              ? 'fa-duotone fa-circle-check'
              : 'fa-duotone fa-circle-x'
          }
          color={applicant.section8?.otherSupport ? 'green' : ''}
          size={20}
        />
        <strong>
          {applicant.section8?.otherSupport
            ? 'Applicant has non-Section 8 housing support'
            : 'Applicant does not have non-Section 8 housing support'}
        </strong>
      </SectionTitle>
      {applicant.section8?.otherSupport && (
        <Section>
          <CardDataDisplay
            label="Housing Support Details"
            description={applicant.section8?.otherSupportDetails}
          />
        </Section>
      )}
    </div>
  );
};

export default ({ applicant, handleActions }) => {
  return (
    <StyledCard>
      <OtherSupportInfo applicant={applicant} handleActions={handleActions} />
      <Section8Info applicant={applicant} handleActions={handleActions} />
    </StyledCard>
  );
};
