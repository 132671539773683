import React from 'react';
import styled from 'styled-components';
import Modal from 'components/modal';
import Header from './header';
import FormGroup from 'components/form-group';
import { DocumentProvider, useDocumentContext } from '../state';
import ControlFactory from './control-factory';
import Button from 'components/button';
import { useUpdateBlock } from 'e-signature/page/pdf';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Body = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  background-color: #f5f5f5;
`;

const SectionWrapper = styled.div`
  margin-bottom: 20px;
  border-left: 1px dashed #444;
  background-color: #fff;
  padding: 20px;
  .title {
    font-weight: bold;
    margin-bottom: 20px;
  }
  .content-fields {
  }
`;

const Tabs = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  gap: 10px;
  button {
    &.active {
      border-bottom: 2px solid #444;
    }
  }
`;

const Section = ({ page, label }) => {
  const { updateBlock, blocks } = useUpdateBlock({ page });

  return (
    <SectionWrapper>
      <div className="title">
        <span>{label}</span>
      </div>
      <div className="content-fields">
        {blocks
          .filter((b) => page.fillableBlocksUuids.includes(b.uuid))
          .map((b, i) => (
            <FormGroup key={i} label={b.name || b.label}>
              <div className="controlWrapper">
                <ControlFactory block={b} updateBlock={updateBlock} />
              </div>
            </FormGroup>
          ))}
      </div>
    </SectionWrapper>
  );
};

const CompleteAssistant = () => {
  const [tab, setTab] = React.useState('required');
  const { ui } = useDocumentContext();

  const pages = ui.pages.map((p) => ({
    ...p,
    fillableBlocksUuids: ui.fillableBlocks
      .filter((b) => {
        const showAll =
          tab === 'all'
            ? true
            : ['initials', 'signature'].includes(b.type)
            ? true
            : b.required === true;
        return b.page.uuid === p.uuid && showAll;
      })
      .map((b) => b.uuid)
  }));

  return (
    <Wrapper>
      <Header
        onDone={() => ui.updateUi({ completeAssistant: false, pageToken: new Date().getTime() })}
      />
      <Body>
        <Tabs>
          <Button className={tab == 'all' ? 'active' : ''} onClick={() => setTab('all')}>
            All
          </Button>
          <Button className={tab == 'required' ? 'active' : ''} onClick={() => setTab('required')}>
            Required Only
          </Button>
        </Tabs>
        {pages.map((page, i) => (
          <Section key={i} label={`Page ${i + 1}`} page={page} />
        ))}
      </Body>
    </Wrapper>
  );
};

export default () => {
  return (
    <Modal width="900px" height="95%" open>
      <CompleteAssistant />
    </Modal>
  );
};
