import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Row, Title, PageContainer, PageWrapper, Description } from '../common-styles';
import Button from 'components/button';
import Input from 'components/input';
import Text from 'components/text';
import FormGroup from 'components/form-group';

const BadgeList = styled.div`
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
`;

const Badge = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  padding: 10px 12px;
  background-color: #fff;
  border: 1px solid #ddd;
  max-width: 160px;
  border-radius: 25px;
  &:hover {
    background-color: #f5f5f5;
  }
  &.selected {
    background-color: #d8f1fa;
    border-color: #00a1ff;
  }
`;

export default ({ onNext, onBack, saveApplicationPart, application }) => {
  const { t } = useTranslation();
  const [reason, setReason] = React.useState(application?.autoIncomeSkipDetails?.reason || '');
  const [skipDetails, setSkipDetails] = React.useState(application?.autoIncomeSkipDetails || {});
  const reasons = [
    { key: 'noBank', label: t('skipIncomeStep.opts.bank'), selected: reason === 'noBank' },
    { key: 'manual', label: t('skipIncomeStep.opts.docs'), selected: reason === 'manual' }
  ];

  const handleBack = () => {
    saveApplicationPart({
      manualVerification: false,
      autoIncomeSkipDetails: {
        reason,
        ...(application?.autoIncomeSkipDetails || {}),
        skipped: false
      }
    }).then(onBack);
  };
  const handleSubmit = () => {
    let payload = null;
    switch (reason) {
      case 'manual':
        payload = { manualVerification: true, autoIncomeSkipDetails: { skipped: true, reason } };
        break;
      case 'noBank':
        payload = {
          manualVerification: true,
          autoIncomeSkipDetails: {
            skipped: true,
            reason,
            bankName: skipDetails.bankName,
            comments: skipDetails.comments
          }
        };
        break;
    }

    if (!payload) return;

    saveApplicationPart(payload).then(onNext);
  };

  let disableNext = true;
  switch (reason) {
    case 'manual':
      disableNext = false;
      break;
    case 'noBank':
      disableNext = !skipDetails.bankName && !skipDetails.comments;
      break;
  }

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('skipIncomeStep.title')}</Title>
        </Row>
        <Row className="center" spacing={40}>
          <Description className="text-center">{t('skipIncomeStep.description')}</Description>
        </Row>
        <Row className="center">
          <BadgeList>
            {reasons.map((r) => (
              <Badge
                key={r.key}
                onClick={() => setReason(r.key)}
                className={r.selected ? 'selected' : ''}>
                {r.label}
              </Badge>
            ))}
          </BadgeList>
        </Row>
        {reason === 'noBank' && (
          <Row>
            <FormGroup label={t('skipIncomeStep.form.bankName')}>
              <Input
                value={skipDetails.bankName}
                onChange={(e) => {
                  const newName = e.target.value;
                  setSkipDetails((v) => ({ ...v, bankName: newName }));
                }}
              />
            </FormGroup>
            <FormGroup label={t('skipIncomeStep.form.comments')}>
              <Text
                value={skipDetails.comments}
                onChange={(e) => {
                  const newName = e.target.value;
                  setSkipDetails((v) => ({ ...v, comments: newName }));
                }}
              />
            </FormGroup>
          </Row>
        )}
        {reason === 'manual' && (
          <Row>
            <p>{t('skipIncomeStep.docsDescription')}</p>
          </Row>
        )}
        <Row className="gap-15 center flex">
          <Button variant="primary" onClick={handleBack}>
            {t('buttonsActions.SwitchToInstantVerification')}
          </Button>
          <Button
            radius="25px"
            variant="secondary"
            disabled={disableNext}
            onClick={handleSubmit}
            width={90}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
