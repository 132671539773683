import React, { useState } from 'react';
import styled from 'styled-components';
import { ADD_FILE, GET_FILE } from 'dal/applications';
import { UserContext } from 'state';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import Icon from 'components/icon';
import Button from 'components/button';
const FileSaver = require('file-saver');

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  .uploadLink {
    flex-shrink: 0;
    flex-grow: 0;
    cursor: pointer;
    font-size: 0.81em;
    padding: 0px;
    color: var(--color-link);
    background-color: transparent;
    font-weight: bold;
  }
  .selectedFile {
    cursor: pointer;
    flex-grow: 1;
    padding: 5px 0;
    > p {
      margin: 2px 0;
    }
    position: absolute;
    left: 0;
    margin-top: 40px;
  }
  p.failed,
  .uploadLink.failed {
    color: red;
  }
  .uploadLink.uploaded {
    color: #007cff;
    border-radius: 25px;
    border: solid 1px #007cff;
    background-color: #fff;
  }
  .file-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
  }
`;

const PlaceHolder = styled.label`
  display: block;
  padding: 20px;
  border-radius: 4px;
  border: 2px dashed #ddd;
  background-color: #fff;
  position: relative;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  &:hover {
    background-color: #f5f5f5;
  }
  .content {
    flex: 1;
    padding-right: 10px;
  }
  .empty {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
  }
  input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .actions {
    display: flex;
    flex-direction: column;
    gap: 10px;
    color: #5781ff;
    cursor: pointer;
    > div {
      &:hover {
        font-weight: bold;
      }
    }
  }
  &.failed {
    border-color: red;
  }
  &.uploaded {
    border: 1px solid #ddd;
    background-color: #fafafa;
  }
`;

const FileSelection = ({ selectedFile, fileStatus, fileFailed, t }) => {
  if (!selectedFile) return null;

  return (
    <div className={`selectedFile ${fileStatus}`}>
      <p className="file-name">
        {t('upload_labels.fileName')} {selectedFile.name}
      </p>
      <p className="upload-progress">
        {t('upload_labels.uploadProgress')} {selectedFile.name}
      </p>
      {!!fileFailed && (
        <p className={fileStatus}>
          {t('upload_labels.uploadFiled')} {fileFailed}
        </p>
      )}
    </div>
  );
};
const FilePicker = ({ fileStatus, t, onPreview, downloading }) => {
  let label = `${t('upload_labels.clickToUpload')}`;
  switch (fileStatus) {
    case 'failed':
      label = `${t('upload_labels.fix')}`;
      break;
    case 'uploaded':
      return (
        <div>
          <p className={`uploadLink  ${fileStatus}`}>{t('buttonsActions.change')}</p>
          <p
            onClick={(e) => {
              if (!downloading) {
                e.preventDefault();
                e.stopPropagation();
                onPreview && onPreview();
              }
            }}
            className={`uploadLink  preview`}>
            {downloading ? t('buttonsActions.loading') : t('buttonsActions.preview')}
          </p>
        </div>
      );
  }
  return <p className={`uploadLink  ${fileStatus}`}>{label}</p>;
};
export default ({
  className,
  selector,
  fileTypes,
  selectionTemplate,
  locationId,
  applicationId,
  field,
  existingFile,
  setLocalFiles,
  localFiles,
  referenceId,
  enforceFileTypes,
  legacy = true,
  label = 'Upload',
  required
}) => {
  const { userContext } = React.useContext(UserContext);
  const [selectedFile, setSelectedFile] = useState(existingFile);
  const [downloading, setDownloading] = useState(false);
  const [isSelected, setIsFilePicked] = useState(false);
  const [fileFailed, setFileFailed] = useState(false);
  const [fileUploaded, setFileUploaded] = useState(!!existingFile);
  const { t } = useTranslation();

  const changeHandler = (event) => {
    const selected = (event.target?.files || [])[0];

    //ensure we have a valid file type
    if (
      enforceFileTypes &&
      selected?.type &&
      fileTypes?.length &&
      !fileTypes?.some((ft) => ft.toLowerCase() === selected.type?.toLowerCase())
    ) {
      setFileUploaded(false);
      setFileFailed(`${t('upload_labels.invalidFileType')}`);
      toast.error(`${selected?.name || ''} ${t('upload_labels.invalidFileType')}`);
      return false;
    }

    setSelectedFile(selected);
    setIsFilePicked(!!selected);
    setLocalFiles({ ...Object.assign(localFiles, { [field]: selected }) });
  };

  const submitFile = () => {
    ADD_FILE({ userContext, locationId, applicationId, field, referenceId, file: selectedFile })
      .then((v) => {
        setFileFailed(false);
        setFileUploaded(true);
        toast.success(`${selectedFile.name} ${t('upload_labels.successfully')}`);
      })
      .catch((err) => {
        setFileUploaded(false);
        setFileFailed(
          err.data?.message
            ? err.data?.message
            : err.message || err.name || `${t('upload_labels.failed')}`
        );
        console.error({ errData: err.data, err });
        toast.error(
          `${selectedFile.name} ${t('upload_labels.failed')}: ${
            err.data?.message ? err.data?.message : err.message || err.name
          }`
        );
      });
  };

  React.useEffect(() => {
    if (selectedFile && isSelected) {
      submitFile();
    }
  }, [selectedFile, isSelected]);

  const fileStatus = fileFailed ? 'failed' : fileUploaded ? 'uploaded' : '';
  const onPreview = () => {
    setDownloading(true);
    GET_FILE({ userContext, locationId, applicationId, field, fileUuid: selectedFile?.uuid })
      .then((fileInfo) => {
        if (fileInfo.url) {
          FileSaver.saveAs(fileInfo.url, selectedFile?.name || 'FILE');
        }
        setDownloading(false);
      })
      .catch((err) => {
        setDownloading(false);
        console.error({ errData: err.data, err });
        toast.error(
          `${selectedFile?.name || 'UNKNOWN FILE'} ${t('upload_labels.failed')}: ${
            err.data?.message ? err.data?.message : err.message || err.name
          }`
        );
      });
  };

  if (!legacy) {
    return (
      <PlaceHolder className={fileStatus}>
        <input
          type="file"
          name="file"
          onChange={changeHandler}
          style={{ visibility: 'hidden' }}
          accept={fileTypes?.length ? fileTypes.join(',') : undefined}
        />
        <div className="content">
          {!fileStatus && (
            <div className="empty">
              <Icon name="fa-duotone fa-file-arrow-up" size={30} color="#00a1ff" />
              <span>{label}</span>
              {required && <span style={{ color: 'red' }}>*Required</span>}
            </div>
          )}
          {fileStatus === 'uploaded' && (
            <div>
              {label}
              <br />
              {selectedFile?.name}
            </div>
          )}
          {fileStatus === 'failed' && (
            <div>
              {label}
              <br />
              Failed to upload
            </div>
          )}
        </div>
        {fileStatus === 'uploaded' && (
          <div className="actions">
            <div>Change</div>
            <div
              variant="link"
              onClick={(e) => {
                if (!downloading) {
                  e.preventDefault();
                  e.stopPropagation();
                  onPreview && onPreview();
                }
              }}>
              {downloading ? 'Loading...' : 'Preview'}
            </div>
          </div>
        )}
      </PlaceHolder>
    );
  }

  return (
    <Wrapper className={className}>
      <input
        type="file"
        name="file"
        onChange={changeHandler}
        accept={fileTypes?.length ? fileTypes.join(',') : undefined}
      />
      {selectionTemplate ? (
        <selectionTemplate
          fileFailed={fileFailed}
          selectedFile={selectedFile}
          fileStatus={fileStatus}
          isSelected={isSelected}
        />
      ) : (
        <div className="file-box">
          <FileSelection
            fileFailed={fileFailed}
            selectedFile={selectedFile}
            fileStatus={fileStatus}
            isSelected={isSelected}
            t={t}
          />
          {selector ? (
            selector
          ) : (
            <FilePicker
              fileFailed={fileFailed}
              isSelected={isSelected}
              selectedFile={selectedFile}
              fileStatus={fileStatus}
              onPreview={onPreview}
              downloading={downloading}
              t={t}
            />
          )}
        </div>
      )}
    </Wrapper>
  );
};
