import React from 'react';
import { Row, Title, PageContainer, PageWrapper } from '../common-styles';
import Button from 'components/button';
import AddressEditionModal, { AddressEdition } from './address-edition';
import AddressPreview from './address-preview';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useTranslation, Trans } from 'react-i18next';
import toast from 'react-hot-toast';
import styled from 'styled-components';

const ErrorText = styled(Row)`
  font-size: 1em;
  margin-top: 5px;
  color: red;
`;

const CurrentBox = styled.div`
  padding: 15px 20px;
  border: solid 1px #ddd;
  background-color: #f7f7f7;
  .title-current {
    //padding: 0px 20px;
    font-weight: bold;
  }
  @media screen and (max-width: 425px) {
    margin-bottom: 20px;
  }
`;

const HeaderBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .title {
    font-weight: bold;
  }
`;
const Required = styled.div`
  flex-shrink: 0;
  font-size: 0.81em;
  color: red;
`;

const shouldRequireMultiple = (addresses, minAddressLength) => {
  const currentAddress = addresses.find((a) => a.current);
  const hasPastAddress = addresses.some((a) => !a.current);

  if (!currentAddress || !currentAddress?.startDate || hasPastAddress) return false;

  let { startDate, endDate } = currentAddress;
  endDate = endDate ? new Date(endDate) : new Date();
  startDate = new Date(startDate);

  return endDate.getTime() - startDate.getTime() < minAddressLength * 365 * 24 * 60 * 60 * 1000;
};

export default ({ application, location, saveApplicationPart, onNext, onBack }) => {
  const [editAddress, setEditAddress] = React.useState();
  const [addresses, setAddresses] = React.useState(application.addresses || []);

  const { t } = useTranslation();
  const minAddressLength = [null, undefined].includes(location?.companyInfo?.housingHistoryYears)
    ? 10
    : location?.companyInfo?.housingHistoryYears;
  const requireMultipleAddresses = shouldRequireMultiple(addresses, minAddressLength);
  const confirmDialog = useConfirmDialog();

  const handleSave = (address) => {
    const isEdit = addresses.some((a) => a.uuid === address.uuid);
    const newAddresses = address.current
      ? addresses.map((a) => ({ ...a, current: false }))
      : addresses;
    setAddresses(
      isEdit
        ? newAddresses.map((a) => {
            if (a.uuid !== address.uuid) return a;
            return address;
          })
        : [...newAddresses, address]
    );
  };

  const handleRemove = ({ address, uuid }) => {
    confirmDialog({
      title: `Are you sure you want to remove ${address} `,
      onConfirm: () => {
        setAddresses(addresses.filter((a) => a.uuid !== uuid));
      }
    });
  };

  const handleNext = () => {
    if (addresses.length === 0) {
      return setEditAddress({ current: true });
    }
    if (requireMultipleAddresses) {
      toast.error('Please provide an additional address');
      return;
    }
    if (!addresses.some((ad) => ['US', 'CA', 'MX'].includes(ad.formattedAddress?.country))) {
      toast.error('You must provide at least one US, CA, or MX address');
      return;
    }
    saveApplicationPart({ addresses }, false).then(() => onNext());
  };

  const currentAddresses = addresses.filter((a) => a.current);
  const prevAddress = addresses.filter((a) => !a.current);

  return (
    <PageWrapper>
      <PageContainer>
        {editAddress ? (
          <>
            <AddressEdition
              address={editAddress}
              onSave={handleSave}
              open={!!editAddress}
              location={location}
              onClose={() => setEditAddress(undefined)}
              showCurrentAddressToggle={!addresses.some((a) => a.current)}
            />
          </>
        ) : (
          <>
            <Row className="center">
              <Title>{t('housingHistory.verifyHousing')} </Title>
            </Row>
            <Row className="center">{t('housingHistory.descriptionCurrent')}</Row>
            <Row>
              <Row>
                <CurrentBox>
                  <HeaderBox>
                    <div>
                      <div className="title">{t('housingHistory.current')}</div>
                      {currentAddresses.length === 0 && <Required> {t('required')}</Required>}
                    </div>
                    {currentAddresses.length === 0 && (
                      <Button variant="primary" onClick={() => setEditAddress({ current: true })}>
                        {t('ADD')}
                      </Button>
                    )}
                  </HeaderBox>
                  {currentAddresses.map((address) => {
                    return (
                      <AddressPreview
                        onEdit={() => setEditAddress(address)}
                        onRemove={() => handleRemove(address)}
                        key={address.uuid}
                        address={address}
                        t={t}
                      />
                    );
                  })}
                </CurrentBox>
              </Row>
              <Row>
                <CurrentBox>
                  <HeaderBox>
                    <div>
                      <div className="title">{t('housingHistory.previous')}</div>
                    </div>
                    <Button variant="primary" onClick={() => setEditAddress({ current: false })}>
                      {t('ADD')}
                    </Button>
                  </HeaderBox>
                  {requireMultipleAddresses && (
                    <ErrorText>
                      <Trans i18nKey="housingHistory.requireMultipleAddresses">
                        *Since you've lived at your current address for less than{' '}
                        {minAddressLength.toString()} years we require a previous address
                      </Trans>
                    </ErrorText>
                  )}
                  {prevAddress.map((address) => {
                    return (
                      <AddressPreview
                        onEdit={() => setEditAddress(address)}
                        onRemove={() => handleRemove(address)}
                        key={address.uuid}
                        address={address}
                        t={t}
                      />
                    );
                  })}
                </CurrentBox>
              </Row>
            </Row>
            <br />
            <Row className="flex gap-10 center">
              <Button radius="25px" variant="secondary" width={90} onClick={onBack}>
                {t('buttonsActions.cancel')}
              </Button>
              <Button radius="25px" variant="primary" onClick={handleNext} width={90}>
                {t('buttonsActions.next')}
              </Button>
            </Row>
          </>
        )}
      </PageContainer>
      {/* <AddressEditionModal
        address={editAddress}
        onSave={handleSave}
        open={!!editAddress}
        location={location}
        onClose={() => setEditAddress(undefined)}
        showCurrentAddressToggle={!addresses.some((a) => a.current)}
      /> */}
    </PageWrapper>
  );
};
