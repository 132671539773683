export const applicantTypes = {
  primary: 'Primary Applicant',
  resident: 'Resident',
  guarantor: 'Guarantor',
  minor: 'Minor'
};

export const incomeTypes = {
  employment: 'Full-Time/Part-Time',
  self: 'Contracting/Self-Employed',
  misc: 'Other',
  gov: 'Government Income'
};

export const APPLICANT_TYPE = {
  primary: {
    iconOnly: true,
    name: 'fa-duotone fa-crown',
    color: '#f7b500',
    title: 'Primary Applicant'
  },
  partner: { iconOnly: true },
  roommate: { iconOnly: true },
  guarantor: {
    iconOnly: true,
    name: 'fa-duotone fa-dollar-sign',
    color: '#49b22f',
    title: 'Guarantor'
  }
};

export const VERIFICATION_STATUS = {
  act: {
    iconOnly: true,
    color: '#3D5AFE',
    name: 'fa-solid fa-hexagon-exclamation'
    // iconProps: {
    //   name: 'fa-solid fa-hexagon-exclamation', //fa-gear fa-play, a-right-long, fa-arrow-right, fa-right, fa-chevron-right, fa-caret-right
    //   color: '#fff'
    // }
  },
  pass: {
    color: '#75c66e',
    iconProps: {
      name: 'fa-duotone fa-check',
      color: '#fff'
    }
  },
  pending: {
    color: '#ccc',
    iconProps: {
      name: 'fa-duotone fa-hourglass-half', // fa-hourglass-half
      color: '#fff'
    }
  },
  warn: {
    color: '#f5a623',
    iconProps: {
      name: 'fa-solid fa-exclamation',
      color: '#fff'
    }
  },
  fail: {
    color: '#db0000',
    iconProps: {
      name: 'fa-solid fa-hand',
      color: '#fff'
    }
  },
  disabled: {
    color: '#ccc',
    iconProps: {
      name: 'fa-solid fa-ban',
      color: '#fff'
    }
  }
};

export const APPLICATION_STATUS = {
  approve: {
    color: '#75c66e',
    iconProps: {
      name: 'fa-duotone fa-thumbs-up',
      color: '#fff'
    }
  },
  conditional: {
    color: '#f5a623',
    iconProps: {
      name: 'fa-duotone fa-face-thinking',
      color: '#fff'
    }
  },
  decline: {
    color: '#d64747',
    iconProps: {
      name: 'fa-duotone fa-thumbs-down',
      color: '#fff'
    }
  }
};
