import Checkbox from './checkbox';

export default ({ value = false, onChange, name }) => {
  return (
    <Checkbox
      checked={value === true}
      onChange={() => onChange && onChange({ target: { name, value: !value } })}
    />
  );
};
