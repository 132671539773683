import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useAPIData } from 'dal/useAPIData';
import { UserContext } from 'state';
import { useActions } from '../useActions';
import AppMissing from './application-missing';
import AppView from './application-view';

const Wrapper = styled.main`
  height: 100%;
  flex: 1;
  overflow-y: auto;
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 0 15px;
`;
const Body = styled.div``;

export default () => {
  const { shareId } = useParams();
  const { userContext } = React.useContext(UserContext);
  const { handleActions, refreshKey, selectedApplicant } = useActions({});

  // this loads all applications for the user
  const [shareDoc, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'application.GET_SHARE_DETAILS',
    { userContext, shareId },
    { reFetchWhen: [shareId, refreshKey], disableEmptyArgs: true }
  );

  if (failedFetch || (dataFetched && !shareDoc)) {
    return (
      <Wrapper>
        <Container>
          <AppMissing handleActions={handleActions} />
        </Container>
      </Wrapper>
    );
  }

  if (!dataFetched) {
    return (
      <Wrapper>
        <Container>Loading...</Container>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <Container>
        <Body>
          <AppView
            application={shareDoc.application}
            shareDetails={shareDoc.shareDetails}
            handleActions={handleActions}
            selectedApplicant={selectedApplicant}
          />
        </Body>
      </Container>
    </Wrapper>
  );
};
