export const renderPrettyDate = (dateOrString, withTime = true) => {
  let localVal = dateOrString;
  if (!localVal?.getTime) {
    localVal = new Date(dateOrString);
  }
  if (!localVal.getTime || isNaN(localVal.getTime())) {
    return `${dateOrString || ''}`;
  }

  return localVal.renderLocale({ time: withTime, date: true });
};

Date.prototype.renderPretty = function (withTime = true) {
  return renderPrettyDate(this, withTime);
};

Date.prototype.renderLocale = function ({ time = true, date = true, spacer = ' ' } = {}) {
  return [date && this.toLocaleDateString(), time && this.toLocaleTimeString()]
    .filter((z) => z)
    .join(spacer);
};

const _MS_PER_DAY = 1000 * 60 * 60 * 24;
Date.prototype.daysSince = function () {
  let a = this;
  let b = new Date();
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((utc2 - utc1) / _MS_PER_DAY);
};

Date.prototype.renderStringRange = function (
  secondDate,
  { spacer = ' - ', missingDefault = 'Current' } = {}
) {
  let a = this;
  let b = !secondDate ? null : secondDate?.getTime ? secondDate : new Date(secondDate);

  return [a.toLocaleDateString(), !b && missingDefault ? missingDefault : b?.toLocaleDateString()]
    .filter((z) => z)
    .join(spacer);
};

// API methods will return string instead of date, these just help to convert it
String.prototype.toLocaleDateString = function (...params) {
  const dateOrString = this;
  let localVal = dateOrString;
  if (!localVal?.getTime) {
    localVal = new Date(dateOrString);
  }
  if (!localVal.getTime || isNaN(localVal.getTime())) {
    return `${dateOrString || ''}`;
  }

  return localVal?.toLocaleDateString(...params);
};
String.prototype.toLocaleTimeString = function (...params) {
  const dateOrString = this;
  let localVal = dateOrString;
  if (!localVal?.getTime) {
    localVal = new Date(dateOrString);
  }
  if (!localVal.getTime || isNaN(localVal.getTime())) {
    return `${dateOrString || ''}`;
  }

  return localVal?.toLocaleTimeString(...params);
};
String.prototype.renderLocale = function (...params) {
  const dateOrString = this;
  let localVal = dateOrString;
  if (!localVal?.getTime) {
    localVal = new Date(dateOrString);
  }
  if (!localVal.getTime || isNaN(localVal.getTime())) {
    return `${dateOrString || ''}`;
  }

  return localVal.renderLocale(...params);
};
