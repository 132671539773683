import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CompanyContext, useNavigateWithContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import { useContextId } from 'hooks/useSessionVariables';
import Icon from 'components/icon';
import Layout from '../application-form-v2/layout';
import LocationList from '../locations-list';
import { LocationPreview } from '../location';
import CreateModal from './create-modal';
import ApplicantType from './applicant-type';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
  .locationAction {
    @media (max-width: 900px) {
      bottom: 60px !important;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
`;

const Loader = styled.div`
  margin: auto;
`;

const AppCreate = ({ location: initialLocation }) => {
  const [step, setStep] = React.useState('type');
  const [location, setLocation] = React.useState(initialLocation);
  const { companyContext } = React.useContext(CompanyContext);
  let navigate = useNavigateWithContext();
  const { t } = useTranslation();

  const steps = [
    { title: `${t('stepsCreateApp.chooseApp')}`, path: 'type' },
    { title: `${t('stepsCreateApp.chooseLocation')}`, path: 'location-list' },
    { title: `${t('stepsCreateApp.confirmLocation')}`, path: 'location' },
    { title: `${t('stepsCreateApp.startAplication')}`, path: 'create' },
    { title: `${t('stepsCreateApp.verifyIdentity')}`, path: 'start' }
  ];

  const stepInfo = React.useMemo(() => {
    let currentIndex = steps.findIndex((i) => i.path === step);
    return {
      currentIndex,
      currentStep: steps[currentIndex],
      nextStep: steps[currentIndex + 1]
    };
  }, [step]);

  const onBack = () => {
    const prevStep = steps[stepInfo.currentIndex - 1];
    if (!prevStep) {
      navigate('app/applications');
      return;
    }
    setStep(prevStep.path);
  };
  const onNext = () => {
    if (stepInfo.currentIndex == 0 && location) {
      return onCreateApp(); // skip the select location step if we already have one set
    }
    const nextStep = steps[stepInfo.currentIndex + 1];
    if (!nextStep) {
      navigate('app/applications');
      return;
    }
    setStep(nextStep.path);
  };

  const onCreateApp = () => {
    setStep('create');
  };
  return (
    <Layout
      hideProperty={!location}
      location={location}
      hidePropertyChanger={false}
      onLocationChange={() => setStep('location-list')}
      showTextSizeChanger={true}>
      <Wrapper>
        <Content>
          {step === 'type' && <ApplicantType onBack={onBack} onNext={onNext} />}
          {step === 'location-list' && (
            <LocationList
              onBack={onBack}
              onSelect={(location) => {
                setLocation(location);
                onCreateApp();
              }}
              onPreview={(location) => {
                if (companyContext?.disableLandingPage) {
                  onCreateApp();
                  return;
                }
                setLocation(location);
                onNext();
              }}
            />
          )}
          {step === 'location' && (
            <LocationPreview
              locationId={location?._id}
              onBack={onBack}
              onSelect={() => onCreateApp()}
            />
          )}
          {step === 'create' && (
            <CreateModal
              onClose={() => {
                setStep('location-list');
                setLocation(undefined);
              }}
              location={location}
            />
          )}
        </Content>
      </Wrapper>
    </Layout>
  );
};

export default () => {
  const contextId = useContextId();

  const [location, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_CONTEXT',
    !contextId ? undefined : { contextId: contextId },
    { reFetchWhen: [contextId], disableEmptyArgs: true }
  );

  if (contextId && !dataFetched && !failedFetch) {
    return (
      <Loader>
        <Icon name="fa-duotone fa-spinner-third fa-spin" size={100} />
      </Loader>
    );
  }

  return <AppCreate location={location?.isLocked ? undefined : location} />;
};
