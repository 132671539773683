import React from 'react';
import toast from 'react-hot-toast';

import { LOGIN_SSO } from '../user';
import { UserContext } from 'state';

/* global google */
function decodeJwtResponseFromGoogleAPI(token) {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}

const login = ({ response, ssoLogin, ssoRegister }) => {
  LOGIN_SSO({
    sso: {
      type: 'google',
      idToken: response.credential
    }
  })
    .then(async (loginResponse) => {
      if (loginResponse.status) {
        ssoLogin(loginResponse);
        return;
      }

      let profile = decodeJwtResponseFromGoogleAPI(response.credential);
      ssoRegister({
        email: profile.email,
        firstName: profile.given_name,
        lastName: profile.family_name,
        sso: {
          type: 'google',
          imageUrl: profile.picture,
          idToken: response.credential
        }
      });
    })
    .catch((err) => {
      console.error({ errData: err.data, err });
      toast.error(err.data?.message ? err.data?.message : err.message || err.name);
    });
};

const initgAuth = ({ oneTap, handleCredentialResponse }) => {
  // eslint-disable-next-line no-undef
  google.accounts.id.initialize({
    client_id: '982328563653-b7oejd4smgk0ubrg9m8kmg7cdamv1590.apps.googleusercontent.com',
    callback: handleCredentialResponse
  });

  if (oneTap) {
    // eslint-disable-next-line no-undef
    google.accounts.id.prompt(); // users can disable the one-click login, so this method is unreliable
  }
};

const initializeGoogle = ({ oneTap = false, handleCredentialResponse }) =>
  // eslint-disable-next-line no-unused-vars
  new Promise((resolve, _reject) => {
    if (typeof google !== 'undefined') {
      resolve(true);
      return;
    } else {
      // eslint-disable-next-line func-names
      window.googleAsyncInit = async function () {
        const auth = await initgAuth({ oneTap, handleCredentialResponse });
        resolve(true);
        return;
      };

      // eslint-disable-next-line func-names
      (function (d, s, id) {
        let js;
        const gjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }

        // eslint-disable-next-line prefer-const
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://accounts.google.com/gsi/client';
        js.onload = window.googleAsyncInit;
        gjs.parentNode.insertBefore(js, gjs);
      })(document, 'script', 'google_ident');
    }
  });

export const useGoogle = ({ oneTap = false, onLogin, onRegister } = {}) => {
  const [isReady, setReady] = React.useState(false);

  const handleCredentialResponse = (response) => {
    login({ response, ssoLogin: onLogin, ssoRegister: onRegister });
  };

  const initGoogle = React.useCallback(async ({ oneTap, handleCredentialResponse }) => {
    const googleLoaded = await initializeGoogle({ oneTap, handleCredentialResponse });
    if (googleLoaded) setReady(true);
  });

  React.useEffect(() => {
    initGoogle({ oneTap, handleCredentialResponse });
  }, [initGoogle]);

  return [
    isReady,
    ({ buttonId, text = 'continue_with', width, parentElementId }) => {
      // eslint-disable-next-line no-undef
      if (typeof google === 'undefined') return false;

      let pWidth = parentElementId ? document.getElementById(parentElementId)?.offsetWidth : 0;
      // eslint-disable-next-line no-undef
      google.accounts.id.renderButton(document.getElementById(buttonId), {
        theme: 'outline',
        size: 'large',
        shape: 'pill',
        width: (pWidth ? pWidth : width) || 100,
        text: text || 'continue_with' // 'signup_with' continue_with  signin
      });
      return true;
    },
    () => {
      // eslint-disable-next-line no-undef
      google.accounts.id.prompt(); // users can disable the one-click login, so this method is unreliable
    }
  ];
};
