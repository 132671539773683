import React from 'react';
import styled, { css } from 'styled-components';
import { Card, CardDataGroup, CardDataDisplay } from './common';
import { primary } from 'styles/colors';
import { darken } from 'polished';
import { incomeTypes, toMoneyString } from 'pages/approver/share/detail/application-view/helpers';

const formatDate = (date) => (date || new Date()).toLocaleDateString();

const ActionDiv = styled.div`
  display: flex;
  gap: 10px;
  > span {
    ${({ onClick, theme }) =>
      onClick
        ? css`
            color: ${theme?.colors?.primary || primary};
            cursor: pointer;
            &:hover {
              color: ${({ theme }) => darken(0.05, theme?.colors?.primary || primary)};
            }
          `
        : ''}
  }
`;

export const Work = ({ work, applicationConfig }) => {
  return (
    <Card title={work.companyName}>
      <CardDataGroup>
        <CardDataDisplay label="Type" description={incomeTypes[work.type]} />
      </CardDataGroup>
      <CardDataGroup>
        <CardDataDisplay label="Company Name" description={work.companyName} />
      </CardDataGroup>
      <CardDataGroup>
        <CardDataDisplay label="Position" description={work.position} />
      </CardDataGroup>
      {work.startDate && (
        <CardDataGroup>
          <CardDataDisplay
            label="Worked"
            description={`${formatDate(work.startDate)} - ${
              work.current ? 'Current' : formatDate(work.endDate)
            }`}
          />
        </CardDataGroup>
      )}
      <CardDataGroup>
        <CardDataDisplay label="Gross Income (pre-tax)" description={toMoneyString(work.income)} />
      </CardDataGroup>
      {!applicationConfig?.disabledSections?.contactInfo && work.contactInfo?.consentToContact && (
        <>
          <CardDataGroup>
            <CardDataDisplay
              label="Consent To Contact"
              description={work.contactInfo.consentToContact ? 'Yes' : 'No'}
            />
          </CardDataGroup>
          <CardDataGroup>
            <CardDataDisplay
              label="Supervisor"
              description={
                <>
                  <div>
                    {work.contactInfo.name
                      ? work.contactInfo.name
                      : `${work.contactInfo.firstName || ''} ${
                          work.contactInfo.lastName || ''
                        }`.trim()}
                  </div>
                  {!applicationConfig?.disabledSections?.contactInfo && (
                    <>
                      {!!work.contactInfo?.phone?.number && (
                        <ActionDiv>
                          Phone: <span>{work.contactInfo.phone?.number}</span>
                        </ActionDiv>
                      )}
                      {!!work.contactInfo?.email && (
                        <ActionDiv>
                          Email: <span>{work.contactInfo.email}</span>
                        </ActionDiv>
                      )}
                    </>
                  )}
                </>
              }
            />
          </CardDataGroup>
        </>
      )}
      <CardDataGroup>
        <CardDataDisplay label="Comments" description={work.comments} />
      </CardDataGroup>
    </Card>
  );
};
