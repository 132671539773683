import React from 'react';
import { IconShape } from 'components/icon';
import {
  baseScreeningInfo,
  VERIFICATION_STATUS
} from 'pages/approver/share/detail/application-view/helpers';

const type = 'identity';
export default ({ rowData, handleActions }) => {
  let { description, icon } = baseScreeningInfo({ type, rowData });
  let onClick = () => handleActions({ id: `applicant-${type}`, data: rowData });

  //no ID yet
  if (!rowData?.identitySource || rowData.identitySource === 'none') {
    description = 'Identity not yet completed';
    icon = VERIFICATION_STATUS.pending;
    onClick = undefined;
  }

  return <IconShape onClick={onClick} title={description} {...icon} />;
};
