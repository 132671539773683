import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { useNavigateWithContext } from 'state';
import { setMultipleKeys } from 'hooks/useSessionStorage';
import useAudience from 'hooks/useAudience';
import { useAPIData } from 'dal/useAPIData';
import Button from 'components/button';
import Icon from 'components/icon';
import ImagePreviewer from 'components/image-previewer';
import Modal from 'components/modal';
import PendingLocation from 'components/pending-location';
import ToggleButton from 'components/toggle-button';
import Avatar from 'components/avatar';

const Wrapper = styled.div`
  background: #f0f3f6;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  @media (max-width: 1250px) {
    padding: 0 10px;
  }
  @media (max-width: 800px) {
    padding: 0;
  }
  hr {
    background-color: #eee;
    height: 1px;
    border: 0;
    margin: 0;
  }
`;

const Content = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  background: #fff;
  border: 1px solid #ddd;
  max-width: 1400px;
  width: 100%;
  @media (max-width: 740px) {
    padding-bottom: 80px;
    margin-top: 0px;
  }
`;

const GalleryWrapper = styled.div`
  position: relative;
  background-color: #fafafa;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40vh;
  overflow: hidden;
  @media (min-width: 800px) {
    height: 50vh;
  }
  @media (max-width: 800px) {
    border-radius: 0px;
  }
  img {
    width: 100%;
    height: 100%;
    flex: 1;
    object-fit: cover;
    vertical-align: bottom;
  }
`;

const Title = styled.h1`
  font-size: 2.25em;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: -0.9px;
  color: #0c2b35;
  margin: 0;
`;

const Row = styled.div`
  margin: 15px 0px;
  line-height: 1.4;
  p {
    margin-top: 30px;
    text-align: justify;
  }
  &.mobile {
    display: none;
  }
  @media (max-width: 800px) {
    padding: 0 5px;
    &.mobile {
      display: block;
    }
    &.desktop {
      display: none;
    }
  }
`;

const Body = styled.div`
  padding: 5px 30px;
  .box-content {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    @media (max-width: 740px) {
      display: block;
    }
  }
  @media (max-width: 740px) {
    padding: 5px 7px;
  }
`;

const Details = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 25px;
  @media (max-width: 740px) {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
  }
`;
const DetailsIcon = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #0c2b35;
  &.clickable {
    cursor: pointer;
  }
  i {
    font-size: 2.19em;
    margin-bottom: 12px;
    @media (max-width: 740px) {
      font-size: 1.56em;
    }
  }
  img {
    height: 35px;
    margin-bottom: 12px;
    @media (max-width: 740px) {
      height: 25px;
    }
  }
  .count {
    font-weight: bold;
  }
  .type {
    margin: 0 auto;
    padding-left: 8px;
    color: #0c2b35;
  }
  @media (max-width: 740px) {
    width: auto;
  }
`;

const ContentAction = styled.div`
  padding: 10px 0px;
`;

const Action = styled.div`
  position: sticky;
  top: 10px;
  width: 350px;
  padding: 10px 0px;
  flex-shrink: 0;
  align-self: self-start;
  .price {
    font-size: 1.63em;
    font-weight: bold;
    color: #0c2b35;
    font-weight: bold;
    display: flex;
    justify-content: center;
  }
  .req-income {
    color: #777;
  }
  button {
    height: 35px;
    width: 100%;
    margin-top: 10px;
  }
  @media (max-width: 900px) {
    box-shadow: rgb(0 0 0 / 12%) 0px 6px 16px;
    z-index: 20;
    display: flex;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    top: initial;
    width: 100%;
    border-radius: 0px;
    border: none;
    font-size: 0.88em;
    background: white;
    display: block;
    padding: 20px 20px;
    .price {
      font-size: 1.25em;
    }
    border-top: 1px solid #eee;
    > div:first-child {
      flex: 1;
    }
    button {
      width: 100%;
    }
  }
`;

const ButtonAction = styled(Button)`
  border-radius: 5px !important;
  background-color: #3790ff;
`;

const QualificationBox = styled.div`
  padding: 15px 15px;
  margin-top: 5px;
  background-color: #f0f3f6;
  border-radius: 6px;
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    margin-bottom: 15px;
    i {
      font-size: 1.13em;
    }
  }
  .row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    margin-bottom: 8px;
    .item {
      font-weight: bold;
      flex: 0.75;
    }
    .info {
      flex: 1;
    }
  }
`;

const AgentBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
  gap: 20px;
  .title {
    font-weight: bold;
    font-size: 1.13em;
  }
  .social {
    display: flex;
    margin-top: 10px;
    align-items: center;
    color: #3790ff;
    gap: 20px;
    i {
      margin-right: 4px;
    }
    a {
      text-decoration: none;
      color: #3790ff;
    }
  }
  @media (max-width: 740px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const AvatarAgent = styled(Avatar)`
  width: 50px;
  height: 50px;
`;

const BoxDetails = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  margin-right: 80px;
  > .flexGrow {
    flex-grow: 1;
  }
  @media (max-width: 740px) {
    margin-right: 0px !important;
  }
`;

const ShowPictures = styled(Button)`
  position: absolute;
  bottom: 15px;
  right: 15px;
  padding: 5px 12px;
  background-color: #fff;
  border-radius: 6px;
  font-size: 0.82em;
  i {
    margin-right: 10px;
  }
`;

const Close = styled(Button)`
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 20;
  border-radius: 10px;
`;

const Gallery = ({ images = [], t }) => {
  const [showPreviewer, setShowPreviewer] = React.useState();
  const previewImage = images?.[0];
  return (
    <>
      <GalleryWrapper onClick={() => setShowPreviewer(true)}>
        <img src={previewImage} alt="" />
        <ShowPictures>
          <Icon name="fa-light fa-grid-2" />
          {t('buttonsActions.allPictures')}
        </ShowPictures>
      </GalleryWrapper>
      <ImagePreviewer open={showPreviewer} urls={images} onClose={() => setShowPreviewer(false)} />
    </>
  );
};

const WrapperQualification = ({ data, t }) => {
  let reqIncome = null;
  let requiredIncomeMessage = null;
  if (!data?.companyInfo?.disableRequiredIncomeNotice) {
    reqIncome = data?.applicationConfig?.incomeToRent
      ? ((data?.applicationConfig?.marketRentValue || 0) / 100) *
        data?.applicationConfig?.incomeToRent
      : null;
    requiredIncomeMessage = data?.applicationConfig?.requiredIncomeMessage;
  }

  return (
    <ContentAction>
      <QualificationBox>
        <div className="header">
          <i className={'fa-duotone fa-clipboard-list-check'} />
          <div> {t('location.qualification.title')}</div>
        </div>
        <div className="details">
          {data?.applicationConfig?.petRestrictions && (
            <div className="row">
              <div className="item">{t('location.qualification.petPolicy')}</div>
              <div className="info">{data?.applicationConfig?.petRestrictions}</div>
            </div>
          )}

          {/* 
          <div className="row">
            <div className="item">{t('location.qualification.smokingPolicy')}</div>
            <div className="info">No Smoking</div>
          </div>*/}
          {/* <div className="row">
            <div className="item">{t('location.qualification.minCredit')}</div>
            <div className="info">700+</div>
          </div> */}

          {!!reqIncome || !!requiredIncomeMessage ? (
            <div className="row">
              <div className="item">{t('location.qualification.houseHold')}</div>
              <div className="info">
                {reqIncome ? reqIncome.renderPrettyCurrency(false) : requiredIncomeMessage}
              </div>
            </div>
          ) : (
            <></>
          )}
          {!!data?.applicationConfig?.leaseTerms && (
            <div className="row">
              <div className="item">{t('location.qualification.minLease')}</div>
              <div className="info">{data?.applicationConfig?.leaseTerms}</div>
            </div>
          )}
        </div>
      </QualificationBox>

      {data?.applicationConfig?.agent && (
        <AgentBox>
          {data?.applicationConfig?.agent?.photo && (
            <AvatarAgent imageUrl={data?.applicationConfig?.agent?.photo} />
          )}
          <div>
            <div className="title">{data?.applicationConfig?.agent?.name}</div>
            <div className="social">
              {(data?.applicationConfig?.agent?.email || data?.applicationConfig?.contactEmail) && (
                <div>
                  <i className={'fa-duotone fa-envelope'} />
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`mailto:${
                      data?.applicationConfig?.agent?.email || data?.applicationConfig?.contactEmail
                    }`}>
                    Email
                  </a>
                </div>
              )}
              {data?.applicationConfig?.agent?.phone && (
                <div>
                  <i className={'fa-duotone fa-phone-arrow-up-right'} />{' '}
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`callto:${data?.applicationConfig?.agent?.phone}`}>
                    Call
                  </a>
                </div>
              )}
            </div>
          </div>
        </AgentBox>
      )}
    </ContentAction>
  );
};
export const LocationPreview = ({ locationId, onSelect, hideActions, selectContent, onClose }) => {
  const [audience] = useAudience();
  const navigate = useNavigateWithContext();
  const isMobile = useMediaQuery({ maxWidth: 740 });
  const [modalOpen, setModalOpen] = React.useState(false);
  const [showPlayModal, setShowPlayModal] = React.useState(false);
  const [data, inProgress, dataFetched, failedFetch] = useAPIData(
    'context.LOAD_CONTEXT',
    !locationId ? undefined : { contextId: locationId, audience: audience?.uuid },
    { reFetchWhen: [locationId], disableEmptyArgs: true }
  );

  const { t } = useTranslation();
  const title = data?.name || data?.applicationConfig?.marketingTitle || '';
  const price = data?.applicationConfig?.marketRentValue
    ? (data?.applicationConfig?.marketRentValue || 0) / 100
    : undefined;

  const setContextAndNavigateHome = () => {
    setMultipleKeys({ contextId: data?._id }); // set the location as the 'default' context
    navigate('home');
  };

  React.useEffect(() => {
    if (data?.companyInfo?.disableLandingPage) {
      if (onSelect) {
        onSelect(data);
      } else {
        setContextAndNavigateHome();
      }
    }
  }, [data?.companyInfo?.disableLandingPage]);

  if (failedFetch) {
    return (
      <Wrapper>
        <Content>
          <Row>
            <Title>{t('location.longer-active')}</Title>
          </Row>
          <Body>
            <div className="flexGrow">
              <p>{t('location.longer-active')}</p>
              <p>{t('location.still-login')}</p>
              {!hideActions && (
                <>
                  {onSelect ? (
                    <Button onClick={() => onSelect(data)} variant="primary">
                      {selectContent ? selectContent : t('buttonsActions.apply')}
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={() => setContextAndNavigateHome()}>
                      {t('buttonsActions.apply')}
                    </Button>
                  )}
                </>
              )}
            </div>
          </Body>
        </Content>
      </Wrapper>
    );
  }

  if (!dataFetched && !failedFetch && locationId) {
    return <div>Loading....</div>;
  }

  const images = []
    .concat(data?.applicationConfig?.coverPhoto)
    .concat(data?.applicationConfig?.marketingImages)
    .filter((z) => z);
  const marketingDescription = data?.applicationConfig?.marketingBlurb;

  return (
    <Wrapper>
      <VideoModal
        videoURL={data?.videoURL}
        isVisible={showPlayModal}
        setIsVisible={setShowPlayModal}
      />
      {modalOpen && (
        <PendingLocation open={modalOpen} onClose={() => setModalOpen(false)} location={location} />
      )}
      <Content>
        <Gallery images={images} t={t} />
        <Body>
          <Row className="desktop">
            <Title>{title}</Title>
          </Row>
          <div className="box-content">
            <BoxDetails>
              <div className="flexGrow">
                <Row className="mobile">
                  <Title>{title}</Title>
                </Row>
                <Row>
                  <Details>
                    {!!data?.bedCount && (
                      <DetailsIcon>
                        <i className="fa-duotone fa-bed" />
                        <div className="count">{data?.bedCount}</div>
                        <div className="type">
                          {t('location.bed')}
                          {data?.bedCount !== 1 ? 's' : ''}&nbsp;&nbsp;&nbsp;
                        </div>
                      </DetailsIcon>
                    )}
                    {!!data?.bathCount && (
                      <DetailsIcon>
                        <i className="fa-duotone fa-bath" />
                        <div className="count">{data?.bathCount} </div>
                        <div className="type">
                          {t('location.bath')}
                          {data?.bathCount !== 1 ? 's' : ''}&nbsp;&nbsp;&nbsp;
                        </div>
                      </DetailsIcon>
                    )}
                    {!!data?.squareFeet && (
                      <DetailsIcon>
                        <img src="/assets/layout.svg" alt="" />
                        <div className="count">{data?.squareFeet}</div>
                        <div className="type">{t('location.sf')}</div>
                      </DetailsIcon>
                    )}
                    {data?.applicationConfig?.availableDate && (
                      <DetailsIcon>
                        <i className="fa-duotone fa-calendar-check" />
                        <div className="type">{data?.applicationConfig?.availableDate}</div>
                      </DetailsIcon>
                    )}
                    {data?.videoURL && (
                      <DetailsIcon className="clickable" onClick={() => setShowPlayModal(true)}>
                        <i className="fa-duotone fa-circle-video" />
                        <div>{t('play')}</div>
                      </DetailsIcon>
                    )}
                  </Details>
                </Row>
                <Row>
                  {data?.applicationConfig?.marketingBlurb && (
                    <Row
                      dangerouslySetInnerHTML={{
                        __html: marketingDescription
                      }}
                    />
                  )}
                </Row>
                <hr />

                {data?.address?.formattedAddress && (
                  <Row>
                    <iframe
                      width="100%"
                      height="450"
                      style={{ border: '0px' }}
                      loading="lazy"
                      allowFullScreen
                      referrerPolicy="no-referrer-when-downgrade"
                      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDwNJevslox8yiM06faMhmlBhC9_jLpjq4&q=${data?.address?.formattedAddress}`}
                    />
                  </Row>
                )}
                {data?.applicationConfig?.applicationRequirements && (
                  <Row>
                    <ToggleButton
                      text={data?.applicationConfig?.applicationRequirements}
                      initialState={true}
                    />
                  </Row>
                )}
              </div>
            </BoxDetails>

            <Action className="locationAction">
              <div>
                {data?.applicationConfig?.marketRentValue ? (
                  <>
                    {!!price && (
                      <div className="price">
                        {t('location.rent')} $
                        {data?.applicationConfig?.marketRentValue
                          ? (data?.applicationConfig?.marketRentValue || 0) / 100
                          : undefined}
                        {t('location.mo')}
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {!!data?.applicationConfig?.marketRentMessage && (
                      <div className="price">
                        {t('location.rent')} {data?.applicationConfig?.marketRentMessage}
                      </div>
                    )}
                  </>
                )}
              </div>

              {!hideActions && (
                <>
                  {onSelect ? (
                    <ButtonAction
                      onClick={() => (data?.isLocked ? setModalOpen(true) : onSelect(data))}
                      variant="primary">
                      {data?.isLocked ? (
                        <>
                          <i className={'fa-solid fa-diamond-exclamation'} />{' '}
                          {t('location.pending')}
                        </>
                      ) : (
                        <>
                          {selectContent
                            ? selectContent
                            : audience?.uuid === 'showing'
                            ? 'Request Showing'
                            : t('locationList.locationItem.apply')}
                        </>
                      )}
                    </ButtonAction>
                  ) : (
                    <ButtonAction variant="primary" onClick={() => setContextAndNavigateHome()}>
                      {audience?.uuid === 'showing'
                        ? 'Request Showing'
                        : t('locationList.locationItem.apply')}
                    </ButtonAction>
                  )}
                </>
              )}
              {!isMobile && <WrapperQualification data={data} t={t} />}
            </Action>
            {isMobile && <WrapperQualification data={data} t={t} />}
          </div>
        </Body>
      </Content>
      {onClose && (
        <Close
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onClose();
          }}>
          <Icon name="fa-light fa-xmark" />
        </Close>
      )}
    </Wrapper>
  );
};

const Location = ({ locationId: id, hideActions, onClose }) => {
  const { locationId } = useParams();
  return (
    <LocationPreview locationId={id || locationId} hideActions={hideActions} onClose={onClose} />
  );
};

const CloseVideo = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  > i {
    line-height: 20px;
  }
`;

const VideoModal = ({ videoURL, isVisible, setIsVisible }) => {
  if (!isVisible || !videoURL) return <></>;

  return (
    <Modal
      open
      height="95%"
      width={'90%'}
      maxWidth={'1200px'}
      closeOnOutsideClick={true}
      closeElement={
        <CloseVideo onClick={() => setIsVisible(false)}>
          <i className="fa-solid fa-xmark" />
        </CloseVideo>
      }
      onClose={() => setIsVisible(false)}>
      <iframe
        width="100%"
        height="100%"
        src={videoURL}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowFullScreen
      />
    </Modal>
  );
};

export const ModalLocation = ({ open, onClose, locationId }) => (
  <Modal width="90%" height="95%" open={open}>
    <Location locationId={locationId} onClose={onClose} hideActions />
  </Modal>
);

export default Location;
