import React from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';

import { UserContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import { AUDIENCES } from 'helpers/enums';
import Button from 'components/button';
import SignaturePad from 'components/signature-pad';
import FormGroup from 'components/form-group';
import Input from 'components/input';
import AddressPin from 'components/addressPin';
import ConsentTerms from './consent-terms';
import Terms from './terms';
import { Title, ActionsRow, Loader } from '../common';

const Wrapper = styled.div`
  display: flex;
  flex-flow: column;
  gap: 20px;
  min-height: 350px;

  ${({ isMobile }) =>
    !isMobile
      ? css`
          padding: 10px 20px 20px;
        `
      : ''}
  .signature {
    max-width: 100% !important;
    height: 100px !important;
  }
`;

const PageDescription = styled.div`
  ul {
    margin: 10px 20px;
    padding: 0;
  }
  .table {
    li {
      list-style-type: none;
      border: 1px solid #777;
      margin-top: -1px;
      display: flex;
      padding: 10px;
      div {
        width: 50%;
      }
    }
  }
  .tableCols {
    li {
      list-style-type: none;
      border: 1px solid #777;
      margin-top: -1px;
      display: flex;
      div {
        padding: 10px;
        width: 50%;
        border-right: 1px solid;
      }
      div:last-child {
        border-right: 0;
      }
      h4 {
        margin: 0;
      }
    }
  }
`;
const ApplicantInfoRow = styled.div`
  display: flex;
  gap: 10px;
  > div {
    width: 50%;
    flex-grow: 1;
    justify-content: flex-start;
  }
`;

const StyledButton = styled(Button)`
  padding: 10px 0px !important;
  text-transform: uppercase;
  width: 100%;
  border-radius: 25px !important;
`;

export default ({ onDone, onBack, action, location, handleActions, onClose }) => {
  const { t } = useTranslation();
  const { userContext } = React.useContext(UserContext);
  const [saving, setSaving] = React.useState(false);

  React.useEffect(() => {
    if (action?.submitted) onDone();
  });

  const [applicationTerms, appTermsInProgress, appTermsFetched] = useAPIData(
    'application.APPLICATION_TERMS',
    !action?.locationId
      ? undefined
      : {
          userContext,
          applicationId: action?._id,
          contextId: action?.locationId,
          bypass: true,
          audience: AUDIENCES.showing.uuid
        },
    {
      disableEmptyArgs: true,
      reFetchWhen: [action?._id, action?.locationId]
    }
  );

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: { name: '', signature: '' },
    validate: (vals) => {
      const errors = {};
      if (!vals.name) errors.name = <Trans>Please provide your legal name</Trans>;
      if (!vals.signature) errors.signature = <Trans>Please sign your request</Trans>;

      return errors;
    },
    onSubmit: (vals) => {
      if (saving) return;
      setSaving(true);
      handleActions({ id: 'submit', data: { signature: vals.signature, name: vals.name } }).then(
        (result) => {
          setSaving(false);
          if (result) {
            onClose();
          }
        }
      );
    }
  });

  const applicantInfo = React.useMemo(() => {
    return []
      .concat({
        firstName: action.applicant.firstName,
        lastName: action.applicant.lastName,
        type: 'self'
      })
      .concat(action.otherContacts)
      .filter((z) => z && z?.type !== 'emergencyContact');
  });

  const locationName = location?.name || location?.applicationConfig?.marketingTitle || '';

  if (!appTermsFetched) {
    return (
      <Wrapper>
        <Title>
          <Trans i18nKey="showing.detail.consent.title">Submit Tour Request</Trans>
        </Title>
        <div>
          <Loader />
        </div>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>
        <Trans i18nKey="showing.detail.consent.title">Submit Tour Request</Trans>
      </Title>
      <div>
        <ApplicantInfoRow>
          <div>
            <strong>
              <Trans i18nKey="showing.detail.consent.locationLabel">Requested Location</Trans>
            </strong>
          </div>
          <div>
            <Trans i18nKey="consent.applicant">
              <strong>Applicant{applicantInfo.length > 1 ? 's' : ''}</strong>
            </Trans>
          </div>
        </ApplicantInfoRow>
      </div>
      <div>
        <ApplicantInfoRow>
          <div>
            {locationName && <div>{locationName}</div>}
            {location?.address && <AddressPin address={location?.address} />}
          </div>
          <div>
            {applicantInfo?.map((z, ix) => (
              <div key={ix}>
                {z.firstName || ''} {z.lastName || ''}
                {z.type === 'self' ? `${t('you')}` : ''}
              </div>
            ))}
          </div>
        </ApplicantInfoRow>
      </div>
      <div>
        {/* <Terms
          title={<Trans i18nKey="showing.detail.consent.aptlyTerms">Tour Request Terms</Trans>}>
          <PageDescription>
            <ConsentTerms />
          </PageDescription>
        </Terms> */}
        {applicationTerms?.content && (
          <Terms
            title={<Trans i18nKey="showing.detail.consent.orgTerms">Touring Requirements</Trans>}>
            <div dangerouslySetInnerHTML={{ __html: applicationTerms?.content }} />
          </Terms>
        )}
      </div>
      <div>
        <FormGroup label={t('consent.lableName')} errorText={errors.name}>
          <Input name="name" value={values.name} onChange={handleChange} />
        </FormGroup>
        <FormGroup label={t('consent.signature')} errorText={errors.signature}>
          <SignaturePad
            className="signature"
            name="signature"
            value={values.signature}
            onChange={handleChange}
          />
        </FormGroup>
      </div>
      <ActionsRow>
        <StyledButton variant="secondary" onClick={onBack}>
          {t('buttonsActions.backButton')}
        </StyledButton>
        <StyledButton
          disabled={saving || !values.signature || !values.name}
          variant="primary"
          onClick={handleSubmit}>
          {t('buttonsActions.next')}
        </StyledButton>
      </ActionsRow>
    </Wrapper>
  );
};
