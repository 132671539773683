import React from 'react';
import styled from 'styled-components';
import ToolTip from 'components/tooltip';
import Icon, { IconShape } from 'components/icon';
import Button from 'components/button';
import ProgressBar from 'components/progress-bar';
import Input from 'components/input';
import { TableSection, BoxSection } from 'components/section';
import { NoteLists, CustomQuestionNotes } from './notes';
import {
  SCORING_CRITERIA,
  SCORE_WEIGHT_LABELS
} from 'pages/approver/share/detail/application-view/helpers';

const Wrapper = styled.div`
  .criteriaColumn {
    max-width: 300px;
    width: 300px;
  }
  .pointColumn {
    max-width: 75px;
    width: 75px;
  }
  .actionColumn {
    max-width: 75px;
    width: 75px;
    text-align: right;
  }
`;

const ScoreRange = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  > strong {
    font-size: 32px;
    width: auto;
    > p {
      font-weight: normal;
      display: block;
      font-size: 16px;
      font-style: italic;
    }
  }
  > div {
    flex: 1;
    display: flex;
    flex-flow: column;
    gap: 10px;
    > strong {
      margin: auto;
      display: block;
      font-size: 18px;
    }
  }
  .overrideIcon {
    margin-right: 5px;
  }
`;

const ExpansionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  > div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .score_metric {
    display: flex;
    align-items: center;
    gap: 5px;
  }
`;
const StyledInput = styled(Input)`
  flex-grow: 0;
  > input {
    width: 55px;
    max-width: 55px;
    height: 25px;
    min-height: 25px;
  }
`;
const ScoreWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ToggleButton = ({ setShowDetails, showDetails, applicant }) => {
  const noteCount = Object.values(applicant?.notes || {})?.reduce(
    (totalCount, noteList) => (noteList?.length || 0) + totalCount,
    0
  );
  return (
    <ExpansionWrapper>
      <Button variant="link" className="no-print" onClick={() => setShowDetails(!showDetails)}>
        <Icon name={showDetails ? 'fa-duotone fa-chevrons-up' : 'fa-duotone fa-chevrons-down'} />
        &nbsp;&nbsp;&nbsp;{showDetails ? 'Hide' : 'View'} Details
      </Button>
      <div>
        {applicant?.qualityScore?.createdAt?.renderPretty && (
          <ToolTip text={`Last updated: ${applicant?.qualityScore?.createdAt?.renderLocale()}`}>
            <div className="score_metric">
              <Icon name="fa-duotone fa-clock" />
              {applicant?.qualityScore?.createdAt?.renderPretty(false, false)}
            </div>
          </ToolTip>
        )}
        <ToolTip text={`${noteCount} comment${noteCount !== 1 ? 's' : ''}`}>
          <div className="score_metric">
            <Icon name="Comments" />
            {noteCount}
          </div>
        </ToolTip>
      </div>
    </ExpansionWrapper>
  );
};

const ScoreSummary = ({
  applicant,
  overridePoints,
  setShowDetails,
  showDetails,
  canSeeDetails
}) => {
  return (
    <>
      <ScoreRange>
        <strong>
          <ScoreWrapper>
            {applicant.closed && (
              <ToolTip text="Scores are locked when an application is closed. A locked score will not update automatically and will only change if a custom question value is modified OR the application is re-opened.">
                <IconShape
                  color="#f1f3f6"
                  size={28}
                  name="fa-duotone fa-lock"
                  iconProps={{ color: '#71818F', size: 12 }}
                  radius="4px"
                />
              </ToolTip>
            )}
            <span>{applicant.qualityScore?.score}%</span>
          </ScoreWrapper>
          <p>
            <strong>
              {overridePoints ? (
                <Icon
                  name="fa-duotone fa-sliders-up overrideIcon"
                  title={`The point total has been manually adjusted by ${overridePoints} point${
                    overridePoints !== 1 ? 's' : ''
                  }`}
                />
              ) : (
                <></>
              )}
              {applicant.qualityScore?.points}
            </strong>{' '}
            of <strong>{applicant.qualityScore?.totalPoints}</strong> points
          </p>
        </strong>
        {applicant.qualityProfile?.scoreRanges?.length ? (
          <div>
            <div>
              <ProgressBar
                value={applicant.qualityScore?.score}
                segments={applicant.qualityProfile.scoreRanges.map((sr) => ({
                  color: sr.color,
                  count: sr.stop - sr.start + (sr.start !== 0 ? 1 : 0)
                }))}
              />
            </div>
            <strong>{applicant.qualityScore?.label}</strong>
          </div>
        ) : (
          <></>
        )}
      </ScoreRange>
      {canSeeDetails && (
        <ToggleButton
          setShowDetails={setShowDetails}
          showDetails={showDetails}
          applicant={applicant}
        />
      )}
    </>
  );
};

const PointWrapper = ({ rowData, qualityProfile }) => {
  if (['custom'].includes(rowData.ruleUuid)) return rowData.points;

  const condition = qualityProfile.conditions?.find((cond) => cond.ruleUuid === rowData.ruleUuid);

  if (!condition) return rowData.points;

  const matchingRange = condition?.rules?.find((rule) => rule.uuid === rowData.rangeUuid);

  let toolTip = '';
  if (!matchingRange) {
    toolTip = `Applicant has not provided the appropriate data or it is out of range.`;
  } else {
    const hasStart = ![undefined, null].includes(matchingRange.start);
    const hasEnd = ![undefined, null].includes(matchingRange.stop);
    const hasBoth = hasStart && hasEnd;

    switch (rowData.ruleUuid) {
      case 'avgMonthlyCashFlow':
        toolTip = `Applicant average monthly cash flow is ${
          hasStart ? `above ${matchingRange.start}` : ''
        }${hasBoth ? ' and is ' : ''}${hasEnd ? ` below ${matchingRange.stop}` : ''}`;
        break;
      case 'creditRating':
        toolTip = `Applicant's credit rating is ${
          hasStart
            ? `above a${matchingRange.start >= 4 ? 'n' : ''} ${
                SCORE_WEIGHT_LABELS[matchingRange.start] || matchingRange.start
              }`
            : ''
        }${hasBoth ? ' and ' : ''}${
          hasEnd
            ? `below a${matchingRange.stop >= 4 ? 'n' : ''} ${
                SCORE_WEIGHT_LABELS[matchingRange.stop] || matchingRange.stop
              }`
            : ''
        }`;
        break;
      case 'nsfCount':
        toolTip = `Applicant's nsf count is ${hasStart ? `above ${matchingRange.start}` : ''}${
          hasBoth ? ' and is ' : ''
        }${hasEnd ? ` below ${matchingRange.stop}` : ''}`;
        break;
      case 'employmentLength':
        toolTip = `Applicant has been employed for ${
          hasStart ? `greater than ${matchingRange.start} months` : ''
        }${hasBoth ? ' and ' : ''}${hasEnd ? ` less than ${matchingRange.stop} months` : ''}`;
        break;
      case 'payrollHistory':
        toolTip = `Applicant's payroll history is ${
          hasStart ? `greater than ${matchingRange.start} months` : ''
        }${hasBoth ? ' and ' : ''}${hasEnd ? ` less than ${matchingRange.stop} months` : ''}`;
        break;
      case 'incomeToRent':
        toolTip = `Applicant's rent to income is ${
          hasStart ? `above ${matchingRange.start}%` : ''
        }${hasBoth ? ' and is ' : ''}${hasEnd ? ` below ${matchingRange.stop}%` : ''}`;
        break;
      case 'debtToIncome':
        toolTip = `Applicant's debt to income is ${
          hasStart ? `above ${matchingRange.start}%` : ''
        }${hasBoth ? ' and is ' : ''}${hasEnd ? ` below ${matchingRange.stop}%` : ''}`;
        break;
      case 'criminal':
        toolTip = `Applicant ${
          matchingRange.condition ? 'does' : 'does not'
        } have a criminal match`;
        break;
      case 'eviction':
        toolTip = `Applicant ${
          matchingRange.condition ? 'does' : 'does not'
        } have an eviction match`;
        break;
      case 'appComplete':
        toolTip = `Applicant ${
          matchingRange.condition ? 'has' : 'has not'
        } completed their application`;
        break;
      case 'idVerification':
        toolTip = `Applicant ${
          matchingRange.condition ? 'has' : 'has not'
        } completed automatic ID verification`;
        break;
      case 'incomeVerification':
        toolTip = `Applicant ${
          matchingRange.condition ? 'has' : 'has not'
        } connected their bank for automatic income verification`;
        break;
      case 'rentPayments':
        toolTip = matchingRange.condition
          ? `Applicant has rent payments detected in their connected bank account`
          : 'Applicant either has not connected a bank account or no rent payments have been detected';
        break;
      case 'fraudulentDocs':
        toolTip = matchingRange.condition
          ? `Applicant has documents marked as potentially fraudulent`
          : 'Applicant has no documents marked as potentially fraudulent';
        break;
      case 'petScreening':
        toolTip = `Applicant ${
          matchingRange.condition ? 'has' : 'has not'
        } completed their PetScreening.com application`;
        break;
    }
  }

  return <ToolTip text={toolTip}>{(rowData.points || 0).toString()}</ToolTip>;
};

export default ({ className, applicant, handleActions, expandedSections, applicationConfig }) => {
  const canSeeDetails = !applicationConfig?.disabledSections?.qualityScoreDetails;
  const [showDetails, setShowDetails] = React.useState(canSeeDetails && expandedSections?.quality);
  const overridePoints = applicant?.qualityScore?.customQuestions?.override?.value || 0;
  if (!Number.isFinite(applicant.qualityScore?.score)) return <></>;

  const columns = [
    {
      label: 'Score Criteria',
      uuid: 'ruleUuid',
      className: 'capitalize criteriaColumn',
      render: (rowData) => {
        const crit = SCORING_CRITERIA[rowData.ruleUuid];
        if (!crit) return <>{rowData.ruleUuid}</>;

        return (
          <div>
            <ToolTip text={crit.description}>{crit.title}</ToolTip>
          </div>
        );
      }
    },
    {
      label: 'Points',
      uuid: 'points',
      className: 'pointColumn',
      render: (rowData) => (
        <PointWrapper qualityProfile={applicant.qualityProfile} rowData={rowData} />
      )
    },
    {
      label: 'Notes',
      uuid: 'notes',
      render: (rowData) => {
        return rowData.ruleUuid === 'custom' ? (
          <CustomQuestionNotes
            condition={rowData}
            applicant={applicant}
            handleActions={handleActions}
          />
        ) : (
          <NoteLists
            condition={rowData}
            applicant={applicant}
            handleActions={handleActions}
            showAddComment={true}
          />
        );
      }
    }
  ];

  if (!canSeeDetails || !showDetails) {
    return (
      <Wrapper>
        <BoxSection
          id="applicant-quality"
          className={className}
          handleActions={handleActions}
          title="Applicant Quality"
          titleIcon="fa-sharp fa-star-sharp">
          <ScoreSummary
            canSeeDetails={canSeeDetails}
            overridePoints={overridePoints}
            setShowDetails={setShowDetails}
            showDetails={showDetails}
            applicant={applicant}
          />
        </BoxSection>
      </Wrapper>
    );
  }

  const totalRow = {
    ruleUuid: (
      <div>
        <ToolTip text="Manual entry to adjust points by a specified amount">
          Manual Point Adjustment
        </ToolTip>
      </div>
    ),
    points: <div>{overridePoints || 0}</div>,
    notes: (
      <NoteLists
        condition={{ ruleUuid: 'override' }}
        applicant={applicant}
        handleActions={handleActions}
        showAddComment={true}
      />
    )
  };

  return (
    <Wrapper>
      <TableSection
        id="applicant-quality"
        childPlacement="pre"
        className={className}
        handleActions={handleActions}
        data={applicant?.qualityScore?.conditions}
        columns={columns}
        title="Applicant Quality"
        noDataMessage="No quality score details"
        titleIcon="fa-sharp fa-star-sharp"
        totalRow={totalRow}>
        <ScoreSummary
          overridePoints={overridePoints}
          setShowDetails={setShowDetails}
          showDetails={showDetails}
          applicant={applicant}
        />
      </TableSection>
    </Wrapper>
  );
};
