import React from 'react';
import styled from 'styled-components';
import Input from './input';
import Select from './select';
import Button from './button';
import Icon from './icon';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  .error-text {
    font-size: 0.81em;
    display: inline-block;
    color: red;
    margin-top: 5px;
  }
`;

const Row = styled.div`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0px;
  }
`;

const Group = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const defaultPhone = { number: '', type: 'mobile' };
export default ({
  name = '',
  typePicker = true,
  allowZero = false,
  value = defaultPhone,
  multi = true,
  onChange,
  errors: initialErrors = [],
  className
}) => {
  const { t } = useTranslation();
  const isFirstRender = React.useRef(true);
  const [phones, setPhones] = React.useState(
    [].concat(value).map((phn) => ({ ...phn, type: phn.type || 'mobile' }))
  );

  const handlePhoneChange = (event, index) => {
    const val = event.target.value;
    const key = event.target.name;

    setPhones((phones) =>
      phones.map((phone, i) => {
        if (index !== i) return phone;
        return { ...phone, [key]: val };
      })
    );
  };

  const handleRemove = (index) => setPhones(phones.filter((f, i) => i !== index));
  const handleAdd = () => setPhones([...phones, { number: '', type: 'mobile' }]);
  const errors = !multi && !Array.isArray(initialErrors) ? [initialErrors] : initialErrors;

  React.useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    onChange && onChange({ target: { value: multi ? phones : phones[0], name } });
  }, [phones]);

  return (
    <Wrapper className={className}>
      {_.isString(errors) && <span className="error-text">{errors}</span>}
      {phones.map((phone, i) => (
        <Row key={i}>
          <Group className="phoneInput">
            <Input
              name="number"
              icon="Calls-SMS"
              value={phone.number}
              onChange={(event) => handlePhoneChange(event, i)}
            />
            {typePicker && (
              <Select
                name="type"
                value={phone.type}
                onChange={(event) => handlePhoneChange(event, i)}>
                <option value="home">{t('form.addAnotherPhone.home')}</option>
                <option value="mobile">{t('form.addAnotherPhone.mobile')}</option>
                <option value="work">{t('form.addAnotherPhone.work')}</option>
              </Select>
            )}
            {multi && (allowZero || phones.length > 1) && (
              <Button variant="warning" onClick={() => handleRemove(i)}>
                {t('form.addAnotherPhone.delete')}
              </Button>
            )}
          </Group>
          {errors[i]?.number && <span className="error-text">{errors[i]?.number}</span>}
        </Row>
      ))}
      {multi && (
        <Row>
          <Button className="" variant="link" onClick={handleAdd}>
            <Icon name="Add" size={9} />
            &nbsp;{' '}
            {phones?.length > 0 ? t('form.addAnotherPhone.add') : t('form.addAnotherPhone.start')}
          </Button>
        </Row>
      )}
    </Wrapper>
  );
};
