export const SCORING_CRITERIA = {
  creditRating: {
    uuid: 'creditRating',
    title: 'Credit Rating',
    description: "Letter grade representation of an applicant's credit score",
    type: 'creditRating',
    prerequisite: { credit: true }
  },
  criminal: {
    uuid: 'criminal',
    title: 'Criminal',
    description: "Any results returned on the applicant's criminal record search.",
    type: 'boolean',
    prerequisite: { criminal: true }
  },
  eviction: {
    uuid: 'eviction',
    title: 'Eviction',
    description: "Any results returned on the applicant's criminal record search.",
    type: 'boolean',
    prerequisite: { eviction: true }
  },
  appComplete: {
    uuid: 'appComplete',
    title: 'App Completeness',
    description: 'The completeness of the required information on an application.',
    type: 'boolean'
  },
  idVerification: {
    uuid: 'idVerification',
    title: 'ID Verification',
    description: 'Applicant has verified their identity instantly electronically.',
    type: 'boolean',
    prerequisite: { idConnect: true }
  },
  incomeVerification: {
    uuid: 'incomeVerification',
    title: 'Income Verification',
    description: 'Applicant has verified their income instantly electronically.',
    type: 'boolean',
    prerequisite: { bankConnect: true }
  },
  incomeToRent: {
    uuid: 'incomeToRent',
    title: 'Rent-to-Income Ratio',
    description:
      "This is the percentage representing how much of a tenant's monthly income will be needed to pay the rent.",
    type: 'percent'
  },
  debtToIncome: {
    uuid: 'debtToIncome',
    title: 'Debt-to-Income Ratio',
    description: 'Compares the total amount owed every month to the total amount earned monthly.',
    type: 'percent',
    premium: true,
    prerequisite: { credit: true }
  },
  rentPayments: {
    uuid: 'rentPayments',
    title: 'Rent Payments',
    description:
      'Verified rent payments from past/current landlord from the instant income verification.',
    type: 'boolean',
    prerequisite: { bankConnect: true }
  },
  avgMonthlyCashFlow: {
    uuid: 'avgMonthlyCashFlow',
    title: 'Avg Monthly Cashflow',
    description:
      'The average monthly income detected from various sources in the instant income verification.',
    type: 'money',
    prerequisite: { bankConnect: true }
  },
  nsfCount: {
    uuid: 'nsfCount',
    title: 'Return Checks',
    description: ' The total detected NSF payments in the instant income verification.',
    type: 'number',
    prerequisite: { bankConnect: true }
  },
  employmentLength: {
    uuid: 'employmentLength',
    title: 'Length of Employment',
    description:
      'The total time in months that the applicant has been employed with their current employer.',
    type: 'number'
  },
  payrollHistory: {
    uuid: 'payrollHistory',
    title: 'Payroll History',
    description:
      'The count of months for detected employment income from the instant income verification.',
    type: 'number',
    prerequisite: { bankConnect: true }
  },
  petScreening: {
    uuid: 'petScreening',
    title: 'PetScreening',
    description:
      'Analyzes if an applicant has completed the PetScreening process and returned FIDO scores.',
    type: 'boolean',
    premium: true,
    prerequisite: { petScreening: true }
  },
  custom: {
    uuid: 'custom',
    title: 'Custom Questions',
    description:
      'Add a customizable question of your choice that needs to be answered for each applicant.',
    type: 'custom'
  },
  override: {
    uuid: 'override',
    title: 'Score Override',
    description: 'Manual override to score total',
    type: 'override',
    hidden: true
  },
  fraudulentDocs: {
    uuid: 'fraudulentDocs',
    title: 'Fraudulent Documents',
    description: 'Assign points based on whether any documents are potentially fraudulent.',
    type: 'boolean',
    prerequisite: { docFraud: true }
  }
};

export const isInRange = (startVal, stopVal, checkVal) => {
  if (Number.isFinite(Number(startVal)) && Number(startVal) > checkVal) return false;
  if (Number.isFinite(Number(stopVal)) && Number(stopVal) < checkVal) return false;
  return true;
};

export const getMatchingScoreRange = ({ score, scoreRanges }) => {
  return scoreRanges?.find((sr) => isInRange(sr.start, sr.stop, score));
};
