import React, { Fragment, useMemo } from 'react';
import styled from 'styled-components';
import ShadowRender from 'components/shadow-render';

const Wrapper = styled.div`
  padding-left: 7px;
  padding-right: 7px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  font-size: 12px;
  .no-comment {
    color: #777;
  }
`;

export default ({ comments = [] }) => {
  const { comment, displayVal } = useMemo(() => {
    const comment = comments?.[comments?.length - 1] || {};
    let actorName = '';

    const commentText = comment.content ? comment.content : '';
    const displayVal = `${actorName ? `${actorName}: ` : ''}${commentText}`;

    return { comment, displayVal };
  }, [comments?.length]);

  if (!comment) return <></>;

  return (
    <Fragment>
      <Wrapper>
        {comment.id ? (
          <>
            <ShadowRender html={displayVal} />
          </>
        ) : (
          <></>
        )}
      </Wrapper>
    </Fragment>
  );
};
