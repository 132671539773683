import Modal from 'components/modal';
import styled from 'styled-components';
import { useUiState } from 'state';
import Button from 'components/button';

const Content = styled.div`
  padding: 20px 15px;
  flex: 1;
  h2 {
    margin: 0px;
  }
`;

const Actions = styled.div`
  padding: 0px 15px 20px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
`;

export default () => {
  const { uiState = {}, updateUiState } = useUiState();
  const { confirmationDialog } = uiState;
  if (!confirmationDialog) return null;
  const handleConfirm = () => {
    updateUiState({ confirmationDialog: undefined });
    confirmationDialog?.onConfirm && confirmationDialog?.onConfirm();
  };

  const handleCancel = () => {
    updateUiState({ confirmationDialog: undefined });
    confirmationDialog?.onCancel && confirmationDialog?.onCancel();
  };

  return (
    <Modal
      open
      height="auto"
      width={confirmationDialog.width || '400px'}
      maxWidth={confirmationDialog.maxWidth}>
      <Content>
        <h2>{confirmationDialog.title}</h2>
        <p>{confirmationDialog.description}</p>
      </Content>
      <Actions>
        {!confirmationDialog.hideCancel && (
          <Button variant="secondary" onClick={handleCancel} width={80}>
            {confirmationDialog.cancelLabel || 'NO'}
          </Button>
        )}
        <Button variant="primary" onClick={handleConfirm} width={80}>
          {confirmationDialog.confirmLabel || 'YES'}
        </Button>
      </Actions>
    </Modal>
  );
};
