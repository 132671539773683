import React from 'react';
import styled from 'styled-components';
import ToolTip from 'components/tooltip';
import Section from './section';

const Wrapper = styled.div`
  font-size: 14px;
  .row {
    display: flex;
    align-items: center;
    padding: 4px 0;
    border-top: 1px solid #ddd;
    line-height: 1.6;
    .rowLabel {
      flex: 1;
      font-weight: bold;
    }
    .distance {
      color: #6a6a6a;
    }
  }
`;
const AttributionWrapper = styled.div`
  font-size: 0.9em;
  text-align: right;
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;
const SchoolItem = styled.div`
  ${({ onClick }) => (onClick ? 'cursor:pointer' : '')}
`;

export default ({ nearbySchools }) => {
  if (!nearbySchools?.length) {
    return <></>;
  }

  return (
    <Section
      label={
        <TitleWrapper>
          <strong>NEARBY SCHOOLS</strong>
          <a href="https://www.greatschools.org/" rel="nofollow noreferrer" target="_blank">
            <img src="/assets/great-schools/great-schools.png" width="95px" />
          </a>
        </TitleWrapper>
      }
      id="schools">
      <Wrapper>
        {nearbySchools.map((school, ix) => {
          const levels = school.level?.split(',') || [];
          const levelRange =
            levels?.length > 1 ? [levels[0], levels[levels.length - 1]].join('-') : levels[0];
          return (
            <SchoolItem
              key={ix}
              className="row"
              onClick={
                !school?.['overview-url']
                  ? undefined
                  : () => {
                      window.open(school?.['overview-url'], '_blank');
                      return false;
                    }
              }>
              <div className="rowLabel">
                {<ToolTip text={school['school-summary']}>{school.name}</ToolTip>}
              </div>
              <div className="distance">
                {levelRange ? `${levelRange} ` : ''}
                {Number(school.distance?.toFixed(2))}ml
              </div>
            </SchoolItem>
          );
        })}
      </Wrapper>
      <AttributionWrapper>
        Source:{' '}
        <a href="https://www.greatschools.org/" rel="nofollow noreferrer" target="_blank">
          GreatSchools.org
        </a>{' '}
        © 2023. All rights reserved.
      </AttributionWrapper>
    </Section>
  );
};
