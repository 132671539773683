import { Trans } from 'react-i18next';
import { STEP_STATES, STEP_STATUS } from 'helpers/enums';

export const isStepDone = (key, steps) =>
  [STEP_STATES.done, STEP_STATES.completed].includes(
    steps?.find((step) => step.key === key)?.status?.state || STEP_STATES.notStarted
  );

export const defaultStatus = () => ({
  label: <Trans i18nKey="buttonsActions.add" />,
  actionEnabled: true,
  state: STEP_STATES.notStarted
});

export const getActionStepStatus = ({ action, location, appStep, step }) => {
  const status = { ...defaultStatus() };
  if (step.getStatus) {
    return step.getStatus({ action, location, appStep, step });
  }

  if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
    status.label = <Trans i18nKey="buttonsActions.update" />;
    status.state = STEP_STATES.completed;
  }

  return status;
};
