import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: ${({ bgColor }) => bgColor};
`;

export default ({ html }) => {
  const [bgColor, setBgColor] = React.useState();
  React.useEffect(() => {
    const bg = document.getElementsByTagName('table')?.[0]?.style?.backgroundColor;
    bg && setBgColor(bg);
  }, []);

  return (
    <Wrapper bgColor={bgColor}>
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Wrapper>
  );
};
