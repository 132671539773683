import React from 'react';
import Modal, { ModalScrollable, ModalHeader } from 'components/modal';
import { Trans, useTranslation } from 'react-i18next';

export default ({ open = false, onClose }) => {
  const { t } = useTranslation();
  return !open ? null : (
    <Modal
      closeOnOutsideClick={true}
      open={open}
      onClose={() => {
        onClose && onClose();
      }}
      width="auto"
      height="auto">
      <ModalHeader>{t('pending_location.title')}</ModalHeader>
      <ModalScrollable>
        <Trans i18nKey="pending_location.description">
          Thanks for your interest! While this home is not off the market, there is another
          applicant in the final stages.
          <br />
          <br />
          Feel free to check out one of our other locations or contact us for any questions.
        </Trans>
      </ModalScrollable>
    </Modal>
  );
};
