import styled from 'styled-components';

const CheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  span {
    margin-left: 10px;
  }
  &.consentBox {
    font-size: 1.25em;
    > input {
      height: 20px;
      width: 20px;
    }
  }
`;

const Checkbox = ({ label, checked, onChange, className, readOnly }) => (
  <CheckboxWrapper className={className}>
    <input
      disabled={readOnly}
      type="checkbox"
      checked={checked}
      onChange={() => onChange && onChange(!checked)}
    />
    {label && <span>{label}</span>}
  </CheckboxWrapper>
);

export default Checkbox;
