import React from 'react';
import toast from 'react-hot-toast';
import { Routes, Route, useLocation } from 'react-router-dom';
import { UserContext, useNavigateWithContext } from 'state';
import { VALID_USER_SESSION } from 'dal/user';
import { REDEEM_CODE } from 'dal/applications';
import { useRefCode } from 'hooks/useSessionVariables';
import { getContinueUrl } from 'helpers/appVersions';
import Code from '../code';
import Applications from '../applications';
import Showings from '../showing/list';
import Showing from '../showing/detail';
import ShowingCreate from '../showing/detail/create';
import CreateApp from '../application-form-create';
import FormV2 from '../application-form-v2';
import LocationList from '../locations-list';
import LocationPreview from '../location';
import {
  LocationRoutes,
  ListingRoutes,
  LoginRoutes,
  ProfileRoutes,
  SupportRoutes
} from './commonRoutes';

const AUTHENTICATED_ROUTES = ['app'];
export default ({ updateChatClient }) => {
  const navigate = useNavigateWithContext();
  const refCode = useRefCode();
  const [initialRefHandled, setInitialRefHandled] = React.useState(false);

  const location = useLocation();
  const { userContext, bounceUser } = React.useContext(UserContext);
  //Need to bounce the user to login if they are missing a usercontext
  React.useEffect(() => {
    const pathTier = ((location?.pathname || '').split('/')[3] || '').toLowerCase();
    if (AUTHENTICATED_ROUTES.includes(pathTier)) {
      if (!VALID_USER_SESSION(userContext?.accessToken)) {
        bounceUser();
      }
    }
  }, [location]);

  //Need to bounce the user to login if they are missing a usercontext
  React.useEffect(() => {
    if (userContext?._id && !initialRefHandled && refCode) {
      setInitialRefHandled(true);
      REDEEM_CODE({ userContext, code: refCode })
        .then((result) => {
          if (result?.application?._id) {
            switch (true) {
              case !!result.application?.submitted &&
                !!result.application?.consent?.consented &&
                !!result.application?.paid &&
                !!result.application?.verified:
                navigate(`app/applications/${result?.application?._id}/summary`);
                break;
              case !!result.application?.step:
                navigate(getContinueUrl(result?.application));
                break;
            }
          }
        })
        .catch((err) => {
          toast.error(
            err.data?.message
              ? err.data?.message
              : err.message || 'Referral code is invalid or expired'
          );
        });
    }
  }, [userContext?._id]);

  return (
    <Routes>
      {LoginRoutes}
      {ProfileRoutes}
      {SupportRoutes}
      {LocationRoutes}
      {ListingRoutes}
      <Route path="app">
        <Route path="code">
          <Route index={true} element={<Code />} />
          <Route path=":codeValue" element={<Code />} />
        </Route>
        <Route path="applications">
          <Route index={true} element={<Applications />} />
          <Route
            path="view/:applicationId/*"
            element={<FormV2 updateChatClient={updateChatClient} />}
          />
          <Route
            path="form/v2/:applicationId/*"
            element={<FormV2 updateChatClient={updateChatClient} />}
          />
          <Route path="form/create" element={<CreateApp />} />
        </Route>
        <Route path="location-list">
          <Route index={true} element={<LocationList />} />
          <Route path=":locationId" element={<LocationPreview />} />
        </Route>
        <Route path="showing">
          <Route path="create" element={<ShowingCreate />} />
          <Route path=":showingId/*" element={<Showing />} />
          <Route index={true} element={<Showings />} />
        </Route>
      </Route>
    </Routes>
  );
};
