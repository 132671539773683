import React, { useCallback } from 'react';
import styled from 'styled-components';
import { AdvancedMarker } from '@vis.gl/react-google-maps';
import Icon from 'components/icon';

const Bubble = styled.div`
  padding: 10px 12px;
  background-color: #000;
  border-radius: 6px;
  color: #fff;
  font-weight: bold;
  &.active {
    background-color: #00a1ff;
  }
`;

export default ({ location, onClick, setMarkerRef, isActive, zoom }) => {
  const handleClick = !onClick ? undefined : useCallback(() => onClick(location, location._id), []);
  const ref = !setMarkerRef
    ? undefined
    : useCallback((marker) => setMarkerRef(marker, location._id), []);

  return (
    <AdvancedMarker
      position={{ lng: location.address.geopoint[0], lat: location.address.geopoint[1] }}
      ref={ref}
      onClick={handleClick}>
      {zoom < 10 ? (
        <Icon name="fa-solid fa-location-pin" size={22} />
      ) : (
        <Bubble className={isActive ? 'active' : ''}>
          {location?.displayInfo?.formattedPrice}
        </Bubble>
      )}
    </AdvancedMarker>
  );
};
