import { Trans } from 'react-i18next';

import { STEP_STATES, STEP_STATUS } from 'helpers/enums';
import { defaultStatus } from './common';

import YourInfo from 'pages/showing/components/personal-info';
import Identity from 'pages/showing/components/identity';
import TourInfo from 'pages/showing/components/tour-info';
import QualificationQuestions from 'pages/showing/components/qualification-questions';
import ActionFee from 'pages/showing/components/action-fee';

import Consent from 'pages/showing/components/consent';
import Success from 'pages/showing/components/success';

export default {
  personalInfo: ({ action }) => ({
    key: 'personalInfo',
    title: <Trans i18nKey="overviewStep.steps.personalInfo.title" />,
    description: <Trans i18nKey="overviewStep.steps.personalInfo.description" />,
    path: 'about-you',
    routes: [{ path: 'about-you', Component: YourInfo }]
    // decode: (action) => action.applicant,
    // encode: (payload) => ({ applicant: payload })
  }),
  identity: ({ action }) => ({
    key: 'identity',
    title: <Trans i18nKey="overviewStep.steps.identity.title" />,
    description: <Trans i18nKey="overviewStep.steps.identity.description" />,
    path: 'verify-identity',
    routes: [{ path: 'verify-identity', Component: Identity }],
    getStatus: ({ appStep, t }) => {
      const status = { ...defaultStatus(t) };
      if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
        status.state = STEP_STATES.done;
        status.label = <Trans i18nKey="buttonsActions.done" />;
        status.actionEnabled = false;
      }
      return status;
    }
  }),
  tourInfo: ({ action }) => ({
    key: 'tourInfo',
    title: <Trans i18nKey="overviewStep.steps.tourInfo.title">Tour Information</Trans>,
    description: (
      <Trans i18nKey="overviewStep.steps.tourInfo.description">
        Let us know when you are interested in touring our home
      </Trans>
    ),
    path: 'tour-info',
    routes: [{ path: 'tour-info', Component: TourInfo }]
  }),
  qualificationQuestions: ({ action }) => ({
    key: 'qualificationQuestions',
    title: (
      <Trans i18nKey="overviewStep.steps.qualificationQuestions.title">
        Qualification Questions
      </Trans>
    ),
    description: (
      <Trans i18nKey="overviewStep.steps.qualificationQuestions.description">
        Basic information to help us better qualify you for our home
      </Trans>
    ),
    path: 'qualification-questions',
    routes: [{ path: 'qualification-questions', Component: QualificationQuestions }]
  }),
  actionFee: ({ action }) => ({
    key: 'actionFee',
    title: <Trans i18nKey="overviewStep.steps.actionFee.title">Payment Info</Trans>,
    description: (
      <Trans i18nKey="overviewStep.steps.actionFee.description">
        Provide a valid credit card to secure your tour date/time.
      </Trans>
    ),
    disabled: !action?.chargeApplicants,
    path: 'fee-details',
    routes: [
      {
        path: 'fee-details',
        Component: ActionFee,
        nextPath: 'fee-success'
      }
    ],
    getStatus: ({ appStep, t }) => {
      const status = { ...defaultStatus(t), label: <Trans i18nKey="buttonsActions.pay" /> };
      if ([STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status)) {
        status.label = <Trans i18nKey="buttonsActions.paid" />;
        status.state = STEP_STATES.done;
        status.actionEnabled = false;
      }
      return status;
    }
  }),
  submit: ({ action }) => ({
    key: 'submit',
    title: <Trans i18nKey="overviewStep.steps.submit.title">Agree & Submit</Trans>,
    description: (
      <Trans i18nKey="overviewStep.steps.submit.description">
        Submit your tour request for review
      </Trans>
    ),
    path: 'consent-submit',
    routes: [
      {
        path: 'consent-submit',
        Component: Consent,
        nextPath: 'success'
      },
      {
        path: 'success',
        Component: Success
      }
    ],
    getStatus: ({ appStep, t }) => {
      const status = { ...defaultStatus(t) };
      switch (true) {
        case action?.submitted:
          status.state = STEP_STATES.done;
          status.subtext = !action.submittedAt?.toLocaleTimeString ? undefined : (
            <>
              <Trans i18nKey="buttonsActions.submitted">Submitted</Trans>:{' '}
              {action.submittedAt?.toLocaleDateString()} at{' '}
              {action.submittedAt?.toLocaleTimeString()}
            </>
          );
          status.className = 'submitted';
          status.label = <Trans i18nKey="buttonsActions.submitted">Submitted</Trans>;
          status.actionEnabled = false;
          break;
        case action?.approved:
          status.icon = 'fa-duotone fa-party-horn';
          status.state = STEP_STATES.done;
          status.className = 'approved';
          status.label = <Trans i18nKey="buttonsActions.approved">Approved</Trans>;
          break;
        case [STEP_STATUS.done, STEP_STATUS.skipped].includes(appStep?.status):
          status.state = STEP_STATES.done;
          status.label = <Trans i18nKey="buttonsActions.done" />;
          status.actionEnabled = false;
          break;
      }

      return status;
    }
  })
};
