import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';

const Wrapper = styled.div`
  padding: 12px 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 4fr 1fr 2fr;
  gap: 10px;
  padding: 10px 0px;
  &.header {
    font-weight: bold;
  }
`;

export default ({ questions }) => {
  return (
    <Wrapper>
      <Row className="header">
        <div>Question</div>
        <div>Response</div>
        <div>Comments</div>
      </Row>
      {questions?.map((q, ix) => (
        <Row key={ix} className="avoidBreak">
          <div className="avoidBreak">{q.question}</div>
          <div>
            {q.type === 'text' ? (
              ''
            ) : (
              <>
                <Icon
                  name={q.answer?.value ? 'fa-solid fa-hand' : 'fa-duotone fa-circle-check'}
                  color={q.answer?.value ? 'red' : 'green'}
                />{' '}
                {q.answer?.value ? 'Yes' : 'No'}
              </>
            )}
          </div>
          <div>{q.answer?.notes || ''}</div>
        </Row>
      ))}
    </Wrapper>
  );
};
