import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';

const Wrapper = styled.div`
  border: 1px solid #ddd;
`;

const Header = styled.div`
  height: 60px;
  padding: 0 20px;
  display: flex;
  gap: 10px;
  align-items: center;
  .title {
    flex: 1;
    font-weight: bold;
  }
`;

const Content = styled.div`
  padding: 20px;
  padding-top: 0;
`;

const ICON_MAP = {
  success: { color: 'green', name: 'fa-duotone fa-circle-check' },
  pending: { color: 'gray', name: 'fa-duotone fa-circle' },
  failed: { color: 'red', name: 'fa-duotone fa-circle-x' }
};

export default ({ className, children, title, text, icon = 'success' }) => {
  return (
    <Wrapper className={className}>
      <Header className="sectionHeader">
        {ICON_MAP[icon] && <Icon {...ICON_MAP[icon]} />}
        <div className="title">{title}</div>
        <div className="text">{text}</div>
      </Header>
      <Content className="sectionContent">{children}</Content>
    </Wrapper>
  );
};
