import React from 'react';
import styled from 'styled-components';
import Header from './header';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: #eff8fd;
  @media (max-width: 600px) {
    font-size: 0.94em;
  }
`;

const Body = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`;

const Routes = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export default ({ children }) => {
  return (
    <Wrapper>
      <Header />
      <Body>
        <Routes>{children}</Routes>
      </Body>
    </Wrapper>
  );
};
