import React from 'react';
import styled from 'styled-components';
import Icon from 'components/icon';
import MultiRangeSlider from 'components/multiRangeSlider';

const SliderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isMobile }) =>
    isMobile
      ? ` width: auto !important;  margin-left: 0px;  margin-top: 15px; justify-content: start;`
      : 'width: 200px !important;  margin-left: 20px; justify-content: center;'}
  margin-right: 15px;
  gap: 5px;
  .MuiSlider-root {
    width: 80% !important;
    font-size: 0.63em;
    margin-left: 10px;
  }
`;

const sliderPrice = ({ changePrice, className, isMobile }) => {
  return (
    <SliderBox isMobile={isMobile} className={className}>
      <div>
        <Icon name="fa-duotone fa-filter-circle-dollar" size={18} />
      </div>
      <MultiRangeSlider min={0} max={15000} onChange={({ min, max }) => changePrice(min, max)} />
    </SliderBox>
  );
};

export default sliderPrice;
