import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export default ({ block }) => {
  const style = {
    borderRadius: block.borderRadius,
    backgroundColor: block.backgroundColor,
    borderWidth: block.borderWidth,
    borderColor: block.borderColor,
    borderStyle: 'solid'
  };
  return <Wrapper style={style} radius={block.borderRadius} />;
};
