import React from 'react';
import _ from 'lodash';
import useBackToOverview from 'hooks/useBackToOverview';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigateWithContext } from 'state';
import { useAPIData } from 'dal/useAPIData';
import {
  UPDATE_APPLICATION,
  SUBMIT_APPLICATION,
  MANUAL_VERIFICATION,
  REDEEM_CODE
} from 'dal/applications';
import APPLICATION_STEPS from './APPLICATION_STEPS';
import { UserContext, CompanyContext } from 'state';
import toast from 'react-hot-toast';

export const useApplication = ({ updateChatClient }) => {
  const [refreshKey, setRefreshKey] = React.useState(new Date().getTime());
  const backToOverview = useBackToOverview({
    onRefresh: () => setRefreshKey(new Date().getTime())
  });
  const params = useParams();
  const { t } = useTranslation();
  const { userContext } = React.useContext(UserContext);
  const { companyContext } = React.useContext(CompanyContext);
  const navigate = useNavigateWithContext();
  const [applicationContext, setApplicationContext] = React.useState();

  const [appFetchContext, fetchInProgress, dataFetched, failedFetch] = useAPIData(
    'application.LOAD_APPLICATION',
    { userContext, applicationId: params.applicationId },
    {
      reFetchWhen: [params.applicationId, refreshKey],
      onSuccess: (val) => {
        if (!val?.status) {
          navigate('app/applications');
          return;
        }
        setApplicationContext(val.application);
        updateChatClient && updateChatClient({ app: val.application });
      },
      onError: (err) => {
        console.error(err);
        navigate('app/applications');
      }
    }
  );

  const [applicationLocationContext] = useAPIData(
    'context.LOAD_CONTEXT',
    !applicationContext?.locationId
      ? undefined
      : { contextId: applicationContext?.locationId, bypass: true },
    {
      reFetchWhen: [applicationContext?.locationId],
      disableEmptyArgs: true,
      onSuccess: (val) => {
        updateChatClient && updateChatClient({ location: val });
      }
    }
  );

  const saveApplicationPart = (contextChanges = {}, options = {}) => {
    const data = { ...contextChanges };
    return UPDATE_APPLICATION({
      userContext,
      applicationId: params.applicationId,
      data,
      options
    })
      .then((newData) => {
        if (newData?.status && newData?.application) {
          setApplicationContext(newData?.application);
        }
        return newData;
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
        throw err;
      });
  };

  const steps = APPLICATION_STEPS({
    translate: t,
    application: applicationContext,
    location: applicationLocationContext,
    companyContext
  });

  const routes = steps.reduce((final, z) => {
    return final.concat(
      z.routes.map((route) => Object.assign(route, { section: z.key, sectionTitle: z.title }))
    );
  }, []);
  const routeMap = routes.reduce(
    (finalMap, rte) => Object.assign(finalMap, { [rte.path]: rte }),
    {}
  );

  const submitApplication = (data = {}) => {
    return SUBMIT_APPLICATION({
      userContext,
      locationId: applicationContext.locationId,
      applicationId: params.applicationId,
      data
    }).catch((err) => {
      console.error({ errData: err.data, err });
      toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      return false;
    });
  };
  const provideCouponCode = (couponCode) => {
    return REDEEM_CODE({
      userContext,
      code: couponCode,
      data: { applicationId: params.applicationId }
    })
      .then((newData) => {
        if (!newData.status) {
          return toast.error('Missing required data');
        }
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
      });
  };
  const manualVerification = ({ type }) => {
    return MANUAL_VERIFICATION({
      userContext,
      locationId: applicationContext.locationId,
      applicationId: applicationContext._id,
      data: { type }
    })
      .then((result) => {
        if (!result.status) {
          toast.error('Missing required data');
        }
        return true;
      })
      .catch((err) => {
        console.error({ errData: err.data, err });
        toast.error(err.data?.message ? err.data?.message : err.message || err.name);
        return false;
      });
  };

  const nextStep = React.useMemo(() => {
    if (!applicationContext) return null;
    return steps.filter((s) => !s?.status?.hidden && !s.status?.locked).pop();
  }, [steps]);

  return {
    onRefresh: () => setRefreshKey(new Date().getTime()),
    applicationLocked:
      applicationLocationContext?.companyInfo?.applicationLock === 'onClose' &&
      !!applicationContext.submitted &&
      !!applicationContext?.completed,
    nextStep,
    backToOverview,
    dataFetched,
    routes,
    routeMap,
    steps,
    application: applicationContext,
    applicationLocationContext,
    saveApplicationPart,
    submitApplication,
    provideCouponCode,
    manualVerification,
    navigate,
    navigateToReview: () => navigate(`app/applications/form/v2/${params.applicationId}/summary`),
    navigateRoute: (path) => navigate(`app/applications/form/v2/${applicationContext._id}/${path}`)
  };
};
