import React from 'react';
import styled from 'styled-components';
import SkeletonShape from 'components/skeleton-shape';

const Wrapper = styled.div`
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: none;
    margin: none;
    padding: none;
  }
  span {
    position: absolute;
    top: 50%;
    left: 50%;
  }
`;

const Loader = styled(SkeletonShape)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 0px;
`;

export default ({ url, fallback, lazy, ...props }) => {
  const [loading, setLoading] = React.useState(true);
  return (
    <Wrapper {...props} className={`smartImage ${props.className || ''}`}>
      <img
        src={!url ? fallback : url}
        onLoad={() => {
          setLoading(false);
        }}
        onError={(event) => {
          setLoading(false);
          event.target.src = fallback;
        }}
        alt="preview"
        loading="lazy"
      />
      {loading && <Loader background="#ddd" />}
    </Wrapper>
  );
};
