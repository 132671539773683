import React from 'react';
import { Row, Title, PageContainer, PageWrapper } from './common-styles';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';

export default ({ application, onNext }) => {
  const { t } = useTranslation();
  // if you've paid you shouldn't be here!
  React.useEffect(() => {
    if (!application?.chargeApplicants) {
      onNext();
    }
  }, [application?.chargeApplicants]);

  return (
    <PageWrapper>
      <PageContainer>
        <Row className="center text-center">
          <Title>{t('appFreeSuccess.title')}</Title>
        </Row>
        <Row className="center" spacing={40}>
          <img width={250} src="/assets/credit_card_confirmed.png" alt="" />
        </Row>
        <Row className="center">
          <Button radius="25px" variant="primary" width={80} onClick={onNext}>
            {t('buttonsActions.next')}
          </Button>
        </Row>
      </PageContainer>
    </PageWrapper>
  );
};
